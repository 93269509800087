import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCourseByCategory, getCourseById } from "../../utils/helper";
import ScrollToTop from "../../components/ScrollToTop";
// import HeaderComponent from "../../components/model/HeaderComonent";
// import BookCourseForm from "../../components/form/BookCourseForm";
import { Select } from "antd";
import { SearchButton } from "../../components/Button/SearchButton";
import { useTranslation } from "react-i18next";
// const { Search } = Input;

const CourseDetails = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<any>("");
  const [relatedCourse, setRelatedCourse] = useState<any>();
  const navigate = useNavigate();
  const { courseId, catName } = useParams();

  useEffect(() => {
    const fetchedCourse = getCourseById(courseId);
    setSelectedCourse(fetchedCourse);
  }, [courseId]);

  useEffect(() => {
    const filrtedCourse = getCourseByCategory(selectedCourse?.category);
    setRelatedCourse(filrtedCourse);
  }, [selectedCourse?.category]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const shuffledCourses = relatedCourse?.sort(() => Math?.random() - 0.5);

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e: any) => {
    const query = e?.target?.value;
    setSearchQuery(query);

    // const results = Courses.filter((course) =>
    //     course.title.toLowerCase().includes(query.toLowerCase())
    // );
    // setSearchResults(results);
  };

  const handleNavigate = (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    navigate(`/programme/${values.category}/${values?.id}`);
    <ScrollToTop />;
  };

  return (
    <>
      <ScrollToTop />
      <main className="pt-36 pb-16 lg:pt-16 lg:pb-24 xbg-[#e4e4e4] bg-white text-gray-900 antialiased">
        <div className="flex justify-between px-2 mx-auto max-w-screen-xl">
          <article className="mx-auto w-full max-w-[1100px]  format format-sm sm:format-base lg:format-lg format-blue format-invert">
            <div className="bg-[#f8fafa] p-4">
              <figure>
                <div className="hidden w-full px-2 xbg-[#e1e8f1] rounded py-2 mb-8 shadow-inner mt-32 md:flex items-center space-x-6">
                  <Select
                    style={{ width: 600, height: 40 }}
                    //   onChange={handleChange}
                    options={[]}
                  />
                  <div>
                    <SearchButton
                      handleChange={handleSearchChange}
                      searchQuery={searchQuery}
                    />
                  </div>
                </div>
              </figure>
              <div className="">
                <h1 className="text-left text-sm md:text-2xl text-[#003d8c] font-semibold font-[arial] md:font-bold">
                  {t(selectedCourse?.title)}
                </h1>
                <p className="text-sm font-sans md:text-lg pt-4">
                  {t(selectedCourse?.desc)}
                </p>
              </div>

              <div>
                <h1 className="font-semibold md:font-bold mb-6 mt-10 text-sm md:text-lg">
                  {t("CourseInformation")}
                </h1>
                <div className="flex items-center justify-between lg:w-3/5">
                  <div>
                    <h1 className="text-xs text-[#003d8c] font-semibold font-[arial] md:font-bold pb-2">
                      {t("VENUE")}
                    </h1>
                    <ul className="list-none">
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {t("In-House")}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          Dubai, UAE
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          Doha, Qatar
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          Nairobi, Kenya
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          Kigali, Rwanda
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h1 className="text-xs text-[#003d8c] font-semibold font-[arial] md:font-bold pb-2">
                      {t("DURATION")}
                    </h1>
                    <ul className="list-none">
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {t("Agreedwithclient")}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          5 {t("Days")}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          5 {t("Days")}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          5 {t("Days")}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          5 {t("Days")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h1 className="text-xs text-[#003d8c] font-semibold font-[arial] md:font-bold pb-2">
                      {t("DATE")}
                    </h1>
                    <ul className="list-none">
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {t("Flexible")}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {t("Flexible")}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {t("Flexible")}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {t("Flexible")}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {t("Flexible")}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h1 className="text-xs text-[#003d8c] font-semibold font-[arial] md:font-bold pb-2">
                      {t("PRICE")}
                    </h1>
                    <ul className="list-none">
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {t("Negotiable")}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {`$${selectedCourse?.courseFess?.toLocaleString()}`}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {`$${selectedCourse?.courseFess?.toLocaleString()}`}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {`$${selectedCourse?.courseFess?.toLocaleString()}`}
                        </span>
                      </li>
                      <li>
                        <span className="text-xs font-sans md:text-sm">
                          {" "}
                          {`$${selectedCourse?.courseFess?.toLocaleString()}`}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                {/* <div className="my-4 ">
                  <p>
                    If you are unable to attend this course on the dates above,
                    please{" "}
                    <Link to={"/contact"}>
                      {" "}
                      <span className="font-semibold underline">
                        Contact us
                      </span>{" "}
                    </Link>{" "}
                    to discuss alternative options.
                  </p>
                </div> */}
                <button
                  type="button"
                  className="text-white bg-[#003d88] w-full md:w-44 md:text-center rounded-md focus:outline-none font-bold text-sm px-5 py-2.5 text-left me-2 mb-2"
                  onClick={() =>
                    navigate(`/programme/${catName}/${courseId}/register`)
                  }
                >
                  {t("RegisterNow")}
                </button>
                <div className="my-4 ">
                  <p>
                    {t("youcanfillinourContact1")}{" "}
                    <Link to={"/contact"}>
                      {" "}
                      <span className="font-semibold underline">
                        {t("contact")}
                      </span>{" "}
                    </Link>{" "}
                    {t("youcanfillinourContact2")}
                  </p>
                </div>
                <button
                  type="button"
                  className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                  onClick={() => navigate("/contact")}
                >
                  {t("contact")}
                </button>
              </div>
            </div>
            <div className="bg-[#f8fafa] p-4 mt-6">
              <div>
                <h1 className="font-semibold md:font-bold mb-6 text-[#003d8c]">
                  {t("whoShouldAttend")}
                </h1>
                <ul className="list-disc pl-6">
                  {selectedCourse?.WhoShouldAttend?.map((d: any) => (
                    <li>
                      <span>{t(d)}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h1 className="font-semibold md:font-bold my-6">
                  {t("Byendcoursedelegates")}
                </h1>
                <ul className="list-disc pl-6">
                  {selectedCourse?.courseExpectation?.map((d: any) => (
                    <li>
                      <span>{t(d)}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="bg-[#f8fafa] p-4 my-6">
              <h1 className="font-semibold md:font-bold mb-6">
                {t("LearningObjectives")}
              </h1>
              {selectedCourse?.courseContent?.map((d: any) => (
                <div>
                  <h2 className="font-medium md:font-semibold mt-6 mb-4 text-[#003d8c]">
                    {t(d?.title)}
                  </h2>
                  <ul className="list-disc pl-6">
                    {d?.contents?.map((el: any) => (
                      <li>{t(el)}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="mt-10">
              {/* <div className="my-4 ">
                <p>
                  If you are unable to attend this course on the dates above,
                  please{" "}
                  <Link to={"/contact"}>
                    {" "}
                    <span className="font-semibold underline">
                      Contact us
                    </span>{" "}
                  </Link>{" "}
                  to discuss alternative options.
                </p>
              </div> */}
              <button
                type="button"
                className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                onClick={() =>
                  navigate(`/programme/${catName}/${courseId}/register`)
                }
              >
                {t("RegisterNow")}
              </button>
            </div>
          </article>
        </div>
      </main>

      <aside
        aria-label="Related articles"
        className="py-8 lg:py-24 bg-gray-100 text-gray-900 pl-6 md:pl-0"
      >
        <div className=" pl-6 md:pl-0 md:px-4 mx-auto max-w-[1100px] ">
          <h2 className="mb-8 text-lg text-[#003d8c]">
            {t("YOUMIGHTINTERESTED")}
          </h2>
          <div className="grid gap-6 md:gap-12 sm:grid-cols-2 lg:grid-cols-4">
            {shuffledCourses?.slice(0, 4)?.map((el: any, index: any) => (
              <article
                className="max-w-xs flex flex-col md:flex-row md:space-x-2 cursor-pointer"
                key={index}
                onClick={() => handleNavigate(el)}
              >
                <img
                  src={el?.image}
                  className="mb-5 rounded-lg w-28 h-28 object-cover rounded-r-3xl rounded-bl-3xl"
                  alt="Image1"
                />
                <div>
                  <p className="text-gray-600 ">{t(el.category)}</p>
                  <h2 className="mt-1.5 pb-3 text-sm font-medium leading-tight text-gray-900">
                    {t(el?.title)}
                  </h2>
                  <p className="mb-4 text-gray-600 font-bold">{`$${el?.courseFess?.toLocaleString()}`}</p>
                </div>
              </article>
            ))}
          </div>
        </div>
      </aside>
    </>
  );
};

export default CourseDetails;
