import { workshopDetails } from "../../assets/workshop/Country";
import WorkshopBanner from "../../assets/images/workShopPreview.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WorkshopDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-full max-w-[1100px] md:mx-auto md:p-4 text-gray-700 py-16 lg:py-32">
      <div className="flex items-center justify-center flex-col mt-20">
        <img src={WorkshopBanner} alt="" />
      </div>
      <div className="py-6 px-4 md:px-0">
        <h1 className="text-lg md:text-4xl font-extrabold pb-4">
          {t("WhoIs")} Edgar Ogao?
        </h1>
        <p>
          <span className="font-medium">Edgar Ogao</span>{" "}
          {t("edgarOgaoSummary1")}
          <br />
          <br />
        </p>
        <p>
          {t("edgarOgaoSummary2")}
          <br /> {t("edgarOgaoSummary3")}
        </p>
      </div>

      <div className="px-4 md:px-0">
        <h1 className="text-lg md:text-4xl font-extrabold pb-4">
          {t("WhoIs")} Isaiah M. Gichana?
        </h1>
        <p>
          <span className="font-medium">CPA and ACCA Isaiah M. Gichana</span>{" "}
          {t("isaiahGichanaSummary1")} <br /> <br />{" "}
          {t("isaiahGichanaSummary2")}
        </p>
      </div>

      <div className="pt-6 pb-10 px-4 md:px-0">
        <h1 className="text-lg md:text-4xl font-extrabold pb-4">
          {t("WhoIs")} Mr. Evans M.Nyarwari?
        </h1>
        <p>
          <span className="font-medium">Mr. Evans M.Nyarwari</span>{" "}
          {t("evansNyarwariSummary1")}
        </p>
      </div>

      <div className="pb-4 flex flex-col space-y-6">
        <div>
          <h1 className="text-lg md:text-2xl font-medium pb-2 pl-4">
            {t("whoShouldAttend")}
          </h1>
          <p>
            <ul className="list-decimal pl-8">
              <span>{t("whoShouldAttendDesc")}</span>
            </ul>
          </p>
        </div>
      </div>

      <div className="pb-4 flex flex-col space-y-6">
        <h2 className="text-lg md:text-2xl font-semibold pl-4">
          {t("objectives")}{" "}
        </h2>
        {workshopDetails?.map((el: any) => (
          <div>
            <h1 className="text-lg md:text-2xl font-medium pb-2 pl-4">
              {t(el?.title)}
            </h1>
            <p>
              <ul className="list-decimal pl-8">
                {el?.content?.map((d: any) => (
                  <li>{t(d)}</li>
                ))}
              </ul>
            </p>
          </div>
        ))}
      </div>

      <button
        type="button"
        className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ml-6 md:ml-0"
        onClick={() => navigate(`/workshop/finance/register`)}
      >
        {t("registerNow")}
      </button>
    </div>
  );
};

export default WorkshopDetails;
