import DubaiFlag from "../images/dubai.jpg";
import RwandaFlag from "../images/rwanda.jpg";
import QatarFlag from "../images/qatar.webp";
import KenyaFlag from "../images/kenya.webp";
import DubaiHotel from "../images/dubaihotel.jpg";
import QuatarHotel from "../images/qatarhotel.jpg";
import KenyaHotel from "../images/kenyahotel.jpg";
import KigaliHotel from "../images/rwandahotel.jpg";

export const data = [
  {
    label: "Dubai",
    country: "dubai",
    value: 0,
    icon: DubaiFlag,
    desc: {
      image: DubaiHotel,
      location: "Dubai, UAE",
      date: [
        { from: "26- 03-2024", to: "30-03-2024" },
        { from: "24- 04-2024", to: "28-04-2024" },
        { from: "03- 05-2024", to: "07-05-2024" },
        { from: "21- 05-2024", to: "25-05-2024" },
        { from: "07- 06-2024", to: "11-06-2024" },
        { from: "18- 06-2024", to: "22-06-2024" },
        { from: "04- 07-2024", to: "08-07-2024" },
        { from: "10- 07-2024", to: "14-07-2024" },
        { from: "24- 07-2024", to: "28-07-2024" },
        { from: "07- 08-2024", to: "11-08-2024" },
        { from: "21- 08-2024", to: "25-08-2024" },
      ],
    },
  },
  {
    label: "Qatar",
    country: "qatar",
    value: 1,
    icon: QatarFlag,
    desc: {
      image: QuatarHotel,
      location: "Doha, QATAR",
      date: [
        { from: "24-08-2024", to: "28-08-2024" },
        { from: "02-09-2024", to: "06-09-2024" },
        { from: "10-09-2024", to: "14-09-2024" },
        { from: "16-09-2024", to: "20-09-2024" },
        { from: "22-09-2024", to: "26-09-2024" },
        { from: "01-10-2024", to: "05-10-2024" },
        { from: "08-10-2024", to: "12-10-2024" },
        { from: "14-10-2024", to: "18-10-2024" },
        { from: "20-10-2024", to: "24-10-2024" },
        { from: "26-10-2024", to: "30-10-2024" },
        { from: "02-11-2024", to: "06-11-2024" },
      ],
    },
  },
  {
    label: "Kenya",
    country: "kenya",
    value: 2,
    icon: KenyaFlag,
    desc: {
      image: KenyaHotel,
      location: "Nairobi, KENYA",
      date: [
        { from: "09-11-2024", to: "13-11-2024" },
        { from: "15-11-2024", to: "19-11-2024" },
        { from: "21-11-2024", to: "25-11-2024" },
        { from: "27-11-2024", to: "01-12-2024" },
        { from: "04-12-2024", to: "08-12-2024" },
        { from: "15-12-2024", to: "19-12-2024" },
        { from: "20-12-2024", to: "24-12-2024" },
      ],
    },
  },
  {
    label: "Rwanda",
    country: "rwanda",
    value: 3,
    icon: RwandaFlag,
    desc: {
      image: KigaliHotel,
      location: "Kigali, RWANDA",
      date: [
        { from: "05-01-2025", to: "09-01-2025" },
        { from: "11-01-2025", to: "13-01-2025" },
        { from: "15-01-2025", to: "19-01-2025" },
        { from: "21-01-2025", to: "25-01-2025" },
      ],
    },
  },
];

export const experience = [
  "Airtel",
  "KCB",
  "GT Bank Kigali",
  "Bank of Kigali",
  "Sonarwa",
  "Britam",
  "UAP",
  "OikoCredit",
  "Horizon Group",
  "Akagera Business Group",
  "Mjengo",
  "Rwanda Private Sector Federation",
  "Business Partners International",
  "World Bank",
  "Chemi Cotex",
  "UNDP",
  "BBOXX International",
  "Techno serve",
  "Girlhub",
  "Whitefield's Rwanda",
];

export const workshopSummary = [
  {
    title: "title1",
    content: [
      "title1Desc1",
      "title1Desc2",
      "title1Desc3",
      "title1Desc4",
      "title1Desc5",
    ],
  },
];

export const workshopDetails = [
  {
    title: "title1",
    content: [
      "title1Desc1",
      "title1Desc2",
      "title1Desc3",
      "title1Desc4",
      "title1Desc5",
    ],
  },
  {
    title: "title2",
    content: [
      "title2Desc1",
      "title2Desc2",
      "title1Desc3",
      "title2Desc3",
      "title2Desc4",
      "title2Desc5",
      "title2Desc6",
      "title2Desc7",
      "title2Desc8",
      "title2Desc9",
      "title2Desc10",
      "title2Desc11",
      "title2Desc12",
      "title2Desc13",
      "title2Desc14",
    ],
  },
  {
    title: "title3",
    content: [
      "title2Desc7",
      "title2Desc8",
      "title2Desc9",
      "title2Desc10",
      "title2Desc11",
      "title2Desc12",
      "title2Desc13 ",
      "title2Desc14",
    ],
  },
  {
    title: "title4",
    content: ["title4Desc1", "title4Desc2", "title4Desc3"],
  },
  {
    title: "title5",
    content: [
      "title5Desc1",
      "title5Desc2",
      "title5Desc3",
      "title5Desc4",
      "title5Desc5",
      "title5Desc6",
      "title5Desc7",
    ],
  },
  {
    title: "title6",
    content: [
      "title6Desc1",
      "title6Desc2",
      "title6Desc3",
      "title6Desc4",
      "title6Desc5",
      "title6Desc6",
      "title6Desc7",
      "title6Desc8",
      "title6Desc9",
      "title6Desc10",
      "title6Desc11",
    ],
  },
  {
    title: "title7",
    content: ["title7Desc1", "title7Desc2"],
  },
];
