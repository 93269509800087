import React from "react";
import CourseCard from "../components/cards/CourseCard";
// import TestimonialCard from "../components/cards/TestimonialCard";
import CompanyDescription from "../components/CompanyDescription";
import WorkshopDetails from "../components/cards/Workshop";
import Slider from "../components/sliders/Slider";
import WelcomePage from "./WelcomePage";
import { CourseByAllocation } from "../assets/course/courseByCategory";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-white text-gray-600">
        <div className="relative h-[80vh] lg:h-screen mx-auto">
          <Slider />
        </div>
        <div className="w-full max-w-[1100px] md:mx-auto md:p-4">
          <CompanyDescription />
          <div className="md:mx-4 mt-6 mb-20 flex flex-col">
            <WorkshopDetails />
          </div>
          <div className="mt-20 mb-2 flex flex-col">
            <h2 className="mt-6 px-4 pb-20 text-xl md:text-3xl text-gray-800 font-medium text-center">
              {t("discoverRangeTrainingCourses")}
            </h2>
            <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-16">
              {CourseByAllocation?.map((d: any) => (
                <CourseCard data={d} />
              ))}
            </div>
          </div>
          <WelcomePage />
          {/* <TestimonialCard /> */}
        </div>
      </div>
    </>
  );
};

export default HomePage;
