import { useDispatch, useSelector } from "react-redux";
import DelegateForm from "./DelegateForm";
import OrganisationForm from "./OrganisationForm";
import {
  getOrganisationDataAction,
  hasOrganisationAction,
  hasUserDataAction,
} from "../../../store/layout/actions";
import { useEffect, useState } from "react";
import { Button, Checkbox, Divider, Modal, notification } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { getCourseById, handlerSelectedCountry } from "../../../utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import {
  IconAddressBook,
  IconEmailArrowRightOutline,
  IconOrganisation,
  IconPersonCheckFill,
  IconRotaryinternational,
  IconSubtitlesOutline,
  IconTelephoneForward,
  IconVisaLine,
} from "../../../assets/IconsSvg";
import { bookCourseAction } from "../../../store/course/actions";
import { myLayoutActions } from "../../../store/layout";
import { bookWorkshopAction } from "../../../store/workshop/actions";
import PaymentProcess from "../../../assets/photo/paymentProccess.png";
import "react-phone-input-2/lib/style.css";
import UploadDocs from "../../upload/UploadDocs";
import BookWorkshopForm from "../BookWorkshopForm";
import { useTranslation } from "react-i18next";

const ApplyCourseForm = (props: any) => {
  const { t } = useTranslation();
  const { layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [selectedCourse, setSelectedCourse] = useState<any>("");
  const [imageUploades, setImmageUploaded] = useState([]);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [allowToEditDelegate, setAllowToEditDelegate] = useState(false);
  const [allowToEditSelectedWorkshop, setAllowToEditSelectedWorkshop] =
    useState(false);
  const [allowToEditOrganisation, setAllowToEditOrganisation] = useState(false);
  const [localStorageUpdate, setLocalStorageUpdate] = useState(0);

  const submissionKey = "courseData";

  const handleFormUpdate = () => {
    setLocalStorageUpdate((prev) => prev + 1);
  };

  useEffect(() => {
    const existingData = localStorage.getItem(submissionKey);
    if (existingData) {
      const parsedData = JSON.parse(existingData);
      getOrganisationDataAction(parsedData)(dispatch);
    }
  }, [localStorageUpdate, dispatch]);

  useEffect(() => {
    const fetchedCourse = getCourseById(courseId);
    setSelectedCourse(fetchedCourse);
  }, [courseId]);

  const handleSubmitCourse = async () => {
    if (imageUploades?.length < 1) {
      return notification.error({ message: "Please Upload Proof of Payemnt" });
    }
    if (props?.route === "course") {
      await bookCourseAction({
        name: selectedCourse?.title,
        // numberOfParticipant: formData?.userInfo?.numberOfParticipant,
        userInfo: {
          names: `${layout?.organisation?.personalDetails?.title} ${layout?.organisation?.personalDetails?.delegateName}`,
          email: layout?.organisation?.personalDetails?.delegateEmail,
          phone: layout?.organisation?.personalDetails?.phone,
          address: layout?.organisation?.organisationDetails?.companyAddress,
          companyName: layout?.organisation?.organisationDetails?.organisation,
          position: layout?.organisation?.personalDetails?.jobTitle,
        },
        isNotify: true,
        notification: {
          title: "Course Booking",
          courseName: selectedCourse?.title,
          participants: 1,
          name: `${layout?.organisation?.personalDetails?.title} ${layout?.organisation?.personalDetails?.delegateName}`,
          role: layout?.organisation?.personalDetails?.jobTitle,
          email: layout?.organisation?.personalDetails?.delegateEmail,
          phone: layout?.organisation?.personalDetails?.phone,
          companyName: layout?.organisation?.organisationDetails?.organisation,
          cost: `$ ${selectedCourse?.courseFess?.toLocaleString()}`,
          receiverIds: "657ee80d7a98c6a903909829",
        },
      })(dispatch);
      dispatch(myLayoutActions.setOrganisationData(null));
      localStorage.clear();
      navigate(`/programme/${selectedCourse?.category}`);
    } else {
      await bookWorkshopAction({
        numberOfParticipant: 1,
        userInfo: {
          names: `${layout?.organisation?.personalDetails?.title} ${layout?.organisation?.personalDetails?.delegateName}`,
          email: layout?.organisation?.personalDetails?.delegateEmail,
          phone: layout?.organisation?.personalDetails?.phone,
          address: layout?.organisation?.organisationDetails?.companyAddress,
          companyName: layout?.organisation?.organisationDetails?.organisation,
          position: layout?.organisation?.personalDetails?.jobTitle,
        },
        workshopInfo: {
          names: "Financial Analysis Workshop",
          location: layout?.selectedWorkshop?.desc?.location,
          // ...values,
        },
        isWorkshop: true,
        notification: {
          title: "Workshop Booking",
          participants: 1,
          name: `${layout?.organisation?.personalDetails?.title} ${layout?.organisation?.personalDetails?.delegateName}`,
          // location: layout?.selectedWorkshop?.label,
          // date: values?.date,
          role: layout?.organisation?.personalDetails?.jobTitle,
          email: layout?.organisation?.personalDetails?.delegateEmail,
          phone: layout?.organisation?.personalDetails?.phone,
          companyName: layout?.organisation?.organisationDetails?.organisation,
          cost: "$5275",
          workshopName: "Financial Analysis Workshop",
          receiverIds: "657ee80d7a98c6a903909829",
        },
      })(dispatch);
      dispatch(myLayoutActions.setOrganisationData(null));
      localStorage.clear();
      navigate(`/`);
    }
  };

  const handleUpdateDelegate = () => {
    setAllowToEditDelegate(true);
  };
  const cancelUpdateDelegate = () => {
    setAllowToEditDelegate(false);
  };

  const handleUpdateOrganisation = () => {
    setAllowToEditOrganisation(true);
  };
  const cancelUpdateOrganisation = () => {
    setAllowToEditOrganisation(false);
  };

  const showPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  const handleConfirmPayment = () => {
    setIsPaymentModalOpen(false);
  };

  const handleCancelPayment = () => {
    setIsPaymentModalOpen(false);
  };

  const handleUpdateSelectedWorkshop = () => {
    setAllowToEditSelectedWorkshop(true);
  };
  const cancelUpdateSelectedWorkshop = () => {
    setAllowToEditSelectedWorkshop(false);
  };

  return (
    <>
      <div className="bg-white pt-20 lg:pt-36 pb-10 lg:mx-4">
        <div className="w-full max-w-[1100px] lg:mx-auto lg:p-4 lg:py-8">
          <div className="space-y-4">
            <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner text-gray-700">
              <h5 className="mb-2.5 md:pb-6 text-xl font-semibold tracking-tight text-[#003d97]">
                {`${
                  props?.route === "course" ? "Course" : "Workshop"
                } Information`}
              </h5>
              <div className="flex flex-col">
                <h1 className="mb-2 text-lg font-medium tracking-tight]">
                  {props?.route === "workshop"
                    ? "Financial Analysis Workshop"
                    : selectedCourse?.title}
                </h1>
                <span className="mb-2 text-lg font-medium tracking-tight]">
                  {selectedCourse?.desc}
                </span>
                <span className="mb-2 text-lg font-bold tracking-tight]">{`$${
                  props?.route === "workshop"
                    ? "5,275"
                    : selectedCourse?.courseFess?.toLocaleString()
                } ${t("perIndividual")}`}</span>
                {/* <span className="mb-2 text-lg font-semibold tracking-tight]">
                  {`${t("Including:")} ${t("Including1")} within ${
                    props?.route === "course" ? "Course" : "Workshop"
                  } 's Period`}
                </span> */}
                <span>{`All fixed prices include the VISA application fee, breakfast, lunch, trainers' payments, trainers' Flights ticket, taxes within ${
                  props?.route === "course" ? "Course" : "Workshop"
                } 's Period, and other expenses related to the training program`}</span>
              </div>

              <div>
                <h1 className="pt-10 text-[#0F0F47] text-base font-bold pb-2 uppercase">
                  {`${
                    props?.route === "course" ? "Course" : "Workshop"
                  } Destination`}
                </h1>
                {layout?.organisation?.selectedWowkshop &&
                !allowToEditSelectedWorkshop ? (
                  <div>
                    <p className="text-lg font-sans font-semibold">
                      {t("Location")}:{" "}
                      <span className="text-lg font-[Exo] font-medium">
                        {layout?.organisation?.selectedWowkshop?.option
                          ? handlerSelectedCountry(
                              layout?.organisation?.selectedWowkshop?.name
                            )
                          : layout?.organisation?.selectedWowkshop?.name}
                      </span>
                    </p>
                    <p className="text-lg font-sans font-semibold">
                      <span className="capitalize">{t("DATE")}: </span>
                      <span className="text-lg font-[Exo] font-medium">
                        {layout?.organisation?.selectedWowkshop?.date}
                      </span>
                    </p>
                    <button
                      className="rounded-md mt-3 shadow gap-x-2 py-2 w-20 border text-white font-[Poppins] text-sm bg-green-400"
                      onClick={handleUpdateSelectedWorkshop}
                    >
                      {t("Edit")}
                    </button>{" "}
                  </div>
                ) : (
                  <BookWorkshopForm
                    onCancel={cancelUpdateSelectedWorkshop}
                    handleFormUpdate={handleFormUpdate}
                    route={props?.route}
                  />
                )}
              </div>
            </div>
            <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner text-gray-700 relative">
              <h5 className="mb-2.5 md:pb-6 text-xl font-semibold tracking-tight text-[#003d97]">
                {t("Delegates Information")}
              </h5>
              {layout?.organisation?.personalDetails && !allowToEditDelegate ? (
                <div>
                  {layout?.organisation?.personalDetails?.delegates?.map(
                    (items: any) => (
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 border rounded-md mb-1 p-4">
                        <p className="flex items-center space-x-4 capitalize">
                          <IconPersonCheckFill />{" "}
                          <span>{`${items?.title} ${items?.delegateName}`}</span>
                        </p>
                        <p className="flex items-center space-x-4">
                          <IconTelephoneForward /> <span> {items?.phone} </span>
                        </p>
                        <p className="flex items-center space-x-4">
                          <IconEmailArrowRightOutline />{" "}
                          <span> {items?.delegateEmail} </span>
                        </p>
                        <p className="flex items-center space-x-4 capitalize">
                          <IconSubtitlesOutline />{" "}
                          <span> {items?.jobTitle} </span>
                        </p>
                        <p className="flex items-center space-x-4">
                          <IconVisaLine /> <span> {items?.visa?.at(0)} </span>
                        </p>
                        <p>
                          {t("Disability")}:{" "}
                          <span> {items?.disability?.at(0)}</span>
                        </p>
                        <p>
                          {t("Approved By Organisation")}:{" "}
                          <span>{items?.approvedByOrganisation?.at(0)}</span>
                        </p>
                        <p>
                          {t("Payment")}: <span>{items?.payment?.at(0)}</span>
                        </p>
                      </div>
                    )
                  )}
                  <button
                    className="rounded-md mt-3 shadow gap-x-2 py-2 w-20 border text-white font-[Poppins] text-sm bg-green-400"
                    onClick={handleUpdateDelegate}
                  >
                    {t("Edit")}
                  </button>{" "}
                </div>
              ) : (
                <DelegateForm
                  onCancel={cancelUpdateDelegate}
                  handleFormUpdate={handleFormUpdate}
                />
              )}
            </div>

            {layout?.organisation?.personalDetails && (
              <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner text-gray-700 relative">
                <h5 className="mb-2.5 md:pb-6 text-xl font-semibold tracking-tight text-[#003d97]">
                  {t("Organisation Information")}
                </h5>
                {layout?.organisation?.organisationDetails &&
                !allowToEditOrganisation ? (
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2"></div>
                    <p className="flex items-center space-x-4 capitalize">
                      <IconOrganisation />{" "}
                      <span>
                        {
                          layout?.organisation?.organisationDetails
                            ?.organisation
                        }
                      </span>
                    </p>
                    <p className="flex items-center space-x-4">
                      <IconSubtitlesOutline />{" "}
                      <span>
                        {" "}
                        {
                          layout?.organisation?.organisationDetails
                            ?.organisationType
                        }{" "}
                      </span>
                    </p>
                    <p className="flex items-center space-x-4 capitalize">
                      <IconAddressBook />{" "}
                      <span>
                        {" "}
                        {
                          layout?.organisation?.organisationDetails
                            ?.companyAddress
                        }{" "}
                      </span>
                    </p>
                    <p className="flex items-center space-x-4 capitalize">
                      <IconRotaryinternational />{" "}
                      <span>
                        {" "}
                        {
                          layout?.organisation?.organisationDetails?.country
                        }{" "}
                      </span>
                    </p>
                    <p className="flex items-center space-x-4 capitalize">
                      <IconPersonCheckFill />{" "}
                      <span>
                        {" "}
                        {
                          layout?.organisation?.organisationDetails
                            ?.hrContactName
                        }{" "}
                      </span>
                    </p>
                    <p className="flex items-center space-x-4">
                      <IconEmailArrowRightOutline />{" "}
                      <span>
                        {" "}
                        {
                          layout?.organisation?.organisationDetails
                            ?.hrContactEmail
                        }{" "}
                      </span>
                    </p>
                    <p className="flex items-center space-x-4">
                      <IconTelephoneForward />{" "}
                      <span>
                        {" "}
                        {
                          layout?.organisation?.organisationDetails
                            ?.hrContactPhone
                        }{" "}
                      </span>
                    </p>
                    <button
                      className="rounded-md mt-3 shadow gap-x-2 py-2 w-20 border text-white font-[Poppins] text-sm bg-green-400"
                      onClick={handleUpdateOrganisation}
                    >
                      {t("Edit")}
                    </button>{" "}
                  </div>
                ) : (
                  <OrganisationForm
                    onCancel={cancelUpdateOrganisation}
                    handleFormUpdate={handleFormUpdate}
                  />
                )}
              </div>
            )}
            {layout?.organisation?.personalDetails &&
              layout?.organisation?.organisationDetails && (
                <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner text-gray-700">
                  <h5 className="mb-2 text-sm font-medium tracking-tight xtext-[#003d97]">
                    {t("personaldetailsareveryimportant")}
                  </h5>
                  <Checkbox>{t("Accept")}</Checkbox>
                </div>
              )}
          </div>
          {layout?.organisation?.personalDetails &&
            layout?.organisation?.organisationDetails && (
              <button
                type="button"
                className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 my-2 outline-none ml-6 md:ml-0"
                onClick={showPaymentModal}
              >
                {t("Next")}
              </button>
            )}
        </div>
      </div>
      <Modal
        style={{ top: 150 }}
        open={isPaymentModalOpen}
        onOk={handleConfirmPayment}
        width={800}
        footer={null}
      >
        <div className="flex flex-col space-y-4 w-full">
          <div className="">
            <img src={PaymentProcess} className=" w-full object-cover" alt="" />
          </div>
          <div>
            <h1 className="text-sm md:text-lg font-semibold font-[Exo] text-[#003d97] pb-4">
              {t("Upload Proof of payment")}
            </h1>
            <UploadDocs setImmageUploaded={setImmageUploaded} />
          </div>
          <div className="flex items-start justify-start">
            <div className="flex items-center space-x-4">
              <button
                type="button"
                className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 my-2 outline-none ml-6 md:ml-0"
                onClick={handleSubmitCourse}
              >
                {t("Submit")}
              </button>
              <button
                type="button"
                className="border focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 border-red-500 text-red-500 hover:text-white hover:bg-red-600 focus:ring-red-900"
                onClick={handleCancelPayment}
              >
                {t("Cancel")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplyCourseForm;
