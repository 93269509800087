import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, notification, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganisationDataAction,
  hasUserDataAction,
} from "../../../store/layout/actions";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";

const DelegateForm = (props: any) => {
  const { t } = useTranslation();
  const { layout } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [localStorageUpdate, setLocalStorageUpdate] = useState(0);
  const [formData, setFormData] = useState<any>(null);
  const submissionKey = "courseData";
  console.log("localStorageUpdate:", localStorageUpdate);

  useEffect(() => {
    const existingData = localStorage.getItem(submissionKey);

    if (existingData) {
      const parsedData = JSON.parse(existingData);
      setFormData(parsedData);
      getOrganisationDataAction(parsedData)(dispatch);
    }
  }, [localStorageUpdate, dispatch]);

  const onFinish = (values: any) => {
    const existingData = localStorage.getItem(submissionKey);
    const existingItem = JSON.parse(
      localStorage.getItem(submissionKey) || "{}"
    );
    const payload = {
      personalDetails: { ...values },
    };

    const newObject = { ...existingItem, ...payload };

    if (existingData) {
      localStorage.setItem(submissionKey, JSON.stringify(newObject));
      notification.success({ message: "Added successfully" });
      props?.handleFormUpdate();
      hasUserDataAction(true)(dispatch);
      form.resetFields();
      props?.onCancel();
    } else {
      localStorage.setItem(submissionKey, JSON.stringify(newObject));
      notification.success({ message: "Saved successfully" });
      props?.handleFormUpdate();
      hasUserDataAction(true)(dispatch);
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const handleChange = (value: any) => {
    setPhoneNumber(value);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.List
        name="delegates"
        initialValue={
          layout?.organisation?.personalDetails &&
          layout?.organisation?.personalDetails?.delegates.length !== 0
            ? [...layout?.organisation?.personalDetails?.delegates]
            : [{}]
        }
      >
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div className="ml-5 lg:max-2xl:ml-0 lg:max-2xl:mt-4">
                  <p className="text-[#0F0F47] text-base font-bold pb-4 uppercase">
                    {t("Delegate")}: {key + 1}
                  </p>
                  <div className="flex items-center xspace-x-4 xrelative">
                    <Form.Item
                      style={{ width: 600 }}
                      label={t("Title")}
                      name={[name, "title"]}
                      rules={[
                        { required: true, message: "Please input your Title!" },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder={t("Select a Title")}
                        optionFilterProp="children"
                        options={[
                          {
                            value: "Mr",
                            label: "Mr",
                          },
                          {
                            value: "Mrs",
                            label: "Mrs",
                          },
                          {
                            value: "Miss",
                            label: "Miss",
                          },
                          {
                            value: "Ms",
                            label: "Ms",
                          },
                          {
                            value: "Rev",
                            label: "Rev",
                          },
                          {
                            value: "Dr",
                            label: "Dr",
                          },
                          {
                            value: "Prefer not to say",
                            label: "Prefer not to say",
                          },
                        ]}
                      />
                    </Form.Item>
                    {key >= 1 && (
                      <button
                        onClick={() => remove(name)}
                        className="  rounded-md mt-10 shadow gap-x-2 py-2 w-fit px-3 border text-red-500 xabsolute xright-96 top-0"
                      >
                        {t("Remove Delegate")}
                      </button>
                    )}
                  </div>
                  <Form.Item
                    label={t("Delegate Names")}
                    name={[name, "delegateName"]}
                    rules={[
                      { required: true, message: "Please input your Names!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={t("Delegate Email")}
                    name={[name, "delegateEmail"]}
                    rules={[
                      { required: true, message: "Please input your Email!" },
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>
                  <Form.Item
                    label={t("Mobile Number")}
                    name={[name, "phone"]}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Telephone!",
                      },
                    ]}
                  >
                    <PhoneInput
                      country={"rw"}
                      value={phoneNumber}
                      onChange={handleChange}
                      countryCodeEditable={false}
                      inputProps={{
                        required: true,
                      }}
                      buttonStyle={{
                        height: "40px",
                        border: "1px solid rgba(128, 128, 128, 0.5)",
                        color: "black",
                      }}
                      inputStyle={{
                        height: "40px",
                        width: "100%",
                        border: "1px solid rgba(128, 128, 128, 0.5)",
                        color: "black",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("Job Title")}
                    name={[name, "jobTitle"]}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Job Title!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={t("bookingapprovedorganisation")}
                    name={[name, "approvedByOrganisation"]}
                  >
                    <Checkbox.Group options={["Yes", "No"]} />
                  </Form.Item>
                  <Form.Item label={t("DoyouhaveVisa")} name={[name, "visa"]}>
                    <Checkbox.Group
                      options={["Yes", "No", "Do not need a Visa"]}
                    />
                  </Form.Item>
                  <Form.Item label={t("Payment")} name={[name, "payment"]}>
                    <Checkbox.Group
                      options={[
                        "My company is paying the course fee",
                        "I will be paying for the course fee",
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("DisabilitySpecialRequirements")}
                    name={[name, "disability"]}
                  >
                    <Checkbox.Group
                      options={["Yes", "No", "Prefer not to say"]}
                    />
                  </Form.Item>
                </div>
              ))}
              <div className="flex">
                <Form.Item className=" flex">
                  <button
                    onClick={() => add()}
                    className="  rounded-md mt-10 shadow gap-x-2 py-2 w-32 border text-[#2943D6]"
                  >
                    {t("Add Delegate")}
                  </button>{" "}
                </Form.Item>
              </div>
            </>
          );
        }}
      </Form.List>
      <Form.Item>
        <Button
          htmlType="submit"
          style={{
            backgroundColor: "#003d97",
            padding: "6px",
            borderRadius: "6px",
            color: "white",
          }}
        >
          <span className="-mt-2">
            {" "}
            {layout?.organisation?.personalDetails
              ? "Update"
              : "Save and Continue"}
          </span>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DelegateForm;
