import { Select } from "antd";
import TopSection from "../../components/TopSection";
import { useNavigate } from "react-router";
import { CourseByAllocation } from "../../assets/course/courseByCategory";
import { useTranslation } from "react-i18next";

const CourseAlocation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="bg-white pt-20 lg:pt-36 border border-gray-200 text-gray-900">
      <TopSection title={t("CourseAlocation")} />
      <div className="w-full max-w-[1100px] md:mx-auto px-6 md:p-4 md:py-8">
        <div className="text-gray-700 mx-4">
          <h1 className="py-6">{t("CourseAlocationDesc")}</h1>
          <div className="pb-6">
            <h1 className="font-medium md:font-semibold text-[#003d97]">
              {t("Select Venue")}
            </h1>
            <Select defaultValue={`Location`} style={{ width: 250 }}>
              {["Dubai", "Qatar", "Kenya", "Rwanda"]?.map(
                (el: any, index: number) => (
                  <Select.Option value={el} key={index}>
                    {" "}
                    {`${el}`}
                  </Select.Option>
                )
              )}
            </Select>
          </div>
          <div className="flow-root w-full">
            <ul className="divide-y text-gray-90 divide-gray-600">
              {CourseByAllocation?.map((item: any) => (
                <li className="py-8">
                  <div className="flex flex-col md:flex-row">
                    <div className="flex-shrink-0">
                      <img
                        className="w-48 h-52 object-cover rounded-md"
                        src={item?.image}
                        alt="Thomasimage"
                      />
                    </div>
                    <div className="flex-1 min-w-0 md:ms-4 pt-2 md:pt-4">
                      <p className="text-xs md:text-sm text-gray-700 truncate">
                        {item?.category}
                      </p>
                      <p className="text-lg font-semibold cursor-pointer text-[#003d97] truncate">
                        {item?.title}
                      </p>
                      <p className="text-lg text-black font-bold truncate">
                        {`$${item?.courseFess?.toLocaleString()}`}
                      </p>
                      <button
                        className="mt-2 relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-green-800"
                        onClick={() =>
                          navigate(`/programme/${item?.category}/${item?.id}`)
                        }
                      >
                        <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-transparent rounded-md group-hover:bg-opacity-0">
                          {t("MoreInfo")}
                        </span>
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseAlocation;
