import { notification } from "antd";
import { myWorkshopActions } from ".";
import { createServiceUser } from "./services";

export const bookWorkshopAction = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWorkshopActions.setIsFetching(true));
      const res = await createServiceUser(data);

      // console.log(">>>>>>>:: user", resCreated );
      if (res?.status === 201) {
        dispatch(myWorkshopActions.setNew(res));
        dispatch(myWorkshopActions.setIsFetching(false));
        notification.success({ message: "Sent Succesfully" });
      }
      dispatch(myWorkshopActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
