import Finance13 from "../../assets/images/finance13.webp";
import Oil5 from "../../assets/images/oil5.jpg";
import HumanResourse2 from "../../assets/images/humanRe2.jpg";
import Law8 from "../../assets/images/law8.png";
import Mskills1 from "../../assets/images/mSkills1.jpg";
import OperationM9 from "../../assets/images/oManagement9.svg";
import Sales1 from "../../assets/images/sales1.jpg";
import Sskills1 from "../../assets/images/mSkills1.jpg";

export const CourseByAllocation = [
  {
    id: 31,
    image: Finance13,
    title: "Financial Risk Management",
    desc: " Take a modern approach to mitigating the financial risks your organization is exposed to",
    WhoShouldAttend: [
      "	Treasury managers who want to know more about financial risk management",
      "Professionals working or planning to work in market, credit or operational risk.",
      "Anyone wanting to find out more about risk management in finance",
      "Risk managers seeking to widen their knowledge",
      "Internal auditors, accountants who need to show CPD ",
    ],
    courseExpectation: [
      "Introduce frameworks for managing risks",
      "Identify key classifications of financial risks",
      "Explore how derivative instruments can be used to protect against pure risks",
      "Review risk mitigation techniques",
      "Establish the need for strong internal controls requirements",
      "Link up Governance, Risk and Compliance (GRC)",
      "Describe the role of directors and senior executives in overseeing, understanding and leveraging risk management",
    ],
    courseContent: [
      {
        title: "Types of Financial Risk",
        contents: [
          "	Risk management frameworks",
          "Key areas of financial risk",
          "The main families of risk and risk classification",
          "The four T’s of risk treatment",
        ],
      },
      {
        title: "Exploring Market Risk",
        contents: [
          "	The main types of market risk",
          "Equity risk",
          "Interest rate risk",
          "Currency risk",
          "Commodity risk",
          "Market risk mitigation strategies",
        ],
      },
      {
        title: "Liquidity Risk",
        contents: [
          "	Defining liquidity risk and how it arises",
          "Market liquidity",
          "Asset liquidity",
          "Funding liquidity",
          "Liquidity risk mitigation strategies",
        ],
      },
      {
        title: "Credit Risk",
        contents: [
          "	Types of credit risk",
          "Default risk (counterparty) and impact",
          "Concentration risk",
          "Gearing risk",
          "Credit risk mitigation strategies",
        ],
      },
      {
        title: "Linking Governance, Risk and Compliance (GRC)",
        contents: [
          "	The role of the board in setting the tone at the top and agreeing risk appetite",
          "Integrating GRC to eliminate duplicated efforts",
          "Development of financial risk audits",
          "Compliance with Codes of Corporate Governance",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
  },
  {
    id: 47,
    image: Oil5,
    title: "Oil & Gas - Contract Management ",
    desc: " Develop an in-depth understanding of all phases of contracting and how to manage contractual relationships in the oil and gas sector ",
    WhoShouldAttend: [
      "This is a stimulating and practical course designed for directors, managers, senior executives and decision makers involved in strategic and operational functions, including managers of procurement, finance, legal, risk and supplier relations and who want to understand how to maximise competitive advantage in the Oil and Gas sector.",
    ],
    courseExpectation: [
      "	Lead and manage procurement processes and contract discussions and negotiations",
      "Identify risks that contracts should address",
      "Employ contractual tools and techniques to increase competitive advantage",
      "Apply typical standard contract terms and conditions",
      "Initiate and develop better commercial contracts within their organisation",
      "Improve supplier performance through better contracts",
      "Plan and manage the resolution of contractual disputes",
    ],
    courseContent: [
      {
        title: "Managing the Purchasing Process",
        contents: [
          "Overview of purchasing best practice",
          "Creating interest from the market in your opportunity",
          "The statement of work for an oil services contract",
          "Results-based purchasing techniques",
          "Evaluating supplier proposals",
          "Is the lowest cost technically compliant bid the right one to choose?",
          "Moving from bid to contract award",
        ],
      },
      {
        title: "Petroleum Industry Contracts",
        contents: [
          "	Formation of the contract",
          "Express and Implied terms",
          "Typical features of exploration and production contracts and contracts for the supply of oil-related services",
          "'Local content' contract requirements",
          "Knock for Knock Liability considered",
          "Anti-corruption measures in petroleum industry contracts",
          "Case study exercises using oil contract examples",
        ],
      },
      {
        title: "Managing Contractor Performance",
        contents: [
          "Identifying and managing supplier risk",
          "Getting and maintaining supplier contract performance",
          "Service level agreements",
          "Setting SMART objectives (SLA)",
          "Key performance indicators (KPIs)",
          "Contract governance; managing contractual variations",
          "Why performance is Important and who is involved?",
          "Post contract closure action",
        ],
      },
      {
        title: "Dispute Resolution in the Petroleum Industry",
        contents: [
          "	Contract breaches and remedies",
          "Termination of contract",
          "Should we litigate?",
          "Using arbitration, mediation or conciliation as means to resolve contractual disputes",
          "Strengths and weaknesses of ADR as an alternative to litigation",
          "Dispute resolution scenario",
        ],
      },
      {
        title:
          "Contract Negotiation Skills and Practice in a Petroleum Industry Context",
        contents: [
          "Preparing for the negotiation",
          "Understanding the position of the other side",
          "Negotiation roles and tactics",
          "Reaching an agreement",
          "Knowing your Best Alternative to Negotiated Agreement (BATNA) - what happens if the negotiation fails",
          "Negotiation role play",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
  },
  {
    id: 51,
    image: HumanResourse2,
    title: "Advanced Strategic Human Resources Management and Leadership ",
    desc: " Position yourself to make a major contribution to the professional management of HR in your organisation",
    WhoShouldAttend: [
      "Senior Directors and Senior Managers who have responsibility for Human Resource (HR) strategy incorporating Organisational Development (OD) and Learning, Training and Development (LTD) and the implementation of these policies and procedures in line with business objectives",
      "Senior Directors and Senior Managers who wish to refresh or develop their skills further",
      "Operational managers who wish to gain a greater understanding of the strategic HR, OD and people management and development issues",
      "Senior Managers who would like a refresher and to develop their confidence, assertiveness and influencing skills",
      "Departmental Directors and Departmental Managers who would like practical and relevant techniques",
    ],
    courseExpectation: [
      "	Evaluate new HR recruitment strategies",
      "Review a Performance Management systems",
      "Take a strategic approach towards learning & development in the organisational context",
      "Design a talent management system which is linked to the organisational strategic plan",
      "Plan a knowledge management system to retain and develop knowledge within the organisation",
      "Create a succession plan that contributes towards future organisational goals",
      "Keep up-to-date with new technological development of HR software",
      "Set objectives and targets to achieve the vision, mission and goals of an organisation",
      "Apply the skills of leadership to enhance the management role",
      "Motivate people and build successful teams",
      "To communicate effectively verbally in all situations with all people",
    ],
    courseContent: [
      {
        title: "Effective HR",
        contents: [
          "	The business and HR strategies",
          "The key roles and specialisms in the HR function",
          "Drivers for strategic change and how to manage this process",
          "Influencing skills in HR management",
          "Analysing your HR strategy using models such as SWOT and PESTLE",
        ],
      },
      {
        title: "Effective development",
        contents: [
          "	Business, HR, OD and LTD strategies including succession planning",
          "The changing role of the trainer in an LTD environment",
          "Identifying the range of LTD interventions",
          "Measuring the return on investment (ROI)",
          "Using HR, OD and LTD to manage business risks, create a competitive advantage and be seen as an employer of choice",
        ],
      },
      {
        title: "Effective performance management",
        contents: [
          "	Defining performance management",
          "HR processes which facilitate performance management",
          "Organisational, team and individual objectives, performance and continuous development",
          "The benefits of developing an LTD organisation",
          "Defining an effective performance appraisal",
        ],
      },
      {
        title: "Effective recruitment",
        contents: [
          "Making a strategic and an operational recruitment business case",
          "Recruiting from within your organisation",
          "The recruitment model (recruitment, interview, assessment and selection)",
          "A recruitment plan to attract the best candidates",
          "Measuring the return on investment (ROI)",
        ],
      },
      {
        title: "HR specialisms",
        contents: [
          "	Employment law",
          "Developing and implementing HR policies and procedures",
          "Employee engagement and communication",
          "Rewards and benefits",
          "Diversity and inclusion",
        ],
      },
      {
        title: "LTD needs analysis",
        contents: [
          "Defining an LTD analysis",
          "Carrying out a skills gap analysis and looking at the different ways you can gather useful information",
          "Training and when it may be, and may not be, the most appropriate response",
          "Assessing the range of LTD interventions in light of the LTD needs analysis",
        ],
      },
      {
        title: "Talent management",
        contents: [
          "	Defining talent, talent management, potential, performance and skills",
          "The business case for, and impact of, a talent management programme",
          "The talent management cycle and process",
          "The Approaches to Development model",
          "Leadership skills and talent management",
        ],
      },
      {
        title: "Career planning",
        contents: [
          "Effective development and career planning",
          "Identifying career limiting behaviours and how to minimise these",
          "Capturing knowledge management",
          "Implementing succession planning",
          "Considering work life balance issues",
        ],
      },
      {
        title: "Creating a personal development plan",
        contents: [
          "Examining your career path using models such as SWOT and PESTLE",
          "Creating a personal development plan",
          "Continuous professional development (CPD)",
          "PDP and CPD",
        ],
      },
      {
        title: "Negotiation Skills",
        contents: [
          "	Pre-negotiation preparation on all aspects of the negotiation",
          "Styles, techniques and skills of a good negotiator",
          "Tricks people may play on you",
          "Close with a win-win result for all",
        ],
      },
      {
        title: "Influencing Skills",
        contents: [
          "	Analysis of factors which influence people",
          "How to influence people without power and authority over them",
          "Chairing and participating in meetings which produce results",
          "Finding common ground and reaching agreement",
        ],
      },
      {
        title: "Assertiveness",
        contents: [
          "	Characteristics and skills of assertiveness",
          "Handling difficult people with confidence",
          "Giving feedback and receiving criticism constructively",
          "Responding appropriately to aggressive, assertive and passive people",
        ],
      },
      {
        title: "Introduction to Neuro-Linguistic Programming",
        contents: [
          "What is Neuro-Linguistic Programming™ (NLP™)?",
          "How does NLP™ work?",
          "NLP™ for self-management and self-development",
          "Application of NLP™ to enhance performance of organisation, teams and individuals",
        ],
      },
      {
        title: "Emotional Intelligence and Body Language",
        contents: [
          "	Key principles, qualities and skills of Emotional Intelligence",
          "Application of EI to develop self, teams, individuals and respond to situations",
          "Sending the right messages through your non-verbal communication",
          "Interpreting the signals and gestures of body language of others",
        ],
      },
    ],
    courseFess: 7940,
    category: "Human Resources",
  },
  {
    id: 69,
    image: Law8,
    title: "Legislative Drafting",
    desc: "Take the objectives of new legislation forward and outline laws for consultation and comment ",
    WhoShouldAttend: [
      "Senior Managers and Government personnel responsible for turning policy into legislation",
      "Officials who instruct Parliamentary draftsmen",
      "Assistants to legislators involved in creating draft legislation",
      "Members of parliament, senators, assembly members",
      "Legal advisors and practitioners from both public and private sectors",
    ],
    courseExpectation: [
      "	Overcome obstacles to turning policy into legislation",
      "Describe the process of making new laws",
      "Contribute to the drafting of new legislation",
      "Prepare instructions for professional draftsmen",
      "Practice legislative drafting skills",
    ],
    courseContent: [
      {
        title: "Legislative instruments under the microscope",
        contents: [
          "	Types of legislation",
          "Primary and secondary legislation",
          "Dissecting a statute",
          "Structure and elements of an Act of Parliament",
          "Assembling the machinery of an Act",
          "Problems with how laws work",
        ],
      },
      {
        title: "Drafting Legislation",
        contents: [
          "Principles of drafting",
          " Alternative approaches",
          "Planning your draft",
          "Writing for the reader",
          "Using plain English",
          "Dos and Don’ts in drafting",
          "Writing instructions for the parliamentary draftsman",
        ],
      },
      {
        title: "Interpretation and construction of Statutes",
        contents: [
          "	Identifying the purpose of a law",
          " The Mischief Rule",
          "The Golden Rule",
          "Judicial interpretation of legislation",
          "Judicial creativity and the sovereignty of parliament",
        ],
      },
      {
        title: "Stakeholder participation in Law-Making",
        contents: [
          "The need for involvement",
          " Formal and informal consultation",
          "Consultation processes",
          "The draft Bill and consultation",
          "Ethical lobbying",
          "The role of the public affairs professional",
        ],
      },
      {
        title: "Turning Policy into practice",
        contents: [
          "Why do some laws fail to achieve their objectives?",
          "Effective and ineffective law making",
          " Education, communication and legal effectiveness",
          "The challenge of over-complex laws",
          "How to address legal effectiveness issues",
          "Alternatives to law making – nudge theory",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
  },
  {
    id: 71,
    image: Mskills1,
    title: "Advanced Supervisory Skills ",
    desc: "Advanced supervisory skills for top results ",
    WhoShouldAttend: [
      "	Senior and experienced supervisors who would like to develop into managerial responsibility",
      "Supervisors who would like to develop into a more senior supervisory role",
      "Senior supervisors who would like to refresh and develop their skills",
      "Supervisors who would like to develop their practical and relevant skills and techniques",
      "Senior team leaders",
      "Senior project supervisors",
    ],
    courseExpectation: [
      "	Set objectives and targets to achieve the vision, mission and goals of an organization",
      "Apply the skills of leadership to enhance the supervisory role",
      "Motivate people and build successful teams",
      "Use verbal skills to influence people with a constructive outcome",
      "Apply assertiveness skills to cope confidently with different people",
      "Solve problems creatively and develop sound decision - making techniques",
      "Increase effectiveness of their time management and stress control",
    ],
    courseContent: [
      {
        title: "Setting Objectives and Targets",
        contents: [
          "	Where are you now and where do you want to be, by when?",
          "Plan and actions required to achieve these objectives",
          "Improve organizational, team and individual performance with quantifiable targets",
          "Monitoring, measuring and feeding- back results",
        ],
      },
      {
        title: "Leadership",
        contents: [
          "	Vision, roles and responsibilities of an inspirational leader",
          "Qualities, skills and commitment required",
          "Adapting leadership styles to different situations",
          "Overcoming the challenges of leadership",
        ],
      },
      {
        title: "Motivation",
        contents: [
          "	Factors which motivate you and others",
          " Achieving organizational goals with a motivated workforce",
          "Increasing motivation to improve individual performance",
          "Management skills required to motivate individuals and teams",
        ],
      },
      {
        title: "Team Building",
        contents: [
          "	Characteristics of an effective team",
          " Roles and responsibilities of individuals in the team",
          "Building a strong team which delivers results",
          "Delegating, appraising, evaluating, coaching and mentoring skills for teambuilding",
        ],
      },
      {
        title: "Verbal Communication Skills",
        contents: [
          "	Structure simple messages using appropriate language and delivery",
          "Listen attentively to ensure mutual understanding",
          "Generate productive and open discussions to solve problems",
          "Overcome the barriers to effective verbal communication",
        ],
      },
      {
        title: "Influencing Skills",
        contents: [
          "	Analysis of factors which influence people",
          "How to influence people without power and authority over them",
          "Chairing and participating in meetings which produce results",
          " Finding common ground and reaching agreement",
        ],
      },
      {
        title: "Assertiveness",
        contents: [
          "	Characteristics and skills of assertiveness",
          " Handling difficult people with confidence",
          " Giving feedback and receiving criticism constructively",
          "Responding appropriately to aggressive, assertive and passive people",
        ],
      },
      {
        title: "Creative Problem Solving and Decision Making",
        contents: [
          "Analysis of causes, symptoms and implications of a problem",
          " Generating a range of creative solutions",
          "Prioritizing options against objective criteria to make a decision",
          "Implementing decisions, evaluating results and responding to feedback",
        ],
      },
      {
        title: "Time and Stress Management",
        contents: [
          "	Prioritizing and organizing work according to goals",
          "Eliminating time wasters",
          "Identifying the causes and symptoms of stress",
          "Planning and implementing your strategy to avoid burnout",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
  },
  {
    id: 87,
    image: OperationM9,
    title: "Customer Service Management",
    desc: "Capture, delight and retain customers through first class customer service ",
    WhoShouldAttend: [
      "This course has been designed for those who understand the importance of improving their customer service in the long term and is suited to those who deal with managing customer service or customer relationships; customer service professionals, service agents, front-line workers, managers, supervisors and business professionals who interact with customers and wish to improve both their skills and organisational success.",
    ],
    courseExpectation: [
      "Generate ideas to make improvements to the way they work with and respond to their customers",
      "Meet the ever - increasing needs of their customers",
      "Make a good first impression, develop and maintain ongoing relationships based on trust and high quality communication",
      " Identify and solve problems related to customer satisfaction and the performance of customer service providers regarding their key performance areas",
      "Manage “difficult” customers more effectively and confidently",
      " Be more customer focused in their work",
      " Apply team work in customer care",
      "Improve handling of customers by frontline customer service providers",
    ],
    courseContent: [
      {
        title: "An Introduction to Customer Service",
        contents: [
          "The core principles of customer service excellence",
          "Understanding of the different types of customer and the value of customers and their loyalty",
          "The costs and impacts of poor customer service",
          "How measuring customer relationships can enable greater business success",
          " Understanding unpleasant experiences",
        ],
      },
      {
        title: "Essential Customer Service Communication Skills",
        contents: [
          "The language and communication skills for successful interactions",
          "The message drop - out",
          "The three elements of effective communication",
          "Building rapport with verbal communication",
          " Adapt your behaviour to meet customers’ needs or expectations",
        ],
      },
      {
        title: "Dealing with stress and calming upset customers",
        contents: [
          "Understanding what makes customers upset",
          "Contrast successful and difficult customer relationships",
          "Strategies for calming upset customers; face - to face, over the telephone, via email",
          "Understanding the stress of customer services roles",
          "Managing, or eliminating, stress",
        ],
      },
      {
        title: "Maintaining a positive and customer-friendly attitude",
        contents: [
          "The individual in customer service activities",
          "10 Tools to help you manage your time in customer services",
          "Maintaining a ‘can do’ approach",
          " Techniques for asking questions",
          "Strategies for adding genuine value",
        ],
      },
      {
        title:
          "Development of a toolkit to build stronger and long lasting customer relationships",
        contents: [
          "Why the telephone is so important to customer service",
          " Recognising the customer’s preference and adapting accordingly",
          " Mastering the telephone",
          "Understanding the signals you send out to customers",
          "Identify and negotiate the best deal/outcome possible",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
  },
  {
    id: 119,
    image: Sales1,
    title: "Advanced Sales Skills",
    desc: "Strategic sales planning, leadership and management, to maximize sales and profit ",
    WhoShouldAttend: [
      "Sales Executives",
      "Sales Managers, Operations Managers and Account Managers",
      "Sales Directors / Operational Directors",
      "Managers / Directors moving more into a strategic business development role",
    ],
    courseExpectation: [
      "Refresh and develop key sales development skills",
      "Improve sales management methods and empower your team to succeed",
      "Drive sales performance, optimize the sales funnel and improve sales performance and results",
      "Create and give excellent sales presentations and pitches",
      "Develop and manage strategic key accounts and strengthen loyalty",
      "Analyze buyer motivation and sales psychology and its link to market positioning",
      "Overcome objections and win over skeptical buyers",
      "Improve communication and influencing skills",
      " Move towards  ‘consultative solution focused selling’",
      "Build lasting relationship with clients",
      "Identify the root causes of issues with clients and offer the best solutions / services",
      " Develop strategic customer relationship management",
      "Create a personal development plan",
    ],
    courseContent: [
      {
        title: "Selling – An Art or a Science",
        contents: [
          "Through interactive learning delegates will explore the factors that make excellent sales people",
          "How to raise personal standards in order to encourage profitability",
          "Do you use a ‘hunter’ or a ‘farmer’ selling style ?",
          "The background of selling and defining your role as part of the organization’s mission",
          "How to use persuasion without crossing boundaries",
        ],
      },
      {
        title: "Effective Planning and Prioritizing",
        contents: [
          "Account analysis, planning and time management",
          "How to plan your territory more productively",
          " Prioritizing prospects well to ensure more consistent sales conversion ratio",
          " Meeting and diary management and increasing opportunities for new business",
          " Strategies for hitting and surpassing your targets",
          "Researching into client, the global, market and customer spheres",
          "Identify key trends in the marketplace",
        ],
      },
      {
        title: "Making Lasting Impressions",
        contents: [
          "Tuning in to your client’s mindset and building trust",
          "Generate influence through matching body language and increased personal credibility",
          " Apply the ‘Aristotle Principle of Persuasion’",
          "Becoming a positive reference top your client and building more loyalty and sales compared to your competitors",
          "Becoming a better communicator using positive unconditional regard(Rogers and Carnegie)",
          "Personal psychometric profiling",
        ],
      },
      {
        title: "Overcoming Objections",
        contents: [
          "How to deal with client objections and still get the sale",
          "7 steps to maintain calm in adverse selling situations",
          "How to use objections as a basis to develop the sale into a mutual beneficial outcome",
          "Maintaining professionalism during the sales process to create a long term focus while gaining short and mid - term wins",
          "Dealing with client excuses of not buying and delaying strategies",
        ],
      },
      {
        title: "Winning the Business",
        contents: [
          "10 closing styles to suit your personality and clients buying style",
          "Overcoming any fear or asking for the business",
          " Dealing with delayed sales proposals",
          " Practical exercises to practice getting the sale with confidence",
          "Creating a clear vision for yourself using positive psychology",
        ],
      },
      {
        title: "Sales Presentation and Pitching Mastery",
        contents: [
          "How to be more effective and charismatic during sales presentations",
          "How to deal with presentation challenges for individual client meetings vs selling to a procurement team",
          "How to bring separate viewpoints together to still leave with a sale",
          "The elevator pitch",
          " How to present more confidently and describe your products and services using customers needs",
          "Moving from transactional selling to consultative selling",
          "Practical exercises and coaching to help you grow and improve",
        ],
      },
      {
        title: "Relationship Building",
        contents: [
          "Become an a trusted advisor to your client",
          "Using advanced influencing skills to connect to your client and get them to reveal more",
          "Selling across different cultures, code and practices",
          "Understanding your personal brand in sales",
          "Mastering emotional intelligence and positive psychology",
          " Explore psychometric profiling of yourself and clients",
          " Making a plan to increase loyalty and pin that to profitability",
          "Feedback of individual strategy assignment",
        ],
      },
      {
        title: "Dealing with Difficult Clients",
        contents: [
          "Problem clients and handling the effects of their action/inaction",
          " 5 different types of difficult buyers",
          " 5 things you must never do while handling a customer objection",
          " Winning back lost business and raising the stakes",
          "Using refined communication strategies of world’s leading business coaches and entrepreneurs to deal with any problem",
          "Buyer’s expectations of suppliers",
        ],
      },
      {
        title: "Strategic Sales",
        contents: [
          "Motivating yourself and your team to be results focused",
          "Dealing with ‘C Level’ selling – selling to the board",
          "Getting ‘buy in’ for internal stakeholders to improve strategy",
          "Increase conversion ratios and customer feedback ratings",
          "Create a success roadmap",
          "Develop your own personal development plan for post course success",
        ],
      },
    ],
    courseFess: 6080,
    category: "Sales and Marketing",
  },
  {
    id: 122,
    image: Sskills1,
    title: "Advanced Dynamic Office Management and Administration ",
    desc: " Significantly improve your day-to-day performance in your Office Management and Administrative roles ",
    WhoShouldAttend: [
      "This course is designed for senior secretaries, executive secretaries, personal assistants and top-level management secretaries aiming to improve their performance and maximise opportunities for promotion to executive positions",
    ],
    courseExpectation: [
      "	Examine the evolving role of the modern secretary",
      "Carry out your duties more efficiently and effectively",
      "Update and raise stands of office management",
      "Align personal performance to organisational vision and success",
      "Develop interpersonal skills and manage excellent teams",
      "Enhance career opportunities and promotion capabilities",
    ],
    courseContent: [
      {
        title: "Office organisation",
        contents: [
          "Practical solution focused management skills",
          "How to support good workflow in the office for employee productivity",
          "How to manage the day-to-day running of the office",
          "Dealing with suppliers and developing good buying relationships",
        ],
      },
      {
        title: "Arranging meetings and taking minutes",
        contents: [
          "	Best practice for handling meetings",
          "The role of teams and teambuilding",
          "Taking accurate minutes and following up",
          "Arranging the environment for best meeting atmosphere",
        ],
      },
      {
        title: "Event management",
        contents: [
          "	Planning, arranging and monitoring events",
          "Coordination and facilitation skills",
          "How to manage different suppliers to deliver a successful event",
          "Managing on time and on budget",
        ],
      },
      {
        title: "Verbal communication skills",
        contents: [
          "	How to communicate clearly and concisely",
          "How to express ideas and core objectives to diverse audiences",
          "Communicating with a confident voice",
        ],
      },
      {
        title: "Written communication skills",
        contents: [
          "	Report writing, structure, theme, and executive summary",
          "Aiming reports to different stakeholders",
          "Email communication best practice",
          "Letter writing skills",
        ],
      },
      {
        title: "Team-working",
        contents: [
          "	Creating winning teams, key roles and team dynamic",
          "How to motivate staff and ensure results",
          "How to create interventions for team conflicts and problems",
          "The pitfalls of underperforming teams and how realign them",
        ],
      },
      {
        title: "Time and stress management",
        contents: [
          "	Emotional intelligence vs. Intelligence Quotient (IQ)",
          "The psychology of wellbeing and performance",
          "How to deal with pressure and stress in the workplace",
          "How to encourage high performance without creating stress",
        ],
      },
      {
        title: "Presentation skills",
        contents: [
          "Delivering professional and memorable presentations",
          "How to write a speech for conferences and events",
          "Proven public speaking tips and techniques for charismatic presentations",
          "Managing nerves and overcoming fear of public speaking",
        ],
      },
      {
        title: "Travel management",
        contents: [
          "Making travel arrangements (i.e. arranging flights, accommodation, visas)",
          "Managing timelines and preparing contingencies",
          "Negotiation with suppliers and getting the best quotes",
          "Researching the best routes for a journey both locally and internationally",
        ],
      },
    ],
    courseFess: 6080,
    category: "Secretarial Pratices",
  },
  {
    id: 99,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Advanced Corporate Social Responsibility",
    desc: "Promote your organisation's contribution to society: Corporate Social Responsibility - strategic planning and implementation ",
    WhoShouldAttend: [
      "Heads of sustainable development",
      "Corporate Affairs managers and officers",
      "CSR managers and officers",
      "Corporate strategists",
      "Community Relations managers and officers",
      "HR, Administration and Environmental managers concerned with CSR planning and implementation",
      "Procurement managers involved in responsible sourcing initiatives",
      "Project managers responsible for developing and implementing CSR projects",
      "Managers and officers involved in non - financial corporate reporting",
      "Communications managers and officers responsible for internal and external reporting on sustainability",
      "Internal auditors concerned with corporate social responsibility risks",
    ],
    courseExpectation: [
      "Understand how Corporate Social Responsibility is important in the public and private sectors",
      "Apply CSR best practices to organisationalprogrammes and projects ",
      "Develop and improve their community relations activities",
      "Interpret the impact of CSR on the reputation of the organisation",
      "Plan, initiate or improve CSR programmes",
      "Report on their sustainability programmes",
      "Identify the links between business ethics, Corporate Social Responsibility and Corporate Governance",
    ],
    courseContent: [
      {
        title: "What is CSR and why does it matter?",
        contents: [
          "The pressure for change",
          "The Legal Background of CSR",
          "Company directors’ obligations and CSR",
          "Voluntary measures",
          "Is CSR “Just Public Relations”?",
          "CSR and corporate governance links",
        ],
      },
      {
        title: "Best practice in CSR – corporate approaches on the key topics",
        contents: [
          "Codes of Practice",
          "Dealing with communities",
          "CSR and employees",
          "Managing suppliers responsibly",
          "Responsible client relations",
          "Corporate Responsibility and the environment",
        ],
      },
      {
        title: "The Business Case for CSR",
        contents: [
          "The Strategic Imperatives involved in CSR",
          "What is a business case?",
          "USING tools to build the business case",
          "Identifying and Managing CSR risks",
          "The public procurement challenges from CSR",
          "Getting and maintaining corporate support and approval",
        ],
      },
      {
        title: "Changing the Organisation to include CSR",
        contents: [
          "Developing the CSR corporate vision",
          "Setting our CSR strategic SMART objectives",
          "Identifying and Managing affected stakeholders",
          "How leaders make CSR happen",
          "The key steps to make the CSR change effective",
        ],
      },
      {
        title: "Developing and implementing the CSR plan",
        contents: [
          "What should be in the CSR plan?",
          "Communicating the plan",
          "Piloting the plan",
          "Overcoming objections",
          "Measuring impact and results",
          "Auditing and reviewing CSR performance",
          "Reporting on CSR",
        ],
      },
      {
        title: "Sustainability in Purchasing",
        contents: [
          "Managing a responsible Supply Chain",
          "The Ethical Trading Initiative Base Code",
          "Combating child labour in manufacturing",
          "Controlling contractor abuses in construction",
          "Maintaining ethical standards",
          "Diversity in the supply chain",
          "Conflict minerals policies",
        ],
      },
      {
        title: "Community Relations and Environmental Sustainability",
        contents: [
          "Working effectively with host communities",
          "Managing sensitive issues including payments and relocation",
          "Building successful community projects",
          "Assessing social and environmental risk",
          "Managing operations sustainably",
          "Case studies on community relations",
        ],
      },
      {
        title: "CSR Standards and Reporting",
        contents: [
          "Sustainability Reporting frameworks",
          "The Global Reporting Initiative",
          "UN Global Compact",
          "International LabourOrganisation instruments",
          "ISO 26000",
          "How to report your CSR progress",
          "Model examples and class exercise using GRI Sustainability Reporting",
        ],
      },
      {
        title: "CSR Perspectives",
        contents: [
          "The politics of CSR",
          "Creating shared value",
          "Ethical consumerism",
          "Greenwashing",
          "CSR and tax avoidance",
          "Applying Corporate Social Responsibility in non-corporate organisations",
          "Future requirements for corporate responsibility",
        ],
      },
    ],
    courseFess: "$5,580 ",
    category: "Public Relations",
    courseCode: "PR01",
  },
];
