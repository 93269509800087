import Carousel from "better-react-carousel";
import TopSection from "../../components/TopSection";
import { CourseByAllocation } from "../../assets/course/courseByCategory";
import { useNavigate } from "react-router";
import { getCoursesByCategory } from "../../utils/helper";
import { Courses } from "../../assets/course/courses";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const categoryCounts = getCoursesByCategory(Courses);
  return (
    <div className="bg-white pt-20 lg:pt-36 pb-10 border">
      <TopSection title={t("aboutUs")} />
      <div className="w-full max-w-[1100px] lg:mx-auto lg:p-4 lg:py-8">
        <div className="space-y-4">
          <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner">
            <h5 className="mb-2 text-xl font-semibold tracking-tight text-[#003d97]">
              {t("Introduction")}
            </h5>
            <p className="mb-3 font-normal text-gray-700 ">
              {t("aboutUsDesc")}
            </p>
          </div>
          <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner">
            <h5 className="mb-2 text-xl font-semibold tracking-tight text-[#003d97]">
              {t("Vision")}
            </h5>
            <p className="mb-3 font-normal text-gray-700 ">
              {t("VisionDesc1")} <br />
              {t("VisionDesc2")}
            </p>
          </div>
          <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner">
            <h5 className="mb-2 text-xl font-semibold tracking-tight text-[#003d97]">
              {t("Mission")}
            </h5>
            <p className="mb-3 font-normal text-gray-700 ">
              {t("MissionDesc")}
            </p>
          </div>

          <div className="max-w-full p-6 bg-white border border-gray-200 shadow-inner">
            <h5 className="mb-2 text-xl font-semibold tracking-tight text-[#003d97]">
              {t("CoreValues")}
            </h5>
            <ul className="list-disc text-gray-800 pl-10 md:pl-6">
              <li>{t("CoreValuesDesc1")}</li>
              <li>{t("CoreValuesDesc2")}</li>
              <li>{t("CoreValuesDesc3")}</li>
              <li>{t("CoreValuesDesc5")}</li>
              <li>{t("CoreValuesDesc4")}</li>
            </ul>
          </div>
        </div>
        <div className="py-10">
          <h2 className="mb-10 text-xl font-semibold text-center tracking-tight text-[#003d97]">
            {t("VIEW OUR COURSE SUBJECTS BELOW")}
          </h2>
          <Carousel
            cols={4}
            rows={1}
            gap={20}
            loop
            autoplay={4000}
            step={1}
            mobileBreakpoint={768}
          >
            {categoryCounts?.map((item: any) => (
              <Carousel.Item>
                <div
                  className="text-gray-700 xw-full cursor-pointer"
                  onClick={() => navigate(`/programme/${item?.category}`)}
                >
                  <img
                    src={item?.image}
                    className="w-full h-64 md:h-52 rounded-e-3xl rounded-bl-3xl object-cover"
                    alt=""
                  />
                  <div className="space-y-2">
                    <p>{`${item?.category} (${item?.count})`}</p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default About;
