import React, { useEffect, useState } from "react";
import { Button, Form, Select, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getWorkshopById } from "../../utils/helper";
import {
  hasUserDataAction,
  selectedWorkshopAction,
} from "../../store/layout/actions";
import CountrySelector from "../country/selector";
import { COUNTRIES } from "../../utils/libs/countries";
import { SelectMenuOption } from "../../utils/libs/types";
import { DatePicker, Space } from "antd";
import type { DatePickerProps } from "antd";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const BookWorkshopForm = (props: any) => {
  const { t } = useTranslation();
  const { course, layout } = useSelector((state: any) => state);
  const [form] = Form.useForm();
  const [custom, setCustom] = useState(
    layout?.organisation?.selectedWowkshop?.option ?? ""
  );
  const [selectedDate, setSelectedDate] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [countries, setCountry] = useState<SelectMenuOption["value"]>(
    layout?.organisation?.selectedWowkshop?.option
      ? layout?.organisation?.selectedWowkshop?.name
      : "RW"
  );
  const [activeTab, setActiveTab] = useState<any>();
  const dispatch = useDispatch();
  const submissionKey = "courseData";

  const onFinish = (values: any) => {
    const existingData = localStorage.getItem(submissionKey);
    const existingItem = JSON.parse(
      localStorage.getItem(submissionKey) || "{}"
    );
    if (custom) {
      const payload = {
        selectedWowkshop: {
          name: countries,
          date: `${selectedDate?.at(0)} to ${selectedDate?.at(1)}`,
          option: "custom",
        },
      };
      const newObject = { ...existingItem, ...payload };

      if (existingData) {
        localStorage.setItem(submissionKey, JSON.stringify(newObject));
        notification.success({ message: "Added successfully" });
        hasUserDataAction(true)(dispatch);
        props?.handleFormUpdate();
        form.resetFields();
        props?.onCancel();
      } else {
        localStorage.setItem(submissionKey, JSON.stringify(newObject));
        notification.success({ message: "Saved successfully" });
        hasUserDataAction(true)(dispatch);
        form.resetFields();
        props?.onCancel();
        props?.handleFormUpdate();
      }
    } else {
      const payload = {
        selectedWowkshop: { ...values },
      };
      const newObject = { ...existingItem, ...payload };

      if (existingData) {
        localStorage.setItem(submissionKey, JSON.stringify(newObject));
        notification.success({ message: "Added successfully" });
        hasUserDataAction(true)(dispatch);
        props?.handleFormUpdate();
        form.resetFields();
        props?.onCancel();
      } else {
        localStorage.setItem(submissionKey, JSON.stringify(newObject));
        notification.success({ message: "Saved successfully" });
        hasUserDataAction(true)(dispatch);
        form.resetFields();
        props?.onCancel();
        props?.handleFormUpdate();
      }
    }
  };

  const country = [
    { label: "Dubai, UAE", value: 0 },
    { label: "Doha, Qatar", value: 1 },
    { label: "Nairobi, Kenya", value: 2 },
    { label: "Kigali, Rwanda", value: 3 },
    { label: "Custom", value: 100 },
  ];

  const handleSelectedCountry = (values: any) => {
    const selectedCountry = getWorkshopById(values);
    setActiveTab(selectedCountry);
    values === "Custom" && setCustom(values);
  };

  useEffect(() => {
    selectedWorkshopAction(activeTab)(dispatch);
  }, [activeTab, dispatch]);

  const handlerRejectCustom = () => {
    setCustom("");
    setActiveTab("");
  };

  const onChange = (date: any, dateString: any) => {
    setSelectedDate(dateString);
  };

  return (
    <Form
      name="trigger"
      style={{ maxWidth: 600 }}
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
    >
      {custom === "" && (
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <Form.Item
            hasFeedback
            label={t("Country")}
            name="name"
            validateTrigger="onBlur"
            rules={[{ required: true, message: "Missing Country" }]}
          >
            {props?.route === "course" ? (
              <Select
                showSearch
                placeholder={t("SelectCountry")}
                className="capitalize"
                onChange={handleSelectedCountry}
              >
                {country.map((d: any) => (
                  <Select.Option value={d?.label} className="capitalize">
                    {d?.label}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Select
                showSearch
                placeholder={t("SelectCountry")}
                className="capitalize"
                onChange={handleSelectedCountry}
              >
                {country
                  ?.filter((d) => d?.label !== "Custom")
                  ?.map((d: any) => (
                    <Select.Option value={d?.label} className="capitalize">
                      {d?.label}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            label={t("DATE")}
            name="date"
            validateTrigger="onBlur"
            rules={[{ required: true, message: "Missing Date" }]}
          >
            <Select
              showSearch
              placeholder={t("SelectDate")}
              className="capitalize"
              // onChange={handleSelectedRole}
            >
              {layout?.selectedWorkshop?.desc?.date?.map(
                (data: any, index: any) => (
                  <Select.Option
                    key={index}
                    value={`${data?.from} to ${data?.to}`}
                    className="capitalize "
                  >
                    {`${data?.from} to ${data?.to}`}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
        </div>
      )}
      {custom && (
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <div>
            <Form.Item
              label={t("Country")}
              name="country"
              rules={[
                { required: true, message: "Please input your Country!" },
              ]}
            >
              <CountrySelector
                id={"country-selector"}
                open={isOpen}
                onToggle={() => setIsOpen(!isOpen)}
                onChange={setCountry}
                selectedValue={COUNTRIES.find(
                  (option) => option.value === countries
                )}
              />
            </Form.Item>
            <button
              onClick={handlerRejectCustom}
              className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-pink-800"
            >
              <span className="relative px-4 py-1 transition-all ease-in duration-75 bg-white text-red-500 hover:text-white rounded-md group-hover:bg-opacity-0">
                {t("Reject")}
              </span>
            </button>
          </div>
          <Form.Item label={t("DATE")} name="date">
            <div>
              <RangePicker onChange={onChange} />
            </div>
          </Form.Item>
        </div>
      )}

      <Form.Item>
        <Button
          style={{
            backgroundColor: "#003d97",
            padding: "6px",
            borderRadius: "6px",
            color: "white",
            width: 100,
          }}
          type="primary"
          htmlType="submit"
          loading={course?.isFetching}
        >
          {t("Save")}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default BookWorkshopForm;
