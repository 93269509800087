import Image2 from "../assets/photo/22222.jpeg";
import Trainer from "../assets/images/trainer.jpg";
import Eric from "../assets/constants/eric.jpg";
import Samuel from "../assets/constants/samuel.jpg";
import Isaiah from "../assets/constants/isaiah.jpg";
import Const1 from "../assets/constants/cons1.jpg";
import Mukiza from "../assets/photo/mukiza.jpg";

export const consultantsData = [
  {
    id: 1,
    name: "Dr Erick Oluoch Manga",
    images: Eric,
    summary: "ErickOluochManga",
  },
  {
    id: 2,
    name: "Dr Samuel M.Kimote",
    images: Samuel,
    summary: "SamuelKimote",
  },
  {
    id: 3,
    name: "CPA and ACCA Isaiah M. Gichana",
    images: Isaiah,
    summary: "IsaiahGichana",
  },
  {
    id: 4,
    name: "Mr. Evans M.Nyarwari",
    images: Const1,
    summary: "EvansNyarwar",
  },
  {
    id: 5,
    name: "Edger Ogao",
    images: Trainer,
    summary: "EdgerOgao",
  },
  {
    id: 6,
    name: "CPA Egide Kagina",
    images: Image2,
    summary: "EgideKagina",
  },
  {
    id: 7,
    name: "Dr MUKIZA Janvier",
    images: Mukiza,
    summary: "MUKIZAJanvier",
  },
];
