import React, { useState } from "react";
import CountrySelector from "../components/country/selector";
import { COUNTRIES } from "../utils/libs/countries";
import { SelectMenuOption } from "../utils/libs/types";
import { Select } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [country, setCountry] = useState<SelectMenuOption["value"]>("RW");
  const [selectedCountry, setSelectedCountry] = useState("250");

  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const handleChange = (value: any) => {
    setPhoneNumber(value);
  };

  return (
    <section className="bg-white">
      <div className="mx-auto max-w-7xl py-16 lg:py-32 px-4 md:px-0">
        <div className="mb-4 px-2 md:px-0">
          <div className="mb-6 max-w-3xl md:text-center md:mx-auto md:mb-12">
            <h2 className="mb-4 text-2xl md:text-3xl tracking-tight font-semibold md:font-extrabold text-center text-gray-900 mt-20">
              {t("contact")}
            </h2>
            <p className="mx-auto mt-4 max-w-3xl text-lg md:text-xl text-gray-600">
              {t("contactDesc")}
            </p>
          </div>
        </div>
        <div className="flex px-2 md:px-32">
          <div className="flex w-full flex-col md:flex-row">
            <div className="h-full pr-6 md:w-1/2">
              <h2 className="mb-4 text-lg md:text-2xl tracking-tight font-medium text-gray-900">
                {t("Get in Touch")}
              </h2>
              <ul className="mb-6 md:mb-0">
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="h-6 w-6"
                    >
                      <path d="M21 5.25L12 12.71 3 5.25M12 12.71L12 2"></path>
                      <path d="M2 5.25h20a2 2 0 0 1 2 2v9.13a1 1 0 0 1-.29.71l-9 8.68a1 1 0 0 1-1.42 0l-9-8.68a1 1 0 0 1-.29-.71v-9.13a2 2 0 0 1 2-2z"></path>
                    </svg>
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-sm md:text-lg font-medium leading-6 text-gray-900 ">
                      {t("Email")}
                    </h3>
                    <p className="text-gray-600 text-sm md:text-lg">
                      info@nctar.org
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="h-6 w-6"
                    >
                      <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                      <path d="M15 7a2 2 0 0 1 2 2"></path>
                      <path d="M15 3a6 6 0 0 1 6 6"></path>
                    </svg>
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-sm md:text-lg font-medium leading-6 text-gray-900 ">
                      {t("Contact")}
                    </h3>
                    <div className="flex space-x-2 text-gray-600 text-sm md:text-lg">
                      <h1>{t("Mobile")}</h1>
                      <p className="flex flex-col space-y-1 text-sm md:text-lg">
                        <span className="text-sm md:text-lg">
                          +250 788 617 857
                        </span>
                        <span className="text-sm md:text-lg">
                          +1 (435) 720-9200
                        </span>
                      </p>
                    </div>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="h-6 w-6"
                    >
                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                      <path d="M12 7v5l3 3"></path>
                    </svg>
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-2 text-sm md:text-lg font-medium leading-6 text-gray-900 ">
                      {t("Working hours")}
                    </h3>
                    <p className="text-gray-600 text-sm md:text-lg">
                      {t("MondayToFriday")}: 08:00 - 17:00
                    </p>
                    <p className="text-gray-600 text-sm md:text-lg">
                      {t("Saturday")} &amp; {t("Sunday")}: 08:00 - 12:00
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="card h-fit w-full px-5" id="form">
              <h2 className="mb-4 text-lg md:text-2xl tracking-tight font-medium text-gray-900">
                {t("Send Us Message")}
              </h2>
              <form action="#" className="space-y-8">
                <div className="flex md:items-center md:space-x-2 w-full flex-col md:flex-row">
                  <div className="md:w-24">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      {t("Title")}
                    </label>
                    <Select
                      style={{ width: "100%", height: 42 }}
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      // onChange={onChange}
                      options={[
                        {
                          value: "Mr",
                          label: "Mr",
                        },
                        {
                          value: "Mrs",
                          label: "Mrs",
                        },
                        {
                          value: "Miss",
                          label: "Miss",
                        },
                        {
                          value: "Ms",
                          label: "Ms",
                        },
                        {
                          value: "Rev",
                          label: "Rev",
                        },
                        {
                          value: "Dr",
                          label: "Dr",
                        },
                        {
                          value: "Prefer not to say",
                          label: "Prefer not to say",
                        },
                      ]}
                    />
                  </div>
                  <div className="flex-1 pt-4 md:pt-0">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      {t("First Name")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="shadow-sm bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      required
                    />
                  </div>
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    {t("Last Name")}
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="shadow-sm bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    {t("Your email")}
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="shadow-sm bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                    placeholder="example@gmail.com"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    {t("Country")}
                  </label>
                  <CountrySelector
                    id={"country-selector"}
                    open={isOpen}
                    onToggle={() => setIsOpen(!isOpen)}
                    onChange={setCountry}
                    selectedValue={COUNTRIES.find(
                      (option) => option.value === country
                    )}
                  />
                </div>

                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    {t("Telephone")}
                  </label>
                  <PhoneInput
                    country={"rw"}
                    value={phoneNumber}
                    onChange={handleChange}
                    countryCodeEditable={false}
                    inputProps={{
                      required: true,
                    }}
                    buttonStyle={{
                      height: "40px",
                      border: "1px solid rgba(128, 128, 128, 0.5)",
                      color: "black",
                    }}
                    inputStyle={{
                      height: "40px",
                      width: "100%",
                      border: "1px solid rgba(128, 128, 128, 0.5)",
                      color: "black",
                    }}
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    {t("Job Title")}
                  </label>
                  <input
                    type="text"
                    id="subject"
                    className="block p-3 w-full text-sm outline-none text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    {t("Company")}
                  </label>
                  <input
                    type="text"
                    id="subject"
                    className="block p-3 w-full text-sm outline-none text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    {t("Howhear")}
                  </label>
                  <Select
                    style={{ width: "100%", height: 42 }}
                    showSearch
                    placeholder="Select"
                    optionFilterProp="children"
                    // onChange={onChange}
                    options={[
                      {
                        value: "Website",
                        label: "Website",
                      },
                      {
                        value: "Twitter",
                        label: "Twitter",
                      },
                      {
                        value: "Facebook",
                        label: "Facebook",
                      },
                      {
                        value: "Instagram",
                        label: "Instagram",
                      },
                      {
                        value: "LinkedIn",
                        label: "LinkedIn",
                      },
                      {
                        value: "Friends / Colleague",
                        label: "Friends / Colleague",
                      },
                      {
                        value: "College/ University",
                        label: "College/ University",
                      },
                      {
                        value: "Others",
                        label: "Others",
                      },
                    ]}
                  />
                </div>
                <div className="sm:col-span-2">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    {t("Your message")}
                  </label>
                  <textarea
                    id="message"
                    className="block p-2.5 w-full text-sm text-gray-900 outline-none bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="Message..."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-500 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300"
                >
                  {t("Send message")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
