import React, { useState } from "react";
import { Button } from "antd";
import { SearchButton } from "../../components/Button/SearchButton";
import TopSection from "../../components/TopSection";
import { CourseByAllocation } from "../../assets/course/courseByCategory";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CourseCalendarPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e: any) => {
    const query = e?.target?.value;
    setSearchQuery(query);
  };

  var today = new Date();
  var dayOfWeek = today.getDay();
  var abbreviatedDaysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  var abbreviatedDay = abbreviatedDaysOfWeek[dayOfWeek];
  var currentDate = today.getDate();
  var currentMonth = today.getMonth();
  var currentYear = today.getFullYear();

  return (
    <div className="bg-white border pt-20 lg:pt-36 text-gray-900">
      {/* <TopSection title={"Course Calendar"} bgImage={"calendar"} /> */}
      <div className="w-full max-w-[1100px] md:mx-auto md:p-4 md:py-8 px-6">
        <div className="h-16 my-4 pt-2">
          <SearchButton
            handleChange={handleSearchChange}
            searchQuery={searchQuery}
          />
        </div>
        <div className="flex items-center my-2 pl-6 md:pl-0">
          <h1>January {currentYear}</h1>
          <div className="h-0.5 w-1/2 md:w-4/5 ml-4 bg-gray-200"></div>
        </div>
        <div className="text-gray-700 mx-4">
          <div className="flow-root w-full md:w-3/4">
            {CourseByAllocation?.map((el: any) => (
              <ul className="text-gray-900 max-w-full">
                <li className="py-4 flex  space-x-6">
                  <div className="flex-shrink-0 flex flex-col">
                    <span className="text-sm text-gray-700 truncate">
                      {abbreviatedDay}
                    </span>
                    <p className="font-bold">{currentDate}</p>
                  </div>
                  <div className="flex items-center ">
                    <div className="flex-1 min-w-0 ms-4">
                      <p className="text-sm text-gray-700 truncate">
                        {currentDate} December - 30 December
                      </p>
                      <p className="text-xl font-semibold md:font-bold font-sans text-gray-900 w-fit">
                        {el?.title}
                      </p>
                      <p className="text-sm text-gray-700 truncate py-1.5">
                        {" "}
                        <Button
                          className="bg-[#003d8c] text-white rounded-3xl capitalize flex items-center justify-center cursor-pointer"
                          onClick={() => navigate(`/programme/${el?.category}`)}
                        >
                          {" "}
                          <span>{t(el?.category)}</span>
                        </Button>
                      </p>
                      <p className="text-sm text-gray-700 truncate pb-1.5">
                        {" "}
                        <span className="font-semibold">Dubai,</span>{" "}
                        {/* London, United Kingdom */}
                      </p>
                      <p className="text-sm text-gray-700 truncate font-semibold">
                        {`$${el?.courseFess?.toLocaleString()}`}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCalendarPage;
