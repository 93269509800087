import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
// import AboutPage from "../pages/AboutPage";
import CoursePage from "../pages/courses/CoursePage";
import PageNotFound from "../pages/PageNotFound";
import CourseDetails from "../pages/courses/CourseDetails";
import CourseByCategory from "../pages/courses/CourseByCategory";
import Workshop from "../pages/Workshop";
import ContactPage from "../pages/ContactPage";
import CourseCalendarPage from "../pages/courses/CourseCalendarPage";
import Team from "../pages/aboutPages/Team";
import TrainingConsultant from "../pages/aboutPages/TrainingConsultant";
import Vanues from "../pages/aboutPages/Vanues";
import About from "../pages/aboutPages/About";
import TermsAndCondition from "../pages/infoPage/TermsAndCondition";
import CourseAlocation from "../pages/infoPage/CourseAlocation";
import Visa from "../pages/infoPage/Visa";
import Plicies from "../pages/infoPage/Policies";
import FAQ from "../pages/FAQ";
import RegisterForCourse from "../pages/RegisterForCourse";
import WorkshopDetails from "../components/cards/WorkshopDetails";
import ConsultantDetailsPage from "../pages/ConsultantDetailsPage";

const MainRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/infomation/Terms" element={<TermsAndCondition />} />
      <Route path="/infomation/policies" element={<Plicies />} />
      <Route
        path="/infomation/programme/alocation"
        element={<CourseAlocation />}
      />
      <Route path="/infomation/visa" element={<Visa />} />
      <Route path="/infomation/faq" element={<FAQ />} />
      {/* <Route path="/about" element={<AboutPage />} /> */}
      <Route path="/about" element={<About />} />
      <Route path="/about/team" element={<Team />} />
      <Route
        path="/about/training/consultant"
        element={<TrainingConsultant />}
      />
      <Route
        path="/about/training/consultant/details"
        element={<ConsultantDetailsPage />}
      />
      <Route path="/about/vanues" element={<Vanues />} />
      <Route path="/workshop" element={<Workshop />} />
      <Route path="/workshop/details" element={<WorkshopDetails />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/programme" element={<CoursePage />} />
      <Route path="/programme/calendar" element={<CourseCalendarPage />} />
      <Route path="/programme/:catName" element={<CourseByCategory />} />
      <Route path="/programme/:catName/:courseId" element={<CourseDetails />} />
      <Route
        path="/programme/:catName/:courseId/register"
        element={<RegisterForCourse route="course" />}
      />
      <Route
        path="/workshop/:workshopName/register"
        element={<RegisterForCourse route="workshop" />}
      />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
};

export default MainRoute;
