import Image1 from "../../assets/photo/11111.jpeg";
import TracyImage from "../../assets/photo/3333.jpeg";
import Trainer from "../../assets/images/trainer.jpg";
import Marketing from "../../assets/photo/0000.jpg";
import Advisor from "../../assets/photo/44444.jpg";
import MDirector from "../../assets/images/director.jpg";
import Const1 from "../../assets/constants/cons1.jpg";
import TopSection from "../../components/TopSection";
import { useTranslation } from "react-i18next";
//

const Team = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#e4e4e4] pt-20 lg:pt-36 pb-10 border">
      <TopSection
        title={t("MeetourTeam")}
        content={t("MeetourTeamDesc")}
        bgImage={"ourTeam"}
      />
      <div className="w-full max-w-[1100px] md:mx-auto md:p-4 md:py-8">
        <div className="text-gray-700 mt-6 lg:mt-20 space-y-8">
          <div className="flex flex-col bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className=" pb-10 w-full md:w-[3000px] px-[20px]">
              <img
                className="object-cover w-[400px] md:rounded-none"
                src={MDirector}
                alt=""
              />
            </div>
            <div className="flex flex-col xw-[100%] pb-6 leading-normal px-6">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Donat NKURIKIYINTWALI
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("DonatRole")}
              </h6>
              <p className="mb-3 font-normal text-gray-700">
                {t("DonatSummary1")}
                <br />
                <br />
                {t("DonatSummary2")}
                <br />
                <br />
              </p>
            </div>
          </div>
          <div className="flex flex-col bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className="md:px-10 md:w-[1500px] pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full md:rounded-none"
                src={Advisor}
                alt=""
              />
            </div>
            <div className="flex flex-col  pb-6 leading-normal px-6">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Kirsten Fagerback
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("KirstenRole")}
              </h6>
              <p className="mb-3 font-normal text-gray-700">
                {t("KirstenSummary1")}
                <br />
                {t("KirstenSummary2")}
              </p>
            </div>
          </div>
          <div className="flex flex-col bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className="md:px-10 md:w-4/5 pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full xmd:h-auto md:rounded-none"
                src={Const1}
                alt=""
              />
            </div>
            <div className="flex flex-col pb-6 leading-normal px-6 w-[100%]">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Mr. Evans M.Nyarwari
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("EdgerOgaoRole")}
              </h6>
              <p className="mb-3 font-normal text-gray-700">
                {t("EvansNyarwar")}
              </p>
              {/* <p className="mb-3 font-normal text-gray-700">
                Edgar Ogao 
                <br />
                <br />
                {t("edgarOgaoSummary2")}.
                <br />
                {t("edgarOgaoSummary3")}
                <br />
                <br />
              </p> */}
            </div>
          </div>
          <div className="flex flex-col xitems-center bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className="md:px-10 md:w-[1600px] pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full xmd:h-auto md:rounded-none"
                src={TracyImage}
                alt=""
              />
            </div>
            <div className="flex flex-col pb-6 leading-normal px-6">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Tracy Mumbua
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("TracyMumbuaRole")}
              </h6>
              <p className=" font-normal text-gray-700">
                {t("TracyMumbuaSummary1")}
                <br />
                {t("TracyMumbuaSummary2")}
                <br />
                <br />
                {t("TracyMumbuaSummary3")}
              </p>
            </div>
          </div>
          <div className="flex flex-col xitems-center bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className="md:px-10 md:w-[2000px] pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full md:rounded-none"
                src={Image1}
                alt=""
              />
            </div>
            <div className="flex flex-col xjustify-between pb-6 leading-normal px-6">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                RITA PETER
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("RITAPETERRole")}
              </h6>
              <p className="mb-3 font-normal text-gray-700">
                {t("RITAPETERSummary1")}
                <br />
                <br />
                {t("RITAPETERSummary2")}
                <br />
                <br />
                {t("RITAPETERSummary3")}
                <br />
                <br />
                {t("RITAPETERSummary4")}
              </p>
            </div>
          </div>

          <div className="flex flex-col xitems-center bg-[#ffffff] md:flex-row md:max-w-6xl mx-6 md:mx-0 pt-10">
            <div className="md:px-10 md:w-[2000px] pb-10 w-full px-[20px]">
              <img
                className="object-cover w-full md:rounded-none"
                src={Marketing}
                alt=""
              />
            </div>
            <div className="flex flex-col xjustify-between pb-6 leading-normal px-6">
              <h5 className="mb-2 text-2xl font-medium tracking-tight text-[#003d8f]">
                Jane Onywere
              </h5>
              <h6 className="mb-2 text-2xl font-medium tracking-tight pb-4">
                {t("JaneOnywereRole")}
              </h6>
              <p className="mb-3 font-normal text-gray-700">
                {t("JaneOnywereSummary1")}
                <br />
                <br />
                {t("JaneOnywereSummary2")}
                <br />
                <br />
                {t("JaneOnywereSummary3")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Team;
