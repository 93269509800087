import AdvancedManagement1 from "../../assets/images/advancedCorporateGovernanceFinancialInstitution.png";
import AdvancedManagement2 from "../../assets/images/advanced2.png";
import AdvancedManagement3 from "../../assets/images/advanceManagementSkills.png";
import AdvancedManagement4 from "../../assets/images/advanced2.png";
import AdvancedManagement5 from "../../assets/images/advanced2.png";
import AdvancedManagement6 from "../../assets/images/advanced2.png";
import AdvancedManagement7 from "../../assets/images/advanced2.png";
import AdvancedManagement8 from "../../assets/images/advanced2.png";
import AdvancedManagement9 from "../../assets/images/advanced2.png";
import AdvancedManagement10 from "../../assets/images/advanced2.png";
import AdvancedManagement11 from "../../assets/images/advanced2.png";
import AdvancedManagement12 from "../../assets/images/advanced2.png";
import AdvancedManagement13 from "../../assets/images/advanced2.png";
import AdvancedManagement14 from "../../assets/images/advanced2.png";
import AdvancedManagement15 from "../../assets/images/advanced2.png";
import AdvancedManagement16 from "../../assets/images/advanced2.png";
import AdvancedManagement17 from "../../assets/images/advanced2.png";
import AdvancedManagement18 from "../../assets/images/advanced2.png";
import Mskills1 from "../../assets/images/mSkills1.jpg";
import Mskills2 from "../../assets/images/mSkills2.webp";
import Mskills3 from "../../assets/images/mSkills3.jpg";
import Mskills4 from "../../assets/images/mSkills4.webp";
import Mskills5 from "../../assets/images/mSkills5.png";
import Mskills6 from "../../assets/images/mSkills6.webp";
import Mskills7 from "../../assets/images/mSkills7.webp";
import Mskills8 from "../../assets/images/mSkills8.png";

// Finance Images
import Finance1 from "../../assets/images/finance1.webp";
import Finance2 from "../../assets/images/finance2.webp";
import Finance3 from "../../assets/images/finance3.webp";
import Finance4 from "../../assets/images/finance4.jpg";
import Finance5 from "../../assets/images/finance5.jpg";
import Finance6 from "../../assets/images/finance6.webp";
import Finance7 from "../../assets/images/finance7.webp";
import Finance8 from "../../assets/images/finance8.jpeg";
import Finance9 from "../../assets/images/finance9.jpg";
import Finance10 from "../../assets/images/finance10.webp";
import Finance11 from "../../assets/images/finance11.png";
import Finance12 from "../../assets/images/finance12.jpg";
import Finance13 from "../../assets/images/finance13.webp";
import Finance14 from "../../assets/images/finance14.webp";
import Finance15 from "../../assets/images/finance15.jpg";
import Finance16 from "../../assets/images/finance16.jpg";
import Finance17 from "../../assets/images/finance17.jpg";
import Finance18 from "../../assets/images/finance18.webp";
import Finance19 from "../../assets/images/finance19.jpg";
import Finance20 from "../../assets/images/finance20.jpg";
import Finance21 from "../../assets/images/finance21.png";
import Finance22 from "../../assets/images/finance22.png";
import Finance23 from "../../assets/images/finance23.jpg";
import Finance24 from "../../assets/images/finance24.webp";

// Oil and gas Image
import Oil1 from "../../assets/images/oil1.jpg";
import Oil2 from "../../assets/images/oil2.jpg";
import Oil3 from "../../assets/images/oil3.jpeg";
import Oil4 from "../../assets/images/oil4.jpg";
import Oil5 from "../../assets/images/oil5.jpg";
import Oil6 from "../../assets/images/oil6.jpg";
import Oil7 from "../../assets/images/oil7.jpg";

// Human Resources Image
import HumanResourse1 from "../../assets/images/humanRe1.jpg";
import HumanResourse2 from "../../assets/images/humanRe2.jpg";
import HumanResourse3 from "../../assets/images/humanRe3.jpg";
import HumanResourse4 from "../../assets/images/humanRe4.jpeg";
import HumanResourse5 from "../../assets/images/humanre5.png";
import HumanResourse6 from "../../assets/images/humanRe6.jpeg";
import HumanResourse7 from "../../assets/images/humanRe7.jpeg";
import HumanResourse8 from "../../assets/images/humanRe8.png";
import HumanResourse9 from "../../assets/images/humanRe9.webp";
import HumanResourse10 from "../../assets/images/humanRe10.jpeg";
import HumanResourse11 from "../../assets/images/humanRe11.webp";
import HumanResourse12 from "../../assets/images/humanRe12.jpg";

// Law Image
import Law1 from "../../assets/images/law1.png";
import Law2 from "../../assets/images/law2.jpg";
import Law3 from "../../assets/images/law3.jpg";
import Law4 from "../../assets/images/law4.jpg";
import Law5 from "../../assets/images/law5.jpg";
import Law6 from "../../assets/images/law6.jpg";
import Law7 from "../../assets/images/law7.jpg";
import Law8 from "../../assets/images/law8.png";
import Law9 from "../../assets/images/law9.jpg";

// Operations Management Images
import OperationM1 from "../../assets/images/oManagement1.png";
import OperationM2 from "../../assets/images/oManagement2.avif";
import OperationM3 from "../../assets/images/oManagement3.png";
import OperationM4 from "../../assets/images/oManagement4.png";
import OperationM5 from "../../assets/images/oManagement5.jpg";
import OperationM6 from "../../assets/images/oManagement6.jpg";
import OperationM7 from "../../assets/images/oManagement7.png";
import OperationM8 from "../../assets/images/oManagement8.jpg";
import OperationM9 from "../../assets/images/oManagement9.svg";
import OperationM10 from "../../assets/images/oManagement10.webp";
import OperationM11 from "../../assets/images/oManagement11.png";
import OperationM12 from "../../assets/images/oManagement12.webp";
import OperationM13 from "../../assets/images/oManagement13.png";
import OperationM14 from "../../assets/images/oManagement14.webp";
import OperationM15 from "../../assets/images/oManagement15.png";
import OperationM16 from "../../assets/images/oManagement16.jpg";
import OperationM17 from "../../assets/images/oManagement17.jpeg";
import OperationM18 from "../../assets/images/oManagement18.png";
import OperationM19 from "../../assets/images/oManagement19.jpg";
import OperationM20 from "../../assets/images/oManagement20.png";

// Secretarial Pratices Images
import Sskills1 from "../../assets/images/mSkills1.jpg";
import Sskills2 from "../../assets/images/mSkills2.webp";
import Sskills3 from "../../assets/images/mSkills3.jpg";

// Sales Images
import Sales1 from "../../assets/images/sales1.jpg";
import Sales2 from "../../assets/images/sales2.png";
import Sales3 from "../../assets/images/sales3.png";

export const Courses = [
  //Management Skills

  {
    id: 1,
    image: AdvancedManagement1,
    title: "management1Title",
    desc: "management1Desc",
    WhoShouldAttend: [
      "management1WhoShouldAttend",
      "management1WhoShouldAttend1",
      "management1WhoShouldAttend2",
      "management1WhoShouldAttend3",
      "management1WhoShouldAttend4",
      "management1WhoShouldAttend5",
      "management1WhoShouldAttend6",
      "management1WhoShouldAttend7",
      "management1WhoShouldAttend8",
      "management1WhoShouldAttend9",
    ],
    courseExpectation: [
      "management1courseExpectation1",
      "management1courseExpectation2",
      "management1courseExpectation3",
      "management1courseExpectation4",
      "management1courseExpectation5",
      "management1courseExpectation6",
      "management1courseExpectation7",
    ],
    courseContent: [
      {
        title: "management1courseContent1title",
        contents: [
          "management1courseContent1Desc1",
          "management1courseContent1Desc2",
          "management1courseContent1Desc3",
          "management1courseContent1Desc4",
          "management1courseContent1Desc5",
        ],
      },
      {
        title: "management1courseContent2title",
        contents: [
          "management1courseContent2Desc1",
          "management1courseContent2Desc2",
          "management1courseContent2Desc3",
          "management1courseContent2Desc4",
          "management1courseContent2Desc5",
        ],
      },
      {
        title: "management1courseContent3title",
        contents: [
          "management1courseContent3Desc1",
          "management1courseContent3Desc2",
          "management1courseContent3Desc3",
          "management1courseContent3Desc4",
          "management1courseContent3Desc5",
        ],
      },
      {
        title: "management1courseContent4title",
        contents: [
          "management1courseContent4Desc1",
          "management1courseContent4Desc2",
          "management1courseContent4Desc3",
          "management1courseContent4Desc4",
          "management1courseContent4Desc5",
        ],
      },
      {
        title: "management1courseContent5title",
        contents: [
          "management1courseContent5Desc1",
          "management1courseContent5Desc2",
          "management1courseContent5Desc3",
          "management1courseContent5Desc4",
          "management1courseContent5Desc5",
          "management1courseContent5Desc6",
        ],
      },
      {
        title: "management1courseContent6title",
        contents: [
          "management1courseContent6Desc1",
          "management1courseContent6Desc2",
          "management1courseContent6Desc3",
          "management1courseContent6Desc4",
        ],
      },
      {
        title: "management1courseContent7title",
        contents: [
          "management1courseContent7Desc1",
          "management1courseContent7Desc2",
          "management1courseContent7Desc3",
          "management1courseContent7Desc4",
          "management1courseContent7Desc5",
        ],
      },
      {
        title: "management1courseContent8title",
        contents: [
          "management1courseContent8Desc1",
          "management1courseContent8Desc2",
          "management1courseContent8Desc3",
          "management1courseContent8Desc4",
        ],
      },
      {
        title: "management1courseContent9title",
        contents: [
          "management1courseContent9Desc1",
          "management1courseContent9Desc2",
          "management1courseContent9Desc3",
          "management1courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "AD01",
  },
  {
    id: 2,
    image: AdvancedManagement2,
    title: "management2Title",
    desc: "management2Desc",
    WhoShouldAttend: [
      "management2WhoShouldAttend1",
      "management2WhoShouldAttend2",
      "management2WhoShouldAttend3",
      "management2WhoShouldAttend4",
      "management2WhoShouldAttend5",
      "management2WhoShouldAttend6",
      "management2WhoShouldAttend7",
      "management2WhoShouldAttend8",
      "management2WhoShouldAttend9",
    ],
    courseExpectation: [
      "management2courseExpectation1",
      "management2courseExpectation2",
      "management2courseExpectation3",
      "management2courseExpectation4",
      "management2courseExpectation5",
      "management2courseExpectation6",
      "management2courseExpectation7",
    ],
    courseContent: [
      {
        title: "management2courseContent1title",
        contents: [
          "management2courseContent1Desc1",
          "management2courseContent1Desc2",
          "management2courseContent1Desc3",
          "management2courseContent1Desc4",
          "management2courseContent1Desc5",
          "management2courseContent1Desc6",
        ],
      },
      {
        title: "management2courseContent2title",
        contents: [
          "management2courseContent2Desc1",
          "management2courseContent2Desc2",
          "management2courseContent2Desc3",
          "management2courseContent2Desc4",
          "management2courseContent2Desc5",
        ],
      },
      {
        title: "management2courseContent3title",
        contents: [
          "management2courseContent3Desc1",
          "management2courseContent3Desc2",
          "management2courseContent3Desc3",
          "management2courseContent3Desc4",
          "management2courseContent3Desc5",
        ],
      },
      {
        title: "management2courseContent4title",
        contents: [
          "management2courseContent4Desc1",
          "management2courseContent4Desc2",
          "management2courseContent4Desc3",
          "management2courseContent4Desc4",
          "management2courseContent4Desc5",
        ],
      },
      {
        title: "management2courseContent5title",
        contents: [
          "management2courseContent5Desc1",
          "management2courseContent5Desc2",
          "management2courseContent5Desc3",
          "management2courseContent5Desc4",
          "management2courseContent5Desc5",
          "management2courseContent5Desc6",
        ],
      },
      {
        title: "management2courseContent6title",
        contents: [
          "management2courseContent6Desc1",
          "management2courseContent6Desc2",
          "management2courseContent6Desc3",
          "management2courseContent6Desc4",
          "management2courseContent6Desc5",
        ],
      },
      {
        title: "management2courseContent7title",
        contents: [
          "management2courseContent7Desc1",
          "management2courseContent7Desc2",
          "management2courseContent7Desc3",
          "management2courseContent7Desc4",
          "management2courseContent7Desc5",
        ],
      },
      {
        title: "management2courseContent8title",
        contents: [
          "management2courseContent8Desc1",
          "management2courseContent8Desc2",
          "management2courseContent8Desc3",
          "management2courseContent8Desc4",
          "management2courseContent8Desc5",
        ],
      },
      {
        title: "management2courseContent9title",
        contents: [
          "management2courseContent9Desc1",
          "management2courseContent9Desc2",
          "management2courseContent9Desc3",
          "management2courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "AD02",
  },
  {
    id: 3,
    image: AdvancedManagement3,
    title: "management3Title",
    desc: "management3Desc",
    WhoShouldAttend: [
      "management3WhoShouldAttend1",
      "management3WhoShouldAttend2",
      "management3WhoShouldAttend3",
      "management3WhoShouldAttend4",
      "management3WhoShouldAttend5",
      "management3WhoShouldAttend6",
      "management3WhoShouldAttend7",
      "management3WhoShouldAttend8",
      "management3WhoShouldAttend9",
      "management3WhoShouldAttend10",
      "management3WhoShouldAttend11",
    ],
    courseExpectation: [
      "management3courseExpectation1",
      "management3courseExpectation2",
      "management3courseExpectation3",
      "management3courseExpectation4",
      "management3courseExpectation5",
      "management3courseExpectation6",
      "management3courseExpectation7",
      "management3courseExpectation8",
      "management3courseExpectation9",
    ],
    courseContent: [
      {
        title: "management3courseContent1title",
        contents: [
          "management3courseContent1Desc1",
          "management3courseContent1Desc2",
          "management3courseContent1Desc3",
          "management3courseContent1Desc4",
          "management3courseContent1Desc5",
        ],
      },
      {
        title: "management3courseContent2title",
        contents: [
          "management3courseContent2Desc1",
          "management3courseContent2Desc2",
          "management3courseContent2Desc3",
          "management3courseContent2Desc4",
        ],
      },
      {
        title: "management3courseContent3title",
        contents: [
          "management3courseContent3Desc1",
          "management3courseContent3Desc2",
          "management3courseContent3Desc3",
          "management3courseContent3Desc4",
        ],
      },
      {
        title: "management3courseContent4title",
        contents: [
          "management3courseContent4Desc1",
          "management3courseContent4Desc2",
          "management3courseContent4Desc3",
          "management3courseContent4Desc4",
        ],
      },
      {
        title: "management3courseContent5title",
        contents: [
          "management3courseContent5Desc1",
          "management3courseContent5Desc2",
          "management3courseContent5Desc3",
          "management3courseContent5Desc4",
        ],
      },
      {
        title: "management3courseContent6title",
        contents: [
          "management3courseContent6Desc1",
          "management3courseContent6Desc2",
          "management3courseContent6Desc3",
          "management3courseContent6Desc4",
        ],
      },
      {
        title: "management3courseContent7title",
        contents: [
          "management3courseContent7Desc1",
          "management3courseContent7Desc2",
          "management3courseContent7Desc3",
          "management3courseContent7Desc4",
        ],
      },
      {
        title: "management3courseContent8title",
        contents: [
          "management3courseContent8Desc1",
          "management3courseContent8Desc2",
          "management3courseContent8Desc3",
          "management3courseContent8Desc4",
        ],
      },
      {
        title: "management3courseContent9title",
        contents: [
          "management3courseContent9Desc1",
          "management3courseContent9Desc2",
          "management3courseContent9Desc3",
          "management3courseContent9Desc4",
        ],
      },
      {
        title: "management3courseContent10title",
        contents: [
          "management3courseContent10Desc1",
          "management3courseContent10Desc2",
          "management3courseContent10Desc3",
          "management3courseContent10Desc4",
        ],
      },
      {
        title: "management3courseContent11title",
        contents: [
          "management3courseContent11Desc1",
          "management3courseContent11Desc2",
          "management3courseContent11Desc3",
          "management3courseContent11Desc4",
        ],
      },
      {
        title: "management3courseContent12title",
        contents: [
          "management3courseContent12Desc1",
          "management3courseContent12Desc2",
          "management3courseContent12Desc3",
          "management3courseContent12Desc4",
        ],
      },
      {
        title: "management3courseContent13title",
        contents: [
          "management3courseContent13Desc1",
          "management3courseContent13Desc2",
          "management3courseContent13Desc3",
          "management3courseContent13Desc4",
        ],
      },
      {
        title: "management3courseContent14title",
        contents: [
          "management3courseContent14Desc4",
          "management3courseContent14Desc2",
          "management3courseContent14Desc3",
          "management3courseContent14Desc4",
        ],
      },
    ],
    courseFess: 7940,
    category: "Management",
    courseCode: "AD03",
  },
  {
    id: 4,
    image: AdvancedManagement4,
    title: "management4Title",
    desc: "management4Desc",
    WhoShouldAttend: [
      "management4WhoShouldAttend1",
      "management4WhoShouldAttend2",
      "management4WhoShouldAttend3",
    ],
    courseExpectation: [
      "management4courseExpectation1",
      "management4courseExpectation2",
      "management4courseExpectation3",
      "management4courseExpectation4",
      "management4courseExpectation5",
      "management4courseExpectation6",
      "management4courseExpectation7",
    ],
    courseContent: [
      {
        title: "management4courseContent1title",
        contents: [
          "management4courseContent1Desc1",
          "management4courseContent1Desc2",
          "management4courseContent1Desc3",
          "management4courseContent1Desc4",
          "management4courseContent1Desc5",
        ],
      },
      {
        title: "management4courseContent2title",
        contents: [
          "management4courseContent2Desc1",
          "management4courseContent2Desc2",
          "management4courseContent2Desc3",
          "management4courseContent2Desc4",
        ],
      },
      {
        title: "management4courseContent3title",
        contents: [
          "management4courseContent3Desc1",
          "management4courseContent3Desc2",
          "management4courseContent3Desc3",
          "management4courseContent3Desc4",
          "management4courseContent3Desc5",
          "management4courseContent3Desc6",
        ],
      },
      {
        title: "management4courseContent4title",
        contents: [
          "management4courseContent4Desc1",
          "management4courseContent4Desc2",
          "management4courseContent4Desc3",
          "management4courseContent4Desc4",
        ],
      },
      {
        title: "management4courseContent5title",
        contents: [
          "management4courseContent5Desc1",
          "management4courseContent5Desc2",
          "management4courseContent5Desc3",
          "management4courseContent5Desc4",
          "management4courseContent5Desc5",
        ],
      },
      {
        title: "management4courseContent6title",
        contents: [
          "management4courseContent6Desc1",
          "management4courseContent6Desc2",
          "management4courseContent6Desc3",
        ],
      },
      {
        title: "management4courseContent7title",
        contents: [
          "management4courseContent7Desc1",
          "management4courseContent7Desc2",
          "management4courseContent7Desc3",
        ],
      },
      {
        title: "management4courseContent8title",
        contents: [
          "management4courseContent8Desc1",
          "management4courseContent8Desc2",
          "management4courseContent8Desc3",
        ],
      },
      {
        title: "management4courseContent9title",
        contents: [
          "management4courseContent9Desc1",
          "management4courseContent9Desc2",
          "management4courseContent9Desc3",
          "management4courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "AD04",
  },
  {
    id: 5,
    image: AdvancedManagement5,
    title: "management5Title",
    desc: "management5Desc",
    WhoShouldAttend: [
      "management5WhoShouldAttend1",
      "management5WhoShouldAttend2",
      "management5WhoShouldAttend3",
    ],
    courseExpectation: [
      "management5courseExpectation1",
      "management5courseExpectation2",
      "management5courseExpectation3",
      "management5courseExpectation4",
    ],
    courseContent: [
      {
        title: "management5courseContent1title",
        contents: [
          "management5courseContent1Desc1",
          "management5courseContent1Desc2",
          "management5courseContent1Desc3",
          "management5courseContent1Desc4",
          "management5courseContent1Desc5",
          "management5courseContent1Desc6",
          "management5courseContent1Desc7",
        ],
      },
      {
        title: "management5courseContent2title",
        contents: [
          "management5courseContent2Desc1",
          "management5courseContent2Desc2",
          "management5courseContent2Desc3",
          "management5courseContent2Desc4",
          "management5courseContent2Desc5",
        ],
      },
      {
        title: "management5courseContent3title",
        contents: [
          "management5courseContent3Desc1",
          "management5courseContent3Desc2",
          "management5courseContent3Desc3",
          "management5courseContent3Desc4",
        ],
      },
      {
        title: "management5courseContent4title",
        contents: [
          "management5courseContent4Desc1",
          "management5courseContent4Desc2",
          "management5courseContent4Desc3",
          "management5courseContent4Desc4",
          "management5courseContent4Desc5",
        ],
      },
      {
        title: "management5courseContent5title",
        contents: [
          "management5courseContent5Desc1",
          "management5courseContent5Desc2",
          "management5courseContent5Desc3",
          "management5courseContent5Desc4",
          "management5courseContent5Desc5",
        ],
      },
      {
        title: "management5courseContent6title",
        contents: [
          "management5courseContent6Desc1",
          "management5courseContent6Desc2",
          "management5courseContent6Desc3",
          "management5courseContent6Desc4",
          "management5courseContent6Desc5",
          "management5courseContent6Desc6",
        ],
      },
      {
        title: "management5courseContent7title",
        contents: [
          "management5courseContent7Desc1",
          "management5courseContent7Desc2",
          "management5courseContent7Desc3",
          "management5courseContent7Desc4",
          "management5courseContent7Desc5",
        ],
      },
      {
        title: "management5courseContent8title",
        contents: [
          "management5courseContent8Desc1",
          "management5courseContent8Desc2",
          "management5courseContent8Desc3",
          "management5courseContent8Desc4",
          "management5courseContent8Desc5",
        ],
      },
      {
        title: "management5courseContent9title",
        contents: [
          "management5courseContent9Desc1",
          "management5courseContent9Desc2",
          "management5courseContent9Desc3",
          "management5courseContent9Desc4",
          "management5courseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "AD05",
  },
  {
    id: 6,
    image: AdvancedManagement6,
    title: "management6Title",
    desc: "management6Desc",
    WhoShouldAttend: [
      "management6WhoShouldAttend1",
      "management6WhoShouldAttend2",
      "management6WhoShouldAttend3",
      "management6WhoShouldAttend4",
      "management6WhoShouldAttend5",
      "management6WhoShouldAttend6",
      "management6WhoShouldAttend7",
      "management6WhoShouldAttend8",
      "management6WhoShouldAttend9",
      "management6WhoShouldAttend10",
      "management6WhoShouldAttend11",
      "management6WhoShouldAttend12",
      "management6WhoShouldAttend13",
      "management6WhoShouldAttend14",
    ],
    courseExpectation: [
      "management6courseExpectation1",
      "management6courseExpectation2",
      "management6courseExpectation3",
      "management6courseExpectation4",
      "management6courseExpectation5",
      "management6courseExpectation6",
      "management6courseExpectation7",
      "management6courseExpectation8",
      "management6courseExpectation9",
    ],
    courseContent: [
      {
        title: "management6courseContent1title",
        contents: [
          "management6courseContent1Desc1",
          "management6courseContent1Desc2",
          "management6courseContent1Desc3",
          "management6courseContent1Desc4",
        ],
      },
      {
        title: "management6courseContent2title",
        contents: [
          "management6courseContent2Desc1",
          "management6courseContent2Desc2",
          "management6courseContent2Desc3",
          "management6courseContent2Desc4",
        ],
      },
      {
        title: "management6courseContent3title",
        contents: [
          "management6courseContent3Desc1",
          "management6courseContent3Desc2",
          "management6courseContent3Desc3",
          "management6courseContent3Desc4",
        ],
      },
      {
        title: "management6courseContent4title",
        contents: [
          "management6courseContent4Desc1",
          "management6courseContent4Desc2",
          "management6courseContent4Desc3",
          "management6courseContent4Desc4",
        ],
      },
      {
        title: "management6courseContent5title",
        contents: [
          "management6courseContent5Desc1",
          "management6courseContent5Desc2",
          "management6courseContent5Desc3",
          "management6courseContent5Desc4",
        ],
      },
      {
        title: "management6courseContent6title",
        contents: [
          "management6courseContent6Desc1",
          "management6courseContent6Desc2",
          "management6courseContent6Desc3",
          "management6courseContent6Desc4",
        ],
      },
      {
        title: "management6courseContent7title",
        contents: [
          "management6courseContent7Desc1",
          "management6courseContent7Desc2",
          "management6courseContent7Desc3",
          "management6courseContent7Desc4",
        ],
      },
      {
        title: "management6courseContent8title",
        contents: [
          "management6courseContent8Desc1",
          "management6courseContent8Desc2",
          "management6courseContent8Desc3",
          "management6courseContent8Desc4",
        ],
      },
      {
        title: "management6courseContent9title",
        contents: [
          "management6courseContent9Desc1",
          "management6courseContent9Desc2",
          "management6courseContent9Desc3",
          "management6courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "AD06",
  },
  {
    id: 7,
    image: AdvancedManagement7,
    title: "management7Title",
    desc: "management7Desc",
    WhoShouldAttend: [
      "management7WhoShouldAttend1",
      "management7WhoShouldAttend2",
      "management7WhoShouldAttend3",
      "management7WhoShouldAttend4",
      "management7WhoShouldAttend5",
      "management7WhoShouldAttend6",
      "management7WhoShouldAttend7",
      "management7WhoShouldAttend8",
    ],
    courseExpectation: [
      "management7courseExpectation1",
      "management7courseExpectation2",
      "management7courseExpectation3",
      "management7courseExpectation4",
      "management7courseExpectation5",
    ],
    courseContent: [
      {
        title: "management7courseContent1title",
        contents: [
          "management7courseContent1Desc1",
          "management7courseContent1Desc2",
          "management7courseContent1Desc3",
          "management7courseContent1Desc4",
          "management7courseContent1Desc5",
        ],
      },
      {
        title: "management7courseContent2title",
        contents: [
          "management7courseContent2Desc1",
          "management7courseContent2Desc2",
          "management7courseContent2Desc3",
          "management7courseContent2Desc4",
          "management7courseContent2Desc5",
          "management7courseContent2Desc6",
        ],
      },
      {
        title: "management7courseContent3title",
        contents: [
          "management7courseContent3Desc1",
          "management7courseContent3Desc2",
          "management7courseContent3Desc3",
          "management7courseContent3Desc4",
          "management7courseContent3Desc5",
        ],
      },
      {
        title: "management7courseContent4title",
        contents: [
          "management7courseContent4Desc1",
          "management7courseContent4Desc2",
          "management7courseContent4Desc3",
          "management7courseContent4Desc4",
          "management7courseContent4Desc5",
        ],
      },
      {
        title: "management7courseContent5title",
        contents: [
          "management7courseContent5Desc1",
          "management7courseContent5Desc2",
          "management7courseContent5Desc3",
          "management7courseContent5Desc4",
          "management7courseContent5Desc5",
          "management7courseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD07",
  },
  {
    id: 8,
    image: AdvancedManagement8,
    title: "management8Title",
    desc: "management8Desc",
    WhoShouldAttend: [
      "management8WhoShouldAttend1",
      "management8WhoShouldAttend2",
      "management8WhoShouldAttend3",
      "management8WhoShouldAttend4",
      "management8WhoShouldAttend5",
      "management8WhoShouldAttend6",
    ],
    courseExpectation: [
      "management8courseExpectation1",
      "management8courseExpectation2",
      "management8courseExpectation3",
      "management8courseExpectation4",
      "management8courseExpectation5",
    ],
    courseContent: [
      {
        title: "management8courseContent1title",
        contents: [
          "management8courseContent1Desc1",
          "management8courseContent1Desc2",
          "management8courseContent1Desc3",
          "management8courseContent1Desc4",
          "management8courseContent1Desc5",
          "management8courseContent1Desc6",
        ],
      },
      {
        title: "management8courseContent2title",
        contents: [
          "management8courseContent2Desc1",
          "management8courseContent2Desc2",
          "management8courseContent2Desc3",
          "management8courseContent2Desc4",
          "management8courseContent2Desc5",
        ],
      },
      {
        title: "management8courseContent3title",
        contents: [
          "management8courseContent3Desc1",
          "management8courseContent3Desc2",
          "management8courseContent3Desc3",
          "management8courseContent3Desc4",
          "management8courseContent3Desc5",
        ],
      },
      {
        title: "management8courseContent4title",
        contents: [
          "management8courseContent4Desc1",
          "management8courseContent4Desc2",
          "management8courseContent4Desc3",
          "management8courseContent4Desc4",
          "management8courseContent4Desc5",
        ],
      },
      {
        title: "management8courseContent5title",
        contents: [
          "management8courseContent5Desc1",
          "management8courseContent5Desc2",
          "management8courseContent5Desc3",
          "management8courseContent5Desc4",
          "management8courseContent5Desc5",
          "management8courseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD08",
  },
  {
    id: 9,
    image: AdvancedManagement9,
    title: "management9Title",
    desc: "management9Desc",
    WhoShouldAttend: [
      "management9WhoShouldAttend1",
      "management9WhoShouldAttend2",
    ],
    courseExpectation: [
      "management9courseExpectation1",
      "management9courseExpectation2",
      "management9courseExpectation3",
      "management9courseExpectation4",
      "management9courseExpectation5",
      "management9courseExpectation6",
    ],
    courseContent: [
      {
        title: "management9courseContent1title",
        contents: [
          "management9courseContent1Desc1",
          "management9courseContent1Desc2",
          "management9courseContent1Desc3",
          "management9courseContent1Desc4",
        ],
      },
      {
        title: "management9courseContent2title",
        contents: [
          "management9courseContent2Desc1",
          "management9courseContent2Desc2",
          "management9courseContent2Desc3",
          "management9courseContent2Desc4",
          "management9courseContent2Desc5",
        ],
      },
      {
        title: "management9courseContent3title",
        contents: [
          "management9courseContent3Desc1",
          "management9courseContent3Desc2",
          "management9courseContent3Desc3",
          "management9courseContent3Desc4",
        ],
      },
      {
        title: "management9courseContent4title",
        contents: [
          "management9courseContent4Desc1",
          "management9courseContent4Desc2",
          "management9courseContent4Desc3",
          "management9courseContent4Desc4",
        ],
      },
      {
        title: "management9courseContent5title",
        contents: [
          "management9courseContent5Desc1",
          "management9courseContent5Desc2",
          "management9courseContent5Desc3",
          "management9courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD09",
  },
  {
    id: 10,
    image: AdvancedManagement10,
    title: "management10Title",
    desc: "",
    WhoShouldAttend: [
      "management10WhoShouldAttend1",
      "management10WhoShouldAttend2",
      "management10WhoShouldAttend3",
      "management10WhoShouldAttend4",
      "management10WhoShouldAttend5",
      "management10WhoShouldAttend6",
      "management10WhoShouldAttend7",
    ],
    courseExpectation: [
      "management10courseExpectation1",
      "management10courseExpectation2",
      "management10courseExpectation3",
      "management10courseExpectation4",
      "management10courseExpectation5",
    ],
    courseContent: [
      {
        title: "management10courseContent1title",
        contents: [
          "management10courseContent1Desc1",
          "management10courseContent1Desc2",
          "management10courseContent1Desc3",
          "management10courseContent1Desc4",
          "management10courseContent1Desc5",
        ],
      },
      {
        title: "management10courseContent2title",
        contents: [
          "management10courseContent2Desc1",
          "management10courseContent2Desc2",
          "management10courseContent2Desc3",
          "management10courseContent2Desc4",
          "management10courseContent2Desc5",
        ],
      },
      {
        title: "management10courseContent3title",
        contents: [
          "management10courseContent3Desc1",
          "management10courseContent3Desc2",
          "management10courseContent3Desc3",
          "management10courseContent3Desc4",
          "management10courseContent3Desc5",
          "management10courseContent3Desc6",
        ],
      },
      {
        title: "management10courseContent4title",
        contents: [
          "management10courseContent4Desc1",
          "management10courseContent4Desc2",
          "management10courseContent4Desc3",
          "management10courseContent4Desc4",
        ],
      },
      {
        title: "management10courseContent5title",
        contents: [
          "management10courseContent5Desc1",
          "management10courseContent5Desc2",
          "management10courseContent5Desc3",
          "management10courseContent5Desc4",
          "management10courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD10",
  },
  {
    id: 11,
    image: AdvancedManagement11,
    title: "management11Title",
    desc: "management11Desc",
    WhoShouldAttend: [
      "management11WhoShouldAttend1",
      "management11WhoShouldAttend2",
      "management11WhoShouldAttend3",
      "management11WhoShouldAttend4",
      "management11WhoShouldAttend5",
      "management11WhoShouldAttend6",
      "management11WhoShouldAttend7",
      "management11WhoShouldAttend8",
      "management11WhoShouldAttend9",
      "management11WhoShouldAttend10",
      "management11WhoShouldAttend11",
      "management11WhoShouldAttend12",
      "management11WhoShouldAttend13",
      "management11WhoShouldAttend14",
    ],
    courseExpectation: [
      "management11courseExpectation3",
      "management11courseExpectation3",
      "management11courseExpectation3",
    ],
    courseContent: [
      {
        title: "management11courseContent1title",
        contents: [
          "management11courseContent1Desc1",
          "management11courseContent1Desc2",
          "management11courseContent1Desc3",
          "management11courseContent1Desc4",
        ],
      },
      {
        title: "management11courseContent2title",
        contents: [
          "management11courseContent2Desc1",
          "management11courseContent2Desc2",
          "management11courseContent2Desc3",
          "management11courseContent2Desc4",
        ],
      },
      {
        title: "management11courseContent3title",
        contents: [
          "management11courseContent3Desc1",
          "management11courseContent3Desc2",
          "management11courseContent3Desc3",
          "management11courseContent3Desc4",
        ],
      },
    ],
    courseFess: 3972,
    category: "Management",
    courseCode: "AD11",
  },
  {
    id: 12,
    image: AdvancedManagement12,
    title: "management12Title",
    desc: "management12Desc",
    WhoShouldAttend: [
      "management12WhoShouldAttend1",
      "management12WhoShouldAttend2",
      "management12WhoShouldAttend3",
      "management12WhoShouldAttend4",
      "management12WhoShouldAttend5",
      "management12WhoShouldAttend6",
      "management12WhoShouldAttend7",
      "management12WhoShouldAttend8",
    ],
    courseExpectation: [
      "management12courseExpectation1",
      "management12courseExpectation2",
      "management12courseExpectation3",
      "management12courseExpectation4",
      "management12courseExpectation5",
    ],
    courseContent: [
      {
        title: "management12courseContent1title",
        contents: [
          "management12courseContent1Desc1",
          "management12courseContent1Desc2",
          "management12courseContent1Desc3",
          "management12courseContent1Desc4",
        ],
      },
      {
        title: "management12courseContent2title",
        contents: [
          "management12courseContent2Desc1",
          "management12courseContent2Desc2",
          "management12courseContent2Desc3",
          "management12courseContent2Desc4",
        ],
      },
      {
        title: "management12courseContent3title",
        contents: [
          "management12courseContent3Desc1",
          "management12courseContent3Desc2",
          "management12courseContent3Desc3",
          "management12courseContent3Desc4",
          "management12courseContent3Desc5",
        ],
      },
      {
        title: "management12courseContent4title",
        contents: [
          "management12courseContent4Desc1",
          "management12courseContent4Desc2",
          "management12courseContent4Desc3",
          "management12courseContent4Desc4",
        ],
      },
      {
        title: "management12courseContent5title",
        contents: [
          "management12courseContent5Desc1",
          "management12courseContent5Desc2",
          "management12courseContent5Desc3",
          "management12courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD12",
  },
  {
    id: 13,
    image: AdvancedManagement13,
    title: "management13Title",
    desc: "management13Desc",
    WhoShouldAttend: [
      "management13WhoShouldAttend1",
      "management13WhoShouldAttend2",
      "management13WhoShouldAttend3",
    ],
    courseExpectation: [
      "management13courseExpectation1",
      "management13courseExpectation2",
      "management13courseExpectation3",
      "management13courseExpectation4",
      "management13courseExpectation5",
      "management13courseExpectation6",
    ],
    courseContent: [
      {
        title: "management13courseContent1title",
        contents: [
          "management13courseContent1Desc1",
          "management13courseContent1Desc2",
          "management13courseContent1Desc3",
          "management13courseContent1Desc4",
          "management13courseContent1Desc5",
        ],
      },
      {
        title: "management13courseContent2title",
        contents: [
          "management13courseContent2Desc1",
          "management13courseContent2Desc2",
          "management13courseContent2Desc3",
          "management13courseContent2Desc4",
        ],
      },
      {
        title: "management13courseContent3title",
        contents: [
          "management13courseContent3Desc1",
          "management13courseContent3Desc2",
          "management13courseContent3Desc3",
        ],
      },
      {
        title: "management13courseContent4title",
        contents: [
          "management13courseContent4Desc1",
          "management13courseContent4Desc2",
          "management13courseContent4Desc3",
          "management13courseContent4Desc4",
        ],
      },
      {
        title: "management13courseContent5title",
        contents: [
          "management13courseContent5Desc1",
          "management13courseContent5Desc2",
          "management13courseContent5Desc3",
          "management13courseContent5Desc4",
          "management13courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD13",
  },
  {
    id: 14,
    image: AdvancedManagement14,
    title: "management14Title",
    desc: "management14Desc",
    WhoShouldAttend: [
      "management14WhoShouldAttend1",
      "management14WhoShouldAttend2",
      "management14WhoShouldAttend3",
    ],
    courseExpectation: [
      "management14courseExpectation1",
      "management14courseExpectation2",
      "management14courseExpectation3",
      "management14courseExpectation4",
      "management14courseExpectation5",
      "management14courseExpectation6",
    ],
    courseContent: [
      {
        title: "management14courseContent1title",
        contents: [
          "management14courseContent1Desc1",
          "management14courseContent1Desc2",
          "management14courseContent1Desc3",
          "management14courseContent1Desc4",
          "management14courseContent1Desc5",
        ],
      },
      {
        title: "management14courseContent2title",
        contents: [
          "management14courseContent2Desc1",
          "management14courseContent2Desc2",
          "management14courseContent2Desc3",
        ],
      },
      {
        title: "management14courseContent3title",
        contents: [
          "management14courseContent3Desc1",
          "management14courseContent3Desc2",
          "management14courseContent3Desc3",
        ],
      },
      {
        title: "management14courseContent4title",
        contents: [
          "management14courseContent4Desc1",
          "management14courseContent4Desc2",
          "management14courseContent4Desc3",
        ],
      },
      {
        title: "management14courseContent5title",
        contents: [
          "management14courseContent5Desc1",
          "management14courseContent5Desc2",
          "management14courseContent5Desc3",
          "management14courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD14",
  },
  {
    id: 15,
    image: AdvancedManagement15,
    title: "management15Title",
    desc: "management15Desc",
    WhoShouldAttend: [
      "management15WhoShouldAttend1",
      "management15WhoShouldAttend2",
      "management15WhoShouldAttend3",
      "management15WhoShouldAttend4",
    ],
    courseExpectation: [
      "management15courseExpectation1",
      "management15courseExpectation2",
      "management15courseExpectation3",
    ],
    courseContent: [
      {
        title: "management15courseContent1title",
        contents: ["management15courseContent1Desc7"],
      },
      {
        title: "management15courseContent2title",
        contents: [
          "management15courseContent2Desc1",
          "management15courseContent2Desc2",
          "management15courseContent2Desc3",
          "management15courseContent2Desc4",
          "management15courseContent2Desc5",
        ],
      },
      {
        title: "management15courseContent3title",
        contents: [
          "management15courseContent3Desc1",
          "management15courseContent3Desc2",
          "management15courseContent3Desc3",
          "management15courseContent3Desc4",
        ],
      },
      {
        title: "management15courseContent4title",
        contents: [
          "management15courseContent4Desc1",
          "management15courseContent4Desc2",
          "management15courseContent4Desc3",
          "management15courseContent4Desc4",
          "management15courseContent4Desc5",
        ],
      },
      {
        title: "management15courseContent5title",
        contents: [
          "management15courseContent5Desc1",
          "management15courseContent5Desc2",
          "management15courseContent5Desc3",
          "management15courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD15",
  },
  {
    id: 16,
    image: AdvancedManagement16,
    title: "management16Title",
    desc: "management16Desc",
    WhoShouldAttend: [
      "management16WhoShouldAttend1",
      "management16WhoShouldAttend2",
      "management16WhoShouldAttend3",
    ],
    courseExpectation: [
      "management16courseExpectation1",
      "management16courseExpectation2",
      "management16courseExpectation3",
      "management16courseExpectation4",
    ],
    courseContent: [
      {
        title: "management16courseContent1title",
        contents: [
          "management16courseContent1Desc1",
          "management16courseContent1Desc2",
          "management16courseContent1Desc3",
          "management16courseContent1Desc4",
          "management16courseContent1Desc5",
          "management16courseContent1Desc6",
          "management16courseContent1Desc7",
        ],
      },
      {
        title: "management16courseContent2title",
        contents: [
          "management16courseContent2Desc1",
          "management16courseContent2Desc2",
          "management16courseContent2Desc3",
          "management16courseContent2Desc4",
          "management16courseContent2Desc5",
          "management16courseContent2Desc6",
        ],
      },
      {
        title: "management16courseContent3title",
        contents: [
          "management16courseContent3Desc1",
          "management16courseContent3Desc2",
          "management16courseContent3Desc3",
          "management16courseContent3Desc4",
          "management16courseContent3Desc5",
        ],
      },
      {
        title: "management16courseContent4title",
        contents: [
          "management16courseContent4Desc1",
          "management16courseContent4Desc2",
          "management16courseContent4Desc3",
          "management16courseContent4Desc4",
          "management16courseContent4Desc5",
        ],
      },
      {
        title: "management16courseContent5title",
        contents: [
          "management16courseContent5Desc1",
          "management16courseContent5Desc2",
          "management16courseContent5Desc3",
          "management16courseContent5Desc4",
          "management16courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD16",
  },
  {
    id: 17,
    image: AdvancedManagement17,
    title: "management17Title",
    desc: "management17Desc",
    WhoShouldAttend: [
      "management17WhoShouldAttend1",
      "management17WhoShouldAttend2",
      "management17WhoShouldAttend3",
      "management17WhoShouldAttend4",
      "management17WhoShouldAttend5",
      "management17WhoShouldAttend6",
      "management17WhoShouldAttend7",
      "management17WhoShouldAttend8",
      "management17WhoShouldAttend9",
      "management17WhoShouldAttend10",
      "management17WhoShouldAttend11",
      "management17WhoShouldAttend12",
      "management17WhoShouldAttend13",
      "management17WhoShouldAttend14",
      "management17WhoShouldAttend15",
      "management17WhoShouldAttend16",
    ],
    courseExpectation: [
      "management17courseExpectation1",
      "management17courseExpectation2",
      "management17courseExpectation3",
      "management17courseExpectation4",
      "management17courseExpectation5",
    ],
    courseContent: [
      {
        title: "management17courseContent1title",
        contents: [
          "management17courseContent1Desc1",
          "management17courseContent1Desc2",
          "management17courseContent1Desc3",
          "management17courseContent1Desc4",
        ],
      },
      {
        title: "management17courseContent2title",
        contents: [
          "management17courseContent2Desc1",
          "management17courseContent2Desc2",
          "management17courseContent2Desc3",
          "management17courseContent2Desc4",
        ],
      },
      {
        title: "management17courseContent3title",
        contents: [
          "management17courseContent3Desc1",
          "management17courseContent3Desc2",
          "management17courseContent3Desc3",
          "management17courseContent3Desc4",
        ],
      },
      {
        title: "management17courseContent4title",
        contents: [
          "management17courseContent4Desc1",
          "management17courseContent4Desc2",
          "management17courseContent4Desc3",
          "management17courseContent4Desc4",
        ],
      },
      {
        title: "management17courseContent5title",
        contents: [
          "management17courseContent5Desc1",
          "management17courseContent5Desc2",
          "management17courseContent5Desc3",
          "management17courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD17",
  },
  {
    id: 18,
    image: AdvancedManagement18,
    title: "management18Title",
    desc: "management18Desc",
    WhoShouldAttend: [
      "management18WhoShouldAttend1",
      "management18WhoShouldAttend2",
      "management18WhoShouldAttend3",
      "management18WhoShouldAttend4",
      "management18WhoShouldAttend5",
      "management18WhoShouldAttend6",
      "management18WhoShouldAttend7",
      "management18WhoShouldAttend8",
      "management18WhoShouldAttend9",
      "management18WhoShouldAttend10",
      "management18WhoShouldAttend11",
      "management18WhoShouldAttend12",
      "management18WhoShouldAttend13",
      "management18WhoShouldAttend14",
    ],
    courseExpectation: [
      "management18courseExpectation1",
      "management18courseExpectation2",
      "management18courseExpectation3",
      "management18courseExpectation4",
      "management18courseExpectation5",
    ],
    courseContent: [
      {
        title: "management18courseContent1title",
        contents: [
          "management18courseContent1Desc1",
          "management18courseContent1Desc2",
          "management18courseContent1Desc3",
          "management18courseContent1Desc4",
        ],
      },
      {
        title: "management18courseContent2title",
        contents: [
          "management18courseContent2Desc1",
          "management18courseContent2Desc2",
          "management18courseContent2Desc3",
          "management18courseContent2Desc4",
        ],
      },
      {
        title: "management18courseContent3title",
        contents: [
          "management18courseContent3Desc1",
          "management18courseContent3Desc2",
          "management18courseContent3Desc3",
          "management18courseContent3Desc4",
        ],
      },
      {
        title: "management18courseContent4title",
        contents: [
          "management18courseContent4Desc1",
          "management18courseContent4Desc2",
          "management18courseContent4Desc3",
          "management18courseContent4Desc4",
        ],
      },
      {
        title: "management18courseContent5title",
        contents: [
          "management18courseContent5Desc1",
          "management18courseContent5Desc2",
          "management18courseContent5Desc3",
          "management18courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "AD18",
  },
  {
    id: 71,
    image: Mskills1,
    title: "management19Title",
    desc: "management19Desc",
    WhoShouldAttend: [
      "management19WhoShouldAttend1",
      "management19WhoShouldAttend2",
      "management19WhoShouldAttend3",
      "management19WhoShouldAttend4",
      "management19WhoShouldAttend5",
      "management19WhoShouldAttend6",
    ],
    courseExpectation: [
      "management19courseExpectation1",
      "management19courseExpectation2",
      "management19courseExpectation3",
      "management19courseExpectation4",
      "management19courseExpectation5",
      "management19courseExpectation6",
      "management19courseExpectation7",
    ],
    courseContent: [
      {
        title: "management19courseContent1title",
        contents: [
          "management19courseContent1Desc1",
          "management19courseContent1Desc2",
          "management19courseContent1Desc3",
          "management19courseContent1Desc4",
        ],
      },
      {
        title: "management19courseContent2title",
        contents: [
          "management19courseContent2Desc1",
          "management19courseContent2Desc2",
          "management19courseContent2Desc3",
          "management19courseContent2Desc4",
        ],
      },
      {
        title: "management19courseContent3title",
        contents: [
          "management19courseContent3Desc1",
          "management19courseContent3Desc2",
          "management19courseContent3Desc3",
          "management19courseContent3Desc4",
        ],
      },
      {
        title: "management19courseContent4title",
        contents: [
          "management19courseContent4Desc1",
          "management19courseContent4Desc2",
          "management19courseContent4Desc3",
          "management19courseContent4Desc4",
        ],
      },
      {
        title: "management19courseContent5title",
        contents: [
          "management19courseContent5Desc1",
          "management19courseContent5Desc2",
          "management19courseContent5Desc3",
          "management19courseContent5Desc4",
        ],
      },
      {
        title: "management19courseContent6title",
        contents: [
          "management19courseContent6Desc1",
          "management19courseContent6Desc2",
          "management19courseContent6Desc3",
          "management19courseContent6Desc4",
        ],
      },
      {
        title: "management19courseContent7title",
        contents: [
          "management19courseContent7Desc1",
          "management19courseContent7Desc2",
          "management19courseContent7Desc3",
          "management19courseContent7Desc4",
        ],
      },
      {
        title: "management19courseContent8title",
        contents: [
          "management19courseContent8Desc1",
          "management19courseContent8Desc2",
          "management19courseContent8Desc3",
          "management19courseContent8Desc4",
        ],
      },
      {
        title: "management19courseContent9title",
        contents: [
          "management19courseContent9Desc1",
          "management19courseContent9Desc2",
          "management19courseContent9Desc3",
          "management19courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "MS01",
  },
  {
    id: 72,
    image: Mskills2,
    title: "management20Title",
    desc: "management20Desc",
    WhoShouldAttend: [
      "management20WhoShouldAttend1",
      "management20WhoShouldAttend2",
      "management20WhoShouldAttend3",
      "management20WhoShouldAttend4",
      "management20WhoShouldAttend5",
      "management20WhoShouldAttend6",
      "management20WhoShouldAttend7",
      "management20WhoShouldAttend8",
      "management20WhoShouldAttend9",
    ],
    courseExpectation: [
      "management20courseExpectation1",
      "management20courseExpectation2",
      "management20courseExpectation3",
      "management20courseExpectation4",
    ],
    courseContent: [
      {
        title: "management20courseContent1title",
        contents: ["management20courseContent1Desc4"],
      },
      {
        title: "management20courseContent2title",
        contents: ["management20courseContent2Desc4"],
      },
      {
        title: "management20courseContent3title",
        contents: ["management20courseContent3Desc4"],
      },
      {
        title: "management20courseContent4title",
        contents: ["management20courseContent4Desc4"],
      },
      {
        title: "management20courseContent5title",
        contents: ["management20courseContent5Desc4"],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MS02",
  },
  {
    id: 73,
    image: Mskills3,
    title: "management21Title",
    desc: "management21Desc",
    WhoShouldAttend: [
      "management21WhoShouldAttend1",
      "management21WhoShouldAttend2",
      "management21WhoShouldAttend3",
      "management21WhoShouldAttend4",
      "management21WhoShouldAttend5",
      "management21WhoShouldAttend6",
      "management21WhoShouldAttend7",
      "management21WhoShouldAttend8",
      "management21WhoShouldAttend9",
    ],
    courseExpectation: [
      "management21courseExpectation1",
      "management21courseExpectation2",
      "management21courseExpectation3",
      "management21courseExpectation4",
    ],
    courseContent: [
      {
        title: "management21courseContent1title",
        contents: [
          "management21courseContent1Desc1",
          "management21courseContent1Desc2",
          "management21courseContent1Desc3",
          "management21courseContent1Desc4",
        ],
      },
      {
        title: "management21courseContent2title",
        contents: [
          "management21courseContent2Desc1",
          "management21courseContent2Desc2",
          "management21courseContent2Desc3",
          "management21courseContent2Desc4",
        ],
      },
      {
        title: "management21courseContent3title",
        contents: [
          "management21courseContent3Desc1",
          "management21courseContent3Desc2",
          "management21courseContent3Desc3",
          "management21courseContent3Desc4",
        ],
      },
      {
        title: "management21courseContent4title",
        contents: [
          "management21courseContent4Desc1",
          "management21courseContent4Desc2",
          "management21courseContent4Desc3",
          "management21courseContent4Desc4",
        ],
      },
      {
        title: "management21courseContent5title",
        contents: [
          "management21courseContent5Desc1",
          "management21courseContent5Desc2",
          "management21courseContent5Desc3",
          "management21courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MS03",
  },
  {
    id: 74,
    image: Mskills4,
    title: "management22Title",
    desc: "management22Desc",
    WhoShouldAttend: [
      "management22WhoShouldAttend1",
      "management22WhoShouldAttend2",
      "management22WhoShouldAttend3",
      "management22WhoShouldAttend4",
      "management22WhoShouldAttend5",
      "management22WhoShouldAttend6",
      "management22WhoShouldAttend7",
    ],
    courseExpectation: [
      "management22courseExpectation1",
      "management22courseExpectation2",
      "management22courseExpectation3",
      "management22courseExpectation4",
    ],
    courseContent: [
      {
        title: "management22courseContent1title",
        contents: [
          "management22courseContent1Desc1",
          "management22courseContent1Desc2",
          "management22courseContent1Desc3",
          "management22courseContent1Desc4",
        ],
      },
      {
        title: "management22courseContent2title",
        contents: [
          "management22courseContent2Desc1",
          "management22courseContent2Desc2",
          "management22courseContent2Desc3",
          "management22courseContent2Desc4",
        ],
      },
      {
        title: "management22courseContent3title",
        contents: [
          "management22courseContent3Desc1",
          "management22courseContent3Desc2",
          "management22courseContent3Desc3",
          "management22courseContent3Desc4",
        ],
      },
      {
        title: "management22courseContent4title",
        contents: [
          "management22courseContent4Desc1",
          "management22courseContent4Desc2",
          "management22courseContent4Desc3",
          "management22courseContent4Desc4",
        ],
      },
      {
        title: "management22courseContent5title",
        contents: [
          "management22courseContent5Desc1",
          "management22courseContent5Desc2",
          "management22courseContent5Desc3",
          "management22courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MS04",
  },
  {
    id: 75,
    image: Mskills5,
    title: "management23Title",
    desc: "management23Desc",
    WhoShouldAttend: [
      "management23WhoShouldAttend1",
      "management23WhoShouldAttend2",
      "management23WhoShouldAttend3",
      "management23WhoShouldAttend4",
      "management23WhoShouldAttend5",
      "management23WhoShouldAttend6",
    ],
    courseExpectation: [
      "management23courseExpectation1",
      "management23courseExpectation2",
      "management23courseExpectation3",
      "management23courseExpectation4",
    ],
    courseContent: [
      {
        title: "management23courseContent1title",
        contents: [
          "management23courseContent1Desc1",
          "management23courseContent1Desc2",
          "management23courseContent1Desc3",
          "management23courseContent1Desc4",
        ],
      },
      {
        title: "management23courseContent2title",
        contents: [
          "management23courseContent2Desc1",
          "management23courseContent2Desc2",
          "management23courseContent2Desc3",
          "management23courseContent2Desc4",
        ],
      },
      {
        title: "management23courseContent3title",
        contents: [
          "management23courseContent3Desc1",
          "management23courseContent3Desc2",
          "management23courseContent3Desc3",
          "management23courseContent3Desc4",
        ],
      },
      {
        title: "management23courseContent4title",
        contents: [
          "management23courseContent4Desc1",
          "management23courseContent4Desc2",
          "management23courseContent4Desc3",
          "management23courseContent4Desc4",
        ],
      },
      {
        title: "management23courseContent5title",
        contents: [
          "management23courseContent5Desc1",
          "management23courseContent5Desc2",
          "management23courseContent5Desc3",
          "management23courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MS05",
  },
  {
    id: 76,
    image: Mskills6,
    title: "management24Title",
    desc: "management24Desc",
    WhoShouldAttend: [
      "management24WhoShouldAttend1",
      "management24WhoShouldAttend2",
      "management24WhoShouldAttend3",
      "management24WhoShouldAttend4",
      "management24WhoShouldAttend5",
      "management24WhoShouldAttend6",
    ],
    courseExpectation: [
      "management24courseExpectation1",
      "management24courseExpectation2",
      "management24courseExpectation3",
      "management24courseExpectation4",
    ],
    courseContent: [
      {
        title: "management24courseContent1title",
        contents: [
          "management24courseContent1Desc1",
          "management24courseContent1Desc2",
          "management24courseContent1Desc3",
          "management24courseContent1Desc4",
        ],
      },
      {
        title: "management24courseContent2title",
        contents: [
          "management24courseContent2Desc1",
          "management24courseContent2Desc2",
          "management24courseContent2Desc3",
          "management24courseContent2Desc4",
        ],
      },
      {
        title: "management24courseContent3title",
        contents: [
          "management24courseContent3Desc1",
          "management24courseContent3Desc2",
          "management24courseContent3Desc3",
          "management24courseContent3Desc4",
        ],
      },
      {
        title: "management24courseContent4title",
        contents: [
          "management24courseContent4Desc1",
          "management24courseContent4Desc2",
          "management24courseContent4Desc3",
          "management24courseContent4Desc4",
        ],
      },
      {
        title: "management24courseContent5title",
        contents: [
          "management24courseContent5Desc1",
          "management24courseContent5Desc2",
          "management24courseContent5Desc3",
          "management24courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MS06",
  },
  {
    id: 77,
    image: Mskills7,
    title: "management25Title",
    desc: "management25Desc",
    WhoShouldAttend: ["management25WhoShouldAttend10"],
    courseExpectation: ["management25courseExpectation6"],
    courseContent: [
      {
        title: "management25courseContent1title",
        contents: [
          "management25courseContent1Desc1",
          "management25courseContent1Desc2",
          "management25courseContent1Desc3",
          "management25courseContent1Desc4",
        ],
      },
      {
        title: "management25courseContent2title",
        contents: [
          "management25courseContent2Desc1",
          "management25courseContent2Desc2",
          "management25courseContent2Desc3",
          "management25courseContent2Desc4",
        ],
      },
      {
        title: "management25courseContent3title",
        contents: [
          "management25courseContent3Desc1",
          "management25courseContent3Desc2",
          "management25courseContent3Desc3",
          "management25courseContent3Desc4",
        ],
      },
      {
        title: "management25courseContent4title",
        contents: [
          "management25courseContent4Desc1",
          "management25courseContent4Desc2",
          "management25courseContent4Desc3",
          "management25courseContent4Desc4",
        ],
      },
      {
        title: "management25courseContent5title",
        contents: [
          "management25courseContent5Desc1",
          "management25courseContent5Desc2",
          "management25courseContent5Desc3",
          "management25courseContent5Desc4",
        ],
      },
      {
        title: "management25courseContent6title",
        contents: [
          "management25courseContent6Desc1",
          "management25courseContent6Desc2",
          "management25courseContent6Desc3",
          "management25courseContent6Desc4",
        ],
      },
      {
        title: "management25courseContent7title",
        contents: [
          "management25courseContent7Desc1",
          "management25courseContent7Desc2",
          "management25courseContent7Desc3",
          "management25courseContent7Desc4",
        ],
      },
      {
        title: "management25courseContent8title",
        contents: [
          "management25courseContent8Desc1",
          "management25courseContent8Desc2",
          "management25courseContent8Desc3",
          "management25courseContent8Desc4",
        ],
      },
      {
        title: "management25courseContent9title",
        contents: [
          "management25courseContent9Desc1",
          "management25courseContent9Desc2",
          "management25courseContent9Desc3",
          "management25courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Management",
    courseCode: "MS07",
  },
  {
    id: 78,
    image: Mskills8,
    title: "management26Title",
    desc: "management26Desc",
    WhoShouldAttend: [
      "management26WhoShouldAttend1",
      "management26WhoShouldAttend2",
      "management26WhoShouldAttend3",
      "management26WhoShouldAttend4",
      "management26WhoShouldAttend5",
      "management26WhoShouldAttend6",
    ],
    courseExpectation: [
      "management26courseExpectation1",
      "management26courseExpectation2",
      "management26courseExpectation3",
      "management26courseExpectation4",
    ],
    courseContent: [
      {
        title: "management26courseContent1title",
        contents: [
          "management26courseContent1Desc1",
          "management26courseContent1Desc2",
          "management26courseContent1Desc3",
          "management26courseContent1Desc4",
        ],
      },
      {
        title: "management26courseContent2title",
        contents: [
          "management26courseContent2Desc1",
          "management26courseContent2Desc2",
          "management26courseContent2Desc3",
          "management26courseContent2Desc4",
        ],
      },
      {
        title: "management26courseContent3title",
        contents: [
          "management26courseContent3Desc1",
          "management26courseContent3Desc2",
          "management26courseContent3Desc3",
          "management26courseContent3Desc4",
        ],
      },
      {
        title: "management26courseContent4title",
        contents: [
          "management26courseContent4Desc1",
          "management26courseContent4Desc2",
          "management26courseContent4Desc3",
          "management26courseContent4Desc4",
        ],
      },
      {
        title: "management26courseContent5title",
        contents: [
          "management26courseContent5Desc1",
          "management26courseContent5Desc2",
          "management26courseContent5Desc3",
          "management26courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Management",
    courseCode: "MS08",
  },
  //Management Skills

  // Finance
  {
    id: 19,
    image: Finance1,
    title: "finance1Title",
    desc: "finance1Desc",
    WhoShouldAttend: ["finance1WhoShouldAttend"],
    courseExpectation: [
      "finance1courseExpectation1",
      "finance1courseExpectation2",
      "finance1courseExpectation3",
      "finance1courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance1coursecourseContent1Title",
        contents: [
          "finance1coursecourseContent1Desc1",
          "finance1coursecourseContent1Desc2",
          "finance1coursecourseContent1Desc3",
          "finance1coursecourseContent1Desc4",
          "finance1coursecourseContent1Desc5",
          "finance1coursecourseContent1Desc6",
          "finance1coursecourseContent1Desc7",
          "finance1coursecourseContent1Desc8",
          "finance1coursecourseContent1Desc9",
          "finance1coursecourseContent1Desc10",
          "finance1coursecourseContent1Desc11",
          "finance1coursecourseContent1Desc12",
        ],
      },
      {
        title: "finance1coursecourseContent2Title",
        contents: [
          "finance1coursecourseContent2Desc1",
          "finance1coursecourseContent2Desc2",
          "finance1coursecourseContent2Desc3",
          "finance1coursecourseContent2Desc4",
          "finance1coursecourseContent2Desc5",
          "finance1coursecourseContent2Desc6",
          "finance1coursecourseContent2Desc7",
          "finance1coursecourseContent2Desc8",
          "finance1coursecourseContent2Desc9",
          "finance1coursecourseContent2Desc10",
          "finance1coursecourseContent2Desc11",
          "finance1coursecourseContent2Desc12",
        ],
      },
      {
        title: "finance1coursecourseContent3Title",
        contents: [
          "finance1coursecourseContent3Desc1",
          "finance1coursecourseContent3Desc2",
          "finance1coursecourseContent3Desc3",
          "finance1coursecourseContent3Desc4",
          "finance1coursecourseContent3Desc5",
          "finance1coursecourseContent3Desc6",
          "finance1coursecourseContent3Desc7",
          "finance1coursecourseContent3Desc8",
          "finance1coursecourseContent3Desc9",
          "finance1coursecourseContent3Desc10",
          "finance1coursecourseContent3Desc11",
          "finance1coursecourseContent3Desc12",
        ],
      },
      {
        title: "finance1coursecourseContent4Title",
        contents: [
          "finance1coursecourseContent4Desc1",
          "finance1coursecourseContent4Desc2",
          "finance1coursecourseContent4Desc3",
          "finance1coursecourseContent4Desc4",
          "finance1coursecourseContent4Desc5",
          "finance1coursecourseContent4Desc6",
        ],
      },
      {
        title: "finance1coursecourseContent5Title",
        contents: [
          "finance1coursecourseContent5Desc1",
          "finance1coursecourseContent5Desc2",
          "finance1coursecourseContent5Desc3",
          "finance1coursecourseContent5Desc4",
          "finance1coursecourseContent5Desc5",
          "finance1coursecourseContent5Desc6",
          "finance1coursecourseContent5Desc7",
          "finance1coursecourseContent5Desc8",
          "finance1coursecourseContent5Desc9",
          "finance1coursecourseContent5Desc10",
          "finance1coursecourseContent5Desc11",
        ],
      },
      {
        title: "finance1coursecourseContent6Title",
        contents: [
          "finance1coursecourseContent6Desc1",
          "finance1coursecourseContent6Desc2",
          "finance1coursecourseContent6Desc3",
          "finance1coursecourseContent6Desc4",
          "finance1coursecourseContent6Desc5",
          "finance1coursecourseContent6Desc6",
          "finance1coursecourseContent6Desc7",
          "finance1coursecourseContent6Desc18",
        ],
      },
      {
        title: "finance1coursecourseContent7Title",
        contents: [
          "finance1coursecourseContent7Desc1",
          "finance1coursecourseContent7Desc2",
          "finance1coursecourseContent7Desc3",
          "finance1coursecourseContent7Desc4",
          "finance1coursecourseContent7Desc5",
          "finance1coursecourseContent7Desc6",
          "finance1coursecourseContent7Desc7",
        ],
      },
      {
        title: "finance1coursecourseContent8Title",
        contents: [
          "finance1coursecourseContent8Desc1",
          "finance1coursecourseContent8Desc2",
          "finance1coursecourseContent8Desc3",
          "finance1coursecourseContent8Desc4",
          "finance1coursecourseContent8Desc5",
          "finance1coursecourseContent8Desc6",
          "finance1coursecourseContent8Desc7",
          "finance1coursecourseContent8Desc8",
        ],
      },
      {
        title: "finance1coursecourseContent9Title",
        contents: [
          "finance1coursecourseContent9Desc1",
          "finance1coursecourseContent9Desc2",
          "finance1coursecourseContent9Desc3",
          "finance1coursecourseContent9Desc4",
          "finance1coursecourseContent9Desc5",
          "finance1coursecourseContent9Desc6",
          "finance1coursecourseContent9Desc7",
        ],
      },
    ],
    courseFess: 6080,
    category: "Finance",
    courseCode: "FIN01",
  },
  {
    id: 20,
    image: Finance2,
    title: "finance2Title",
    desc: "finance2Desc",
    WhoShouldAttend: [
      "finance2WhoShouldAttend1",
      "finance2WhoShouldAttend2",
      "finance2WhoShouldAttend3",
      "finance2WhoShouldAttend4",
      "finance2WhoShouldAttend5",
      "finance2WhoShouldAttend6",
      "finance2WhoShouldAttend7",
      "finance2WhoShouldAttend7",
    ],
    courseExpectation: [
      "finance2courseExpectation1",
      "finance2courseExpectation2",
      "finance2courseExpectation3",
      "finance2courseExpectation4",
      "finance2courseExpectation5",
      "finance2courseExpectation6",
      "finance2courseExpectation7",
    ],
    courseContent: [
      {
        title: "finance2courseContent1title",
        contents: [
          "finance2courseContent1Desc1",
          "finance2courseContent1Desc2",
          "finance2courseContent1Desc3",
          "finance2courseContent1Desc4",
          "finance2courseContent1Desc5",
          "finance2courseContent1Desc6",
        ],
      },
      {
        title: "finance2courseContent2title",
        contents: [
          "finance2courseContent2Desc1",
          "finance2courseContent2Desc2",
          "finance2courseContent2Desc3",
          "finance2courseContent2Desc4",
          "finance2courseContent2Desc5",
        ],
      },
      {
        title: "finance2courseContent3title",
        contents: [
          "finance2courseContent3Desc1",
          "finance2courseContent3Desc2",
          "finance2courseContent3Desc3",
          "finance2courseContent3Desc4",
          "finance2courseContent3Desc5",
        ],
      },
      {
        title: "finance2courseContent4title",
        contents: [
          "finance2courseContent4Desc1",
          "finance2courseContent4Desc2",
          "finance2courseContent4Desc3",
          "finance2courseContent4Desc4",
          "finance2courseContent4Desc5",
        ],
      },
      {
        title: "Mfinance2courseContent5title",
        contents: [
          "finance2courseContent5Desc1",
          "finance2courseContent5Desc2",
          "finance2courseContent5Desc3",
          "finance2courseContent5Desc4",
          "finance2courseContent5Desc5",
        ],
      },
      {
        title: "finance2courseContent6title",
        contents: [
          "finance2courseContent6Desc1",
          "finance2courseContent6Desc2",
          "finance2courseContent6Desc3",
          "finance2courseContent6Desc4",
        ],
      },
      {
        title: "finance2courseContent7title",
        contents: [
          "finance2courseContent7Desc1",
          "finance2courseContent7Desc2",
          "finance2courseContent7Desc3",
          "finance2courseContent7Desc4",
          "finance2courseContent7Desc5",
        ],
      },
      {
        title: "finance2courseContent8title",
        contents: [
          "finance2courseContent8Desc1",
          "finance2courseContent8Desc2",
          "finance2courseContent8Desc3",
          "finance2courseContent8Desc4",
        ],
      },
      {
        title: "finance2courseContent9title",
        contents: [
          "finance2courseContent9Desc1",
          "finance2courseContent9Desc2",
          "finance2courseContent9Desc3",
          "finance2courseContent9Desc4",
          "finance2courseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Finance",
    courseCode: "FIN02",
  },
  {
    id: 21,
    image: Finance3,
    title: "finance3Title",
    desc: "finance3Desc",
    WhoShouldAttend: [
      "finance3WhoShouldAttend1",
      "finance3WhoShouldAttend2",
      "finance3WhoShouldAttend3",
      "finance3WhoShouldAttend4",
      "finance3WhoShouldAttend5",
      "finance3WhoShouldAttend6",
      "finance3WhoShouldAttend7",
    ],
    courseExpectation: [
      "finance3courseExpectation1",
      "finance3courseExpectation2",
      "finance3courseExpectation3",
      "finance3courseExpectation4",
      "finance3courseExpectation5",
      "finance3courseExpectation6",
      "finance3courseExpectation7",
      "finance3courseExpectation8",
      "finance3courseExpectation8",
    ],
    courseContent: [
      {
        title: "finance3courseContent1title",
        contents: [
          "finance3courseContent1Desc1",
          "finance3courseContent1Desc2",
          "finance3courseContent1Desc3",
          "finance3courseContent1Desc4",
        ],
      },
      {
        title: "finance3courseContent2title",
        contents: [
          "finance3courseContent2Desc1",
          "finance3courseContent2Desc2",
          "finance3courseContent2Desc3",
          "finance3courseContent2Desc4",
        ],
      },
      {
        title: "finance3courseContent3title",
        contents: [
          "finance3courseContent3Desc1",
          "finance3courseContent3Desc2",
          "finance3courseContent3Desc3",
          "finance3courseContent3Desc4",
          "finance3courseContent3Desc5",
        ],
      },
      {
        title: "finance3courseContent4title",
        contents: [
          "finance3courseContent4Desc1",
          "finance3courseContent4Desc2",
          "finance3courseContent4Desc3",
          "finance3courseContent4Desc4",
        ],
      },
      {
        title: "finance3courseContent5title",
        contents: [
          "finance3courseContent5Desc1",
          "finance3courseContent5Desc2",
          "finance3courseContent5Desc3",
          "finance3courseContent5Desc4",
          "finance3courseContent5Desc5",
        ],
      },
      {
        title: "finance3courseContent6title",
        contents: [
          "finance3courseContent6Desc1",
          "finance3courseContent6Desc2",
          "finance3courseContent6Desc3",
        ],
      },
      {
        title: "finance3courseContent7title",
        contents: [
          "finance3courseContent7Desc1",
          "finance3courseContent7Desc2",
          "finance3courseContent7Desc3",
          "finance3courseContent7Desc4",
          "finance3courseContent7Desc5",
          "finance3courseContent7Desc6",
        ],
      },
      {
        title: "finance3courseContent8title",
        contents: [
          "finance3courseContent8Desc1",
          "finance3courseContent8Desc2",
          "finance3courseContent8Desc3",
          "finance3courseContent8Desc4",
          "finance3courseContent8Desc5",
        ],
      },
      {
        title: "finance3courseContent9title",
        contents: [
          "finance3courseContent9Desc1",
          "finance3courseContent9Desc2",
          "finance3courseContent9Desc3",
          "finance3courseContent9Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN03",
  },
  {
    id: 22,
    image: Finance4,
    title: "finance4Title",
    desc: "finance4Desc",
    WhoShouldAttend: ["finance4WhoShouldAttend"],
    courseExpectation: [
      "finance4courseExpectation1",
      "finance4courseExpectation2",
      "finance4courseExpectation3",
      "finance4courseExpectation4",
      "finance4courseExpectation5",
      "finance4courseExpectation6",
    ],
    courseContent: [
      {
        title: "finance4courseContent1title",
        contents: [
          "finance4courseContent1Desc1",
          "finance4courseContent1Desc2",
          "finance4courseContent1Desc3",
          "finance4courseContent1Desc4",
        ],
      },
      {
        title: "finance4courseContent2title",
        contents: [
          "finance4courseContent2Desc1",
          "finance4courseContent2Desc2",
          "finance4courseContent2Desc3",
          "finance4courseContent2Desc4",
        ],
      },
      {
        title: "finance4courseContent3title",
        contents: [
          "finance4courseContent3Desc1",
          "finance4courseContent3Desc2",
          "finance4courseContent3Desc3",
          "finance4courseContent3Desc4",
        ],
      },
      {
        title: "finance4courseContent4title",
        contents: [
          "finance4courseContent4Desc1",
          "finance4courseContent4Desc2",
          "finance4courseContent4Desc3",
          "finance4courseContent4Desc4",
        ],
      },
      {
        title: "finance4courseContent5title",
        contents: [
          "finance4courseContent5Desc1",
          "finance4courseContent5Desc2",
          "finance4courseContent5Desc3",
          "finance4courseContent5Desc4",
        ],
      },
      {
        title: "finance4courseContent6title",
        contents: [
          "finance4courseContent6Desc1",
          "finance4courseContent6Desc2",
          "finance4courseContent6Desc3",
          "finance4courseContent6Desc4",
        ],
      },
      {
        title: "finance4courseContent7title",
        contents: [
          "finance4courseContent7Desc1",
          "finance4courseContent7Desc2",
          "finance4courseContent7Desc3",
          "finance4courseContent7Desc4",
        ],
      },
      {
        title: "finance4courseContent8title",
        contents: [
          "finance4courseContent8Desc1",
          "finance4courseContent8Desc2",
          "finance4courseContent8Desc3",
          "finance4courseContent8Desc4",
          "finance4courseContent8Desc5",
        ],
      },
      {
        title: "finance4courseContent9title",
        contents: [
          "finance4courseContent9Desc1",
          "finance4courseContent9Desc2",
          "finance4courseContent9Desc3",
          "finance4courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Finance",
    courseCode: "FIN04",
  },
  {
    id: 23,
    image: Finance5,
    title: "finance5Title",
    desc: "finance5Desc",
    WhoShouldAttend: [
      "finance5WhoShouldAttend1",
      "finance5WhoShouldAttend2",
      "finance5WhoShouldAttend3",
      "finance5WhoShouldAttend4",
      "finance5WhoShouldAttend5",
      "finance5WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance5courseExpectation1",
      "finance5courseExpectation2",
      "finance5courseExpectation3",
      "finance5courseExpectation4",
      "finance5courseExpectation5",
      "finance5courseExpectation6",
      "finance5courseExpectation7",
      "finance5courseExpectation8",
    ],
    courseContent: [
      {
        title: "finance5courseContent1title",
        contents: [
          "finance5courseContent1Desc1",
          "finance5courseContent1Desc2",
          "finance5courseContent1Desc3",
          "finance5courseContent1Desc4",
        ],
      },
      {
        title: "finance5courseContent2title",
        contents: [
          "finance5courseContent2Desc1",
          "finance5courseContent2Desc2",
          "finance5courseContent2Desc3",
          "finance5courseContent2Desc4",
        ],
      },
      {
        title: "finance5courseContent3title",
        contents: [
          "finance5courseContent3Desc1",
          "finance5courseContent3Desc2",
          "finance5courseContent3Desc3",
          "finance5courseContent3Desc4",
          "finance5courseContent3Desc5",
        ],
      },
      {
        title: "finance5courseContent4title",
        contents: [
          "finance5courseContent4Desc1",
          "finance5courseContent4Desc2",
          "finance5courseContent4Desc3",
          "finance5courseContent4Desc4",
        ],
      },
      {
        title: "finance5courseContent5title",
        contents: [
          "finance5courseContent5Desc1",
          "finance5courseContent5Desc2",
          "finance5courseContent5Desc3",
          "finance5courseContent5Desc4",
        ],
      },
      {
        title: "finance5courseContent6title",
        contents: [
          "finance5courseContent6Desc1",
          "finance5courseContent6Desc2",
          "finance5courseContent6Desc3",
          "finance5courseContent6Desc4",
        ],
      },
      {
        title: "finance5courseContent7title",
        contents: [
          "finance5courseContent7Desc1",
          "finance5courseContent7Desc2",
          "finance5courseContent7Desc3",
          "finance5courseContent7Desc4",
        ],
      },
      {
        title: "finance5courseContent8title",
        contents: [
          "finance5courseContent8Desc1",
          "finance5courseContent8Desc2",
          "finance5courseContent8Desc3",
          "finance5courseContent8Desc4",
        ],
      },
      {
        title: "finance5courseContent9title",
        contents: [
          "finance5courseContent9Desc1",
          "finance5courseContent9Desc2",
          "finance5courseContent9Desc3",
          "finance5courseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Finance",
    courseCode: "FIN05",
  },
  {
    id: 24,
    image: Finance6,
    title: "finance6Title",
    desc: "finance6Desc",
    WhoShouldAttend: [
      "finance6WhoShouldAttend1",
      "finance6WhoShouldAttend2",
      "finance6WhoShouldAttend3",
      "finance6WhoShouldAttend4",
      "finance6WhoShouldAttend5",
    ],
    courseExpectation: [
      "finance6courseExpectation1",
      "finance6courseExpectation2",
      "finance6courseExpectation3",
      "finance6courseExpectation4",
      "finance6courseExpectation5",
      "finance6courseExpectation6",
    ],
    courseContent: [
      {
        title: "finance6courseContent1title",
        contents: [
          "finance6courseContent1Desc1",
          "finance6courseContent1Desc2",
          "finance6courseContent1Desc3",
          "finance6courseContent1Desc4",
        ],
      },
      {
        title: "finance6courseContent2title",
        contents: [
          "finance6courseContent2Desc1",
          "finance6courseContent2Desc2",
          "finance6courseContent2Desc3",
          "finance6courseContent2Desc4",
        ],
      },
      {
        title: "finance6courseContent3title",
        contents: [
          "finance6courseContent3Desc1",
          "finance6courseContent3Desc2",
          "finance6courseContent3Desc3",
          "finance6courseContent3Desc4",
        ],
      },
      {
        title: "finance6courseContent4title",
        contents: [
          "finance6courseContent4Desc1",
          "finance6courseContent4Desc2",
          "finance6courseContent4Desc3",
          "finance6courseContent4Desc4",
        ],
      },
      {
        title: "finance6courseContent5title",
        contents: [
          "finance6courseContent5Desc1",
          "finance6courseContent5Desc2",
          "finance6courseContent5Desc3",
          "finance6courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN06",
  },
  {
    id: 25,
    image: Finance7,
    title: "finance7Title",
    desc: "finance7Desc",
    WhoShouldAttend: [
      "finance7WhoShouldAttend1",
      "finance7WhoShouldAttend2",
      "finance7WhoShouldAttend3",
      "finance7WhoShouldAttend4",
      "finance7WhoShouldAttend5",
      "finance7WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance7courseExpectation1",
      "finance7courseExpectation2",
      "finance7courseExpectation3",
      "finance7courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance7courseContent1title",
        contents: [
          "finance7courseContent1Desc1",
          "finance7courseContent1Desc2",
          "finance7courseContent1Desc3",
          "finance7courseContent1Desc4",
        ],
      },
      {
        title: "finance7courseContent2title",
        contents: [
          "finance7courseContent2Desc1",
          "finance7courseContent2Desc2",
          "finance7courseContent2Desc3",
          "finance7courseContent2Desc4",
        ],
      },
      {
        title: "finance7courseContent3title",
        contents: [
          "finance7courseContent3Desc1",
          "finance7courseContent3Desc2",
          "finance7courseContent3Desc3",
          "finance7courseContent3Desc4",
          "finance7courseContent3Desc5",
        ],
      },
      {
        title: "finance7courseContent4title",
        contents: [
          "finance7courseContent4Desc1",
          "finance7courseContent4Desc2",
          "finance7courseContent4Desc3",
          "finance7courseContent4Desc4",
        ],
      },
      {
        title: "finance7courseContent5title",
        contents: [
          "finance7courseContent5Desc1",
          "finance7courseContent5Desc2",
          "finance7courseContent5Desc3",
          "finance7courseContent5Desc4",
          "finance7courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN07",
  },
  {
    id: 26,
    image: Finance8,
    title: "finance8Title",
    desc: "finance8Desc",
    WhoShouldAttend: [
      "finance8WhoShouldAttend1",
      "finance8WhoShouldAttend2",
      "finance8WhoShouldAttend3",
      "finance8WhoShouldAttend4",
      "finance8WhoShouldAttend5",
      "finance8WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance8courseExpectation1",
      "finance8courseExpectation2",
      "finance8courseExpectation3",
      "finance8courseExpectation4",
      "finance8courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance8courseContent1title",
        contents: [
          "finance8courseContent1Desc1",
          "finance8courseContent1Desc2",
          "finance8courseContent1Desc3",
          "finance8courseContent1Desc4",
          "finance8courseContent1Desc5",
          "finance8courseContent1Desc6",
        ],
      },
      {
        title: "finance8courseContent2title",
        contents: [
          "finance8courseContent2Desc1",
          "finance8courseContent2Desc2",
          "finance8courseContent2Desc3",
          "finance8courseContent2Desc4",
          "finance8courseContent2Desc5",
        ],
      },
      {
        title: "finance8courseContent3title",
        contents: [
          "finance8courseContent3Desc1",
          "finance8courseContent3Desc2",
          "finance8courseContent3Desc3",
          "finance8courseContent3Desc4",
          "finance8courseContent3Desc5",
        ],
      },
      {
        title: "finance8courseContent4title",
        contents: [
          "finance8courseContent4Desc1",
          "finance8courseContent4Desc2",
          "finance8courseContent4Desc3",
          "finance8courseContent4Desc4",
          "finance8courseContent4Desc5",
        ],
      },
      {
        title: "finance8courseContent5title",
        contents: [
          "finance8courseContent5Desc1",
          "finance8courseContent5Desc2",
          "finance8courseContent5Desc3",
          "finance8courseContent5Desc4",
          "finance8courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN08",
  },
  {
    id: 27,
    image: Finance9,
    title: "finance9Title",
    desc: "finance9Desc",
    WhoShouldAttend: ["finance9WhoShouldAttend"],
    courseExpectation: [
      "finance9courseExpectation1",
      "finance9courseExpectation2",
      "finance9courseExpectation3",
      "finance9courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance9courseContent1title",
        contents: [
          "finance9courseContent1Desc1",
          "finance9courseContent1Desc2",
          "finance9courseContent1Desc3",
          "finance9courseContent1Desc4",
          "finance9courseContent1Desc5",
          "finance9courseContent1Desc6",
          "finance9courseContent1Desc7",
        ],
      },
      {
        title: "finance9courseContent2title",
        contents: [
          "finance9courseContent2Desc1",
          "finance9courseContent2Desc2",
          "finance9courseContent2Desc3",
          "finance9courseContent2Desc4",
          "finance9courseContent2Desc5",
          "finance9courseContent2Desc6",
        ],
      },
      {
        title: "finance9courseContent3title",
        contents: [
          "finance9courseContent3Desc1",
          "finance9courseContent3Desc2",
          "finance9courseContent3Desc3",
          "finance9courseContent3Desc4",
          "finance9courseContent3Desc5",
          "finance9courseContent3Desc6",
        ],
      },
      {
        title: "finance9courseContent4title",
        contents: [
          "finance9courseContent4Desc1",
          "finance9courseContent4Desc2",
          "finance9courseContent4Desc3",
          "finance9courseContent4Desc4",
          "finance9courseContent4Desc5",
          "finance9courseContent4Desc6",
          "finance9courseContent4Desc7",
          "finance9courseContent4Desc8",
        ],
      },
      {
        title: "finance9courseContent5title",
        contents: [
          "finance9courseContent5Desc1",
          "finance9courseContent5Desc2",
          "finance9courseContent5Desc3",
          "finance9courseContent5Desc4",
          "finance9courseContent5Desc5",
          "finance9courseContent5Desc6",
          "finance9courseContent5Desc7",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN09",
  },
  {
    id: 28,
    image: Finance10,
    title: "finance10Title",
    desc: "finance10Desc",
    WhoShouldAttend: ["finance10WhoShouldAttend"],
    courseExpectation: [
      "finance10courseExpectation1",
      "finance10courseExpectation2",
      "finance10courseExpectation3",
      "finance10courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance10courseContent1title",
        contents: [
          "finance10courseContent1Desc1",
          "finance10courseContent1Desc2",
          "finance10courseContent1Desc3",
          "finance10courseContent1Desc4",
          "finance10courseContent1Desc5",
          "finance10courseContent1Desc6",
          "finance10courseContent1Desc7",
          "finance10courseContent1Desc8",
          "finance10courseContent1Desc9",
          "finance10courseContent1Desc10",
          "finance10courseContent1Desc11",
          "finance10courseContent1Desc12",
        ],
      },
      {
        title: "finance10courseContent2title",
        contents: [
          "finance10courseContent2Desc1",
          "finance10courseContent2Desc2",
          "finance10courseContent2Desc3",
          "finance10courseContent2Desc4",
          "finance10courseContent2Desc5",
          "finance10courseContent2Desc6",
          "finance10courseContent2Desc7",
          "finance10courseContent2Desc8",
          "finance10courseContent2Desc9",
          "finance10courseContent2Desc10",
          "finance10courseContent2Desc11",
        ],
      },
      {
        title: "finance10courseContent3title",
        contents: [
          "finance10courseContent3Desc1",
          "finance10courseContent3Desc2",
          "finance10courseContent3Desc3",
          "finance10courseContent3Desc4",
          "finance10courseContent3Desc5",
          "finance10courseContent3Desc6",
          "finance10courseContent3Desc7",
          "finance10courseContent3Desc8",
          "finance10courseContent3Desc9",
          "finance10courseContent3Desc10",
          "finance10courseContent3Desc11",
          "finance10courseContent3Desc12",
        ],
      },
      {
        title: "finance10courseContent4title",
        contents: [
          "finance10courseContent4Desc1",
          "finance10courseContent4Desc2",
          "finance10courseContent4Desc3",
          "finance10courseContent4Desc4",
          "finance10courseContent4Desc5",
          "finance10courseContent4Desc6",
          "finance10courseContent4Desc7",
          "finance10courseContent4Desc8",
          "finance10courseContent4Desc9",
          "finance10courseContent4Desc10",
          "finance10courseContent4Desc11",
        ],
      },
      {
        title: "finance10courseContent5title",
        contents: [
          "finance10courseContent5Desc1",
          "finance10courseContent5Desc2",
          "finance10courseContent5Desc3",
          "finance10courseContent5Desc4",
          "finance10courseContent5Desc5",
          "finance10courseContent5Desc6",
          "finance10courseContent5Desc7",
          "finance10courseContent5Desc8",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN10",
  },
  {
    id: 29,
    image: Finance11,
    title: "finance11Title",
    desc: "finance11Desc",
    WhoShouldAttend: [
      "finance11WhoShouldAttend1",
      "finance11WhoShouldAttend2",
      "finance11WhoShouldAttend3",
      "finance11WhoShouldAttend4",
      "finance11WhoShouldAttend5",
      "finance11WhoShouldAttend6",
      "finance11WhoShouldAttend7",
    ],
    courseExpectation: [
      "finance11courseExpectation1",
      "finance11courseExpectation2",
      "finance11courseExpectation3",
      "finance11courseExpectation4",
      "finance11courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance11courseContent1title",
        contents: [
          "finance11courseContent1Desc1",
          "finance11courseContent1Desc2",
          "finance11courseContent1Desc3",
          "finance11courseContent1Desc4",
          "finance11courseContent1Desc5",
        ],
      },
      {
        title: "finance11courseContent2title",
        contents: [
          "finance11courseContent2Desc1",
          "finance11courseContent2Desc2",
          "finance11courseContent2Desc3",
          "finance11courseContent2Desc4",
          "finance11courseContent2Desc5",
        ],
      },
      {
        title: "finance11courseContent3title",
        contents: [
          "finance11courseContent3Desc1",
          "finance11courseContent3Desc2",
          "finance11courseContent3Desc3",
          "finance11courseContent3Desc4",
        ],
      },
      {
        title: "finance11courseContent4title",
        contents: [
          "finance11courseContent4Desc1",
          "finance11courseContent4Desc2",
          "finance11courseContent4Desc3",
          "finance11courseContent4Desc4",
        ],
      },
      {
        title: "finance11courseContent5title",
        contents: [
          "finance11courseContent5Desc1",
          "finance11courseContent5Desc2",
          "finance11courseContent5Desc3",
          "finance11courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN11",
  },
  {
    id: 30,
    image: Finance12,
    title: "finance12Title",
    desc: "finance12Desc",
    WhoShouldAttend: ["finance12WhoShouldAttend1", "finance12WhoShouldAttend2"],
    courseExpectation: [
      "finance12courseExpectation1",
      "finance12courseExpectation2",
      "finance12courseExpectation3",
      "finance12courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance12courseContent1title",
        contents: [
          "finance12courseContent1Desc1",
          "finance12courseContent1Desc2",
          "finance12courseContent1Desc3",
          "finance12courseContent1Desc4",
        ],
      },
      {
        title: "finance12courseContent2title",
        contents: [
          "finance12courseContent2Desc1",
          "finance12courseContent2Desc2",
          "finance12courseContent2Desc3",
          "finance12courseContent2Desc4",
        ],
      },
      {
        title: "finance12courseContent3title",
        contents: [
          "finance12courseContent3Desc1",
          "finance12courseContent3Desc2",
          "finance12courseContent3Desc3",
          "finance12courseContent3Desc4",
        ],
      },
      {
        title: "finance12courseContent4title",
        contents: [
          "finance12courseContent4Desc1",
          "finance12courseContent4Desc2",
          "finance12courseContent4Desc3",
          "finance12courseContent4Desc4",
        ],
      },
      {
        title: "finance12courseContent5title",
        contents: [
          "finance12courseContent5Desc1",
          "finance12courseContent5Desc2",
          "finance12courseContent5Desc3",
          "finance12courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN12",
  },
  {
    id: 31,
    image: Finance13,
    title: "finance13Title",
    desc: "finance13Desc",
    WhoShouldAttend: [
      "finance13WhoShouldAttend1",
      "finance13WhoShouldAttend2",
      "finance13WhoShouldAttend3",
      "finance13WhoShouldAttend4",
      "finance13WhoShouldAttend5",
    ],
    courseExpectation: [
      "finance13courseExpectation1",
      "finance13courseExpectation2",
      "finance13courseExpectation3",
      "finance13courseExpectation4",
      "finance13courseExpectation5",
      "finance13courseExpectation6",
      "finance13courseExpectation7",
    ],
    courseContent: [
      {
        title: "finance13courseContent1title",
        contents: [
          "finance13courseContent1Desc1",
          "finance13courseContent1Desc2",
          "finance13courseContent1Desc3",
          "finance13courseContent1Desc4",
        ],
      },
      {
        title: "finance13courseContent2title",
        contents: [
          "finance13courseContent2Desc1",
          "finance13courseContent2Desc2",
          "finance13courseContent2Desc3",
          "finance13courseContent2Desc4",
          "finance13courseContent2Desc5",
          "finance13courseContent2Desc6",
        ],
      },
      {
        title: "finance13courseContent3title",
        contents: [
          "finance13courseContent3Desc1",
          "finance13courseContent3Desc2",
          "finance13courseContent3Desc3",
          "finance13courseContent3Desc4",
          "finance13courseContent3Desc5",
        ],
      },
      {
        title: "finance13courseContent4title",
        contents: [
          "finance13courseContent4Desc1",
          "finance13courseContent4Desc2",
          "finance13courseContent4Desc3",
          "finance13courseContent4Desc4",
          "finance13courseContent4Desc5",
        ],
      },
      {
        title: "finance13courseContent5title",
        contents: [
          "finance13courseContent5Desc1",
          "finance13courseContent5Desc2",
          "finance13courseContent5Desc3",
          "finance13courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN13",
  },
  {
    id: 32,
    image: Finance14,
    title: "finance14Title",
    desc: "finance14Desc",
    WhoShouldAttend: [
      "finance14WhoShouldAttend1",
      "finance14WhoShouldAttend2",
      "finance14WhoShouldAttend3",
      "finance14WhoShouldAttend4",
      "finance14WhoShouldAttend5",
      "finance14WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance14courseExpectation1",
      "finance14courseExpectation2",
      "finance14courseExpectation3",
      "finance14courseExpectation4",
      "finance14courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance14courseContent1title",
        contents: [
          "finance14courseContent1Desc1",
          "finance14courseContent1Desc2",
          "finance14courseContent1Desc3",
        ],
      },
      {
        title: "finance14courseContent2title",
        contents: [
          "finance14courseContent2Desc1",
          "finance14courseContent2Desc2",
          "finance14courseContent2Desc3",
          "finance14courseContent2Desc4",
          "finance14courseContent2Desc5",
        ],
      },
      {
        title: "finance14courseContent3title",
        contents: [
          "finance14courseContent3Desc1",
          "finance14courseContent3Desc2",
          "finance14courseContent3Desc3",
        ],
      },
      {
        title: "finance14courseContent4title",
        contents: [
          "finance14courseContent4Desc1",
          "finance14courseContent4Desc2",
          "finance14courseContent4Desc3",
        ],
      },
      {
        title: "finance14courseContent5title",
        contents: [
          "finance14courseContent5Desc1",
          "finance14courseContent5Desc2",
          "finance14courseContent5Desc3",
          "finance14courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN14",
  },
  {
    id: 33,
    image: Finance15,
    title: "finance15Title",
    desc: "finance15Desc",
    WhoShouldAttend: ["finance15WhoShouldAttend"],
    courseExpectation: [
      "finance15courseExpectation1",
      "finance15courseExpectation2",
      "finance15courseExpectation3",
      "finance15courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance15courseContent1title",
        contents: [
          "finance15courseContent1Desc1",
          "finance15courseContent1Desc2",
          "finance15courseContent1Desc3",
          "finance15courseContent1Desc4",
        ],
      },
      {
        title: "finance15courseContent2title",
        contents: [
          "finance15courseContent2Desc1",
          "finance15courseContent2Desc2",
          "finance15courseContent2Desc3",
          "finance15courseContent2Desc4",
        ],
      },
      {
        title: "finance15courseContent3title",
        contents: [
          "finance15courseContent3Desc1",
          "finance15courseContent3Desc2",
          "finance15courseContent3Desc3",
          "finance15courseContent3Desc4",
        ],
      },
      {
        title: "finance15courseContent4title",
        contents: [
          "finance15courseContent4Desc1",
          "finance15courseContent4Desc2",
          "finance15courseContent4Desc3",
          "finance15courseContent4Desc4",
        ],
      },
      {
        title: "finance15courseContent5title",
        contents: [
          "finance15courseContent5Desc1",
          "finance15courseContent5Desc2",
          "finance15courseContent5Desc3",
          "finance15courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN15",
  },
  {
    id: 34,
    image: Finance16,
    title: "finance16Title",
    desc: "finance16Desc",
    WhoShouldAttend: ["finance16WhoShouldAttend"],
    courseExpectation: [
      "finance16courseExpectation1",
      "finance16courseExpectation2",
      "finance16courseExpectation3",
      "finance16courseExpectation4",
      "finance16courseExpectation5",
      "finance16courseExpectation6",
    ],
    courseContent: [
      {
        title: "finance16courseContent1title",
        contents: [
          "finance16courseContent1Desc1",
          "finance16courseContent1Desc2",
          "finance16courseContent1Desc3",
          "finance16courseContent1Desc4",
        ],
      },
      {
        title: "finance16courseContent2title",
        contents: [
          "finance16courseContent2Desc1",
          "finance16courseContent2Desc2",
          "finance16courseContent2Desc3",
          "finance16courseContent2Desc4",
        ],
      },
      {
        title: "finance16courseContent3title",
        contents: [
          "finance16courseContent3Desc1",
          "finance16courseContent3Desc2",
          "finance16courseContent3Desc3",
          "finance16courseContent3Desc4",
        ],
      },
      {
        title: "finance16courseContent4title",
        contents: [
          "finance16courseContent4Desc1",
          "finance16courseContent4Desc2",
          "finance16courseContent4Desc3",
          "finance16courseContent4Desc4",
          "finance16courseContent4Desc5",
          "finance16courseContent4Desc6",
          "finance16courseContent4Desc7",
          "finance16courseContent4Desc8",
          "finance16courseContent4Desc9",
          "finance16courseContent4Desc10",
          "finance16courseContent4Desc11",
        ],
      },
      {
        title: "finance16courseContent5title",
        contents: [
          "finance16courseContent5Desc1",
          "finance16courseContent5Desc2",
          "finance16courseContent5Desc3",
          "finance16courseContent5Desc4",
          "finance16courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN16",
  },
  {
    id: 35,
    image: Finance17,
    title: "finance17Title",
    desc: "finance17Desc",
    WhoShouldAttend: ["finance16WhoShouldAttend"],
    courseExpectation: [
      "finance17courseExpectation1",
      "finance17courseExpectation2",
      "finance17courseExpectation3",
      "finance17courseExpectation4",
      "finance17courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance17courseContent1title",
        contents: [
          "finance17courseContent1Desc1",
          "finance17courseContent1Desc2",
          "finance17courseContent1Desc3",
          "finance17courseContent1Desc4",
        ],
      },
      {
        title: "finance17courseContent2title",
        contents: [
          "finance17courseContent2Desc1",
          "finance17courseContent2Desc2",
          "finance17courseContent2Desc3",
          "finance17courseContent2Desc4",
          "finance17courseContent2Desc5",
          "finance17courseContent2Desc6",
        ],
      },
      {
        title: "finance17courseContent3title",
        contents: [
          "finance17courseContent3Desc1",
          "finance17courseContent3Desc2",
          "finance17courseContent3Desc3",
          "finance17courseContent3Desc4",
        ],
      },
      {
        title: "finance17courseContent4title",
        contents: [
          "finance17courseContent4Desc1",
          "finance17courseContent4Desc2",
          "finance17courseContent4Desc3",
          "finance17courseContent4Desc4",
          "finance17courseContent4Desc5",
          "finance17courseContent4Desc6",
          "finance17courseContent4Desc7",
          "finance17courseContent4Desc8",
          "finance17courseContent4Desc9",
        ],
      },
      {
        title: "finance17courseContent5title",
        contents: [
          "finance17courseContent5Desc1",
          "finance17courseContent5Desc2",
          "finance17courseContent5Desc3",
          "finance17courseContent5Desc4",
          "finance17courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN17",
  },
  {
    id: 36,
    image: Finance18,
    title: "finance18Title",
    desc: "finance18Desc",
    WhoShouldAttend: ["finance16WhoShouldAttend"],
    courseExpectation: [
      "finance18courseExpectation1",
      "finance18courseExpectation2",
      "finance18courseExpectation3",
      "finance18courseExpectation4",
      "finance18courseExpectation5",
      "finance18courseExpectation6",
    ],
    courseContent: [
      {
        title: "finance18courseContent1title",
        contents: [
          "finance18courseContent1Desc1",
          "finance18courseContent1Desc2",
          "finance18courseContent1Desc3",
          "finance18courseContent1Desc4",
        ],
      },
      {
        title: "finance18courseContent2title",
        contents: [
          "finance18courseContent2Desc1",
          "finance18courseContent2Desc2",
          "finance18courseContent2Desc3",
          "finance18courseContent2Desc4",
        ],
      },
      {
        title: "finance18courseContent3title",
        contents: [
          "finance18courseContent3Desc1",
          "finance18courseContent3Desc2",
          "finance18courseContent3Desc3",
          "finance18courseContent3Desc4",
          "finance18courseContent3Desc5",
          "finance18courseContent3Desc6",
          "finance18courseContent3Desc7",
          "finance18courseContent3Desc8",
        ],
      },
      {
        title: "finance18courseContent4title",
        contents: [
          "finance18courseContent4Desc1",
          "finance18courseContent4Desc2",
          "finance18courseContent4Desc3",
          "finance18courseContent4Desc4",
        ],
      },
      {
        title: "finance18courseContent5title",
        contents: [
          "finance18courseContent5Desc1",
          "finance18courseContent5Desc2",
          "finance18courseContent5Desc3",
          "finance18courseContent5Desc4",
          "finance18courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN18",
  },
  {
    id: 37,
    image: Finance19,
    title: "finance19Title",
    desc: "finance19Desc",
    WhoShouldAttend: [
      "finance19WhoShouldAttend1",
      "finance19WhoShouldAttend2",
      "finance19WhoShouldAttend3",
      "finance19WhoShouldAttend4",
      "finance19WhoShouldAttend5",
      "finance19WhoShouldAttend6",
      "finance19WhoShouldAttend7",
    ],
    courseExpectation: [
      "finance19courseExpectation1",
      "finance19courseExpectation2",
      "finance19courseExpectation3",
      "finance19courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance19courseContent1title",
        contents: [
          "finance19courseContent1Desc1",
          "finance19courseContent1Desc2",
          "finance19courseContent1Desc3",
          "finance19courseContent1Desc4",
          "finance19courseContent1Desc5",
        ],
      },
      {
        title: "finance19courseContent2title",
        contents: [
          "finance19courseContent2Desc1",
          "finance19courseContent2Desc2",
          "finance19courseContent2Desc3",
          "finance19courseContent2Desc4",
          "finance19courseContent2Desc5",
        ],
      },
      {
        title: "finance19courseContent3title",
        contents: [
          "finance19courseContent3Desc1",
          "finance19courseContent3Desc2",
          "finance19courseContent3Desc3",
          "finance19courseContent3Desc4",
          "finance19courseContent3Desc5",
          "finance19courseContent3Desc6",
          "finance19courseContent3Desc7",
        ],
      },
      {
        title: "finance19courseContent4title",
        contents: [
          "finance19courseContent4Desc1",
          "finance19courseContent4Desc2",
          "finance19courseContent4Desc3",
          "finance19courseContent4Desc4",
          "finance19courseContent4Desc5",
          "finance19courseContent4Desc6",
        ],
      },
      {
        title: "finance19courseContent5title",
        contents: [
          "finance19courseContent5Desc1",
          "finance19courseContent5Desc2",
          "finance19courseContent5Desc3",
          "finance19courseContent5Desc4",
          "finance19courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN19",
  },
  {
    id: 38,
    image: Finance20,
    title: "finance20Title",
    desc: "finance20Desc",
    WhoShouldAttend: [
      "finance20WhoShouldAttend1",
      "finance20WhoShouldAttend2",
      "finance20WhoShouldAttend3",
      "finance20WhoShouldAttend4",
      "finance20WhoShouldAttend5",
      "finance20WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance20courseExpectation1",
      "finance20courseExpectation2",
      "finance20courseExpectation3",
      "finance20courseExpectation4",
      "finance20courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance20courseContent1title",
        contents: [
          "finance20courseContent1Desc1",
          "finance20courseContent1Desc2",
          "finance20courseContent1Desc3",
          "finance20courseContent1Desc4",
          "finance20courseContent1Desc5",
        ],
      },
      {
        title: "finance20courseContent2title",
        contents: [
          "finance20courseContent2Desc1",
          "finance20courseContent2Desc2",
          "finance20courseContent2Desc3",
          "finance20courseContent2Desc4",
          "finance20courseContent2Desc5",
        ],
      },
      {
        title: "finance20courseContent3title",
        contents: [
          "finance20courseContent3Desc1",
          "finance20courseContent3Desc2",
          "finance20courseContent3Desc3",
          "finance20courseContent3Desc4",
          "finance20courseContent3Desc5",
          "finance20courseContent3Desc6",
          "finance20courseContent3Desc7",
        ],
      },
      {
        title: "finance20courseContent4title",
        contents: [
          "finance20courseContent4Desc1",
          "finance20courseContent4Desc2",
          "finance20courseContent4Desc3",
          "finance20courseContent4Desc4",
          "finance20courseContent4Desc5",
        ],
      },
      {
        title: "finance20courseContent5title",
        contents: [
          "finance20courseContent5Desc1",
          "finance20courseContent5Desc2",
          "finance20courseContent5Desc3",
          "finance20courseContent5Desc4",
          "finance20courseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN20",
  },
  {
    id: 39,
    image: Finance21,
    title: "finance21Title",
    desc: "finance21Desc",
    WhoShouldAttend: [
      "finance21WhoShouldAttend1",
      "finance21WhoShouldAttend2",
      "finance21WhoShouldAttend3",
      "finance21WhoShouldAttend4",
      "finance21WhoShouldAttend5",
      "finance21WhoShouldAttend6",
    ],
    courseExpectation: [
      "finance21courseExpectation1",
      "finance21courseExpectation2",
      "finance21courseExpectation3",
      "finance21courseExpectation4",
      "finance21courseExpectation5",
    ],
    courseContent: [
      {
        title: "finance21courseContent1title",
        contents: [
          "finance21courseContent1Desc1",
          "finance21courseContent1Desc2",
          "finance21courseContent1Desc3",
          "finance21courseContent1Desc4",
        ],
      },
      {
        title: "finance21courseContent2title",
        contents: [
          "finance21courseContent2Desc1",
          "finance21courseContent2Desc2",
          "finance21courseContent2Desc3",
          "finance21courseContent2Desc4",
        ],
      },
      {
        title: "finance21courseContent3title",
        contents: [
          "finance21courseContent3Desc1",
          "finance21courseContent3Desc2",
          "finance21courseContent3Desc3",
          "finance21courseContent3Desc4",
          "finance21courseContent3Desc5",
        ],
      },
      {
        title: "finance21courseContent4title",
        contents: [
          "finance21courseContent4Desc1",
          "finance21courseContent4Desc2",
          "finance21courseContent4Desc3",
          "finance21courseContent4Desc4",
        ],
      },
      {
        title: "finance21courseContent5title",
        contents: [
          "finance21courseContent5Desc1",
          "finance21courseContent5Desc2",
          "finance21courseContent5Desc3",
          "finance21courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN21",
  },
  {
    id: 40,
    image: Finance22,
    title: "finance22Title",
    desc: "finance21Desc",
    WhoShouldAttend: [
      "finance21WhoShouldAttend1",
      "finance21WhoShouldAttend3",
      "finance22WhoShouldAttend1",
      "finance22WhoShouldAttend2",
      "finance22WhoShouldAttend3",
      "finance22WhoShouldAttend4",
      "finance22WhoShouldAttend5",
    ],
    courseExpectation: [
      "finance22courseExpectation1",
      "finance22courseExpectation2",
      "finance22courseExpectation3",
      "finance22courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance22courseContent1title",
        contents: [
          "finance22courseContent1Desc1",
          "finance22courseContent1Desc2",
          "finance22courseContent1Desc3",
          "finance22courseContent1Desc4",
          "finance22courseContent1Desc5",
        ],
      },
      {
        title: "finance22courseContent2title",
        contents: [
          "finance22courseContent2Desc1",
          "finance22courseContent2Desc2",
          "finance22courseContent2Desc3",
        ],
      },
      {
        title: "finance22courseContent3title",
        contents: [
          "finance22courseContent3Desc1",
          "finance22courseContent3Desc2",
          "finance22courseContent3Desc3",
          "finance22courseContent3Desc4",
          "finance22courseContent3Desc5",
          "finance22courseContent3Desc6",
        ],
      },
      {
        title: "finance22courseContent4title",
        contents: [
          "finance22courseContent4Desc1",
          "finance22courseContent4Desc2",
          "finance22courseContent4Desc3",
          "finance22courseContent4Desc4",
          "finance22courseContent4Desc5",
        ],
      },
      {
        title: "finance22courseContent5title",
        contents: [
          "finance22courseContent5Desc1",
          "finance22courseContent5Desc2",
          "finance22courseContent5Desc3",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN22",
  },
  {
    id: 41,
    image: Finance23,
    title: "finance23Title",
    desc: "finance23Desc",
    WhoShouldAttend: ["finance23WhoShouldAttend"],
    courseExpectation: [
      "finance23courseExpectation1",
      "finance23courseExpectation2",
      "finance23courseExpectation3",
      "finance23courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance23courseContent1title",
        contents: [
          "finance23courseContent1Desc1",
          "finance23courseContent1Desc2",
          "finance23courseContent1Desc3",
          "finance23courseContent1Desc4",
        ],
      },
      {
        title: "finance23courseContent2title",
        contents: [
          "finance23courseContent2Desc1",
          "finance23courseContent2Desc2",
          "finance23courseContent2Desc3",
          "finance23courseContent2Desc4",
        ],
      },
      {
        title: "finance23courseContent3title",
        contents: [
          "finance23courseContent3Desc1",
          "finance23courseContent3Desc2",
          "finance23courseContent3Desc3",
          "finance23courseContent3Desc4",
        ],
      },
      {
        title: "finance23courseContent4title",
        contents: [
          "finance23courseContent4Desc1",
          "finance23courseContent4Desc2",
          "finance23courseContent4Desc3",
          "finance23courseContent4Desc4",
          "finance23courseContent4Desc5",
          "finance23courseContent4Desc6",
          "finance23courseContent4Desc7",
          "finance23courseContent4Desc8",
          "finance23courseContent4Desc9",
          "finance23courseContent4Desc10",
          "finance23courseContent4Desc11",
        ],
      },
      {
        title: "finance23courseContent5title",
        contents: [
          "finance23courseContent5Desc1",
          "finance23courseContent5Desc2",
          "finance23courseContent5Desc3",
          "finance23courseContent5Desc4",
          "finance23courseContent5Desc5",
        ],
      },
      {
        title: "finance23courseContent6title",
        contents: [
          "finance23courseContent6Desc1",
          "finance23courseContent6Desc2",
          "finance23courseContent6Desc3",
          "finance23courseContent6Desc4",
          "finance23courseContent6Desc5",
          "finance23courseContent6Desc6",
        ],
      },
      {
        title: "finance23courseContent7title",
        contents: [
          "finance23courseContent7Desc1",
          "finance23courseContent7Desc2",
          "finance23courseContent7Desc3",
          "finance23courseContent7Desc4",
        ],
      },
      {
        title: "finance23courseContent8title",
        contents: [
          "finance23courseContent8Desc1",
          "finance23courseContent8Desc2",
          "finance23courseContent8Desc3",
          "finance23courseContent8Desc4",
          "finance23courseContent8Desc5",
          "finance23courseContent8Desc6",
          "finance23courseContent8Desc7",
          "finance23courseContent8Desc8",
          "finance23courseContent8Desc9",
        ],
      },
      {
        title: "finance23courseContent9title",
        contents: [
          "finance23courseContent9Desc1",
          "finance23courseContent9Desc2",
          "finance23courseContent9Desc3",
          "finance23courseContent9Desc4",
          "finance23courseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Finance",
    courseCode: "FIN23",
  },
  {
    id: 42,
    image: Finance24,
    title: "finance24Title",
    desc: "finance24Desc",
    WhoShouldAttend: [
      "finance24WhoShouldAttend1",
      "finance24WhoShouldAttend2",
      "finance24WhoShouldAttend3",
      "finance24WhoShouldAttend4",
      "finance24WhoShouldAttend5",
    ],
    courseExpectation: [
      "finance24courseExpectation1",
      "finance24courseExpectation2",
      "finance24courseExpectation3",
      "finance24courseExpectation4",
    ],
    courseContent: [
      {
        title: "finance24courseContent1title",
        contents: [
          "finance24courseContent1Desc1",
          "finance24courseContent1Desc2",
          "finance24courseContent1Desc3",
          "finance24courseContent1Desc4",
        ],
      },
      {
        title: "finance24courseContent2title",
        contents: [
          "finance24courseContent2Desc1",
          "finance24courseContent2Desc2",
          "finance24courseContent2Desc3",
          "finance24courseContent2Desc4",
        ],
      },
      {
        title: "finance24courseContent3title",
        contents: [
          "finance24courseContent3Desc1",
          "finance24courseContent3Desc2",
          "finance24courseContent3Desc3",
          "finance24courseContent3Desc4",
          "finance24courseContent3Desc5",
        ],
      },
      {
        title: "finance24courseContent4title",
        contents: [
          "finance24courseContent4Desc1",
          "finance24courseContent4Desc2",
          "finance24courseContent4Desc3",
          "finance24courseContent4Desc4",
        ],
      },
      {
        title: "finance24courseContent5title",
        contents: [
          "finance24courseContent5Desc1",
          "finance24courseContent5Desc2",
          "finance24courseContent5Desc3",
          "finance24courseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Finance",
    courseCode: "FIN24",
  },
  // Finance

  // Oil & Gas
  {
    id: 43,
    image: Oil1,
    title: "Fundamentals of Oil & Gas Exploration & Production ",
    desc: " Become familiar with the key elements, technologies and processes of the upstream oil and gas sector",
    WhoShouldAttend: [
      "Professionals who need to learn about the industry",
      "Personnel within the industry",
      "Technical support staff entering the industry",
      "Technical staff who want to expand their understanding of the industry",
      "Students considering the industry as a career",
    ],
    courseExpectation: [
      "	Deliver a comprehensive overview of the oil and gas exploration and production industry",
      "Describe a historical, current and future perspective for both non-technical and technical staff",
      "Explain the principles of exploration, drilling, development and production",
      "Elaborate on what is happening in the industry, now and in the future",
      "Explain the full lifecycle of exploration to product delivery",
      "Identify the nomenclature and terminology used in the industry",
    ],
    courseContent: [
      {
        title: "Introduction, History and geopolitics of oil and gas",
        contents: [
          "	Pre-history",
          "1800s to the great wars",
          "Post war to present",
        ],
      },
      {
        title: "The role of oil and gas in society",
        contents: ["The nature of oil and gas", "Uses for oil and gas"],
      },
      {
        title: "The big picture",
        contents: ["Upstream", "Midstream", "Downstream"],
      },
      {
        title: "Petroleum Geology",
        contents: [
          "	Geological time",
          "Rock types",
          "Sedimentary basins",
          "Plate tectonics",
          "Source rocks",
          "Reservoirs",
          "Traps and seals",
        ],
      },
      {
        title: "Exploration",
        contents: [
          "	Gravity and magnetic surveying",
          "2D, 3D and 4D seismic",
          "Field valuation",
          "Field appraisal",
        ],
      },
      {
        title: "Drilling",
        contents: [
          "	Drilling basics",
          "Casing and completing a well",
          "Sampling and testing",
          "Well information",
          "Well completions",
        ],
      },
      {
        title: "Field development and production",
        contents: [
          "	Field development planning",
          "Lifecycle costing",
          "Economic analysis",
          "Development drilling",
          "Facilities and production",
          "Abandonment",
        ],
      },
      {
        title:
          "Petroleum contracts, Petroleum economics, Health, Safety and Environment",
        contents: [
          "	Health considerations",
          "Safety considerations",
          "Environmental considerations",
        ],
      },
      {
        title: "Alternatives",
        contents: [
          "Unconventionals",
          "Alternatives to oil and gas",
          "Carbon capture and storage (CO2 sequestration)",
        ],
      },
      {
        title: "The future",
        contents: [
          "New technologies",
          "Where will we find oil and gas in the future?",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO01",
  },
  {
    id: 44,
    image: Oil2,
    title: "Introduction to Process Safety ",
    desc: " Learn the fundamentals of process safety in the oil and gas industry",
    WhoShouldAttend: [
      "	Technical, commercial and management staff that require a high level technical overview of process safety in the hydrocarbon processing industry",
      "Personnel within the industry",
      "Technical support staff entering the industry",
      "Technical staff who want to expand their understanding of the industry",
      "Anyone who would like to develop a broad understanding of process safety",
    ],
    courseExpectation: [
      "	Define process safety and the human, environmental and business consequences of poor process safety",
      "Apply a simple model for analysing process safety incidents",
      "Describe process safety hazards and risk",
      "Describe the elements of a modern process safety management system and how such systems are organised",
    ],
    courseContent: [
      {
        title: "Hazards of Hydrocarbon",
        contents: [
          "	Hazards of the Loss of Containment of Liquid Hydrocarbons",
          "Hydrocarbon Gas Leaks – Dispersion and Detection",
          "Hydrocarbon Ignition",
          "Hydrocarbon Fires",
          "Hydrocarbon Explosions",
        ],
      },
      {
        title: "Importance of Process Safety",
        contents: [
          "	Importance of process safety",
          "Process safety in design",
          "Asset integrity",
          "A review of some of the major process safety incidents in the industry and Lessons learned",
          "Using incident data to make risk-based decisions",
        ],
      },
      {
        title: "Process Safety Protection systems",
        contents: [
          "	Inherently Safe Design (ISD)",
          "Active protection systems",
          "Passive protection systems",
          "Procedural protective systems",
          "Layers of Protection",
        ],
      },
      {
        title: "Process Monitoring and Control",
        contents: [
          "	Fire and Gas Detection",
          "Alarm systems",
          "SIL levels and safety instrumented systems",
          "Control loops e.g. anti-surge control",
          "Shutdown and Suppression systems",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO02",
  },
  {
    id: 45,
    image: Oil3,
    title: "Oil & Gas - Business Environment and Finance ",
    desc: " Get to understand the complexity of the global oil and gas business environment ",
    WhoShouldAttend: [
      "Senior managers within the Oil & Gas sector or those identified via organisational talent management for fast track to a senior role",
      "Public sector officials with responsibility for the state management of Oil & Gas resources",
      "Engineers and Project Managers who are looking to develop leading edge practice for both themselves and their organisation",
    ],
    courseExpectation: [
      "	Present the main feature of the global Oil & Gas business environment",
      "Speculate on emerging trends and opportunities",
      "Apply knowledge of macro-economic factors both regionally and globally that shape the industry",
      "Use knowledge of the key financial management systems",
      "Describe  financial instruments and their impacts",
      "Manage: Market risk, Credit risk and Liquidity risk",
      "Undertake effective financial modelling and scenario planning",
    ],
    courseContent: [
      {
        title: "Global Market Forces",
        contents: [
          "The influence of OPEC",
          "The US Position",
          "Asean influence",
          "Africa influence",
        ],
      },
      {
        title: "Global Threats and Opportunities",
        contents: [
          "	Overview of global reserves and Peak Oil concept",
          "Alternative technologies – threat or opportunity",
          "Emerging territories and cost of access",
          "Environmental aspects",
        ],
      },
      {
        title: "The Cohesive Producer",
        contents: [
          "	Aligning strategy with the global picture",
          "Input and cost management",
          "Output and pricing management",
          "Corporate Social Responsibility and Communication",
        ],
      },
      {
        title: "Strategic Relationships",
        contents: [
          "	Production agreements",
          "Joint venture partnering",
          "Regional Government",
          "Global upstream and downstream models",
        ],
      },
      {
        title: "Industry Governance",
        contents: [
          "	Developing and enhancing governance policies",
          "Combating economic crime and corruption",
          "The impacts of the “Dodd Frank” Act",
          "Global best practice and case studies",
        ],
      },
      {
        title: "Key Financial Management Systems",
        contents: [
          "	Effective Budget preparation and management",
          "Cash flow management and forecasting",
          "Interpreting key financial statements",
          "Key financial ratios and Analysis",
        ],
      },
      {
        title: "Effective Treasury Management",
        contents: [
          "	Overview of global treasury models",
          "Structuring effective return, income and defensive portfolios",
          "The roles and impacts of financial instruments",
          "Effectively managing geographic and asset risk",
        ],
      },
      {
        title: "Effective Risk Management",
        contents: [
          "	The key concepts and elements of risk management",
          "Managing Market Risk",
          "Managing Credit Risk",
          "Managing Liquidity Risk",
        ],
      },
      {
        title: "Practical Financial Modelling",
        contents: [
          "Key principles within Financial Modelling",
          "Constructing key financial models",
          "Linkage of models and sub-models",
          "Scenario Planning Applications",
        ],
      },
    ],
    courseFess: 6080,
    category: "Gas and Oil",
    courseCode: "GO03",
  },
  {
    id: 46,
    image: Oil4,
    title: "Oil & Gas - Business Environment ",
    desc: " Learn to understand the complexity of the oil and gas business environment",
    WhoShouldAttend: [
      "	Senior managers within the Oil & Gas sector or those identified via organizational talent management for fast track to a senior role",
      "Public sector officials with responsibility for the state management of Oil & gas resources",
      "Engineers and Project Managers who are looking to develop leading edge practice for both themselves and their organization",
    ],
    courseExpectation: [
      "	Present the main features of the global Oil & Gas business environment",
      "Speculate on emerging trends and opportunities",
      "Apply knowledge of the macro-economic factors both regionally and globally that shape the industry",
    ],
    courseContent: [
      {
        title: "Global Market Forces",
        contents: [
          "	The influence of OPEC",
          "The US Position",
          "Asean influence",
          "Africa influence",
        ],
      },
      {
        title: "Global Threats and Opportunities",
        contents: [
          "Overview of global reserves & Peak oil concept",
          "Alternative technologies – threat or opportunity",
          "Emerging territories and cost of access",
          "Environmental aspects",
        ],
      },
      {
        title: "The Cohesive Producer",
        contents: [
          "	Aligning strategy with the global picture",
          "Input and cost management",
          "Output and pricing management",
          "Corporate Social Responsibility & Communication",
        ],
      },
      {
        title: "Strategic Relationships",
        contents: [
          "	Production agreements",
          "Joint venture partnering",
          "Regional Government",
          "Global upstream and downstream models",
        ],
      },
      {
        title: "Industry Governance",
        contents: [
          "Developing & enhancing governance policies",
          "Combating economic crime and corruption",
          "The impacts of the “Dodd Frank” Act",
          "Global best practice & case studies",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO04",
  },
  {
    id: 47,
    image: Oil5,
    title: "Oil & Gas - Contract Management ",
    desc: " Develop an in-depth understanding of all phases of contracting and how to manage contractual relationships in the oil and gas sector ",
    WhoShouldAttend: [
      "This is a stimulating and practical course designed for directors, managers, senior executives and decision makers involved in strategic and operational functions, including managers of procurement, finance, legal, risk and supplier relations and who want to understand how to maximise competitive advantage in the Oil and Gas sector.",
    ],
    courseExpectation: [
      "	Lead and manage procurement processes and contract discussions and negotiations",
      "Identify risks that contracts should address",
      "Employ contractual tools and techniques to increase competitive advantage",
      "Apply typical standard contract terms and conditions",
      "Initiate and develop better commercial contracts within their organisation",
      "Improve supplier performance through better contracts",
      "Plan and manage the resolution of contractual disputes",
    ],
    courseContent: [
      {
        title: "Managing the Purchasing Process",
        contents: [
          "Overview of purchasing best practice",
          "Creating interest from the market in your opportunity",
          "The statement of work for an oil services contract",
          "Results-based purchasing techniques",
          "Evaluating supplier proposals",
          "Is the lowest cost technically compliant bid the right one to choose?",
          "Moving from bid to contract award",
        ],
      },
      {
        title: "Petroleum Industry Contracts",
        contents: [
          "	Formation of the contract",
          "Express and Implied terms",
          "Typical features of exploration and production contracts and contracts for the supply of oil-related services",
          "'Local content' contract requirements",
          "Knock for Knock Liability considered",
          "Anti-corruption measures in petroleum industry contracts",
          "Case study exercises using oil contract examples",
        ],
      },
      {
        title: "Managing Contractor Performance",
        contents: [
          "Identifying and managing supplier risk",
          "Getting and maintaining supplier contract performance",
          "Service level agreements",
          "Setting SMART objectives (SLA)",
          "Key performance indicators (KPIs)",
          "Contract governance; managing contractual variations",
          "Why performance is Important and who is involved?",
          "Post contract closure action",
        ],
      },
      {
        title: "Dispute Resolution in the Petroleum Industry",
        contents: [
          "	Contract breaches and remedies",
          "Termination of contract",
          "Should we litigate?",
          "Using arbitration, mediation or conciliation as means to resolve contractual disputes",
          "Strengths and weaknesses of ADR as an alternative to litigation",
          "Dispute resolution scenario",
        ],
      },
      {
        title:
          "Contract Negotiation Skills and Practice in a Petroleum Industry Context",
        contents: [
          "Preparing for the negotiation",
          "Understanding the position of the other side",
          "Negotiation roles and tactics",
          "Reaching an agreement",
          "Knowing your Best Alternative to Negotiated Agreement (BATNA) - what happens if the negotiation fails",
          "Negotiation role play",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO05",
  },
  {
    id: 48,
    image: Oil6,
    title: "Oil & Gas - Finance ",
    desc: "Develop a robust knowledge of project financing in the oil and gas sector ",
    WhoShouldAttend: [
      "Senior managers within the Oil & Gas sector or those identified via organisational talent management for fast track to a senior role",
      "Public sector officials with responsibility for the state management of Oil & Gas resources",
      "Engineers and Project Managers who are looking to develop leading edge practice for both themselves and their organisation",
    ],
    courseExpectation: [
      "	Develop the key components of a financial management systems",
      "Use knowledge of  financial instruments and their impacts",
      "Manage: Market risk, Credit risk and Liquidity risk",
      "Undertake effective financial modelling and scenario planning",
    ],
    courseContent: [
      {
        title: "Key Financial Management Systems",
        contents: [
          "Effective Budget preparation and managemenT",
          "Cash flow management and forecasting",
          "Interpreting key financial statements",
          "Key financial ratios & Analysis",
        ],
      },
      {
        title: "Effective Treasury Management",
        contents: [
          "	Overview of global treasury models",
          "Pure return portfolio structuring",
          "Pure income portfolio structuring",
          "Defensive portfolio structuring",
        ],
      },
      {
        title: "The role and impact of financial instruments",
        contents: ["	Derivatives", "Bonds", "Options", "Futures"],
      },
      {
        title: "Effective Risk Management",
        contents: [
          "The key concepts and elements of risk management",
          "Managing Market Risk",
          "Managing Credit Risk",
          "Managing Liquidity Risk",
        ],
      },
      {
        title: "Practical Financial Modelling",
        contents: [
          "	Key principles within Financial Modelling",
          "Constructing key financial models",
          "Linkage of models and sub-models",
          "Scenario Planning Applications",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO06",
  },
  {
    id: 49,
    image: Oil7,
    title: "Upstream Oil & Gas Development Lifecycle Costing ",
    desc: "Learn the fundamentals of lifecycle costing and how to implement cost efficiency at each stage in upstream oil and gas ",
    WhoShouldAttend: [
      "Upstream oil and gas industry professionals, managers and discipline experts, who need to gain a deeper understanding of upstream lifecycle costing",
      "Persons involved in industry oversight, from regulators, government agencies, non-governmental organisations (NGOs), charities, special interest and campaigning groups that need to better understand the cost constraints under which oil companies operate",
      "Early career professionals wishing to advance their understanding of upstream oil and gas costing and economics issues",
    ],
    courseExpectation: [
      "Better collaborate within multidisciplinary teams on field development planning tasks leading to investment decisions",
      "Give a comprehensive overview of the nature and magnitude of exploration, development, production and decommissioning costs",
      "Explain how to express lifecycle costings in cash flow and economic models",
      "Describe the challenges of upstream lifecycle costing, and dealing with uncertain and incomplete information",
    ],
    courseContent: [
      {
        title: "Upstream Oil & Gas Lifecycle Costing",
        contents: [
          "Aspects of the upstream oil and gas exploration, development and production industry.",
          "The asset (oil or gas field) lifecycle from acquisition and exploration, through to production and decommissioning",
          "Relationship to cost estimation and cost engineering",
          "Approaches to costing",
          "Bottom-up vs. top-down approaches",
          "Analog, benchmarking, parametric and cost metrics approaches",
          "Provision for engineering growth, contingency, unallocated provision, operator's reserve",
          "Types of cost: exploration; capital (capex), operating (opex) and decommissioning (abex) ",
        ],
      },
      {
        title: "Upstream Oil and Gas Lifecycle Cost Components",
        contents: [
          "Description of the items (activities, services, wells, facilities) that add to lifecycle costs (so they mean more than just numbers in a spreadsheet)",
          "Illustrative costs for all components",
          "The parameters ('key-drivers') that most influence the cost of components",
          "Cost metrics and rules-of-thumb",
          "Building of a cost model ",
        ],
      },
      {
        title: "Field Development Planning",
        contents: [
          "	Introduction of the time element",
          "Time value of money, discounted cash flow, basic petroleum economics",
          "Cost, production, revenue, cash-flow profiles",
          "Building of an economic model ",
        ],
      },
      {
        title: "The Wider Context",
        contents: [
          "	Issues relevant to how costs are prepared, used and influenced",
          "Petroleum production licences and contracts",
          "Cost 'train wrecks' – when costs go out of control",
          "The wisdom (and cost) of front end loading (FEL)",
          "Current and future challenges to oil and gas production (and hence to costing)",
          "Human aspects (biases, irrationality, vested interest) that can skew costing",
          "Dealing with risk, uncertainty and incomplete information",
          "Decision making",
          "Regional and host-country factors, including productivity and local content issues",
        ],
      },
    ],
    courseFess: 4840,
    category: "Gas and Oil",
    courseCode: "GO07",
  },
  // Oil & Gas

  // Human Resources
  {
    id: 50,
    image: HumanResourse1,
    title: "Advanced Human Resources Analytics ",
    desc: "Unearth amazing insights to improve your decision-making ",
    WhoShouldAttend: [
      "	Human Resources Directors who would like to strengthen their position in the boardroom",
      "HR professionals who would like to go the extra mile in developing a robust HR strategy",
      "HR professionals who would like to measure contribution of HR towards organisational performance",
    ],
    courseExpectation: [
      "	Identify the reasoning behind predictive analytics and its benefits",
      "Explore various levels of analytics and their uses; showing the evolution of metrics into predictive analytics",
      "Describe the predictive management model and the HCM:21 model",
      "Appreciate Risk Assessment as a fundamental part of modern human capital management",
      "Take an evidence-based approach using advanced analytics to develop a collaborative workplace when selecting and motivating people",
      "Develop Workforce Planning that replaces gap-analysis with the concept of generating human capability",
      "Change HR service delivery into a value-generating process",
    ],
    courseContent: [
      {
        title: "Introduction to Predictive Analytics",
        contents: [
          "What is analytics?",
          "Why is analytics important?",
          "The language of metrics and analytics",
          "The power of analytics",
          "The model of predictive management",
          "Human resources analytics case study",
        ],
      },
      {
        title: "Human Capital Predictive Management",
        contents: [
          "	The big picture and the value of statistical analytics",
          "The importance of risk assessments",
          "How to improve hr process",
          "The employee value proposition",
          "Getting support to implement strategy exercise",
          "Strategic choice and advanced analytics case study",
        ],
      },
      {
        title: "HCM practice choice and advanced analytics",
        contents: [
          "	Business intelligence alignment of HCM",
          "Decision science and implications of HCM decisions",
          "Human science and selection decisions",
          "HCM case study",
        ],
      },
      {
        title: "Optimizing selection and promotion decisions",
        contents: [
          "	Talent acquisition and decisions analytics",
          "Biases and the selection decisions",
          "Application of expert intuition to selection and promotion decisions",
          "Predictive modelling and selections decisions",
          "Applied econometrics and machine learning techniques ",
        ],
      },
      {
        title: "Human Science and Incentives",
        contents: [
          "	Incentive contracts",
          "Complexity theory and incentive contracts",
          "The application of expert intuition to incentive and motivation issues",
          "Predictive modelling and incentive contracts",
          "HR incentives case study ",
        ],
      },
      {
        title: "HCM Applications, Analytics and HR Decisions",
        contents: [
          "	IT options and optimal HCM practice",
          "Enterprise resource planning (ERP)",
          "Business intelligence applications",
          "Talent management and advanced analytics",
          "The new face of workforce planning",
        ],
      },
      {
        title: "The New Face of Workplace Planning",
        contents: [
          "Human resources versus human capital planning",
          "Quality employee engagement measurements",
          "Collapsing the silos",
          "Process analysis for human resources",
          "Human capital planning case study",
        ],
      },
      {
        title: "Turning Data into Business Intelligence",
        contents: [
          "	What are metrics?",
          "Avoiding common metrics mistakes",
          "Second generation metrics: benchmarking",
          "Third generation metrics: predictive analytics",
          "Understanding the data vs In",
          "Understanding the data vs Insight exercise",
          "Business intelligence case study",
        ],
      },
      {
        title: "Data Interpretation",
        contents: [
          "	Predictive Analytics for Human Capital Management",
          "Using Human Capital Data for Performance Management",
          "Using HR metrics to make a difference",
          "Common pitfalls in data interpretation",
        ],
      },
    ],
    courseFess: 6080,
    category: "Human Resources",
    courseCode: "HR01",
  },
  {
    id: 51,
    image: HumanResourse2,
    title: "Advanced Strategic Human Resources Management and Leadership ",
    desc: " Position yourself to make a major contribution to the professional management of HR in your organisation",
    WhoShouldAttend: [
      "Senior Directors and Senior Managers who have responsibility for Human Resource (HR) strategy incorporating Organisational Development (OD) and Learning, Training and Development (LTD) and the implementation of these policies and procedures in line with business objectives",
      "Senior Directors and Senior Managers who wish to refresh or develop their skills further",
      "Operational managers who wish to gain a greater understanding of the strategic HR, OD and people management and development issues",
      "Senior Managers who would like a refresher and to develop their confidence, assertiveness and influencing skills",
      "Departmental Directors and Departmental Managers who would like practical and relevant techniques",
    ],
    courseExpectation: [
      "	Evaluate new HR recruitment strategies",
      "Review a Performance Management systems",
      "Take a strategic approach towards learning & development in the organisational context",
      "Design a talent management system which is linked to the organisational strategic plan",
      "Plan a knowledge management system to retain and develop knowledge within the organisation",
      "Create a succession plan that contributes towards future organisational goals",
      "Keep up-to-date with new technological development of HR software",
      "Set objectives and targets to achieve the vision, mission and goals of an organisation",
      "Apply the skills of leadership to enhance the management role",
      "Motivate people and build successful teams",
      "To communicate effectively verbally in all situations with all people",
    ],
    courseContent: [
      {
        title: "Effective HR",
        contents: [
          "	The business and HR strategies",
          "The key roles and specialisms in the HR function",
          "Drivers for strategic change and how to manage this process",
          "Influencing skills in HR management",
          "Analysing your HR strategy using models such as SWOT and PESTLE",
        ],
      },
      {
        title: "Effective development",
        contents: [
          "	Business, HR, OD and LTD strategies including succession planning",
          "The changing role of the trainer in an LTD environment",
          "Identifying the range of LTD interventions",
          "Measuring the return on investment (ROI)",
          "Using HR, OD and LTD to manage business risks, create a competitive advantage and be seen as an employer of choice",
        ],
      },
      {
        title: "Effective performance management",
        contents: [
          "	Defining performance management",
          "HR processes which facilitate performance management",
          "Organisational, team and individual objectives, performance and continuous development",
          "The benefits of developing an LTD organisation",
          "Defining an effective performance appraisal",
        ],
      },
      {
        title: "Effective recruitment",
        contents: [
          "Making a strategic and an operational recruitment business case",
          "Recruiting from within your organisation",
          "The recruitment model (recruitment, interview, assessment and selection)",
          "A recruitment plan to attract the best candidates",
          "Measuring the return on investment (ROI)",
        ],
      },
      {
        title: "HR specialisms",
        contents: [
          "	Employment law",
          "Developing and implementing HR policies and procedures",
          "Employee engagement and communication",
          "Rewards and benefits",
          "Diversity and inclusion",
        ],
      },
      {
        title: "LTD needs analysis",
        contents: [
          "Defining an LTD analysis",
          "Carrying out a skills gap analysis and looking at the different ways you can gather useful information",
          "Training and when it may be, and may not be, the most appropriate response",
          "Assessing the range of LTD interventions in light of the LTD needs analysis",
        ],
      },
      {
        title: "Talent management",
        contents: [
          "	Defining talent, talent management, potential, performance and skills",
          "The business case for, and impact of, a talent management programme",
          "The talent management cycle and process",
          "The Approaches to Development model",
          "Leadership skills and talent management",
        ],
      },
      {
        title: "Career planning",
        contents: [
          "Effective development and career planning",
          "Identifying career limiting behaviours and how to minimise these",
          "Capturing knowledge management",
          "Implementing succession planning",
          "Considering work life balance issues",
        ],
      },
      {
        title: "Creating a personal development plan",
        contents: [
          "Examining your career path using models such as SWOT and PESTLE",
          "Creating a personal development plan",
          "Continuous professional development (CPD)",
          "PDP and CPD",
        ],
      },
      {
        title: "Negotiation Skills",
        contents: [
          "	Pre-negotiation preparation on all aspects of the negotiation",
          "Styles, techniques and skills of a good negotiator",
          "Tricks people may play on you",
          "Close with a win-win result for all",
        ],
      },
      {
        title: "Influencing Skills",
        contents: [
          "	Analysis of factors which influence people",
          "How to influence people without power and authority over them",
          "Chairing and participating in meetings which produce results",
          "Finding common ground and reaching agreement",
        ],
      },
      {
        title: "Assertiveness",
        contents: [
          "	Characteristics and skills of assertiveness",
          "Handling difficult people with confidence",
          "Giving feedback and receiving criticism constructively",
          "Responding appropriately to aggressive, assertive and passive people",
        ],
      },
      {
        title: "Introduction to Neuro-Linguistic Programming",
        contents: [
          "What is Neuro-Linguistic Programming™ (NLP™)?",
          "How does NLP™ work?",
          "NLP™ for self-management and self-development",
          "Application of NLP™ to enhance performance of organisation, teams and individuals",
        ],
      },
      {
        title: "Emotional Intelligence and Body Language",
        contents: [
          "	Key principles, qualities and skills of Emotional Intelligence",
          "Application of EI to develop self, teams, individuals and respond to situations",
          "Sending the right messages through your non-verbal communication",
          "Interpreting the signals and gestures of body language of others",
        ],
      },
    ],
    courseFess: 7940,
    category: "Human Resources",
    courseCode: "HR02",
  },
  {
    id: 52,
    image: HumanResourse3,
    title: "Advanced Strategic Human Resources Management ",
    desc: "Analyse and develop cutting-edge Human Resource Management strategy that neatly aligns with your corporate strategy for success ",
    WhoShouldAttend: [
      "	Senior Directors and Senior Managers who have responsibility for Human Resource (HR) strategy incorporating Organisational Development (OD) and Learning, Training and Development (LTD) and the implementation of these policies and procedures in line with business objectives",
      "Senior Directors and Senior Managers who wish to refresh or develop their skills further",
      "Operational managers who wish to gain a greater understanding of the strategic HR, OD and people management and development issues",
      "Senior Managers who would like a refresher and to develop their confidence, assertiveness and influencing skills",
      "Departmental Directors and Departmental Managers who would like practical and relevant techniques",
    ],
    courseExpectation: [
      "Explore new HR recruitment strategies",
      "Review their organisation’s approach to Performance Management systems",
      "Develop a strategic approach towards learning and development in their organisation ",
      "Design a talent management system which is link to their organisation’s strategic plan",
      "Plan a knowledge management system to retain and develop knowledge within their organisation",
      "Review their organisation’s approach to succession planning ",
      "Review new technological development of HR software",
    ],
    courseContent: [
      {
        title: "Effective HR",
        contents: [
          "	The business and HR strategies",
          "The key roles and specialisms in the HR function",
          "Drivers for strategic change and how to manage this process",
          "Influencing skills in HR management",
          "Analysing your HR strategy using models such as SWOT and PESTLE",
        ],
      },
      {
        title: "Effective development",
        contents: [
          "	Business, HR, OD and LTD strategies including succession planning",
          "The changing role of the trainer in an LTD environment",
          "Identifying the range of LTD interventions",
          "Measuring the return on investment (ROI)",
          "Using HR, OD and LTD to manage business risks, create a competitive advantage and be seen as an employer of choice",
        ],
      },
      {
        title: "Effective performance management",
        contents: [
          "	Defining performance management",
          "HR processes which facilitate performance management",
          "Organisational, team and individual objectives, performance and continuous development",
          "The benefits of developing an LTD organisation",
          "Defining an effective performance appraisal",
        ],
      },
      {
        title: "Effective recruitment",
        contents: [
          "	Making a strategic and an operational recruitment business case",
          "Recruiting from within your organisation",
          "The recruitment model (recruitment, interview, assessment and selection)",
          "A recruitment plan to attract the best candidates",
          "Measuring the return on investment (ROI)",
        ],
      },
      {
        title: "HR specialisms",
        contents: [
          "	Employment law",
          "Developing and implementing HR policies and procedures",
          "Employee engagement and communication",
          "Rewards and benefits",
          "Diversity and inclusion",
        ],
      },
      {
        title: "LTD needs analysis",
        contents: [
          "	Defining an LTD analysis",
          "Carrying out a skills gap analysis and looking at the different ways you can gather useful information",
          "Training and when it may be, and may not be, the most appropriate response",
          "Assessing the range of LTD interventions in light of the LTD needs analysis",
        ],
      },
      {
        title: "Talent management",
        contents: [
          "	Defining talent, talent management, potential, performance and skills",
          "The business case for, and impact of, a talent management programme",
          "The talent management cycle and process",
          "The Approaches to Development model",
          "Leadership skills and talent management",
        ],
      },
      {
        title: "Career planning",
        contents: [
          "	Effective development and career planning",
          "Identifying career limiting behaviours and how to minimise these",
          "Capturing knowledge management",
          "Implementing succession planning",
          "Considering work life balance issues",
        ],
      },
      {
        title: "Creating a personal development plan",
        contents: [
          "Examining your career path using models such as SWOT and PESTLE",
          "Creating a personal development plan",
          "Continuous professional development (CPD)",
          "PDP and CPD",
        ],
      },
      {
        title: "Negotiation Skills",
        contents: [
          "	Pre-negotiation preparation on all aspects of the negotiation",
          "Styles, techniques and skills of a good negotiator",
          "Tricks people may play on you",
          "Close with a win-win result for all",
        ],
      },
      {
        title: "Influencing Skills",
        contents: [
          "	Analysis of factors which influence people",
          "How to influence people without power and authority over them",
          "Chairing and participating in meetings which produce results",
          "Finding common ground and reaching agreement",
        ],
      },
      {
        title: "Assertiveness",
        contents: [
          "	Characteristics and skills of assertiveness",
          "Handling difficult people with confidence",
          "Giving feedback and receiving criticism constructively",
          "Responding appropriately to aggressive, assertive and passive people",
        ],
      },
      {
        title: "Introduction to Neuro-Linguistic Programming",
        contents: [
          "What is Neuro-Linguistic Programming™ (NLP™)?",
          "How does NLP™ work?",
          "NLP™ for self-management and self-development",
          "Application of NLP™ to enhance performance of organisation, teams and individuals",
        ],
      },
      {
        title: "Emotional Intelligence and Body Language",
        contents: [
          "	Key principles, qualities and skills of Emotional Intelligence",
          "Application of EI to develop self, teams, individuals and respond to situations",
          "Sending the right messages through your non-verbal communication",
          "Interpreting the signals and gestures of body language of others",
        ],
      },
    ],
    courseFess: 6080,
    category: "Human Resources",
    courseCode: "HR03",
  },
  {
    id: 53,
    image: HumanResourse4,
    title: "Human Resources Analytics - Level 1 ",
    desc: " Take H.R. management to a new level ",
    WhoShouldAttend: [
      "	Human Resources Directors who would like to strengthen their position in the boardroom",
      "HR professionals who would like to go the extra mile in developing a robust HR strategy",
      "HR professionals who would like to measure the contribution of HR towards organisational performance",
    ],
    courseExpectation: [
      "To understand the reasoning behind predictive analytics and its benefits",
      "To explore various levels of analytics and their uses; showing the evolution of metrics into predictive analytics",
      "To learn about the predictive management model and the HCM:21 model",
      "To appreciate risk assessment as a fundamental part of modern human capital management",
      "To learn an evidence-based approach using advanced analytics in developing a collaborative workplace, and for selecting and motivating people",
      "To stay up-to-date with HR software development and options available",
      "To learn how to change HR service delivery into a value-generating process",
    ],
    courseContent: [
      {
        title: "Introduction to Predictive Analytics",
        contents: [
          "	What is analytics?",
          "Why is analytics important?",
          "The language of metrics and analytics",
          "The power of analytics",
          "The model of predictive management",
          "Human resources analytics case study",
        ],
      },
      {
        title: "Human Capital Predictive Management",
        contents: [
          "	The big picture and the value of statistical analytics",
          "The importance of risk assessments",
          "How to improve hr process",
          "The employee value proposition",
          "Getting support to implement strategy exercise",
          "Strategic choice and advanced analytics case study",
        ],
      },
      {
        title: "HCM practice choice and advanced analytics",
        contents: [
          "Business intelligence alignment of HCM",
          "Decision science and implications of HCM decisions",
          "Human science and selection decisions",
          "HCM case study",
        ],
      },
      {
        title: "Optimizing selection and promotion decisions",
        contents: [
          "	Talent acquisition and decisions analytics",
          "Biases and the selection decisions",
          "Application of expert intuition to selection and promotion decisions",
          "Predictive modelling and selections decisions",
          "Applied econometrics and machine learning techniques",
        ],
      },
      {
        title: "Human Science and Incentives",
        contents: [
          "	Incentive contracts",
          "Complexity theory and incentive contracts",
          "The application of expert intuition to incentive and motivation issues",
          "Predictive modelling and incentive contracts",
          "HR incentives case study",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR04",
  },
  {
    id: 54,
    image: HumanResourse5,
    title: "Human Resources Analytics - Level 2 ",
    desc: " Mix Information Technology with H.R. to target and improve performance ",
    WhoShouldAttend: [
      "Human Resources Directors who would like to strengthen their position in the boardroom",
      "HR professionals who would like to go the extra mile in developing a robust HR strategy",
      "HR professionals who would like to measure contribution of HR towards organisational performance ",
    ],
    courseExpectation: [
      "To understand the reasoning behind predictive analytics and its benefits",
      "To appreciate the HCM:21 model",
      "To learn an evidence-based approach using advanced analytics in developing a collaborative workplace, and for selecting and motivating people",
      "To develop Workforce Planning that replaces gap-analysis with the concept of generating human capability",
      "To learn how to change HR service delivery into a value-generating process",
    ],
    courseContent: [
      {
        title: "HR Analytics in a nutshell",
        contents: [
          "	What is Analytics?",
          "Human Capital Predictive Management",
          "HCM practice choice and Advanced Analytics",
          "Optimizing selection and promotion decisions",
          "Human Science and Incentives",
        ],
      },
      {
        title: "HCM  Applications, Analytics and HR Decisions",
        contents: [
          "	IT options and optimal HCM practice",
          "Enterprise resource planning (ERP)",
          "Business intelligence applications",
          "Talent management and advanced analytics",
          "The new face of workforce planning",
        ],
      },
      {
        title: "The New Face of Workforce Planning",
        contents: [
          "	Human resources versus human capital planning",
          "Quality employee engagement measurements",
          "Collapsing the silos",
          "Process analysis for human resources",
          "Human capital planning case study",
        ],
      },
      {
        title: "Turning Data Into Business Intelligence",
        contents: [
          "	What are metrics?",
          "Avoiding common metrics mistakes",
          "Second generation metrics: benchmarking",
          "Third generation metrics: predictive analytics",
          "Understanding the data vs Insight exercise",
          "Business intelligence case study",
          "Human capital planning case study",
        ],
      },
      {
        title: "Data Interpretation",
        contents: [
          "	Predictive Analytics for Human Capital Management",
          "Using Human Capital Data for Performance Management",
          "Using HR metrics to make a difference",
          "Common pitfalls in data interpretation",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR05",
  },
  {
    id: 55,
    image: HumanResourse6,
    title: "Performance Management ",
    desc: " Develop an understanding of the key factors that will transform your organisation",
    WhoShouldAttend: [
      "	Managers who have responsibility for Human Resource (HR) performance management policies and procedures",
      "Operational managers who wish to gain a greater understanding of performance management",
      "All HR and operational line managers who manage a team and carry out performance appraisal interviews",
    ],
    courseExpectation: [
      "	Describe the evolution of performance management systems",
      "Explain the importance of psychological contracts in organisations and how it influences performance",
      "Use enhanced knowledge of today's performance management challenges",
      "Identify key component of evidence-based performance management",
      "Develop a robust performance management system",
      "Address underperformance within organisations",
      "Differentiate the importance of reward strategy and how it should be embedded into performance management systems",
    ],
    courseContent: [
      {
        title: "Effective performance management",
        contents: [
          "	Defining performance management",
          "HR processes which facilitate performance management",
          "Organisational objectives, team and individual performance and continuous organisational and individual improvement and development",
          "The benefits of developing an LTD organisation",
          "Defining an effective performance appraisal",
        ],
      },
      {
        title: "Performance management tools",
        contents: [
          "	Examining how to give and receive feedback",
          "Gathering, reporting and using 360 degree feedback",
          "Creating a performance balanced scorecard",
          "Coaching for performance",
          "Managing stress",
        ],
      },
      {
        title: "Conducting an effective performance appraisal",
        contents: [
          "	Clarifying the purpose of a performance appraisal",
          "Preparing for, managing and following up performance appraisal meeting",
          "Developing key performance indicators and SMART objectives",
          "Personal development plans (PDP) and continuous professional development CPD)",
        ],
      },
      {
        title: "Managing poor performance",
        contents: [
          "Developing a positive manager / subordinate relationship",
          "Identifying poor performance and reasons behind it",
          "Dealing with poor performance",
          "Managing conflict",
          "Using the organisation’s discipline policy",
        ],
      },
      {
        title: "Benchmarking your organisation's performance",
        contents: [
          "	The value of benchmarking against other organisations",
          "How to benchmark",
          "Acting to bring about positive change",
          "Evaluation of your organisation using the SWOT and PESTLE models",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR06",
  },
  {
    id: 56,
    image: HumanResourse7,
    title: "Personal Development Planning (PDP) ",
    desc: " Prepare for promotion by creating greater self-awareness and a truer sense of purpose",
    WhoShouldAttend: [
      "	Managers who have responsibility for implementing Organisational Development (OD) and Learning, Training and Development (LTD) and Personal Development Planning (PDP) policies and procedures",
      "Operational managers who wish to gain a greater understanding of OD, LTD and PDP",
      "All managers who are looking for new ways to develop their people",
      "Employees who have been identified as having the potential to be promoted and who wish to explore further ways to develop their skills and career",
    ],
    courseExpectation: [
      "	Improve the effectiveness of the PDP by putting it into context of the organisation’s LTD culture and performance management processes",
      "Support employees develop and plan their careers",
      "Create a personal development plan",
      "Develop the skills needed to enhance job opportunities and career prospects",
    ],
    courseContent: [
      {
        title: "PDP and Performance management",
        contents: [
          "	Why Performance Management?",
          "Performance management process",
          "Managing organisational performance",
          "Moving towards Performance Evaluation and its link to PDP",
        ],
      },
      {
        title: "PDP and Learning and Development",
        contents: [
          "	The purpose of Learning and Development strategy",
          "How to create a learning culture",
          "Organisational learning",
          "Learning organisations",
          "How to embed PDP in your Learning and Development Strategy",
        ],
      },
      {
        title: "Creating a Personal Development Plan",
        contents: [
          "	PDP vs CPD",
          "Personal SWOT analysis",
          "Personal PEST analysis",
          "Personal Ansoff Matrix",
          "Personal Branding",
          "Plan your career learning stream",
        ],
      },
      {
        title: "Developing career and personal development skills",
        contents: [
          "	Personal learning styles and strategies",
          "Schein’s Career Anchors",
          "Developing Personal Accountability",
          "Developing Personas",
          "Self-mastery",
          "Understanding Emotional Intelligence",
        ],
      },
      {
        title: "Building company-wide personal development plans",
        contents: [
          "	The Pyramid of Organisational Development",
          "The Hogan Development Survey",
          "The DiSC Model",
          "The ADDIE Model",
          "Reidenbach and Robin’s five stages of Corporate Ethical Development",
          "Mentoring Skills",
          "Coaching for talent management",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR07",
  },
  {
    id: 57,
    image: HumanResourse8,
    title: "Strategic Human Resources Management - Level 1 ",
    desc: "Apply today's HR standards in today's world ",
    WhoShouldAttend: [
      "	HR Practitioners who are responsible for the development of HR Strategy and or delegates who are responsible for operational delivery of recruitment, appraisal management, training or staff development",
    ],
    courseExpectation: [
      "Explore new HR recruitment strategies",
      "Review their Performance Management systems",
      "Take a more strategic approach towards learning and development in organisational context",
      "Recognise the importance of designing a reward strategy and e-reward strategy",
    ],
    courseContent: [
      {
        title: "Effective HR",
        contents: [
          "	The business and HR strategies",
          "The key roles and specialisms in the HR function",
          "Drivers for strategic change and how to manage this process",
          "Influencing skills in HR management",
          "Analysing your HR strategy using models such as SWOT and PESTLE",
        ],
      },
      {
        title: "Effective development",
        contents: [
          "	Business, HR, OD and LTD strategies including succession planning",
          "The changing role of the trainer in an LTD environment",
          "Identifying the range of LTD interventions",
          "Measuring the return on investment (ROI)",
          "Using HR, OD and LTD to manage business risks, create a competitive advantage and be seen as an employer of choice",
        ],
      },
      {
        title: "Performance management",
        contents: [
          "Defining performance management",
          "HR processes which facilitate performance management",
          "Organisational, team and individual objectives, performance and continuous development",
          "The benefits of developing an LTD organisation",
          "Defining an effective performance appraisal",
        ],
      },
      {
        title: "Recruitment",
        contents: [
          "	Making a strategic and an operational recruitment business case",
          "Recruiting from within your organisation",
          "The recruitment model (recruitment, interview, assessment and selection)",
          "A recruitment plan to attract the best candidates",
          "Measuring the return on investment (ROI)",
        ],
      },
      {
        title: "HR specialisms",
        contents: [
          "	Employment law",
          "Developing and implementing HR policies and procedures",
          "Employee engagement and communication",
          "Rewards and benefits",
          "Diversity and inclusion",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR08",
  },
  {
    id: 58,
    image: HumanResourse9,
    title: "Strategic Human Resources Management - Level 2 ",
    desc: " Discover how to build HR strategy into your corporate strategy",
    WhoShouldAttend: [
      "	Senior HR executives who would like have a greater understanding of new HR trends",
      "HR functional managers who are in charge of shifting human resource management towards a strategic role in the organisation",
    ],
    courseExpectation: [
      "	Design a talent management system which is linked to organisation’s strategic plan",
      "Plan a knowledge management system designed to retain and develop knowledge within the organisation",
      "Develop succession planning that contributes towards future organisational goals",
      "Review technological development of HR software",
    ],
    courseContent: [
      {
        title: "Human Resources Strategy",
        contents: [
          "Types of HR strategy",
          "Resourcing strategy",
          "Learning and Development strategy",
          "Performance Management",
          "Reward Strategy",
        ],
      },
      {
        title: "Talent Management Strategy",
        contents: [
          "	Talent management defined",
          "The process of talent management",
          "Developing a talent management strategy",
        ],
      },
      {
        title: "Knowledge Management Strategies",
        contents: [
          "	The process of knowledge management",
          "Sources and types of knowledge",
          "Approaches to the development of knowledge management strategies",
          "Strategic knowledge management issues",
          "Components of a knowledge management strategy",
        ],
      },
      {
        title: "Succession Planning",
        contents: [
          "	Effective Succession Planning",
          "Strategic Approach to Succession Planning",
          "Career Planning",
          "Personal Development Planning",
          "Develop and implement succession strategies",
        ],
      },
      {
        title: "Software Applications, Analytics and HR Decisions",
        contents: [
          "	Software options and Optimal HCM practice",
          "Enterprise Resource Planning Software",
          "Talent Analytics",
          "SAS Business Intelligence",
          "Talent scorecard",
          "Talent Management and Advanced Analytics",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR09",
  },
  {
    id: 59,
    image: HumanResourse10,
    title: "Succession Planning ",
    desc: " Prepare a succession strategy through workforce planning, skills analysis, performance management and talent training ",
    WhoShouldAttend: [
      "This course is designed for HR managers, and organisational managers, who wish to develop the understanding and ability to produce effective resourcing plans, manage talent and develop robust succession plans within organisations.",
    ],
    courseExpectation: [
      "	Identify the links between Succession Planning, Talent Management and Performance Management",
      "Address the role of Human Resources in forecasting future needs of business and to formulate strategies for Attracting, Retaining and Developing skilled individuals",
      "Develop future leaders from within while retaining intellectual/operational expertise",
      "Take a targeted approach to identify the Competencies needed for specific positions",
      "Design a Succession Planning Process and an Effective Monitoring and Evaluation technique",
    ],
    courseContent: [
      {
        title: "Effective Succession Planning",
        contents: [
          "Building Leadership pipeline",
          "Links between succession planning and Talent Development Process",
          "Succession Planning and Performance Management",
        ],
      },
      {
        title: "The role of HR",
        contents: [
          "	Links with business planning",
          "Forecasting future needs and strategic alignment",
          "Effective Performance Management Process",
          "Identifying successors",
          "Identifying Competencies",
        ],
      },
      {
        title: "Managing Learning and Development",
        contents: [
          "	Strategic Approach to support Succession Planning",
          "L&D Opportunities; result of Effective Appraisal",
          "Career Planning",
          "Personal Development Planning",
        ],
      },
      {
        title: "Developing the succession planning process",
        contents: [
          "	Link Strategic and Workforce Planning Decisions",
          "Analyse Gaps",
          "Identify Talent Pool",
          "Develop and Implement Succession Strategy",
        ],
      },
      {
        title: "Effective Monitoring and Evaluation",
        contents: [
          "	Monitoring and Evaluation",
          "Tracking selections from Talent Pool",
          "Success of Internal Talent and Internal Hire",
          "Follow-up process",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR10",
  },
  {
    id: 60,
    image: HumanResourse11,
    title: "Training and Development ",
    desc: "Master the knowledge and skills that enable you to play an active role in the development of talent in your organisation ",
    WhoShouldAttend: [
      "	Senior L&D/HR practitioners responsible for decision making at either operational, tactical or more strategic level or those involved in the implementation of processes of learning and talent development strategies.",
      "L&D practitioner who understands the strategic direction of the organisation.",
      "L&D managers who want to ensure that their function provides a cost effective results driven service aligned to business objectives",
    ],
    courseExpectation: [
      "Lead the initiation, development and implementation of learning and development strategies, interventions and activities",
      "Critically analyse and evaluate the formulation and implementation of learning and talent development strategies for defining and achieving current and future effectiveness at organisational, group and individual levels",
      "Apply learning and talent development strategies, policies and methods ",
      "Establish the key components of a strategic knowledge management system and design one",
    ],
    courseContent: [
      {
        title: "Learning and Development Strategy",
        contents: [
          "	Strategic Human Resource Development",
          "Elements of human resource development",
          "Strategies for creating a learning culture",
          "Organisational learning strategies",
          "Learning organisations strategies",
          "Individual learning strategies",
        ],
      },
      {
        title: "Needs Assessment",
        contents: [
          "Training Wants Versus Training Needs",
          "Needs Assessment frequency",
          "Using needs assessment results",
          "Training design and delivery",
          "Evaluating the training",
        ],
      },
      {
        title: "Six Sigma and DMADDI model",
        contents: [
          "	Six Sigma in the training world",
          "Six Sigma Model",
          "The Six Sigma Organisation",
          "The DMADDI model",
          "Evaluation in DMADDI",
        ],
      },
      {
        title: "Talent Management Strategy",
        contents: [
          "	Talent management defined",
          "The process of talent management",
          "Developing a talent management strategy",
        ],
      },
      {
        title: "Knowledge Management Strategies",
        contents: [
          "	The process of knowledge management",
          "Sources and types of knowledge",
          "Approaches to the development of knowledge management strategies",
          "Strategic knowledge management issues",
          "Components of a knowledge management strategy",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR11",
  },
  {
    id: 61,
    image: HumanResourse12,
    title: "Training the Trainer",
    desc: " Develop skills to make you an outstanding trainer who consistently gets good results",
    WhoShouldAttend: [
      "	L&D professionals who would like to ensure a fit for purpose training and development strategy",
      "HR professional who would like to increase ROI of training plans",
      "Trainers who would like to enhance their skills in delivering a purposeful training",
    ],
    courseExpectation: [
      "Describe the adult learning process and conduct a Needs Assessment",
      "Develop skills to adjust delivery of training based on different learning styles",
      "Enhance their ability to select, design and develop an active-training method",
      "Strengthen their ability to create a positive learning environment to encourage participation",
      "Develop training evaluation methods to ensure ROI",
    ],
    courseContent: [
      {
        title: "Assessing Needs and Understanding Adult Learners",
        contents: [
          "	How to conduct a Needs Assessment process",
          "Andragogical Versus Pedagogical Model",
          "Understating how people learn",
          "Learning styles",
          "Application of learning principles",
        ],
      },
      {
        title: "Training Styles",
        contents: [
          "	Trainer characteristics and competencies",
          "Training style",
          "Increasing effectiveness",
          "Learner - centred Versus Information - centred",
          "The changing training environment",
        ],
      },
      {
        title: "Selecting, Designing, and Developing Active-training Methods",
        contents: [
          "	Cooperative learning",
          "Creating an active - learning environment",
          "Designing active - training activities",
          "Experiential learning activities",
          "Experiential and active training techniques",
          "Action learnings",
        ],
      },
      {
        title: "Delivering Training",
        contents: [
          "	Creating a positive learning environment",
          "Presentation skills",
          "Using visual aids",
          "Distance learning",
          "Benefits and drawbacks of distance learning",
          "Blended learnings",
          "Ways to encourage participation",
        ],
      },
      {
        title: "Evaluating Training",
        contents: [
          "	When to evaluate",
          "Whom to involve",
          "How to evaluate",
          "What to evaluate",
          "Four-level model for evaluation",
        ],
      },
    ],
    courseFess: 4840,
    category: "Human Resources",
    courseCode: "HR12",
  },
  // Human Resources

  // Law
  {
    id: 62,
    image: Law1,
    title: "Advanced Alternative Dispute Resolution and Mediation",
    desc: "Advise and lead your organization through different approaches to resolving disputes and avoiding litigation ",
    WhoShouldAttend: [
      "	Legal managers",
      "Company lawyers and in -house counsel",
      "Commercial contract managers",
      "Academic lawyers",
      "Lawyers and other professional advisers in private practice",
      "Anyone involved in the professional management of relationships",
    ],
    courseExpectation: [
      "	Distinguish between different forms of ADR ",
      "Evaluate strategies for resolving conflict",
      "Prepare their organization to participate in dispute resolution processes",
      "Use negotiation skills in commercial and other disputes",
      "Evaluate the merits of using an intermediary to facilitate a win-win result",
      "Plan and implement a dispute resolution strategy ",
      "Determine when mediation is the best means of resolving disputes",
      "Prepare their organization to participate in the mediation process",
      "Use mediation skills",
    ],
    courseContent: [
      {
        title: "Introduction to Dispute Resolution",
        contents: [
          "	Typical Causes of commercial disputes",
          "Common legal remedies from court action",
          "Do we want an enforceable court judgment ?",
          "Litigation strengths and weaknesses",
          "Considering ADR contract mechanisms - tiered clauses",
          "Jurisdiction and applicable law issues",
          "Dispute case studies and exercises",
        ],
      },
      {
        title: "Arbitration",
        contents: [
          "Arbitration principles",
          "When is arbitration appropriate ?",
          "Selecting and appointing the arbitrator and the forum",
          "Strengths and weaknesses of arbitration",
          "What happens if we lose ?",
          "Recognition and enforcement of arbitration awards",
          "Arbitration variants",
          "Pendulum(baseball) arbitration",
          "Class exercise: Preparing for arbitration",
        ],
      },
      {
        title: "Negotiating solutions to disputes",
        contents: [
          "	Conflict management styles",
          "Distributive and integrative negotiation",
          "Building relationships to improve success",
          "BATNA - What's my Plan B?",
          "Negotiation phases",
          "Using the third side in negotiation",
          "Class exercise : Negotiation preparation and role play",
        ],
      },
      {
        title: "Mediation",
        contents: [
          "Mediation fundamentals",
          "Working with neutral intermediaries",
          "Facilitative vs Evaluative mediation approaches",
          "Appointing and Working with a mediator",
          "Process and Stages of a mediation",
          "Reaching a settlement agreement",
          "Practical arrangements",
        ],
      },
      {
        title: "Other Dispute Resolution Mechanisms",
        contents: [
          "	What are the other choices and when might we use them?",
          "Conciliation",
          "Settlement conference",
          "Expert determination",
          "Early Neutral evaluation",
          "Dispute Review Boards",
          "Med / Arb",
          "Ombudsman",
        ],
      },
      {
        title: "Mediation Agreements and other Formalities",
        contents: [
          "	Contractual commitments to mediate",
          "ADR Contract Clauses",
          "The Mediation Agreement",
          "Important Clauses in the agreement",
          "Establishing the rules of the mediation",
          "Without prejudice status of discussions",
          "Confidentiality",
          "Terminating the agreement",
          "Fees and expenses",
          "Settlement Agreements",
          "Staying future proceedings",
        ],
      },
      {
        title: "Mediation Processes",
        contents: [
          "Structuring a mediation process",
          "Preparation phase",
          "Opening joint sessions",
          "Caucus meetings in private",
          "The Conclusion phase",
          "Alternative mediation processes",
          "Formalizing the settlement agreement",
          "After the mediation",
          "Class exercise: Mediating a dispute",
        ],
      },
      {
        title: "Developing Mediation skills",
        contents: [
          "	Building trust and rapport",
          "Active listening",
          "Identifying common interests",
          "Engaging reluctant participants",
          "Facilitating co - operative problem - solving behaviors",
          "Dealing with deadlock",
          "Helping both sides get a win - win",
          "Class exercise",
        ],
      },
      {
        title: "Mediation Scenarios",
        contents: [
          "	Choosing the right mediator for the job",
          "Duties of the mediator and professional advisers",
          "Mediation scenarios",
          "Commercial disputes",
          "Domestic / Family disputes",
          "Workplace disputes",
          "Online mediation",
          "Beyond mediation - next steps and alternatives",
        ],
      },
    ],
    courseFess: 6080,
    category: "Law",
    courseCode: "LW01",
  },
  {
    id: 63,
    image: Law2,
    title:
      "Advanced Corporate Legal Advisers and Chartered Company Secretaries",
    desc: " Provide guidance and advice to the Board whilst maintaining complete control of all statutory and legal aspects of the business",
    WhoShouldAttend: [
      "Company Executive and Non- Executive Directors",
      "Shareholder representatives",
      "Pension and Investment Fund Managers",
      " Public officials in a regulatory, supervisory or compliance functions",
      "Company Secretaries of public companies",
      "Executives involved in strategic and operational functions, including finance, corporate strategy, human resources, and government affairs.",
      "Senior managers involved in setting up corporate governance initiatives",
      "Corporate lawyers who wish to improve their understanding of new company law developments concerning corporate responsibility.",
      "Investor Relations managers responsible for their organization’s dealings with the finance community",
    ],
    courseExpectation: [
      "	Review and clarify the duties of company directors",
      "Compare best practice of how company boards are organized ",
      "Describe the benefits to stakeholders of applying corporate governance principles ",
      "Develop checks and balance that apply to the boards of listed companies ",
      "Relate the  UK Corporate Governance Code to their own environment",
      "Refresh the commercial skills needed by legal and regulatory executives",
      "Explore the importance of the Company Secretary’s role",
      "Negotiate more effectively",
    ],
    courseContent: [
      {
        title: "What is corporate governance?",
        contents: [
          "•	Essential structures of corporate governance",
          "Principle functions and responsibilities of the Board",
          "Setting the company strategic direction",
          "Establishing corporate values",
          "Holding the executives to account",
          "Maintaining the corporate reputation",
        ],
      },
      {
        title: "Company Directors and Company Boards",
        contents: [
          "	Duties of a Director",
          "Promoting the success of the company",
          "Role of the chairman",
          "Executive and Non - executive directors",
          "Making a difference using non - executive directors",
        ],
      },
      {
        title: "Corporate failure resulting from poor governance",
        contents: [
          "	The ineffective board",
          "Examples of corporate failure: Maxwell, Polly Peck, Enron",
          "Analysis of the banking crisis as a failure of governance",
          "Sarbanes - Oxley and other regulatory responses to corporate governance failure",
          "Lessons learned from corporate failures",
        ],
      },
      {
        title: "Protecting shareholders and other stakeholders",
        contents: [
          "	The UK Corporate Governance Code",
          "The Comply or Explain rule",
          "How to protect the shareholder ?",
          "Communicating with the shareholder",
          "A Legal framework for corporate governance",
        ],
      },
      {
        title: "Corporate Social Responsibility (CSR)",
        contents: [
          "	The pressure for corporate behavior change",
          "The Legal Background of CSR",
          "Company directors’ obligations and CSR",
          "Voluntary measures",
          "Is CSR “Just Public Relations”?",
          "CSR and corporate governance links",
        ],
      },
      {
        title: "Roles and responsibilities of company secretary",
        contents: [
          "	Assisting the Board",
          "Record keeping",
          "Managing the registered office",
          "Supporting and managing board and company meetings",
        ],
      },
      {
        title: "Roles and responsibilities of corporate legal department",
        contents: [
          "	Typical functions",
          "Adding value to company performance",
          "Reducing corporate risk",
          "Measuring corporate legal performance",
        ],
      },
      {
        title: "Resolving legal disputes",
        contents: [
          "	Litigation",
          "Principles of Arbitration",
          "Non - binding dispute resolution methods",
          "Mediation",
          "Conciliation",
        ],
      },
      {
        title: "Negotiating, drafting and structuring legal agreements",
        contents: [
          "	Contract law refresher",
          "Drafting legal agreements",
          "Negotiation principles",
          "Tools and techniques for negotiation",
          "What are our negotiable?",
          "BATNA",
        ],
      },
    ],
    courseFess: 6080,
    category: "Law",
    courseCode: "LW02",
  },
  {
    id: 64,
    image: Law3,
    title: "Alternative Dispute Resolution (ADR)",
    desc: " ADR as the best alternative to judicial settlement of disputes",
    WhoShouldAttend: [
      "	Legal managers",
      "Finance managers",
      "Commercial managers",
      "Claims managers",
      "Supply Chain, Procurement and Purchasing Managers",
      "Contract Managers, Engineers and Analysts",
      "Anyone involved in the management of commercial relationships",
    ],
    courseExpectation: [
      "	Evaluate alternative methods of resolving commercial disputes",
      "Distinguish between forms of ADR that give a binding decision and others that facilitate agreement between the parties",
      "Evaluate strategies for resolving conflict",
      "Prepare their organization to participate in a dispute resolution process",
      "Plan a negotiation, including considering contingencies in the event of failure",
      "Apply negotiation techniques to typical commercial disputes",
      "Use appropriate behavioral styles in negotiation",
      "Consider the merits of using an intermediary to facilitate a win-win result",
      "Manage conflict through a negotiated approach",
      "Achieve mutually satisfactory outcomes in resolving contractual disputes ",
    ],
    courseContent: [
      {
        title: "Introduction to Dispute Resolution",
        contents: [
          "Typical Causes of commercial disputes",
          "Common legal remedies from court action",
          "Do we want an enforceable court judgment ?",
          "Litigation strengths and weaknesses",
          "Considering ADR contract mechanisms – tiered clauses",
          "Jurisdiction and applicable law issues",
          "Dispute case studies and exercises",
        ],
      },
      {
        title: "Arbitration",
        contents: [
          "	Arbitration principles",
          "When is arbitration appropriate ?",
          "Selecting and appointing the arbitrator and the forum",
          "Strengths and weaknesses of arbitration",
          "What happens if we lose ?",
          "Recognition and enforcement of arbitration awards",
          "Arbitration variants - Pendulum arbitration; Med / Arb and Arb / Med",
          " Class exercise: Preparing for arbitration",
        ],
      },
      {
        title: "Negotiating solutions to disputes",
        contents: [
          "•	Conflict management styles",
          "Distributive and integrative negotiation",
          " Building relationships to improve success",
          "BATNA – What’s my Plan B ?",
          "Negotiation phases",
          "Using “the third side” in negotiation",
          "Class exercise: Negotiation preparation and role play",
        ],
      },
      {
        title: "Mediation",
        contents: [
          "	Mediation fundamentals",
          "Facilitated negotiation and neutral intermediaries",
          "Appointing and Working with a mediator",
          " Process and Stages of a mediation",
          " Reaching a settlement agreement",
          "Class exercise: Mediating a dispute",
        ],
      },
      {
        title: "Other Dispute Resolution mechanisms",
        contents: [
          "	What are the other choices and when might we use them?",
          "Conciliation",
          "Settlement conference",
          "Expert determination",
          "Neutral evaluation",
          "Dispute Review Boards",
          "Course summary and final exercises",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW03",
  },
  {
    id: 65,
    image: Law4,
    title: "Corporate Legal Advisers and Chartered Company Secretaries",
    desc: " Apply your professional skills to lift your board to a higher level of strategic performance",
    WhoShouldAttend: [
      "	New or Experienced in-house lawyers wanting to refresh their skills and knowledge about their role",
      "Lawyers transferring from private practice to a corporate role",
      "Executive and Non - executive Company Directors",
      "Public officials in a regulatory, supervisory or compliance functions",
      " Company Secretaries of public companies",
    ],
    courseExpectation: [
      "	Describe the duties of company directors",
      "Review how company boards are organized",
      "Apply the oversight obligation of boards of listed companies ",
      "Refresh their existing and develop new commercial skills applicable to legal roles ",
      "Examine the importance of the Company Secretary’s role",
      "Apply alternative dispute resolution techniques to resolving corporate disputes",
      "Negotiate more effectively",
    ],
    courseContent: [
      {
        title: "Corporate Behaviors – Best Practices",
        contents: [
          "	Company director duties",
          "The role of the NED",
          "Creating an effective board",
          " Corporate conduct - the social responsibilities of the company",
          "Consequences of failure in corporate behavior",
        ],
      },
      {
        title: "Roles and Responsibilities of the Company Secretary",
        contents: [
          "Assisting the Board",
          "Record keeping",
          " Managing the registered office",
          "Supporting and managing board and company meetings",
        ],
      },
      {
        title: "Roles and Responsibilities of the Corporate Legal Department",
        contents: [
          "	What's in the job of the in-house lawyer?",
          "Adding value to company performance",
          "Identifying and reducing corporate risks",
          "Measuring the effectiveness of the corporate legal department",
        ],
      },
      {
        title: "Resolving Legal Disputes",
        contents: [
          "Where do the problems occur?",
          "Resolving disputes without recourse to the courts",
          "Alternative dispute resolution techniques – arbitration, mediation, conciliation",
          "Other dispute procedures",
        ],
      },
      {
        title: "Negotiating, Drafting and Structuring Legal Agreements",
        contents: [
          "	Contract law refresher",
          " Drafting legal agreements",
          " Negotiation principles",
          "Tools and techniques for negotiation",
          "What are our negotiable?",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW04",
  },
  {
    id: 66,
    image: Law5,
    title: "Development and Impact of Public Sector Policy",
    desc: "Putting public sector policy at the service of the people ",
    WhoShouldAttend: [
      "	Delegates who are directly responsible for the interpretation, development and implementation of public sector planning and the effective assessment of the impacts of state policies both economically, socially and environmentally.",
    ],
    courseExpectation: [
      "	Identify the key role and objectives of government",
      "Describe the policy development process ",
      "Appreciate and measure the key impacts of public sector policy making",
      "Make informed policy improvement recommendations",
      "Assess the Economic Impact of Public Sector Policy",
    ],
    courseContent: [
      {
        title: "Role and Objectives of Government",
        contents: [
          "	Overview of the key role of government",
          " Understanding the changing objectives of government",
          " The role of the legislature and judiciary within policy development",
          "Interpretation of policy by the legislative drafter",
        ],
      },
      {
        title: "Key Elements of the Policy Development Process",
        contents: [
          "	Alignment of public sector policy with governmental objectives",
          "Integrating public sector policy with existing domestic legislation",
          "Integrating policy with international frameworks and treaties",
          "Ensuring due process in policy development",
        ],
      },
      {
        title: "Measuring the Economic Impact of Public Sector Policy",
        contents: [
          "	Measuring overall economic benefit",
          "Measuring policy impact upon direct foreign investment",
          "Measuring policy impact by industrial sector",
          " Measuring the ‘Local content’ impact",
        ],
      },
      {
        title: "Measuring the Social impact of Public Sector Policy",
        contents: [
          "	Defining key socio-economic stakeholders",
          "Measuring per capita income",
          "Measuring skills development and capacity building",
          "Measuring standards of living impacts",
        ],
      },
      {
        title: "Measuring the Environmental Impact of Public Sector Policy",
        contents: [
          "	Defining key environmental measures",
          "International environmental standards",
          " Impacts of policy by industry sector – including Oil & Gas, and Agricultural sectors",
          "Case study review and analysis – European Union Policies and their ensuing impacts",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW05",
  },
  {
    id: 67,
    image: Law6,
    title: "Law - Making and Legislative Drafting",
    desc: " Influence the design and creation of enforceable legislation",
    WhoShouldAttend: [
      "Departmental Policy makers in the public service",
      "Senior Managers and Government personnel responsible for turning policy into legislation",
      " Officials who instruct Parliamentary draftsmen",
      "Members of parliament, senators, assembly members,",
      "Legal advisors and practitioners from both public and private sectors",
      "Corporate advisors concerned with influencing the creation of new legislation",
      " Public affairs professionals",
    ],
    courseExpectation: [
      "	Identify the role of law in solving policy challenges",
      "Influence the development of policy through legal innovation",
      "Overcome obstacles to turning policy into legislation",
      "Follow a structured process of making new laws",
      "Contribute to the drafting of new legislation",
      "Practice their legislative drafting skills",
      "Prepare instructions for professional draftsmen",
    ],
    courseContent: [
      {
        title: "Introduction to Law-Making",
        contents: [
          "Sources and Origins of Law",
          "Types of Law",
          "The Common Law system",
          "Continental legal systems and the Napoleonic Code",
          "Law and the constitution",
          " Key legal principles examined from English criminal law, law of torts, contract law",
        ],
      },
      {
        title: "The Law Makers – Government Systems Compared",
        contents: [
          "	Institutions of government",
          "Separation of powers",
          "Role of the Judiciary",
          " How the Legislature functions",
          " The Westminster model",
          "The presidential system",
          "Scrutiny of the Law Makers",
        ],
      },
      {
        title: "Effective Policy Making",
        contents: [
          "	What is policy?",
          "Sources of policy",
          "Role and responsibilities in policy making",
          "Setting policy priorities",
          "The process of turning policy into new laws",
          "Gaining buy -in to legal change",
        ],
      },
      {
        title: "The Law-Making Process",
        contents: [
          "From policy to the draft Bill",
          "Who does what in making legislation ?",
          "Stages of the process",
          "Legislative timetables",
          "Assessing regulatory impact",
          "How the Bill team functions",
        ],
      },
      {
        title: "Getting Results with Law-Making",
        contents: [
          "Constraints on the process of making laws",
          "Amending and developing the law",
          "Compliance and enforcement",
          " Avoidance and evasion",
          "Is legal compliance voluntary ?",
        ],
      },
      {
        title: "Legislative Instruments Under the Microscope",
        contents: [
          "Types of legislation",
          "Primary and secondary legislation",
          "Dissecting a statute",
          "Structure and elements of an Act of Parliament",
          "Assembling the machinery of an Act",
          "Problems with how laws work",
        ],
      },
      {
        title: "Drafting Legislation",
        contents: [
          "Principles of drafting",
          " Alternative approaches",
          "Planning your draft",
          "Writing for the reader",
          " Using plain English",
          " Dos and Don’ts in drafting",
          "Writing instructions for the parliamentary draftsman",
        ],
      },
      {
        title: "Stakeholder Participation in Law-Making",
        contents: [
          "The need for involvement",
          "Formal and informal consultation",
          "Consultation processes",
          "The draft Bill and consultation",
          "Ethical lobbying",
          " The role of the public affairs professional",
        ],
      },
      {
        title: "Turning Policy into Practice",
        contents: [
          "	Why do some laws fail to achieve their objectives?",
          "Effective and ineffective law making",
          "Education, communication and legal effectiveness",
          "The challenge of over-complex laws",
          " How to address legal effectiveness issues",
          "Alternatives to law making – nudge theory",
        ],
      },
    ],
    courseFess: 6080,
    category: "Law",
    courseCode: "LW06",
  },
  {
    id: 68,
    image: Law7,
    title: "Law - making",
    desc: "Use legal knowledge to articulate and put new legislation into practice ",
    WhoShouldAttend: [
      "Departmental Policy makers in the public service",
      "Senior Managers and Government personnel responsible for turning policy into legislation",
      "Officials who instruct Parliamentary draftsmen",
      "Assistants to legislators involved in creating draft legislation",
      " Members of parliament, senators and assembly members",
      "Legal advisors and practitioners from both public and private sectors",
      "Corporate advisors concerned with influencing the creation of new legislation",
      "Public affairs professionals",
    ],
    courseExpectation: [
      "	Identify the role of law in solving policy challenges",
      "Influence the development of policy through legal innovation",
      "Overcome obstacles to turning policy into legislation",
      "Describe the process of making new laws",
    ],
    courseContent: [
      {
        title: "Introduction to Law-Making",
        contents: [
          "Sources and Origins of Law",
          "Types of Law",
          "The Common Law system",
          "Continental legal systems and the Napoleonic Code",
          " Law and the constitution",
          "Key legal principles examined from English criminal law, law of torts, contract law",
        ],
      },
      {
        title: "The Law Makers – Government Systems Compared",
        contents: [
          "Institutions of government",
          "Separation of powers",
          "Role of the Judiciary",
          "How the Legislature functions",
          "The Westminster model",
          "The presidential system",
          "Scrutiny of the Law Makers",
        ],
      },
      {
        title: "Effective Policy Making",
        contents: [
          "	What is policy?",
          "Sources of policy",
          "Role and responsibilities in policy making",
          "Setting policy priorities",
          "The process of turning policy into new laws",
          "Gaining buy -in to legal change",
        ],
      },
      {
        title: "The Law-Making process",
        contents: [
          "	From policy to the draft Bill",
          "Who does what in making legislation ?",
          "Stages of the process",
          "Legislative timetables",
          "Assessing regulatory impact",
          "How the Bill team functions",
        ],
      },
      {
        title: "Getting Results with Law-Making",
        contents: [
          "	Constraints on the process of making laws",
          "Amending and developing the law",
          "Compliance and enforcement",
          "Avoidance and evasion",
          "Is legal compliance voluntary?",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW07",
  },
  {
    id: 69,
    image: Law8,
    title: "Legislative Drafting",
    desc: "Take the objectives of new legislation forward and outline laws for consultation and comment ",
    WhoShouldAttend: [
      "Senior Managers and Government personnel responsible for turning policy into legislation",
      "Officials who instruct Parliamentary draftsmen",
      "Assistants to legislators involved in creating draft legislation",
      "Members of parliament, senators, assembly members",
      "Legal advisors and practitioners from both public and private sectors",
    ],
    courseExpectation: [
      "	Overcome obstacles to turning policy into legislation",
      "Describe the process of making new laws",
      "Contribute to the drafting of new legislation",
      "Prepare instructions for professional draftsmen",
      "Practice legislative drafting skills",
    ],
    courseContent: [
      {
        title: "Legislative instruments under the microscope",
        contents: [
          "	Types of legislation",
          "Primary and secondary legislation",
          "Dissecting a statute",
          "Structure and elements of an Act of Parliament",
          "Assembling the machinery of an Act",
          "Problems with how laws work",
        ],
      },
      {
        title: "Drafting Legislation",
        contents: [
          "Principles of drafting",
          " Alternative approaches",
          "Planning your draft",
          "Writing for the reader",
          "Using plain English",
          "Dos and Don’ts in drafting",
          "Writing instructions for the parliamentary draftsman",
        ],
      },
      {
        title: "Interpretation and construction of Statutes",
        contents: [
          "	Identifying the purpose of a law",
          " The Mischief Rule",
          "The Golden Rule",
          "Judicial interpretation of legislation",
          "Judicial creativity and the sovereignty of parliament",
        ],
      },
      {
        title: "Stakeholder participation in Law-Making",
        contents: [
          "The need for involvement",
          " Formal and informal consultation",
          "Consultation processes",
          "The draft Bill and consultation",
          "Ethical lobbying",
          "The role of the public affairs professional",
        ],
      },
      {
        title: "Turning Policy into practice",
        contents: [
          "Why do some laws fail to achieve their objectives?",
          "Effective and ineffective law making",
          " Education, communication and legal effectiveness",
          "The challenge of over-complex laws",
          "How to address legal effectiveness issues",
          "Alternatives to law making – nudge theory",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW08",
  },
  {
    id: 70,
    image: Law9,
    title: "Mediation",
    desc: " Helping parties in dispute to reach a win-win resolution",
    WhoShouldAttend: [
      "Legal managers",
      "Claims managers",
      "Company lawyers and in -house counsel",
      "Commercial contract managers",
      "Lawyers and other professional advisers in private practice",
      "Judges",
      "Academic lawyers",
      "Anyone involved in the professional management of relationships",
    ],
    courseExpectation: [
      "	Evaluate and apply mediation-based strategies for resolving conflict",
      "Identify the  strengths and weaknesses of mediation when applied to specific dispute",
      "Prepare their organization to participate in the mediation process",
      "Use newly gained skills to select an appropriate mediator",
      "Manage conflict through a negotiated approach",
    ],
    courseContent: [
      {
        title: "Overview of Mediation",
        contents: [
          "Mediation fundamentals",
          "Facilitated negotiation and neutral intermediaries",
          "Appointing and working with a mediator",
          "Process and stages of a mediation",
          "Reaching a settlement agreement",
          "Practical arrangements",
        ],
      },
      {
        title: "Mediation Agreements and other Formalities",
        contents: [
          "	Contractual commitments to mediate",
          "ADR Contract Clauses",
          "The Mediation Agreement",
          "Important clauses in the agreement",
          "Establishing the rules of the mediation",
          "“Without prejudice” status of discussions",
          " Confidentiality",
          "Terminating the agreement",
          "Fees and expenses",
          "Settlement Agreements",
          "Staying future proceedings",
          "Class exercise in preparing an ADR contract clause",
        ],
      },
      {
        title: "Mediation Processes",
        contents: [
          "	Structuring a mediation process",
          "Preparation phase",
          "Opening joint sessions",
          "Caucus meetings in private",
          "The Conclusion phase",
          "Alternative mediation processes",
          " Formalizing the settlement agreement",
          "After the mediation",
          "Class exercise: Mediating a dispute",
        ],
      },
      {
        title: "Developing Mediation skills",
        contents: [
          "	Building trust and rapport",
          " Active listening",
          "Identifying common interests",
          "Engaging reluctant participants",
          "Facilitating co - operative problem - solving behaviors",
          "Dealing with deadlock",
          "Helping both sides get a win - win",
          "Class exercise",
        ],
      },
      {
        title: "Mediation Scenarios",
        contents: [
          "Choosing the right mediator for the job",
          "Duties of the mediator and professional advisers",
          "Mediation scenarios",
          "Commercial disputes",
          "Domestic / Family disputes",
          "Workplace disputes",
          "Online mediation",
          "Beyond mediation – next steps and alternatives",
          "Course summary and post-course questionnaire exercise",
        ],
      },
    ],
    courseFess: 4840,
    category: "Law",
    courseCode: "LW09",
  },
  // Law

  // Operations Management
  {
    id: 79,
    image: OperationM1,
    title: "operationsManagement1Title",
    desc: "operationsManagement1Desc",
    WhoShouldAttend: ["operationsManagement1WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement1courseExpectation1",
      "operationsManagement1courseExpectation2",
      "operationsManagement1courseExpectation3",
      "operationsManagement1courseExpectation4",
      "operationsManagement1courseExpectation5",
      "operationsManagement1courseExpectation6",
    ],
    courseContent: [
      {
        title: "operationsManagement1coursecourseContent1Title",
        contents: [
          "operationsManagement1coursecourseContent1Desc1",
          "operationsManagement1coursecourseContent1Desc2",
          "operationsManagement1coursecourseContent1Desc3",
          "operationsManagement1coursecourseContent1Desc4",
          "operationsManagement1coursecourseContent1Desc5",
          "operationsManagement1coursecourseContent1Desc6",
          "operationsManagement1coursecourseContent1Desc7",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent2Title",
        contents: [
          "operationsManagement1coursecourseContent2Desc1",
          "operationsManagement1coursecourseContent2Desc2",
          "operationsManagement1coursecourseContent2Desc3",
          "operationsManagement1coursecourseContent2Desc4",
          "operationsManagement1coursecourseContent2Desc5",
          "operationsManagement1coursecourseContent2Desc6",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent3Title",
        contents: [
          "operationsManagement1coursecourseContent3Desc1",
          "operationsManagement1coursecourseContent3Desc2",
          "operationsManagement1coursecourseContent3Desc3",
          "operationsManagement1coursecourseContent3Desc4",
          "operationsManagement1coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent4Title",
        contents: [
          "operationsManagement1coursecourseContent4Desc1",
          "operationsManagement1coursecourseContent4Desc2",
          "operationsManagement1coursecourseContent4Desc3",
          "operationsManagement1coursecourseContent4Desc4",
          "operationsManagement1coursecourseContent4Desc5",
          "operationsManagement1coursecourseContent4Desc6",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent5Title",
        contents: [
          "operationsManagement1coursecourseContent5Desc1",
          "operationsManagement1coursecourseContent5Desc2",
          "operationsManagement1coursecourseContent5Desc3",
          "operationsManagement1coursecourseContent5Desc4",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent6Title",
        contents: [
          "operationsManagement1coursecourseContent6Desc1",
          "operationsManagement1coursecourseContent6Desc2",
          "operationsManagement1coursecourseContent6Desc3",
          "operationsManagement1coursecourseContent6Desc4",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent7Title",
        contents: [
          "operationsManagement1coursecourseContent7Desc1",
          "operationsManagement1coursecourseContent7Desc2",
          "operationsManagement1coursecourseContent7Desc3",
          "operationsManagement1coursecourseContent7Desc4",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent8Title",
        contents: [
          "operationsManagement1coursecourseContent8Desc1",
          "operationsManagement1coursecourseContent8Desc2",
          "operationsManagement1coursecourseContent8Desc3",
          "operationsManagement1coursecourseContent8Desc4",
        ],
      },
      {
        title: "operationsManagement1coursecourseContent9Title",
        contents: [
          "operationsManagement1coursecourseContent9Desc1",
          "operationsManagement1coursecourseContent9Desc2",
          "operationsManagement1coursecourseContent9Desc3",
          "operationsManagement1coursecourseContent9Desc4",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM01",
  },
  {
    id: 80,
    image: OperationM2,
    title: "operationsManagement2Title",
    desc: "operationsManagement2Desc",
    WhoShouldAttend: [
      "operationsManagement2WhoShouldAttend1",
      "operationsManagement2WhoShouldAttend2",
      "operationsManagement2WhoShouldAttend3",
      "operationsManagement2WhoShouldAttend4",
      "operationsManagement2WhoShouldAttend5",
      "operationsManagement2WhoShouldAttend6",
      "operationsManagement2WhoShouldAttend7",
      "operationsManagement2WhoShouldAttend8",
      "operationsManagement2WhoShouldAttend9",
    ],
    courseExpectation: [
      "operationsManagement2courseExpectation1",
      "operationsManagement2courseExpectation2",
      "operationsManagement2courseExpectation3",
      "operationsManagement2courseExpectation4",
      "operationsManagement2courseExpectation5",
      "operationsManagement2courseExpectation6",
      "operationsManagement2courseExpectation7",
      "operationsManagement2courseExpectation8",
      "operationsManagement2courseExpectation9",
    ],
    courseContent: [
      {
        title: "operationsManagement2coursecourseContent1Title",
        contents: [
          "operationsManagement2coursecourseContent1Desc1",
          "operationsManagement2coursecourseContent1Desc2",
          "operationsManagement2coursecourseContent1Desc3",
          "operationsManagement2coursecourseContent1Desc4",
          "operationsManagement2coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent2Title",
        contents: [
          "operationsManagement2coursecourseContent2Desc1",
          "operationsManagement2coursecourseContent2Desc2",
          "operationsManagement2coursecourseContent2Desc3",
          "operationsManagement2coursecourseContent2Desc4",
          "operationsManagement2coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent3Title",
        contents: [
          "operationsManagement2coursecourseContent3Desc1",
          "operationsManagement2coursecourseContent3Desc2",
          "operationsManagement2coursecourseContent3Desc3",
          "operationsManagement2coursecourseContent3Desc4",
          "operationsManagement2coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent4Title",
        contents: [
          "operationsManagement2coursecourseContent4Desc1",
          "operationsManagement2coursecourseContent4Desc2",
          "operationsManagement2coursecourseContent4Desc3",
          "operationsManagement2coursecourseContent4Desc4",
          "operationsManagement2coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent5Title",
        contents: [
          "operationsManagement2coursecourseContent5Desc1",
          "operationsManagement2coursecourseContent5Desc2",
          "operationsManagement2coursecourseContent5Desc3",
          "operationsManagement2coursecourseContent5Desc4",
          "operationsManagement2coursecourseContent5Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent6Title",
        contents: [
          "operationsManagement2coursecourseContent6Desc1",
          "operationsManagement2coursecourseContent6Desc2",
          "operationsManagement2coursecourseContent6Desc3",
          "operationsManagement2coursecourseContent6Desc4",
          "operationsManagement2coursecourseContent6Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent7Title",
        contents: [
          "operationsManagement2coursecourseContent7Desc1",
          "operationsManagement2coursecourseContent7Desc2",
          "operationsManagement2coursecourseContent7Desc3",
          "operationsManagement2coursecourseContent7Desc4",
          "operationsManagement2coursecourseContent7Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent8Title",
        contents: [
          "operationsManagement2coursecourseContent8Desc1",
          "operationsManagement2coursecourseContent8Desc2",
          "operationsManagement2coursecourseContent8Desc3",
          "operationsManagement2coursecourseContent8Desc4",
          "operationsManagement2coursecourseContent8Desc5",
        ],
      },
      {
        title: "operationsManagement2coursecourseContent9Title",
        contents: [
          "operationsManagement2coursecourseContent9Desc1",
          "operationsManagement2coursecourseContent9Desc2",
          "operationsManagement2coursecourseContent9Desc3",
          "operationsManagement2coursecourseContent9Desc4",
          "operationsManagement2coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM02",
  },
  {
    id: 81,
    image: OperationM3,
    title: "Advanced Procurement and Managing Suppliers",
    desc: " Take a strategic approach to procurement to assure cost-effective sourcing of critical suppliers",
    WhoShouldAttend: [],
    courseExpectation: [],
    courseContent: [
      {
        title: "operationsManagement3coursecourseContent1Title",
        contents: [
          "operationsManagement3coursecourseContent1Desc1",
          "operationsManagement3coursecourseContent1Desc2",
          "operationsManagement3coursecourseContent1Desc3",
          "operationsManagement3coursecourseContent1Desc4",
          "operationsManagement3coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent2Title",
        contents: [
          "operationsManagement3coursecourseContent2Desc1",
          "operationsManagement3coursecourseContent2Desc2",
          "operationsManagement3coursecourseContent2Desc3",
          "operationsManagement3coursecourseContent2Desc4",
          "operationsManagement3coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent3Title",
        contents: [
          "operationsManagement3coursecourseContent3Desc1",
          "operationsManagement3coursecourseContent3Desc2",
          "operationsManagement3coursecourseContent3Desc3",
          "operationsManagement3coursecourseContent3Desc4",
          "operationsManagement3coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent4Title",
        contents: [
          "operationsManagement3coursecourseContent4Desc1",
          "operationsManagement3coursecourseContent4Desc2",
          "operationsManagement3coursecourseContent4Desc3",
          "operationsManagement3coursecourseContent4Desc4",
          "operationsManagement3coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent5Title",
        contents: [
          "operationsManagement3coursecourseContent5Desc1",
          "operationsManagement3coursecourseContent5Desc2",
          "operationsManagement3coursecourseContent5Desc3",
          "operationsManagement3coursecourseContent5Desc4",
          "operationsManagement3coursecourseContent5Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent6Title",
        contents: [
          "operationsManagement3coursecourseContent6Desc1",
          "operationsManagement3coursecourseContent6Desc2",
          "operationsManagement3coursecourseContent6Desc3",
          "operationsManagement3coursecourseContent6Desc4",
          "operationsManagement3coursecourseContent6Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent7Title",
        contents: [
          "operationsManagement3coursecourseContent7Desc1",
          "operationsManagement3coursecourseContent7Desc2",
          "operationsManagement3coursecourseContent7Desc3",
          "operationsManagement3coursecourseContent7Desc4",
          "operationsManagement3coursecourseContent7Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent8Title",
        contents: [
          "operationsManagement3coursecourseContent8Desc1",
          "operationsManagement3coursecourseContent8Desc2",
          "operationsManagement3coursecourseContent8Desc3",
          "operationsManagement3coursecourseContent8Desc4",
          "operationsManagement3coursecourseContent8Desc5",
        ],
      },
      {
        title: "operationsManagement3coursecourseContent9Title",
        contents: [
          "operationsManagement3coursecourseContent9Desc1",
          "operationsManagement3coursecourseContent9Desc2",
          "operationsManagement3coursecourseContent9Desc3",
          "operationsManagement3coursecourseContent9Desc4",
          "operationsManagement3coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM03",
  },
  {
    id: 82,
    image: OperationM4,
    title: "operationsManagement4Title",
    desc: "",
    WhoShouldAttend: [
      "operationsManagement4WhoShouldAttend1",
      "operationsManagement4WhoShouldAttend2",
      "operationsManagement4WhoShouldAttend3",
      "operationsManagement4WhoShouldAttend4",
      "operationsManagement4WhoShouldAttend5",
      "operationsManagement4WhoShouldAttend6",
      "operationsManagement4WhoShouldAttend7",
    ],
    courseExpectation: [
      "operationsManagement4courseExpectation1",
      "operationsManagement4courseExpectation2",
      "operationsManagement4courseExpectation3",
      "operationsManagement4courseExpectation4",
      "operationsManagement4courseExpectation5",
      "operationsManagement4courseExpectation6",
      "operationsManagement4courseExpectation7",
      "operationsManagement4courseExpectation8",
      "operationsManagement4courseExpectation9",
      "operationsManagement4courseExpectation10",
      "operationsManagement4courseExpectation11",
      "operationsManagement4courseExpectation12",
      "operationsManagement4courseExpectation13",
      "operationsManagement4courseExpectation14",
    ],
    courseContent: [
      {
        title: "operationsManagement4coursecourseContent1Title",
        contents: [
          "operationsManagement4coursecourseContent1Desc1",
          "operationsManagement4coursecourseContent1Desc2",
          "operationsManagement4coursecourseContent1Desc3",
          "operationsManagement4coursecourseContent1Desc4",
          "operationsManagement4coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent2Title",
        contents: [
          "operationsManagement4coursecourseContent2Desc1",
          "operationsManagement4coursecourseContent2Desc2",
          "operationsManagement4coursecourseContent2Desc3",
          "operationsManagement4coursecourseContent2Desc3",
          "operationsManagement4coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent3Title",
        contents: [
          "operationsManagement4coursecourseContent3Desc1",
          "operationsManagement4coursecourseContent3Desc2",
          "operationsManagement4coursecourseContent3Desc3",
          "operationsManagement4coursecourseContent3Desc4",
          "operationsManagement4coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent4Title",
        contents: [
          "operationsManagement4coursecourseContent4Desc1",
          "operationsManagement4coursecourseContent4Desc2",
          "operationsManagement4coursecourseContent4Desc3",
          "operationsManagement4coursecourseContent4Desc4",
          "operationsManagement4coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent5Title",
        contents: [
          "operationsManagement4coursecourseContent5Desc1",
          "operationsManagement4coursecourseContent5Desc2",
          "operationsManagement4coursecourseContent5Desc3",
          "operationsManagement4coursecourseContent5Desc4",
          "operationsManagement4coursecourseContent5Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent6Title",
        contents: [
          "operationsManagement4coursecourseContent6Desc1",
          "operationsManagement4coursecourseContent6Desc2",
          "operationsManagement4coursecourseContent6Desc3",
          "operationsManagement4coursecourseContent6Desc4",
          "operationsManagement4coursecourseContent6Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent7Title",
        contents: [
          "operationsManagement4coursecourseContent7Desc1",
          "operationsManagement4coursecourseContent7Desc2",
          "operationsManagement4coursecourseContent7Desc3",
          "operationsManagement4coursecourseContent7Desc4",
          "operationsManagement4coursecourseContent7Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent8Title",
        contents: [
          "operationsManagement4coursecourseContent8Desc1",
          "operationsManagement4coursecourseContent8Desc2",
          "operationsManagement4coursecourseContent8Desc3",
          "operationsManagement4coursecourseContent8Desc4",
          "operationsManagement4coursecourseContent8Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent9Title",
        contents: [
          "operationsManagement4coursecourseContent9Desc1",
          "operationsManagement4coursecourseContent9Desc2",
          "operationsManagement4coursecourseContent9Desc3",
          "operationsManagement4coursecourseContent9Desc4",
          "operationsManagement4coursecourseContent9Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent10Title",
        contents: [
          "operationsManagement4coursecourseContent10Desc1",
          "operationsManagement4coursecourseContent10Desc2",
          "operationsManagement4coursecourseContent10Desc3",
          "operationsManagement4coursecourseContent10Desc4",
          "operationsManagement4coursecourseContent10Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent11Title",
        contents: [
          "operationsManagement4coursecourseContent11Desc1",
          "operationsManagement4coursecourseContent11Desc2",
          "operationsManagement4coursecourseContent11Desc3",
          "operationsManagement4coursecourseContent11Desc4",
          "operationsManagement4coursecourseContent11Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent12Title",
        contents: [
          "operationsManagement4coursecourseContent12Desc1",
          "operationsManagement4coursecourseContent12Desc2",
          "operationsManagement4coursecourseContent12Desc3",
          "operationsManagement4coursecourseContent12Desc4",
          "operationsManagement4coursecourseContent12Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent13Title",
        contents: [
          "operationsManagement4coursecourseContent13Desc1",
          "operationsManagement4coursecourseContent13Desc2",
          "operationsManagement4coursecourseContent13Desc3",
          "operationsManagement4coursecourseContent13Desc4",
          "operationsManagement4coursecourseContent13Desc5",
        ],
      },
      {
        title: "operationsManagement4coursecourseContent14Title",
        contents: [
          "operationsManagement4coursecourseContent14Desc1",
          "operationsManagement4coursecourseContent14Desc2",
          "operationsManagement4coursecourseContent14Desc3",
          "operationsManagement4coursecourseContent14Desc4",
          "operationsManagement4coursecourseContent14Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM04",
  },
  {
    id: 83,
    image: OperationM5,
    title: "operationsManagement5Title",
    desc: "operationsManagement5Desc",
    WhoShouldAttend: [
      "operationsManagement5WhoShouldAttend1",
      "operationsManagement5WhoShouldAttend2",
      "operationsManagement5WhoShouldAttend3",
      "operationsManagement5WhoShouldAttend4",
      "operationsManagement5WhoShouldAttend5",
    ],
    courseExpectation: [
      "operationsManagement5courseExpectation1",
      "operationsManagement5courseExpectation2",
      "operationsManagement5courseExpectation3",
      "operationsManagement5courseExpectation4",
      "operationsManagement5courseExpectation5",
      "operationsManagement5courseExpectation6",
      "operationsManagement5courseExpectation7",
      "operationsManagement5courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement5coursecourseContent1Title",
        contents: [
          "operationsManagement5coursecourseContent1Desc1",
          "operationsManagement5coursecourseContent1Desc2",
          "operationsManagement5coursecourseContent1Desc3",
          "operationsManagement5coursecourseContent1Desc4",
          "operationsManagement5coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent2Title",
        contents: [
          "operationsManagement5coursecourseContent2Desc1",
          "operationsManagement5coursecourseContent2Desc2",
          "operationsManagement5coursecourseContent2Desc3",
          "operationsManagement5coursecourseContent2Desc4",
          "operationsManagement5coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent3Title",
        contents: [
          "operationsManagement5coursecourseContent3Desc1",
          "operationsManagement5coursecourseContent3Desc2",
          "operationsManagement5coursecourseContent3Desc3",
          "operationsManagement5coursecourseContent3Desc4",
          "operationsManagement5coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent4Title",
        contents: [
          "operationsManagement5coursecourseContent4Desc1",
          "operationsManagement5coursecourseContent4Desc2",
          "operationsManagement5coursecourseContent4Desc3",
          "operationsManagement5coursecourseContent4Desc4",
          "operationsManagement5coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent5Title",
        contents: [
          "operationsManagement5coursecourseContent5Desc1",
          "operationsManagement5coursecourseContent5Desc2",
          "operationsManagement5coursecourseContent5Desc3",
          "operationsManagement5coursecourseContent5Desc4",
          "operationsManagement5coursecourseContent5Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent6Title",
        contents: [
          "operationsManagement5coursecourseContent6Desc1",
          "operationsManagement5coursecourseContent6Desc2",
          "operationsManagement5coursecourseContent6Desc3",
          "operationsManagement5coursecourseContent6Desc4",
          "operationsManagement5coursecourseContent6Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent7Title",
        contents: [
          "operationsManagement5coursecourseContent7Desc1",
          "operationsManagement5coursecourseContent7Desc2",
          "operationsManagement5coursecourseContent7Desc3",
          "operationsManagement5coursecourseContent7Desc4",
          "operationsManagement5coursecourseContent7Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent8Title",
        contents: [
          "operationsManagement5coursecourseContent8Desc1",
          "operationsManagement5coursecourseContent8Desc2",
          "operationsManagement5coursecourseContent8Desc3",
          "operationsManagement5coursecourseContent8Desc4",
          "operationsManagement5coursecourseContent8Desc5",
        ],
      },
      {
        title: "operationsManagement5coursecourseContent9Title",
        contents: [
          "operationsManagement5coursecourseContent9Desc1",
          "operationsManagement5coursecourseContent9Desc2",
          "operationsManagement5coursecourseContent9Desc3",
          "operationsManagement5coursecourseContent9Desc4",
          "operationsManagement5coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM05",
  },
  {
    id: 84,
    image: OperationM6,
    title: "operationsManagement6Title",
    desc: "operationsManagement6Desc",
    WhoShouldAttend: [
      "operationsManagement6WhoShouldAttend1",
      "operationsManagement6WhoShouldAttend2",
      "operationsManagement6WhoShouldAttend3",
      "operationsManagement6WhoShouldAttend4",
      "operationsManagement6WhoShouldAttend5",
      "operationsManagement6WhoShouldAttend6",
      "operationsManagement6WhoShouldAttend7",
    ],
    courseExpectation: [
      "operationsManagement6courseExpectation1",
      "operationsManagement6courseExpectation2",
      "operationsManagement6courseExpectation3",
      "operationsManagement6courseExpectation4",
      "operationsManagement6courseExpectation5",
      "operationsManagement6courseExpectation6",
      "operationsManagement6courseExpectation7",
      "operationsManagement6courseExpectation8",
      "operationsManagement6courseExpectation9",
    ],
    courseContent: [
      {
        title: "operationsManagement6coursecourseContent1Title",
        contents: [
          "operationsManagement6coursecourseContent1Desc1",
          "operationsManagement6coursecourseContent1Desc2",
          "operationsManagement6coursecourseContent1Desc3",
          "operationsManagement6coursecourseContent1Desc4",
          "operationsManagement6coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent2Title",
        contents: [
          "operationsManagement6coursecourseContent2Desc1",
          "operationsManagement6coursecourseContent2Desc2",
          "operationsManagement6coursecourseContent2Desc3",
          "operationsManagement6coursecourseContent2Desc4",
          "operationsManagement6coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent3Title",
        contents: [
          "operationsManagement6coursecourseContent3Desc1",
          "operationsManagement6coursecourseContent3Desc2",
          "operationsManagement6coursecourseContent3Desc3",
          "operationsManagement6coursecourseContent3Desc4",
          "operationsManagement6coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent4Title",
        contents: [
          "operationsManagement6coursecourseContent4Desc1",
          "operationsManagement6coursecourseContent4Desc2",
          "operationsManagement6coursecourseContent4Desc3",
          "operationsManagement6coursecourseContent4Desc4",
          "operationsManagement6coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent5Title",
        contents: [
          "operationsManagement6coursecourseContent5Desc1",
          "operationsManagement6coursecourseContent5Desc2",
          "operationsManagement6coursecourseContent5Desc3",
          "operationsManagement6coursecourseContent5Desc4",
          "operationsManagement6coursecourseContent5Desc5",
          "operationsManagement6coursecourseContent5Desc6",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent6Title",
        contents: [
          "operationsManagement6coursecourseContent6Desc1",
          "operationsManagement6coursecourseContent6Desc2",
          "operationsManagement6coursecourseContent6Desc3",
          "operationsManagement6coursecourseContent6Desc4",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent7Title",
        contents: [
          "operationsManagement6coursecourseContent7Desc1",
          "operationsManagement6coursecourseContent7Desc2",
          "operationsManagement6coursecourseContent7Desc3",
          "operationsManagement6coursecourseContent7Desc4",
          "operationsManagement6coursecourseContent7Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent8Title",
        contents: [
          "operationsManagement6coursecourseContent8Desc1",
          "operationsManagement6coursecourseContent8Desc2",
          "operationsManagement6coursecourseContent8Desc3",
          "operationsManagement6coursecourseContent8Desc4",
          "operationsManagement6coursecourseContent8Desc5",
        ],
      },
      {
        title: "operationsManagement6coursecourseContent9Title",
        contents: [
          "operationsManagement6coursecourseContent9Desc1",
          "operationsManagement6coursecourseContent9Desc2",
          "operationsManagement6coursecourseContent9Desc3",
          "operationsManagement6coursecourseContent9Desc4",
          "operationsManagement6coursecourseContent9Desc5",
        ],
      },
    ],
    courseFess: 6080,
    category: "Operations Management",
    courseCode: "OM06",
  },
  {
    id: 85,
    image: OperationM7,
    title: "operationsManagement7Title",
    desc: "operationsManagement7Desc",
    WhoShouldAttend: ["operationsManagement7WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement7courseExpectation1",
      "operationsManagement7courseExpectation2",
      "operationsManagement7courseExpectation3",
      "operationsManagement7courseExpectation4",
      "operationsManagement7courseExpectation5",
      "operationsManagement7courseExpectation6",
      "operationsManagement7courseExpectation7",
    ],
    courseContent: [
      {
        title: "operationsManagement7coursecourseContent1Title",
        contents: [
          "operationsManagement7coursecourseContent1Desc1",
          "operationsManagement7coursecourseContent1Desc2",
          "operationsManagement7coursecourseContent1Desc3",
          "operationsManagement7coursecourseContent1Desc4",
        ],
      },
      {
        title: "operationsManagement7coursecourseContent2Title",
        contents: [
          "operationsManagement7coursecourseContent2Desc1",
          "operationsManagement7coursecourseContent2Desc2",
          "operationsManagement7coursecourseContent2Desc3",
          "operationsManagement7coursecourseContent2Desc4",
        ],
      },
      {
        title: "operationsManagement7coursecourseContent3Title",
        contents: [
          "operationsManagement7coursecourseContent3Desc1",
          "operationsManagement7coursecourseContent3Desc2",
          "operationsManagement7coursecourseContent3Desc3",
          "operationsManagement7coursecourseContent3Desc4",
        ],
      },
      {
        title: "operationsManagement7coursecourseContent4Title",
        contents: [
          "operationsManagement7coursecourseContent4Desc1",
          "operationsManagement7coursecourseContent4Desc2",
          "operationsManagement7coursecourseContent4Desc3",
          "operationsManagement7coursecourseContent4Desc4",
        ],
      },
      {
        title: "operationsManagement7coursecourseContent5Title",
        contents: [
          "operationsManagement7coursecourseContent5Desc1",
          "operationsManagement7coursecourseContent5Desc2",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM07",
  },
  {
    id: 86,
    image: OperationM8,
    title: "operationsManagement8Title",
    desc: "operationsManagement8Desc",
    WhoShouldAttend: [
      "operationsManagement8WhoShouldAttend1",
      "operationsManagement8WhoShouldAttend2",
      "operationsManagement8WhoShouldAttend3",
      "operationsManagement8WhoShouldAttend4",
      "operationsManagement8WhoShouldAttend5",
      "operationsManagement8WhoShouldAttend6",
      "operationsManagement8WhoShouldAttend7",
    ],
    courseExpectation: [
      "operationsManagement8courseExpectation1",
      "operationsManagement8courseExpectation2",
      "operationsManagement8courseExpectation3",
      "operationsManagement8courseExpectation4",
      "operationsManagement8courseExpectation5",
      "operationsManagement8courseExpectation6",
      "operationsManagement8courseExpectation7",
    ],
    courseContent: [
      {
        title: "operationsManagement8coursecourseContent1Title",
        contents: [
          "operationsManagement8coursecourseContent1Desc1",
          "operationsManagement8coursecourseContent1Desc2",
          "operationsManagement8coursecourseContent1Desc3",
          "operationsManagement8coursecourseContent1Desc4",
          "operationsManagement8coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement8coursecourseContent2Title",
        contents: [
          "operationsManagement8coursecourseContent2Desc1",
          "operationsManagement8coursecourseContent2Desc2",
          "operationsManagement8coursecourseContent2Desc3",
          "operationsManagement8coursecourseContent2Desc4",
          "operationsManagement8coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement8coursecourseContent3Title",
        contents: [
          "operationsManagement8coursecourseContent3Desc1",
          "operationsManagement8coursecourseContent3Desc2",
          "operationsManagement8coursecourseContent3Desc3",
          "operationsManagement8coursecourseContent3Desc4",
          "operationsManagement8coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement8coursecourseContent4Title",
        contents: [
          "operationsManagement8coursecourseContent4Desc1",
          "operationsManagement8coursecourseContent4Desc2",
          "operationsManagement8coursecourseContent4Desc3",
          "operationsManagement8coursecourseContent4Desc4",
          "operationsManagement8coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement8coursecourseContent5Title",
        contents: [
          "operationsManagement8coursecourseContent5Desc1",
          "operationsManagement8coursecourseContent5Desc2",
          "operationsManagement8coursecourseContent5Desc3",
          "operationsManagement8coursecourseContent5Desc4",
          "operationsManagement8coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM08",
  },
  {
    id: 87,
    image: OperationM9,
    title: "operationsManagement9Title",
    desc: "operationsManagement9Desc",
    WhoShouldAttend: ["operationsManagement9WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement9courseExpectation1",
      "operationsManagement9courseExpectation2",
      "operationsManagement9courseExpectation3",
      "operationsManagement9courseExpectation4",
      "operationsManagement9courseExpectation5",
      "operationsManagement9courseExpectation6",
      "operationsManagement9courseExpectation7",
      "operationsManagement9courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement9coursecourseContent1Title",
        contents: [
          "operationsManagement9coursecourseContent1Desc1",
          "operationsManagement9coursecourseContent1Desc2",
          "operationsManagement9coursecourseContent1Desc3",
          "operationsManagement9coursecourseContent1Desc4",
          "operationsManagement9coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent2Title",
        contents: [
          "operationsManagement9coursecourseContent2Desc1",
          "operationsManagement9coursecourseContent2Desc2",
          "operationsManagement9coursecourseContent2Desc3",
          "operationsManagement9coursecourseContent2Desc4",
          "operationsManagement9coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent3Title",
        contents: [
          "operationsManagement9coursecourseContent3Desc1",
          "operationsManagement9coursecourseContent3Desc2",
          "operationsManagement9coursecourseContent3Desc3",
          "operationsManagement9coursecourseContent3Desc4",
          "operationsManagement9coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent4Title",
        contents: [
          "operationsManagement9coursecourseContent4Desc1",
          "operationsManagement9coursecourseContent4Desc2",
          "operationsManagement9coursecourseContent4Desc3",
          "operationsManagement9coursecourseContent4Desc4",
          "operationsManagement9coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement9coursecourseContent5Title",
        contents: [
          "operationsManagement9coursecourseContent5Desc1",
          "operationsManagement9coursecourseContent5Desc2",
          "operationsManagement9coursecourseContent5Desc3",
          "operationsManagement9coursecourseContent5Desc4",
          "operationsManagement9coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM09",
  },
  {
    id: 88,
    image: OperationM10,
    title: "operationsManagement10Title",
    desc: "operationsManagement10Desc",
    WhoShouldAttend: ["operationsManagement10WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement10courseExpectation1",
      "operationsManagement10courseExpectation2",
      "operationsManagement10courseExpectation3",
      "operationsManagement10courseExpectation4",
      "operationsManagement10courseExpectation5",
      "operationsManagement10courseExpectation6",
      "operationsManagement10courseExpectation7",
    ],
    courseContent: [
      {
        title: "operationsManagement10coursecourseContent1Title",
        contents: [
          "operationsManagement10coursecourseContent1Desc1",
          "operationsManagement10coursecourseContent1Desc2",
          "operationsManagement10coursecourseContent1Desc3",
          "operationsManagement10coursecourseContent1Desc4",
          "operationsManagement10coursecourseContent1Desc5",
          "operationsManagement10coursecourseContent1Desc6",
        ],
      },
      {
        title: "operationsManagement10coursecourseContent2Title",
        contents: [
          "operationsManagement10coursecourseContent2Desc1",
          "operationsManagement10coursecourseContent2Desc2",
          "operationsManagement10coursecourseContent2Desc3",
          "operationsManagement10coursecourseContent2Desc4",
          "operationsManagement10coursecourseContent2Desc5",
          "operationsManagement10coursecourseContent2Desc6",
        ],
      },
      {
        title: "operationsManagement10coursecourseContent3Title",
        contents: [
          "operationsManagement10coursecourseContent3Desc1",
          "operationsManagement10coursecourseContent3Desc2",
          "operationsManagement10coursecourseContent3Desc3",
          "operationsManagement10coursecourseContent3Desc4",
          "operationsManagement10coursecourseContent3Desc5",
          "operationsManagement10coursecourseContent3Desc6",
        ],
      },
      {
        title: "operationsManagement10coursecourseContent4Title",
        contents: [
          "operationsManagement10coursecourseContent4Desc1",
          "operationsManagement10coursecourseContent4Desc2",
          "operationsManagement10coursecourseContent4Desc3",
          "operationsManagement10coursecourseContent4Desc4",
          "operationsManagement10coursecourseContent4Desc5",
          "operationsManagement10coursecourseContent4Desc6",
        ],
      },
      {
        title: "operationsManagement10coursecourseContent5Title",
        contents: [
          "operationsManagement10coursecourseContent5Desc1",
          "operationsManagement10coursecourseContent5Desc2",
          "operationsManagement10coursecourseContent5Desc3",
          "operationsManagement10coursecourseContent5Desc4",
          "operationsManagement10coursecourseContent5Desc5",
          "operationsManagement10coursecourseContent5Desc6",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM10",
  },
  {
    id: 89,
    image: OperationM11,
    title: "operationsManagement11Title",
    desc: "operationsManagement11Desc",
    WhoShouldAttend: [
      "operationsManagement11WhoShouldAttend1",
      "operationsManagement11WhoShouldAttend2",
      "operationsManagement11WhoShouldAttend3",
      "operationsManagement11WhoShouldAttend4",
      "operationsManagement11WhoShouldAttend5",
      "operationsManagement11WhoShouldAttend6",
    ],
    courseExpectation: [
      "operationsManagement11courseExpectation1",
      "operationsManagement11courseExpectation2",
      "operationsManagement11courseExpectation3",
      "operationsManagement11courseExpectation4",
      "operationsManagement11courseExpectation5",
      "operationsManagement11courseExpectation6",
      "operationsManagement11courseExpectation7",
      "operationsManagement11courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement11coursecourseContent1Title",
        contents: [
          "operationsManagement11coursecourseContent1Desc1",
          "operationsManagement11coursecourseContent1Desc2",
          "operationsManagement11coursecourseContent1Desc3",
          "operationsManagement11coursecourseContent1Desc4",
          "operationsManagement11coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement11coursecourseContent2Title",
        contents: [
          "operationsManagement11coursecourseContent2Desc1",
          "operationsManagement11coursecourseContent2Desc2",
          "operationsManagement11coursecourseContent2Desc3",
          "operationsManagement11coursecourseContent2Desc4",
          "operationsManagement11coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement11coursecourseContent3Title",
        contents: [
          "operationsManagement11coursecourseContent3Desc1",
          "operationsManagement11coursecourseContent3Desc2",
          "operationsManagement11coursecourseContent3Desc3",
          "operationsManagement11coursecourseContent3Desc4",
          "operationsManagement11coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement11coursecourseContent4Title",
        contents: [
          "operationsManagement11coursecourseContent4Desc1",
          "operationsManagement11coursecourseContent4Desc2",
          "operationsManagement11coursecourseContent4Desc3",
          "operationsManagement11coursecourseContent4Desc4",
          "operationsManagement11coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement11coursecourseContent5Title",
        contents: [
          "operationsManagement11coursecourseContent5Desc1",
          "operationsManagement11coursecourseContent5Desc2",
          "operationsManagement11coursecourseContent5Desc3",
          "operationsManagement11coursecourseContent5Desc4",
          "operationsManagement11coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM11",
  },
  {
    id: 90,
    image: OperationM12,
    title: "operationsManagement12Title",
    desc: "operationsManagement12Desc",
    WhoShouldAttend: [
      "operationsManagement12WhoShouldAttend1",
      "operationsManagement12WhoShouldAttend2",
      "operationsManagement12WhoShouldAttend3",
      "operationsManagement12WhoShouldAttend4",
      "operationsManagement12WhoShouldAttend5",
      "operationsManagement12WhoShouldAttend6",
      "operationsManagement12WhoShouldAttend7",
      "operationsManagement12WhoShouldAttend8",
      "operationsManagement12WhoShouldAttend9",
    ],
    courseExpectation: [
      "operationsManagement12courseExpectation1",
      "operationsManagement12courseExpectation2",
      "operationsManagement12courseExpectation3",
      "operationsManagement12courseExpectation4",
      "operationsManagement12courseExpectation5",
      "operationsManagement12courseExpectation6",
      "operationsManagement12courseExpectation7",
      "operationsManagement12courseExpectation8",
      "operationsManagement12courseExpectation9",
    ],
    courseContent: [
      {
        title: "operationsManagement12coursecourseContent1Title",
        contents: [
          "operationsManagement12coursecourseContent1Desc1",
          "operationsManagement12coursecourseContent1Desc2",
          "operationsManagement12coursecourseContent1Desc3",
          "operationsManagement12coursecourseContent1Desc4",
          "operationsManagement12coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement12coursecourseContent2Title",
        contents: [
          "operationsManagement12coursecourseContent2Desc1",
          "operationsManagement12coursecourseContent2Desc2",
          "operationsManagement12coursecourseContent2Desc3",
          "operationsManagement12coursecourseContent2Desc4",
          "operationsManagement12coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement12coursecourseContent3Title",
        contents: [
          "operationsManagement12coursecourseContent3Desc1",
          "operationsManagement12coursecourseContent3Desc2",
          "operationsManagement12coursecourseContent3Desc3",
          "operationsManagement12coursecourseContent3Desc4",
          "operationsManagement12coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement12coursecourseContent4Title",
        contents: [
          "operationsManagement12coursecourseContent4Desc1",
          "operationsManagement12coursecourseContent4Desc2",
          "operationsManagement12coursecourseContent4Desc3",
          "operationsManagement12coursecourseContent4Desc4",
          "operationsManagement12coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement12coursecourseContent5Title",
        contents: [
          "operationsManagement12coursecourseContent5Desc1",
          "operationsManagement12coursecourseContent5Desc2",
          "operationsManagement12coursecourseContent5Desc3",
          "operationsManagement12coursecourseContent5Desc4",
          "operationsManagement12coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM12",
  },
  {
    id: 91,
    image: OperationM13,
    title: "operationsManagement13Title",
    desc: "operationsManagement13Desc",
    WhoShouldAttend: ["operationsManagement13WhoShouldAttend"],
    courseExpectation: [
      "operationsManagement13courseExpectation1",
      "operationsManagement13courseExpectation2",
      "operationsManagement13courseExpectation3",
      "operationsManagement13courseExpectation4",
      "operationsManagement13courseExpectation5",
    ],
    courseContent: [
      {
        title: "operationsManagement13coursecourseContent1Title",
        contents: [
          "operationsManagement13coursecourseContent1Desc1",
          "operationsManagement13coursecourseContent1Desc2",
          "operationsManagement13coursecourseContent1Desc3",
          "operationsManagement13coursecourseContent1Desc4",
        ],
      },
      {
        title: "operationsManagement13coursecourseContent2Title",
        contents: [
          "operationsManagement13coursecourseContent2Desc1",
          "operationsManagement13coursecourseContent2Desc2",
          "operationsManagement13coursecourseContent2Desc3",
          "operationsManagement13coursecourseContent2Desc4",
        ],
      },
      {
        title: "operationsManagement13coursecourseContent3Title",
        contents: [
          "operationsManagement13coursecourseContent3Desc1",
          "operationsManagement13coursecourseContent3Desc2",
          "operationsManagement13coursecourseContent3Desc3",
          "operationsManagement13coursecourseContent3Desc4",
        ],
      },
      {
        title: "operationsManagement13coursecourseContent4Title",
        contents: [
          "operationsManagement13coursecourseContent4Desc1",
          "operationsManagement13coursecourseContent4Desc2",
          "operationsManagement13coursecourseContent4Desc3",
          "operationsManagement13coursecourseContent4Desc4",
        ],
      },
      {
        title: "operationsManagement13coursecourseContent5Title",
        contents: [
          "operationsManagement13coursecourseContent5Desc1",
          "operationsManagement13coursecourseContent5Desc2",
          "operationsManagement13coursecourseContent5Desc3",
          "operationsManagement13coursecourseContent5Desc4",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM13",
  },
  {
    id: 92,
    image: OperationM14,
    title: "operationsManagement14Title",
    desc: "operationsManagement14Desc",
    WhoShouldAttend: [
      "operationsManagement14WhoShouldAttend1",
      "operationsManagement14WhoShouldAttend2",
      "operationsManagement14WhoShouldAttend3",
      "operationsManagement14WhoShouldAttend4",
      "operationsManagement14WhoShouldAttend5",
    ],
    courseExpectation: [
      "operationsManagement14courseContent1",
      "operationsManagement14courseContent2",
      "operationsManagement14courseContent3",
      "operationsManagement14courseContent4",
      "operationsManagement14courseContent5",
      "operationsManagement14courseContent6",
      "operationsManagement14courseContent7",
      "operationsManagement14courseContent8",
    ],
    courseContent: [
      {
        title: "operationsManagement14coursecourseContent1Title",
        contents: [
          "operationsManagement14coursecourseContent1Desc1",
          "operationsManagement14coursecourseContent1Desc2",
          "operationsManagement14coursecourseContent1Desc3",
          "operationsManagement14coursecourseContent1Desc4",
          "operationsManagement14coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement14coursecourseContent2Title",
        contents: [
          "operationsManagement14coursecourseContent2Desc1",
          "operationsManagement14coursecourseContent2Desc2",
          "operationsManagement14coursecourseContent2Desc3",
          "operationsManagement14coursecourseContent2Desc4",
          "operationsManagement14coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement14coursecourseContent3Title",
        contents: [
          "operationsManagement14coursecourseContent3Desc1",
          "operationsManagement14coursecourseContent3Desc2",
          "operationsManagement14coursecourseContent3Desc3",
          "operationsManagement14coursecourseContent3Desc4",
          "operationsManagement14coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement14coursecourseContent4Title",
        contents: [
          "operationsManagement14coursecourseContent4Desc1",
          "operationsManagement14coursecourseContent4Desc2",
          "operationsManagement14coursecourseContent4Desc3",
          "operationsManagement14coursecourseContent4Desc4",
          "operationsManagement14coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement14coursecourseContent5Title",
        contents: [
          "operationsManagement14coursecourseContent5Desc1",
          "operationsManagement14coursecourseContent5Desc2",
          "operationsManagement14coursecourseContent5Desc3",
          "operationsManagement14coursecourseContent5Desc4",
          "operationsManagement14coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM14",
  },
  {
    id: 93,
    image: OperationM15,
    title: "operationsManagement15Title",
    desc: "operationsManagement15Desc",
    WhoShouldAttend: [
      "operationsManagement15WhoShouldAttend1",
      "operationsManagement15WhoShouldAttend2",
      "operationsManagement15WhoShouldAttend3",
      "operationsManagement15WhoShouldAttend4",
      "operationsManagement15WhoShouldAttend5",
      "operationsManagement15WhoShouldAttend6",
    ],
    courseExpectation: [
      "operationsManagement15courseExpectation1",
      "operationsManagement15courseExpectation2",
      "operationsManagement15courseExpectation3",
      "operationsManagement15courseExpectation4",
      "operationsManagement15courseExpectation5",
      "operationsManagement15courseExpectation6",
      "operationsManagement15courseExpectation7",
      "operationsManagement15courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement15coursecourseContent1Title",
        contents: [
          "operationsManagement15coursecourseContent1Desc1",
          "operationsManagement15coursecourseContent1Desc2",
          "operationsManagement15coursecourseContent1Desc3",
          "operationsManagement15coursecourseContent1Desc4",
          "operationsManagement15coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement15coursecourseContent2Title",
        contents: [
          "operationsManagement15coursecourseContent2Desc1",
          "operationsManagement15coursecourseContent2Desc2",
          "operationsManagement15coursecourseContent2Desc3",
          "operationsManagement15coursecourseContent2Desc4",
          "operationsManagement15coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement15coursecourseContent3Title",
        contents: [
          "operationsManagement15coursecourseContent3Desc1",
          "operationsManagement15coursecourseContent3Desc2",
          "operationsManagement15coursecourseContent3Desc3",
          "operationsManagement15coursecourseContent3Desc4",
          "operationsManagement15coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement15coursecourseContent4Title",
        contents: [
          "operationsManagement15coursecourseContent4Desc1",
          "operationsManagement15coursecourseContent4Desc2",
          "operationsManagement15coursecourseContent4Desc3",
          "operationsManagement15coursecourseContent4Desc4",
          "operationsManagement15coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement15coursecourseContent5Title",
        contents: [
          "operationsManagement15coursecourseContent5Desc1",
          "operationsManagement15coursecourseContent5Desc2",
          "operationsManagement15coursecourseContent5Desc3",
          "operationsManagement15coursecourseContent5Desc4",
          "operationsManagement15coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM15",
  },
  {
    id: 94,
    image: OperationM16,
    title: "operationsManagement16Title",
    desc: "operationsManagement16Desc",
    WhoShouldAttend: [
      "operationsManagement16WhoShouldAttend1",
      "operationsManagement16WhoShouldAttend2",
      "operationsManagement16WhoShouldAttend3",
      "operationsManagement16WhoShouldAttend4",
      "operationsManagement16WhoShouldAttend5",
      "operationsManagement16WhoShouldAttend6",
    ],
    courseExpectation: [
      "operationsManagement16courseExpectation1",
      "operationsManagement16courseExpectation2",
      "operationsManagement16courseExpectation3",
      "operationsManagement16courseExpectation4",
      "operationsManagement16courseExpectation5",
      "operationsManagement16courseExpectation6",
      "operationsManagement16courseExpectation7",
      "operationsManagement16courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement16coursecourseContent1Title",
        contents: [
          "operationsManagement16coursecourseContent1Desc1",
          "operationsManagement16coursecourseContent1Desc2",
          "operationsManagement16coursecourseContent1Desc3",
          "operationsManagement16coursecourseContent1Desc4",
          "operationsManagement16coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement16coursecourseContent2Title",
        contents: [
          "operationsManagement16coursecourseContent2Desc1",
          "operationsManagement16coursecourseContent2Desc2",
          "operationsManagement16coursecourseContent2Desc3",
          "operationsManagement16coursecourseContent2Desc4",
          "operationsManagement16coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement16coursecourseContent3Title",
        contents: [
          "operationsManagement16coursecourseContent3Desc1",
          "operationsManagement16coursecourseContent3Desc2",
          "operationsManagement16coursecourseContent3Desc3",
          "operationsManagement16coursecourseContent3Desc4",
          "operationsManagement16coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement16coursecourseContent4Title",
        contents: [
          "operationsManagement16coursecourseContent4Desc1",
          "operationsManagement16coursecourseContent4Desc2",
          "operationsManagement16coursecourseContent4Desc3",
          "operationsManagement16coursecourseContent4Desc4",
          "operationsManagement16coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement16coursecourseContent5Title",
        contents: [
          "operationsManagement16coursecourseContent5Desc1",
          "operationsManagement16coursecourseContent5Desc2",
          "operationsManagement16coursecourseContent5Desc3",
          "operationsManagement16coursecourseContent5Desc4",
          "operationsManagement16coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM16",
  },
  {
    id: 95,
    image: OperationM17,
    title: "operationsManagement17Title",
    desc: "operationsManagement17Desc",
    WhoShouldAttend: [
      "operationsManagement17WhoShouldAttend1",
      "operationsManagement17WhoShouldAttend2",
      "operationsManagement17WhoShouldAttend3",
      "operationsManagement17WhoShouldAttend4",
      "operationsManagement17WhoShouldAttend5",
    ],
    courseExpectation: [
      "operationsManagement17courseExpectation1",
      "operationsManagement17courseExpectation2",
      "operationsManagement17courseExpectation3",
      "operationsManagement17courseExpectation4",
      "operationsManagement17courseExpectation5",
      "operationsManagement17courseExpectation6",
      "operationsManagement17courseExpectation7",
      "operationsManagement17courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement17coursecourseContent1Title",
        contents: [
          "operationsManagement17coursecourseContent1Desc1",
          "operationsManagement17coursecourseContent1Desc2",
          "operationsManagement17coursecourseContent1Desc3",
          "operationsManagement17coursecourseContent1Desc4",
          "operationsManagement17coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement17coursecourseContent2Title",
        contents: [
          "operationsManagement17coursecourseContent2Desc1",
          "operationsManagement17coursecourseContent2Desc2",
          "operationsManagement17coursecourseContent2Desc3",
          "operationsManagement17coursecourseContent2Desc4",
          "operationsManagement17coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement17coursecourseContent3Title",
        contents: [
          "operationsManagement17coursecourseContent3Desc1",
          "operationsManagement17coursecourseContent3Desc2",
          "operationsManagement17coursecourseContent3Desc3",
          "operationsManagement17coursecourseContent3Desc4",
          "operationsManagement17coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement17coursecourseContent4Title",
        contents: [
          "operationsManagement17coursecourseContent4Desc1",
          "operationsManagement17coursecourseContent4Desc2",
          "operationsManagement17coursecourseContent4Desc3",
          "operationsManagement17coursecourseContent4Desc4",
          "operationsManagement17coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement17coursecourseContent5Title",
        contents: [
          "operationsManagement17coursecourseContent5Desc1",
          "operationsManagement17coursecourseContent5Desc2",
          "operationsManagement17coursecourseContent5Desc3",
          "operationsManagement17coursecourseContent5Desc4",
          "operationsManagement17coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM17",
  },
  {
    id: 96,
    image: OperationM18,
    title: "operationsManagement18Title",
    desc: "operationsManagement18Desc",
    WhoShouldAttend: [
      "operationsManagement18WhoShouldAttend1",
      "operationsManagement18WhoShouldAttend2",
      "operationsManagement18WhoShouldAttend3",
      "operationsManagement18WhoShouldAttend4",
      "operationsManagement18WhoShouldAttend5",
      "operationsManagement18WhoShouldAttend6",
    ],
    courseExpectation: [
      "operationsManagement18courseExpectation1",
      "operationsManagement18courseExpectation2",
      "operationsManagement18courseExpectation3",
      "operationsManagement18courseExpectation4",
      "operationsManagement18courseExpectation5",
      "operationsManagement18courseExpectation6",
      "operationsManagement18courseExpectation7",
      "operationsManagement18courseExpectation8",
    ],
    courseContent: [
      {
        title: "operationsManagement18coursecourseContent1Title",
        contents: [
          "operationsManagement18coursecourseContent1Desc1",
          "operationsManagement18coursecourseContent1Desc2",
          "operationsManagement18coursecourseContent1Desc3",
          "operationsManagement18coursecourseContent1Desc4",
          "operationsManagement18coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement18coursecourseContent2Title",
        contents: [
          "operationsManagement18coursecourseContent2Desc1",
          "operationsManagement18coursecourseContent2Desc2",
          "operationsManagement18coursecourseContent2Desc3",
          "operationsManagement18coursecourseContent2Desc4",
          "operationsManagement18coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement18coursecourseContent3Title",
        contents: [
          "operationsManagement18coursecourseContent3Desc1",
          "operationsManagement18coursecourseContent3Desc2",
          "operationsManagement18coursecourseContent3Desc3",
          "operationsManagement18coursecourseContent3Desc4",
          "operationsManagement18coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement18coursecourseContent4Title",
        contents: [
          "operationsManagement18coursecourseContent4Desc1",
          "operationsManagement18coursecourseContent4Desc2",
          "operationsManagement18coursecourseContent4Desc3",
          "operationsManagement18coursecourseContent4Desc4",
          "operationsManagement18coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement18coursecourseContent5Title",
        contents: [
          "operationsManagement18coursecourseContent5Desc1",
          "operationsManagement18coursecourseContent5Desc2",
          "operationsManagement18coursecourseContent5Desc3",
          "operationsManagement18coursecourseContent5Desc4",
          "operationsManagement18coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM18",
  },
  {
    id: 97,
    image: OperationM19,
    title: "operationsManagement19Title",
    desc: "operationsManagement19Desc",
    WhoShouldAttend: [
      "operationsManagement19WhoShouldAttend1",
      "operationsManagement19WhoShouldAttend2",
      "operationsManagement19WhoShouldAttend3",
      "operationsManagement19WhoShouldAttend4",
      "operationsManagement19WhoShouldAttend5",
      "operationsManagement19WhoShouldAttend6",
      "operationsManagement19WhoShouldAttend7",
    ],
    courseExpectation: [
      "operationsManagement19courseExpectation1",
      "operationsManagement19courseExpectation2",
      "operationsManagement19courseExpectation3",
      "operationsManagement19courseExpectation4",
      "operationsManagement19courseExpectation5",
      "operationsManagement19courseExpectation6",
      "operationsManagement19courseExpectation7",
      "operationsManagement19courseExpectation8",
      "operationsManagement19courseExpectation9",
    ],
    courseContent: [
      {
        title: "operationsManagement19coursecourseContent1Title",
        contents: [
          "operationsManagement19coursecourseContent1Desc1",
          "operationsManagement19coursecourseContent1Desc2",
          "operationsManagement19coursecourseContent1Desc3",
          "operationsManagement19coursecourseContent1Desc4",
          "operationsManagement19coursecourseContent1Desc5",
          "operationsManagement19coursecourseContent1Desc6",
        ],
      },
      {
        title: "operationsManagement19coursecourseContent2Title",
        contents: [
          "operationsManagement19coursecourseContent2Desc1",
          "operationsManagement19coursecourseContent2Desc2",
          "operationsManagement19coursecourseContent2Desc3",
          "operationsManagement19coursecourseContent2Desc4",
          "operationsManagement19coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement19coursecourseContent3Title",
        contents: [
          "operationsManagement19coursecourseContent3Desc1",
          "operationsManagement19coursecourseContent3Desc2",
          "operationsManagement19coursecourseContent3Desc3",
          "operationsManagement19coursecourseContent3Desc4",
          "operationsManagement19coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement19coursecourseContent4Title",
        contents: [
          "operationsManagement19coursecourseContent4Desc1",
          "operationsManagement19coursecourseContent4Desc2",
          "operationsManagement19coursecourseContent4Desc3",
          "operationsManagement19coursecourseContent4Desc4",
          "operationsManagement19coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement19coursecourseContent5Title",
        contents: [
          "operationsManagement19coursecourseContent5Desc1",
          "operationsManagement19coursecourseContent5Desc2",
          "operationsManagement19coursecourseContent5Desc3",
          "operationsManagement19coursecourseContent5Desc4",
          "operationsManagement19coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM19",
  },
  {
    id: 98,
    image: OperationM20,
    title: "operationsManagement20Title",
    desc: "operationsManagement20Desc",
    WhoShouldAttend: [
      "operationsManagement20WhoShouldAttend1",
      "operationsManagement20WhoShouldAttend2",
      "operationsManagement20WhoShouldAttend3",
      "operationsManagement20WhoShouldAttend4",
      "operationsManagement20WhoShouldAttend5",
      "operationsManagement20WhoShouldAttend6",
      "operationsManagement20WhoShouldAttend7",
    ],
    courseExpectation: [
      "operationsManagement20courseExpectation1",
      "operationsManagement20courseExpectation2",
      "operationsManagement20courseExpectation3",
      "operationsManagement20courseExpectation4",
      "operationsManagement20courseExpectation5",
      "operationsManagement20courseExpectation6",
      "operationsManagement20courseExpectation7",
    ],
    courseContent: [
      {
        title: "operationsManagement20coursecourseContent1Title",
        contents: [
          "operationsManagement20coursecourseContent1Desc1",
          "operationsManagement20coursecourseContent1Desc2",
          "operationsManagement20coursecourseContent1Desc3",
          "operationsManagement20coursecourseContent1Desc4",
          "operationsManagement20coursecourseContent1Desc5",
        ],
      },
      {
        title: "operationsManagement20coursecourseContent2Title",
        contents: [
          "operationsManagement20coursecourseContent2Desc1",
          "operationsManagement20coursecourseContent2Desc2",
          "operationsManagement20coursecourseContent2Desc3",
          "operationsManagement20coursecourseContent2Desc4",
          "operationsManagement20coursecourseContent2Desc5",
        ],
      },
      {
        title: "operationsManagement20coursecourseContent3Title",
        contents: [
          "operationsManagement20coursecourseContent3Desc1",
          "operationsManagement20coursecourseContent3Desc2",
          "operationsManagement20coursecourseContent3Desc3",
          "operationsManagement20coursecourseContent3Desc4",
          "operationsManagement20coursecourseContent3Desc5",
        ],
      },
      {
        title: "operationsManagement20coursecourseContent4Title",
        contents: [
          "operationsManagement20coursecourseContent4Desc1",
          "operationsManagement20coursecourseContent4Desc2",
          "operationsManagement20coursecourseContent4Desc3",
          "operationsManagement20coursecourseContent4Desc4",
          "operationsManagement20coursecourseContent4Desc5",
        ],
      },
      {
        title: "operationsManagement20coursecourseContent5Title",
        contents: [
          "operationsManagement20coursecourseContent5Desc1",
          "operationsManagement20coursecourseContent5Desc2",
          "operationsManagement20coursecourseContent5Desc3",
          "operationsManagement20coursecourseContent5Desc4",
          "operationsManagement20coursecourseContent5Desc5",
        ],
      },
    ],
    courseFess: 4840,
    category: "Operations Management",
    courseCode: "OM20",
  },
  // Operations Management

  // Public Relations
  {
    id: 99,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Advanced Corporate Social Responsibility",
    desc: "Promote your organisation's contribution to society: Corporate Social Responsibility - strategic planning and implementation ",
    WhoShouldAttend: [
      "Heads of sustainable development",
      "Corporate Affairs managers and officers",
      "CSR managers and officers",
      "Corporate strategists",
      "Community Relations managers and officers",
      "HR, Administration and Environmental managers concerned with CSR planning and implementation",
      "Procurement managers involved in responsible sourcing initiatives",
      "Project managers responsible for developing and implementing CSR projects",
      "Managers and officers involved in non - financial corporate reporting",
      "Communications managers and officers responsible for internal and external reporting on sustainability",
      "Internal auditors concerned with corporate social responsibility risks",
    ],
    courseExpectation: [
      "Understand how Corporate Social Responsibility is important in the public and private sectors",
      "Apply CSR best practices to organisationalprogrammes and projects ",
      "Develop and improve their community relations activities",
      "Interpret the impact of CSR on the reputation of the organisation",
      "Plan, initiate or improve CSR programmes",
      "Report on their sustainability programmes",
      "Identify the links between business ethics, Corporate Social Responsibility and Corporate Governance",
    ],
    courseContent: [
      {
        title: "What is CSR and why does it matter?",
        contents: [
          "The pressure for change",
          "The Legal Background of CSR",
          "Company directors’ obligations and CSR",
          "Voluntary measures",
          "Is CSR “Just Public Relations”?",
          "CSR and corporate governance links",
        ],
      },
      {
        title: "Best practice in CSR – corporate approaches on the key topics",
        contents: [
          "Codes of Practice",
          "Dealing with communities",
          "CSR and employees",
          "Managing suppliers responsibly",
          "Responsible client relations",
          "Corporate Responsibility and the environment",
        ],
      },
      {
        title: "The Business Case for CSR",
        contents: [
          "The Strategic Imperatives involved in CSR",
          "What is a business case?",
          "USING tools to build the business case",
          "Identifying and Managing CSR risks",
          "The public procurement challenges from CSR",
          "Getting and maintaining corporate support and approval",
        ],
      },
      {
        title: "Changing the Organisation to include CSR",
        contents: [
          "Developing the CSR corporate vision",
          "Setting our CSR strategic SMART objectives",
          "Identifying and Managing affected stakeholders",
          "How leaders make CSR happen",
          "The key steps to make the CSR change effective",
        ],
      },
      {
        title: "Developing and implementing the CSR plan",
        contents: [
          "What should be in the CSR plan?",
          "Communicating the plan",
          "Piloting the plan",
          "Overcoming objections",
          "Measuring impact and results",
          "Auditing and reviewing CSR performance",
          "Reporting on CSR",
        ],
      },
      {
        title: "Sustainability in Purchasing",
        contents: [
          "Managing a responsible Supply Chain",
          "The Ethical Trading Initiative Base Code",
          "Combating child labour in manufacturing",
          "Controlling contractor abuses in construction",
          "Maintaining ethical standards",
          "Diversity in the supply chain",
          "Conflict minerals policies",
        ],
      },
      {
        title: "Community Relations and Environmental Sustainability",
        contents: [
          "Working effectively with host communities",
          "Managing sensitive issues including payments and relocation",
          "Building successful community projects",
          "Assessing social and environmental risk",
          "Managing operations sustainably",
          "Case studies on community relations",
        ],
      },
      {
        title: "CSR Standards and Reporting",
        contents: [
          "Sustainability Reporting frameworks",
          "The Global Reporting Initiative",
          "UN Global Compact",
          "International LabourOrganisation instruments",
          "ISO 26000",
          "How to report your CSR progress",
          "Model examples and class exercise using GRI Sustainability Reporting",
        ],
      },
      {
        title: "CSR Perspectives",
        contents: [
          "The politics of CSR",
          "Creating shared value",
          "Ethical consumerism",
          "Greenwashing",
          "CSR and tax avoidance",
          "Applying Corporate Social Responsibility in non-corporate organisations",
          "Future requirements for corporate responsibility",
        ],
      },
    ],
    courseFess: "$5,580 ",
    category: "Public Relations",
    courseCode: "PR01",
  },
  {
    id: 101,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Advanced Protocol and Events Management",
    desc: "Manage protocol and events, efficiently and effectively - through strategic leadership, management and communication ",
    WhoShouldAttend: [
      "Protocol Senior Managers, Protocol Managers and Assistants",
      "Events - Senior Managers, Events - Project Planners, Conference Project Planners, Exhibition Managers and Meetings Managers",
      " Customer Relations Senior Managers and Managers",
      "Public Relations Senior Managers and Managers",
      "Communications Senior Managers and Communications Managers",
    ],
    courseExpectation: [
      "Manage the of protocol and event functions efficiently and effectively",
      "Apply the tips and avoid the traps of organising events",
      "Use communication skills to promote successful events",
      "Identify risk and understand how to minimise its impact",
      "Ensure that events are delivered on time and to budget",
    ],
    courseContent: [
      {
        title: "Managing the Protocol and Events Function",
        contents: [
          "Role and responsibilities of the functions, internal and external teams",
          "Creating streamlined plans, policies, systems, structures and procedures",
          "Keeping up - to - date nationally and internationally",
          "Record - keeping, monitoring, updating and maintaining",
          "Appropriate policies, processes and procedures according to protocol",
        ],
      },
      {
        title:
          "Exhibitions, Conferences, Visits and Events – National And International",
        contents: [
          "Planning and organising events, conferences and visits",
          "Organising, exhibiting at or attending exhibitions",
          "Trade visits and the protocol for them",
          "Greeting foreign dignitaries, flying flags correctly and meeting expectations",
          "Order of precedence for official ceremonies",
        ],
      },
      {
        title:
          "Communication Appropriate for Guests, Audiences and Stakeholders",
        contents: [
          "Written communication to prevent misunderstandings",
          "Oral communication including talking, listening and understanding",
          "Body language, facial expressions and gestures to prevent offence",
          "Organisational communication: media, photography, seating, timing, speeches",
          "Cultural: demonstrating  respect for national and international protocol",
        ],
      },
      {
        title: "Risk and Crisis Management for Different Events",
        contents: [
          "Identifying all the possible risks for an event",
          "Evaluation of likelihood and impact",
          "Seven ways to deal with risk",
          "Creating and rehearsing contingency plans",
        ],
      },
      {
        title: "Budgeting and Cost Control",
        contents: [
          "Budgeting accurately and meaningfully",
          " Monitoring authorisation, approvals, expenditure, allowances, expenses and receipts",
          "Monitoring expenditure against budget",
          "Cancellations, claims, reimbursements, hospitality",
          "Insurance which is robust, appropriate and up - to - date",
        ],
      },
      {
        title: "Project Management in Protocol and Events",
        contents: [
          "Project management principles and approach applied to events",
          "Measuring and monitoring the effectiveness of the planning and the resulting event",
          "Managing and maintaining the quality of the range of external suppliers",
          "Resources required to manage events  efficiently",
          "Role and responsibilities before, during and after the event",
        ],
      },
      {
        title: "PR and Marketing for Successful Events",
        contents: [
          "Identifying target markets and objectives",
          "Clarifying message for each market",
          "Appropriate channels to reach guests, audiences and stakeholders",
          "Promoting organisation’s brand and enhancing its reputation",
          "Evaluation, feedback and lessons learned",
        ],
      },
      {
        title: "Building and Maintaining Strong Relationships",
        contents: [
          "Principles of durable relationships",
          "Building strong relations with your internal clients, suppliers, agents and contacts",
          "Building diplomatic relationships with Embassies and Missions",
          "Cultural etiquette in business, social, entertaining, dress, giving and receiving presents",
          "Promoting cross cultural understanding of history and traditions",
        ],
      },
      {
        title: "Meetings at Home and Abroad",
        contents: [
          "Pre-meeting preparation including seating, translator,  interpreters, and timings",
          "Chairing, participating, agenda, minutes, follow - up and action plans",
          "Order of dignitaries for entering a room, introductions, talking and making presentations",
          " Conflict resolution, decision - making and problem - solving",
        ],
      },
    ],
    courseFess: "$5,580 ",
    category: "Public Relations",
    courseCode: "PR02",
  },
  {
    id: 102,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Advanced Protocol and Travel Management",
    desc: " Plan and lead high profile successful events, exhibitions, conferences and travel; and strategically manage the protocol function",
    WhoShouldAttend: [
      "Travel directors, managers, supervisors, team leaders, executives and assistants",
      "Protocol directors, managers, supervisors, team leaders, executives and assistants",
      "PR directors, managers, supervisors, team leaders, executives, and assistants who would like an understanding of the role of protocol and travel management in the promotion of the positive corporate image of their organisation",
      "International relations managers and directors",
      " All those who are involved in the welcoming of visitors to their country or sending their staff overseas",
    ],
    courseExpectation: [
      "Revise, refresh and develop skills for efficient protocol and travel management",
      "Organise national and international travel for your staff and for visitors to your country",
      "Plan international exhibitions, visits and events",
      "Strengthen relationships and develop powerful communication skills",
      "Ensure that meetings at home and abroad achieve objectives",
      "Create realistic budgets and control costs",
      "Reduce the impact of unexpected emergencies and crises",
    ],
    courseContent: [
      {
        title: "Managing the Protocol and Travel function",
        contents: [
          "Role and responsibilities of the function",
          " Creating streamlined policies, systems, structure and procedures",
          "Keeping up - to - date with changes in rules and regulations nationally and internationally",
          "Record- keeping, monitoring, updating and maintaining",
          "Applying all policies, processes and procedures fairly to all",
        ],
      },
      {
        title: "The Right Documents, at the Right Time, in the Right Place",
        contents: [
          "Organising passports, processing visas and overcoming the complexities and problems",
          "Compliance with entry requirements and immigration rules",
          " Ensuring the health, safety and security of staff and visitors",
          " Booking tickets; timetables; time differences; holidays; cancellations",
          "Airport procedures, requirements and duties",
        ],
      },
      {
        title: "Accommodation and International Travel Requirements",
        contents: [
          "Factors to chose and book the most appropriate hotel and rooms",
          "Customer and protocol requirements; preferred hotels list; change in plans; families",
          " Car transport; tolls; parking; rental; security; motorcades",
          "Meeting the demands of business travel: payment methods and exchange rates",
          "Methods to research all possible options and prioritise the best",
        ],
      },
      {
        title:
          "Exhibitions, Conferences, Visits and Events – Nationally and Internationally",
        contents: [
          "Planning and organising events, conferences and visits",
          "Organising, exhibiting at or attending exhibitions",
          " Trade visits and the protocol for them",
          "Greeting foreign dignitaries, flying flags correctly and meeting expectations",
          "Order of precedence for official ceremonies",
        ],
      },
      {
        title: "Budgeting and Cost Control",
        contents: [
          "Budgeting accurately and meaningfully",
          "Monitoring authorisation, approvals, expenditure, allowances, expenses and receipts",
          "Cost control for ensuring that expenses are legitimate",
          "Cancellations, claims, reimbursements, hospitality",
          "Insurance which is robust, appropriate and up - to - date",
        ],
      },
      {
        title: "Building and Maintaining Strong Relationships",
        contents: [
          "Principles of durable relationships",
          "Building strong relations with your internal clients; suppliers, agents and contacts",
          "Building relationships with Embassies and Missions",
          " Cultural etiquette in business, social, entertaining, dress, giving and receiving presents",
          "Promoting cross cultural understanding of history and traditions",
        ],
      },
      {
        title: "Communication Skills Required",
        contents: [
          "Qualities required: questioning and active listening",
          "Dealing with difficult people",
          "Negotiating win - win deals with suppliers, agents and contacts while maintaining relationship and quality of service",
          " Communication in a crisis, emergency or disruption",
          "Turning complaints into opportunities",
        ],
      },
      {
        title: "Meetings at Home and Abroad",
        contents: [
          "Pre-meeting preparation including seating, interpreters, and timings",
          "Chairing, participating and preparing agenda",
          "Order of dignitaries for entering a room, introductions, talking and making presentations",
          "Conflict resolution, decision - making and problem - solving",
        ],
      },
      {
        title: "Risks – Disruption to Travel: Pro-active Management",
        contents: [
          "Identification, evaluation and management of threats and risks",
          "Creating contingency plans for emergencies",
          " Disaster recovery planning, implementing and practice",
          "Ensuring continuity throughout disruption",
          " Planning for unexpected crises",
        ],
      },
    ],
    courseFess: "$5,580 ",
    category: "Public Relations",
    courseCode: "PR03",
  },
  {
    id: 103,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Advanced Strategic Public Relations Management",
    desc: "Promote and protect your organisation's reputation: strategic planning and leadership for quality Public Relations ",
    WhoShouldAttend: [
      "PR Directors",
      "PR Managers",
      " Marketing Directors",
      "Marketing Managers",
      "Company Directors",
      "Those in PR in public sector, private sector and not -for-profit organisations",
      "Those in PR at the national, regional and community level",
      "Those working for international, global or supranational organisations",
      "Advisors at Ministerial level in government departments",
      "Advisors to Directors and top Managers in other organisations",
      "Owners or account directors in public relations consultancies",
    ],
    courseExpectation: [
      "Lead and project manage a successful PR campaign ",
      " Apply PR techniques, methodologies, and tools effectively",
      "Identify and capitalise on PR opportunities",
      "Overcome challenges and minimise risks",
      "Maximise the Return on Investment(ROI)",
    ],
    courseContent: [
      {
        title: "What you are promoting?",
        contents: [
          "PR to promote organisation’s vision, mission and strategy",
          "Benefits of products and services",
          "Target messages for specific audiences, stakeholders and markets",
          " Objective measures of success: KPIs",
          "Return on Investment: ROI",
        ],
      },
      {
        title: "Social media and multi-media",
        contents: [
          "Understanding the scope, power and immediacy of these media",
          "Monitoring what is said, where and by whom",
          "Responding to negative feedback positively",
          "Pro - actively participating in social and multi - media",
          "Dos and don’ts",
        ],
      },
      {
        title: "Five steps for a successful PR plan",
        contents: [
          "Agree your objectives, set targets and quantify results",
          "Appropriate channels, methods and mix",
          "Maximise budget and resources",
          " Recruit and manage your PR team: internal and external",
          "Effective project management of the implementation",
        ],
      },
      {
        title: "Media relations",
        contents: [
          "What do you want from editors, journalists and their media?",
          "What do they need from you ?",
          " Understand the pressures and deadlines they are under",
          "Work together to build long - term durable relationships",
          "Ten top tips for developing lasting relationships",
        ],
      },
      {
        title: "Press conferences and interviews",
        contents: [
          "Managing press conferences and dealing with journalists’ enquiries",
          "Giving winning television interviews",
          "Radio interviews with impact",
          " Handle difficult questions on the telephone",
          " Avoid the 5 most common mistakes",
        ],
      },
      {
        title: "Planning a successful PR campaign",
        contents: [
          "Agree strategic objectives globally, nationally, regionally, locally",
          " Appropriate communication for each market, stakeholder and audience",
          "Objectives measures of success and desired outcomes",
          " Project management of implementation of plan",
          "Anticipate and prevent problems",
        ],
      },
      {
        title: "Advising and developing senior people",
        contents: [
          "Advising politicians, senior civil servants, directors and managers",
          "Give winning presentations",
          "Public relations challenges as opportunities",
          "Harnessing creative conflict into productive output",
          "Budgeting and resource management",
        ],
      },
      {
        title: "Building a reputation",
        contents: [
          "Clarify the key elements which comprise reputation",
          "Strategy to maintain a long - term reputation",
          "Minimise threats to reputation",
          "Defend reputation when under attack",
          " Rebuild reputation after it has been undermined or discredited",
        ],
      },
      {
        title: "Issues and crisis management",
        contents: [
          "Anticipate sources of crises and mitigate risks",
          "Appoint crisis leaders and teams and allocate resources",
          "Create crisis plans for key eventualities",
          "Practise crisis plans regularly",
          "Case studies of impact on organisations of good and poor PR crisis management",
        ],
      },
    ],
    courseFess: "$5,580 ",
    category: "Public Relations",
    courseCode: "PR04",
  },
  {
    id: 104,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Corporate Social Responsibility - Level 1",
    desc: " Corporate Social Responsibility - key features and requirements",
    WhoShouldAttend: [
      "	Executives involved in strategic and operational functions, including finance, corporate strategy, human resources, supplier or community relations, and government affairs",
      "Senior managers involved in setting up corporate governance or CSR initiatives",
      "Corporate lawyers who wish to improve their understanding of new company law developments concerning corporate responsibility",
      "Stakeholder managers responsible for their organisation’s dealings with the community, their employees and elsewhere",
      "Managers involved in developing, implementing and monitoring change programmes to improve standards of corporate behaviour and business conduct",
    ],
    courseExpectation: [
      "	Appreciate why corporate social responsibility is important in the public and private sectors",
      " Examine how organisationalbehaviour is impacted by CSR",
      "Develop skills to initiate or improve CSR programmes in their organisations",
      "Understand the legal duties company directors must observe concerning CSR",
      "Examine examples of good and bad business behaviour across different industrial sectors",
      "Explore how business ethics, corporate social responsibility and corporate governance are converging",
      " Develop the components of a CSR action plan for their own organisations",
    ],
    courseContent: [
      {
        title:
          "What is Corporate Social Responsibility and why does it matter?",
        contents: [
          "	The pressure for change",
          "The Legal Background of CSR",
          "Company directors’ obligations and CSR",
          "Voluntary measures",
          "Is CSR “Just Public Relations”?",
          "CSR and corporate governance links",
        ],
      },
      {
        title: "Best practice in CSR – corporate approaches on the key topics",
        contents: [
          "	Codes of Practice",
          "Dealing with communities",
          "CSR and employees",
          "Managing suppliers responsibly",
          "Responsible client relations",
          "Corporate Responsibility and the environment",
        ],
      },
      {
        title: "The Business Case for Corporate Social Responsibility",
        contents: [
          "The Strategic Imperatives involved in CSR",
          "What is a business case?",
          "USING tools to build the business case",
          "Identifying and Managing CSR risks",
          "The public procurement challenges from CSR",
          "Getting and maintaining corporate support and approval",
        ],
      },
      {
        title: "Changing the Organisation to include CSR",
        contents: [
          "	Developing the CSR corporate vision",
          "Setting our CSR strategic SMART objectives",
          "Identifying and Managing affected stakeholders",
          "How leaders make CSR happen",
          "The key steps to make the CSR change effective",
        ],
      },
      {
        title: "Developing and implementing the CSR plan",
        contents: [
          "	What should be in the CSR plan?",
          "Communicating the plan",
          "Piloting the plan",
          "Overcoming objections",
          "Measuring impact and results",
          "Auditing and reviewing CSR performance",
          "Reporting on CSR",
        ],
      },
    ],
    courseFess: "4840",
    category: "Public Relations",
    courseCode: "PR05",
  },
  {
    id: 105,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Corporate Social Responsibility - Level 2",
    desc: " Promote community relations and environmental sustainability through Corporate Social Responsibility - your organisation's contribution to enhancing society",
    WhoShouldAttend: [
      "	Corporate Affairs managers and officers",
      "CSR managers and officers",
      "Corporate strategists",
      "Community Relations managers and officers",
      "HR, Administration and Environmental managers concerned with CSR planning and implementation",
      "Project managers responsible for developing and implementing CSR projects",
      "Communications managers and officers responsible for internal and external reporting on sustainability",
    ],
    courseExpectation: [
      "	Understand how Corporate Social Responsibility is important in the public and private sectors",
      "Develop and Improve their community relations activities",
      "Interpret the impact of CSR on the reputation of the organisation",
      "Plan, initiate or improve CSR programmes",
      "Report on their sustainability programmes",
    ],
    courseContent: [
      {
        title:
          "he Significance and Relevance of Corporate Social Responsibility",
        contents: [
          "	What is the scope of CSR?",
          "Who is involved? - CSR stakeholders",
          "What are the risks of ignoring corporate social responsibilities ?",
          "Comparing the roles of Public and Private Organisations in CSR",
          "Is CSR Just Good Public Relations ?",
        ],
      },
      {
        title: "Sustainability in Purchasing",
        contents: [
          "	Managing a responsible Supply Chain",
          "The Ethical Trading Initiative Base Code",
          "Combating child labour in manufacturing",
          "Controlling contractor abuses in construction",
          "Maintaining ethical standards",
          "Diversity in the supply chain",
          "Conflict minerals policies",
        ],
      },
      {
        title: "Community Relations and Environmental Sustainability",
        contents: [
          "	Working effectively with host communities",
          "Managing sensitive issues including payments and relocation",
          "Building successful community projects",
          "Assessing social and environmental risk",
          "Managing operations sustainably",
          "Case studies on community relations",
        ],
      },
      {
        title: "CSR Standards and Reporting",
        contents: [
          "	Sustainability Reporting frameworks",
          "The Global Reporting Initiative",
          "UN Global Compact",
          "International LabourOrganisation instruments",
          "ISO 26000",
          "How to report your CSR progress",
          "Model examples and class exercise using GRI Sustainability Reporting",
        ],
      },
      {
        title: "CSR Perspectives",
        contents: [
          "	The politics of CSR",
          "Creating shared value",
          "Ethical consumerism",
          "Greenwashing",
          "CSR and tax avoidance",
          "Applying Corporate Social Responsibility in non - corporate organisations",
          "Future requirements for corporate responsibility",
        ],
      },
    ],
    courseFess: "4840",
    category: "Public Relations",
    courseCode: "PR06",
  },
  {
    id: 107,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Media Relations",
    desc: " Create and develop strong, successful and durable media relations",
    WhoShouldAttend: [
      "	Corporate and government spokespersons",
      "Public Relations and Media Relations Managers",
      "Marketing and Communications Managers",
      "Owners and directors of PR consultancies",
      "CEOs, Directors and Staff who undertake media interviews",
    ],
    courseExpectation: [
      "	Appreciate how media works and benefit from effective media relations",
      "Identify story types and how they should be ‘pitched’ to editors",
      "Gauge the importance of stories and how the media will assess them",
      "Plan a successful press conference and give winning interviews",
      "Use interactive; social and digital media",
    ],
    courseContent: [
      {
        title: "How to Establish Successful Media Relations",
        contents: [
          "	Understanding the difference between media relations and public relations",
          "Establishing, managing and maintaining relationships with target media",
          "Appreciating news values and analysing reporters stories",
          "Who is who in the news structure; reporters, editors, desk journalists and others",
          "Importance of maintaining lists and data on target journalists",
        ],
      },
      {
        title: "Pitching Stories Successfully",
        contents: [
          "	Identifying reporters’ beats and understanding their importance",
          "How to pitch your stories to key journalists",
          "Recognising news deadlines and observing them",
          "Planning, setting out and writing successful press releases",
          "Understanding how and where reporters get their news material",
        ],
      },
      {
        title: "Identifying the Importance of Stories",
        contents: [
          "	Deciding whether a story is for all media or specific news outlets",
          "Gauging how important a story might be to media",
          "Gaining a reputation as a dependable source of information for reporters",
          "Following up your story with reporters",
          "Developing and utilising ‘tip’ sheets",
        ],
      },
      {
        title: "Press Conferences and Interviews",
        contents: [
          "	Planning, preparing, implementing and following up a press conference",
          "The difference between a press briefing and press conference",
          "Preparing for an interview and understanding the reason for it",
          "Adopting television skills for before during and after an interview",
          "Pitfalls and how to avoid no comment and off the record",
        ],
      },
      {
        title: "Techniques for Digital and Social Media ",
        contents: [
          "	24hr news requirements",
          "Use of online media",
          "Auditing and monitoring social media ",
          "Utilising ‘citizen’ reporting ",
          "Differences in approach to traditional and interactive media",
        ],
      },
    ],
    courseFess: "4840",
    category: "Public Relations",
    courseCode: "PR08",
  },
  {
    id: 108,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Protocol and Events Management - Level 1",
    desc: "The key steps to planning high profile successful events, exhibitions and conferences  ",
    WhoShouldAttend: [
      "	Protocol Officers, Protocol Executives and Assistants",
      "Event Officers, Events Executives, Conference Organisers, Exhibition Organisers and Meetings organisers and Assistants",
      "Customer Relations Officers, Customer Relations Executives and Assistants",
      "Public Relations Officers and Public Relations Executives",
      "Communications Officers, Communications Executives And Researchers",
    ],
    courseExpectation: [
      "	Manage the of protocol and event functions efficiently and effectively",
      "Apply the tips and avoid the traps of organising events",
      "Use communication skills to promote successful events",
      "Identify risk and understand how to minimise its impact",
      "Ensure that events are delivered on time and to budget",
    ],
    courseContent: [
      {
        title: "Managing The Protocol And Events Function",
        contents: [
          "	Role and responsibilities of the functions, internal and external teams",
          "Creating streamlined plans, policies, systems, structures and procedures",
          "Keeping up - to - date nationally and internationally",
          "Record - keeping, monitoring, updating and maintaining",
          "Appropriate policies, processes and procedures according to protocol",
        ],
      },
      {
        title:
          "Exhibitions, Conferences, Visits And Events – National And International",
        contents: [
          "	Planning and organising events, conferences and visits",
          "Organising, exhibiting at or attending exhibitions",
          "Trade visits and the protocol for them",
          "Greeting foreign dignitaries, flying flags correctly and meeting expectations",
          "Order of precedence for official ceremonies",
        ],
      },
      {
        title:
          "Communication Appropriate For Guests, Audiences and Stakeholders",
        contents: [
          "	Written communication to prevent misunderstandings",
          "Oral communication including talking, listening and understanding",
          "Body language, facial expressions and gestures to prevent offence",
          "Organisational communication: media, photography, seating, timing, speeches",
          "Cultural: demonstrating  respect for national and international protocol",
        ],
      },
      {
        title: "Risk and Crisis Management for Different Events",
        contents: [
          "	Identifying all the possible risks for an event",
          "Evaluation of likelihood and impact",
          "Seven ways to deal with risk",
          "Creating and rehearsing contingency plans",
          "Handling unexpected problems, crises and issues",
        ],
      },
      {
        title: "Budgeting and Cost Control",
        contents: [
          "	Budgeting accurately and meaningfully",
          "Monitoring authorisation, approvals, expenditure, allowances, expenses and receipts",
          "Monitoring expenditure against budget",
          "Cancellations, claims, reimbursements, hospitality",
          "Insurance which is robust, appropriate and up-to-date",
        ],
      },
    ],
    courseFess: "4840 ",
    category: "Public Relations",
    courseCode: "PR09",
  },
  {
    id: 109,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Protocol and Events Management - Level 2",
    desc: " Plan high profile successful events, exhibitions and conferences through efficient project management",
    WhoShouldAttend: [
      "Protocol Senior Managers, Protocol Managers and Assistants",
      "Events - Senior Managers, Events - Project Planners, Conference Project Planners, Exhibition Managers and Meetings Managers",
      "Customer Relations Senior Managers and Managers",
      "Public Relations Senior Managers and Managers",
      "Communications Senior Managers and Communications Managers",
    ],
    courseExpectation: [
      "	Manage protocol and events departments and teams",
      "Project manage successful events",
      "Develop and apply the core skills of PR and marketing for event promotion",
      "Build and maintain strong relationships",
      "Ensure that meetings at home and abroad achieve strategic objectives",
    ],
    courseContent: [
      {
        title: "Protocol and Event Management Role and Responsibilities",
        contents: [
          " Managing the protocol and events function",
          "Exhibitions, conferences, visits and events – national and international",
          "Communication appropriate for guests, audiences and stakeholders",
          "Risk and crisis management for different events",
          "Budgeting and cost control",
        ],
      },
      {
        title: "Project Management in Protocol and Events",
        contents: [
          "Project management principles and approach applied to events",
          "Measuring and monitoring the effectiveness of the planning and the resulting event",
          "Managing and maintaining the quality of the range of external suppliers",
          "Resources required to manage events efficiently",
          "Role and responsibilities before, during and after the event",
        ],
      },
      {
        title: "PR and Marketing for Successful Events",
        contents: [
          " Identifying target markets and objectives",
          "Clarifying message for each market",
          "Appropriate channels to reach guests, audiences and stakeholders",
          "Promoting organisation’s brand and enhancing its reputation",
          "Evaluation, feedback and lessons learned",
        ],
      },
      {
        title: "Building and Maintaining Strong Relationships",
        contents: [
          "Principles of durable relationships",
          "Building strong relations with your internal clients; suppliers, agents and contacts",
          "Building diplomatic relationships with Embassies and Missions",
          "Cultural etiquette in business, social, entertaining, dress, giving and receiving presents",
          "Promoting cross cultural understanding of history and traditions",
        ],
      },
      {
        title: "Meetings at Home and Abroad",
        contents: [
          " Pre-meeting preparation including seating, translators/interpreters, and timings",
          "Chairing, participating, agenda, minutes, follow-up and action plans",
          "Order of dignitaries for entering a room, introductions, talking and making presentations",
          "Conflict resolution, decision-making and problem-solving",
        ],
      },
    ],
    courseFess: "4840",
    category: "Public Relations",
    courseCode: "PR10",
  },
  {
    id: 110,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Protocol and Travel Management - Level 1",
    desc: " Key basic skills for managing protocol and travel functions",
    WhoShouldAttend: [
      "	Protocol Managers and Travel Managers, who are experienced but who would like a refresher",
      "Protocol Officers and Travel Officers, who would like to develop their expertise and skills",
      "Protocol Executives and Travel Executives who would like to develop their expertise and skills",
      "Events, exhibitions and meetings organisers to help to incorporate Protocol and Travel needs in their planning",
      "Public Relations and Communications Managers to promote knowledge and understanding of how Protocol and Travel Management contributes to successful Public Relations",
      "Public Relations Officers and Public Relations Executives to develop knowledge and understanding of the skills and what is required in the Protocol and Travel Management function",
      "Communications Officers and Communications Executives",
    ],
    courseExpectation: [
      "	Develop a strong travel management function",
      "Understand all aspects of protocol",
      "Ensure the delivery of quality service to all users in the most cost - effective way",
      "Understand the process, procedures and requirements for all documents",
      "Organise events that run smoothly",
      "Create budgets and apply cost control systems",
    ],
    courseContent: [
      {
        title: "Managing the Protocol and Travel function",
        contents: [
          "Role and responsibilities of the function",
          "Creating streamlined policies, systems, structure and procedures",
          "Keeping up - to - date with changes in rules and regulations nationally and internationally",
          "Record- keeping, monitoring, updating and maintaining",
          "Applying all policies, processes and procedures fairly to all",
        ],
      },
      {
        title: "The Right Documents, at the Right Time, in the Right Place",
        contents: [
          "Organising passports, processing visas and overcoming the complexities and problems",
          "Compliance with entry requirements and immigration rules",
          "Ensuring the health, safety and security of staff and visitors",
          "Booking tickets; timetables; time differences; holidays; cancellations",
          "Airport procedures, requirements and duties",
        ],
      },
      {
        title: "Accommodation and International Travel Requirements",
        contents: [
          "Factors to chose and book the most appropriate hotel and rooms",
          "Customer and protocol requirements; preferred hotels list; change in plans; families",
          "Car transport; tolls; parking; rental; security; motorcades",
          "Meeting the demands of business travel: payment methods and exchange rates",
          "Methods to research all possible options and prioritise the best",
        ],
      },
      {
        title:
          "Exhibitions, Conferences, Visits and Events – Nationally and Internationally",
        contents: [
          "Planning and organising events, conferences and visits",
          "Organising, exhibiting at or attending exhibitions",
          "Trade visits and the protocol for them",
          "Greeting foreign dignitaries, flying flags correctly and meeting expectations",
          "Order of precedence for official ceremonies",
        ],
      },
      {
        title: "Budgeting and Cost Control",
        contents: [
          "Budgeting accurately and meaningfully",
          "Monitoring authorisation, approvals, expenditure, allowances, expenses and receipts",
          "Cost control for ensuring that expenses are legitimate",
          "Cancellations, claims, reimbursements, hospitality",
          "Insurance which is robust, appropriate and up-to-date",
        ],
      },
    ],
    courseFess: "4840",
    category: "Public Relations",
    courseCode: "PR11",
  },
  {
    id: 111,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Protocol and Travel Management - Level 2",
    desc: "Manage protocol and travel, efficiently and effectively - by organised project management and risk mitigation ",
    WhoShouldAttend: [
      "	Travel directors, senior managers, operations managers and their deputies",
      "Protocol directors, senior managers, operations managers and their deputies",
      "PR directors, senior managers, managers and their deputies",
      "Heads of travel and protocol departments",
      "International relations directors and managers",
      "All those who are involved in the welcoming of visitors to their country or sending their staff overseas",
      "Those who have some experience of travel and protocol management but who would  like to develop a more strategic approach and understand its importance in that context",
    ],
    courseExpectation: [
      "	Revise, refresh and develop advanced skills for efficient protocol and travel management",
      "Organise national and international travel for staff and for visitors Strengthen relationships and develop powerful communication skills",
      "Ensure that meetings at home and abroad achieve objectives",
      "To reduce the impact of unexpected emergencies and crises",
    ],
    courseContent: [
      {
        title: "Project Management in Protocol and Travel",
        contents: [
          "	Protocol and travel management in the promotion of the positive corporate image of the organisation and helping to achieve vision and mission",
          "Monitoring the effectiveness of the function",
          "Managing the range of external suppliers",
          "Resources required to manage the function efficiently",
          "Role and responsibilities of the function communicated internally",
        ],
      },
      {
        title: "Building and Maintaining Strong Relationships",
        contents: [
          "	Principles of durable relationships",
          "Building strong relations with your internal clients; suppliers, agents and contacts",
          "Building relationships with Embassies and Missions",
          "Cultural etiquette in business, social, entertaining, dress, giving and receiving presents",
          "Promoting cross cultural understanding of history and traditions",
        ],
      },
      {
        title: "Communication Skills Required ",
        contents: [
          "	Qualities required: questioning and active listening",
          "Dealing with difficult people",
          "Negotiating win - win deals with suppliers, agents and contacts while maintaining relationship and quality of service",
          "Communication in a crisis, emergency or disruption",
          "Turning complaints into opportunities",
        ],
      },
      {
        title: "Meetings at Home and Abroad",
        contents: [
          "	Pre-meeting preparation including seating, interpreters, and timings",
          "Chairing, participating and preparing agenda",
          "Order of dignitaries for entering a room, introductions, talking and making presentations",
          "Conflict resolution, decision - making and problem - solving",
        ],
      },
      {
        title: "Risks – Disruption to Travel: Pro-active Management",
        contents: [
          "	Identification, evaluation and management of threats and risks",
          "Creating contingency plans for emergencies",
          "Disaster recovery planning, implementing and practice",
          "Ensuring continuity throughout disruption",
          "Planning for unexpected crises",
        ],
      },
    ],
    courseFess: "4840",
    category: "Public Relations",
    courseCode: "PR12",
  },
  {
    id: 112,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Public Relations Management - Level 2",
    desc: " Public Relations - preparing and implementing a PR plan",
    WhoShouldAttend: [
      "	PR Managers",
      "	PR Assistants",
      "PR Executives",
      "Marketing Managers",
      "Marketing Assistants",
      "Marketing Executives",
      "Those in PR in public sector, private sector and not -for-profit organisations",
      "Those in PR at the national, regional and community level",
      "Those working for international, global or supranational organisations",
      "Those who need to understand the rapidly changing media environment",
    ],
    courseExpectation: [
      "	Strengthen current management, structures, methods and techniques of PR",
      "Create a successful PR plan ",
      "Implement plan effectively",
      "Maximise the Return on Investment(ROI) of PR",
      "Build strong and durable relationships with all media ",
    ],
    courseContent: [
      {
        title: "What you are promoting?",
        contents: [
          "	PR to promote organisation’s vision, mission and strategy",
          "Benefits of products and services",
          "Target messages for specific audiences, stakeholders and markets",
          "Objective measures of success: KPIs",
          "Return on Investment: ROI",
        ],
      },
      {
        title: "Social media and multi-media",
        contents: [
          "	Understanding the scope, power and immediacy of these media",
          "Monitoring what is said, where and by whom",
          "Responding to negative feedback positively",
          "Pro - actively participating in social and multi - media",
          "Dos and don’ts",
        ],
      },
      {
        title: "Five steps for a successful PR plan",
        contents: [
          "	Agree your objectives, set targets and quantify results",
          "Appropriate channels, methods and mix",
          "Maximise budget and resources",
          "Recruit and manage your PR team: internal and external",
          "Effective project management of the implementation",
        ],
      },
      {
        title: "Media relations",
        contents: [
          "	What do you want from editors, journalists and their media?",
          "What do they need from you ?",
          "Understand the pressures and deadlines they are under",
          "Work together to build long - term durable relationships",
          "Ten top tips for developing lasting relationships",
        ],
      },
      {
        title: "Press conferences and interviews",
        contents: [
          "	Managing press conferences and dealing with journalists’ enquiries",
          "Giving winning television interviews",
          "Radio interviews with impact",
          "Handle difficult questions on the telephone",
          "Avoid the 5 most common mistakes",
        ],
      },
    ],
    courseFess: "4840",
    category: "Public Relations",
    courseCode: "PR13",
  },
  {
    id: 113,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Public Relations Management - Level 3",
    desc: " Public Relations - campaigns and crisis management to protect your company's reputation",
    WhoShouldAttend: [
      "	Directors of Public Relations",
      "Directors of Marketing",
      "Senior Public Relations Managers",
      "Company Directors",
      "General Managers",
      "Senior Managers",
      "Advisors at Ministerial level in government departments",
      "Advisors to Directors and top Managers in other organisations",
      "Those in public sector, private sector and not -for-profit organisations",
      "Those at the national, regional and community level",
      "Those working for international, global or supranational organisations",
      "Owners or account directors in public relations consultancies",
    ],
    courseExpectation: [
      "	Identify and overcome key PR challenges",
      "Use appropriately all methodologies",
      "Identify PR risks and manage or mitigate them",
      "Plan a successful PR campaign",
      "Understand how to manage a crisis constructively",
    ],
    courseContent: [
      {
        title: "Importance of internal PR for your organisation",
        contents: [
          "	What employees expect from you",
          "All the different ways of communicating with them",
          "Information they want to know",
          "Who should send those messages?",
          "Appropriate methods and frequency",
        ],
      },
      {
        title: "Planning a successful PR campaign",
        contents: [
          "	Agree strategic objectives globally, nationally, regionally, locally",
          "	Appropriate communication for each market, stakeholder and audience",
          "Objectives measures of success and desired outcomes",
          "Project management of implementation of plan",
          "Anticipate and prevent problems",
        ],
      },
      {
        title: "Advising and developing senior people",
        contents: [
          "	Advising politicians, senior civil servants, directors and managers",
          "Give winning presentations",
          "Public relations challenges as opportunities",
          "Harnessing creative conflict into productive output",
          "Budgeting and resource management",
        ],
      },
      {
        title: "Building a reputation",
        contents: [
          "	Clarify the key elements which comprise reputation",
          "Strategy to maintain a long - term reputation",
          "Minimise threats to reputation",
          "Defend reputation when under attack",
          "Rebuild reputation after it has been undermined or discredited",
        ],
      },
      {
        title: "Issues and crisis management",
        contents: [
          "	Anticipate sources of crises and mitigate risks",
          "Appoint crisis leaders and teams and allocate resources",
          "Create crisis plans for key eventualities",
          "Practise crisis plans regularly",
          "Case studies of impact on organisations of good and poor PR crisis management",
        ],
      },
    ],
    courseFess: "4840",
    category: "Public Relations",
    courseCode: "PR14",
  },
  {
    id: 114,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Public Relations Management - Level 1",
    desc: "Public Relations - the key basic skills to promote your organization ",
    WhoShouldAttend: [
      "	Those new to PR Management",
      "Those who need to understand the rapidly changing media environment",
      "PR Assistants",
      "PR Executives",
      "PR Researchers",
      "Marketing Assistants",
      "Marketing Executives",
      "Those in PR in public sector, private sector and not -for-profit organisations",
      "Those in PR at the national, regional and community level",
      "Those working for international, global or supranational organisations",
    ],
    courseExpectation: [
      "	Practise PR successfully in their organisation",
      "Assess the range of approaches, methods and techniques required",
      "Understand the markets and audiences and their different PR requirements ",
      "To write appropriately for all media ",
      "Manage PR events and activities",
    ],
    courseContent: [
      {
        title: "What is PR - Public Relations?",
        contents: [
          "	Objectives and key elements of Public Relations",
          "Importance, benefits and challenges of Public Relations management",
          "How PR differs from marketing, advertising and sales",
          "Advantages and disadvantages of all PR options",
          "Importance of PR internally",
        ],
      },
      {
        title: "PR channels",
        contents: [
          "	Print media and its current and likely future role in PR",
          "Television and the impact it can have",
          "Radio - relying on voice only",
          "Mobile technology and increasing accessibility",
          "Social media and multi - media - growth, complexity and impact",
        ],
      },
      {
        title: "Writing for all media",
        contents: [
          "	What makes a good story?",
          "How to win and keep reader’s attention",
          "Writing for print: marketing materials, news, features, editorial and letters",
          "Press releases which are published not ignored",
          "Writing effectively for electronic media",
        ],
      },
      {
        title: "Target markets",
        contents: [
          "	Requirements of target audiences and stakeholders",
          "What are your competitors doing and how successfully ?",
          "15 different types of research to find out the answers",
          "Identifying the most appropriate way to reach your audiences",
          "Pitfalls to avoid on messages and content",
        ],
      },
      {
        title: "PR events and activities",
        contents: [
          "	Organising, preparing for and delivering presentations",
          "Visits to your organisation by media and journalists",
          "Lobbying: advantages, risks and impact",
          "Sponsorship: maximising return on investment (ROI)",
          "Corporate social responsibility",
        ],
      },
    ],
    courseFess: "4840",
    category: "Public Relations",
    courseCode: "PR15",
  },
  {
    id: 116,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Strategic Marketing, Brand Management and Public Relations",
    desc: "Promote your organisation with strategic marketing and brand management ",
    WhoShouldAttend: [
      "	Directors of Marketing",
      "Marketing Managers",
      "Brand Directors",
      "Brand Managers",
      "Directors of Public Relations",
      "Senior Public Relations Managers",
      "Company Directors",
      "Senior Managers",
      "Those who would like to strengthen, develop and enhance their Strategic Marketing skills",
      "Advisors at Ministerial level in government departments",
      "Advisors to Directors and top Managers",
      "Owners or account directors in public relations consultancies",
    ],
    courseExpectation: [
      "	Plan, implement and obtain the desired results from all Strategic Marketing and PR",
      "Obtain the highest return on investment(ROI) from the Strategic Marketing",
      "Create, promote, protect and manage the organisation’s brand, products and services",
      "Apply the appropriate approach, structures, methods and techniques of PR",
      "Utilise the rapidly changing media environment",
    ],
    courseContent: [
      {
        title: "Benefits of Strategic Marketing",
        contents: [
          "	What is Strategic Marketing?",
          "Objectives and key elements of Strategic Marketing",
          "Importance, benefits and challenges of Strategic Marketing to your organisation",
          "Marketing positioning, market analysis and competitor evaluation",
          "The Marketing Mix - 4 Ps evolving into 7 Ps",
        ],
      },
      {
        title: "Consumer and market research",
        contents: [
          "	Advantages and disadvantages of 15 types of research",
          "Identify, analyse, segment and target appropriate markets according to common factors",
          "Create powerful, targeted and appropriate messages",
          "Maximise the mix of communication methods",
          "Behavioural economics and consumer behaviour theory in marketing",
        ],
      },
      {
        title: "Brand Management",
        contents: [
          "	What is a brand, its key characteristics and why do people buy a brand?",
          "Create, protect, enhance and maintain long - term brand value",
          "Evaluate and choose the most appropriate media and mix to promote the brand",
          "Defend your brand when it is under attack: top tips and traps to avoid",
          "Rebuild your brand after it has been undermined or discredited",
        ],
      },
      {
        title: "Social media and multi-media",
        contents: [
          "	Understanding the scope, power, complexity and immediacy",
          "Monitoring what is said, where and by whom",
          "Responding to feedback",
          "Pro - actively participating in social and multi - media",
          "Dos and don’ts",
        ],
      },
      {
        title: "Issues and crises: PR to manage impact on the brand",
        contents: [
          "	Anticipate potential sources of a crisis and mitigate the risks",
          "Appoint crisis leaders, teams and allocate resources required",
          "Create crisis plans for key eventualities",
          "Practise regularly for the crisis to minimise effect if it happens",
          "Case studies of impact on organisations of good and poor PR crisis management",
        ],
      },
    ],
    courseFess: "4840",
    category: "Public Relations",
    courseCode: "PR17",
  },
  {
    id: 117,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Strategic Public Relations Management",
    desc: "Management of strategic public relations projects to develop and protect your company's reputation ",
    WhoShouldAttend: [
      "PR Managers",
      "PR Assistants",
      "PR Executives",
      "Marketing Managers",
      "Marketing Assistants",
      "Marketing Executives",
      "Those in PR in public sector, private sector and not -for-profit organisations",
      "Those in PR at the national, regional and community level",
      "Those working for international, global or supranational organisations",
    ],
    courseExpectation: [
      "Create an appropriate PR strategy",
      "Plan and manage the approach and methods",
      "Overcome the challenges of implementation",
      "Measure the results and impact – Key Performance Indicators(KPIs)",
      "Maximise the Return on Investment(ROI)",
    ],
    courseContent: [
      {
        title: "What is PR - Public Relations?",
        contents: [
          "Objectives and key elements of Public Relations",
          "	Importance, benefits and challenges of Public Relations management",
          "How PR differs from marketing, advertising and sales",
          "Advantages and disadvantages of all PR options",
          "Importance of PR internally",
        ],
      },
      {
        title: "PR channels",
        contents: [
          "Print media and its current and likely future role in PR",
          "Television and the impact it can have",
          "Radio - relying on voice only",
          "Mobile technology and increasing accessibility",
          "Social media and multi - media - growth, complexity and impact",
        ],
      },
      {
        title: "Writing for all media",
        contents: [
          "What makes a good story?",
          "How to win and keep reader’s attention",
          "Writing for print: marketing materials, news, features, editorial and letters",
          "Press releases which are published not ignored",
          "Writing effectively for electronic media",
        ],
      },
      {
        title: "Target markets",
        contents: [
          "Requirements of target audiences and stakeholders",
          "What are your competitors doing and how successfully ?",
          "15 different types of research to find out the answers",
          "Identifying the most appropriate way to reach your audiences",
          "Pitfalls to avoid on messages and content",
        ],
      },
      {
        title: "PR events and activities",
        contents: [
          "Organising, preparing for and delivering presentations",
          "Visits to your organisation by media and journalists",
          "Lobbying: advantages, risks and impact",
          "Sponsorship: maximising return on investment(ROI)",
          "Corporate social responsibility",
        ],
      },
      {
        title: "Social media and multi-media",
        contents: [
          "Understanding the scope, power and immediacy of these media",
          "Monitoring what is said, where and by whom",
          "Responding to negative feedback positively",
          "Pro - actively participating in social and multi - media",
          "Dos and don’ts",
        ],
      },
      {
        title: "Five steps for a successful PR plan",
        contents: [
          "Agree your objectives, set targets and quantify results",
          "Appropriate channels, methods and mix",
          "Maximise budget and resources",
          "Recruit and manage your PR team: internal and external",
          "Effective project management of the implementation",
        ],
      },
      {
        title: "Media relations",
        contents: [
          "What do you want from editors, journalists and their media?",
          "What do they need from you ?",
          "Understand the pressures and deadlines they are under",
          "Work together to build long - term durable relationships",
          "Ten top tips for developing lasting relationships",
        ],
      },
      {
        title: "Press conferences and interviews",
        contents: [
          "Managing press conferences and dealing with journalists’ enquiries",
          "Giving winning television interviews",
          "Radio interviews with impact",
          "Handle difficult questions on the telephone",
          "Avoid the 5 most common mistakes",
        ],
      },
    ],
    courseFess: "6080",
    category: "Public Relations",
    courseCode: "PR18",
  },
  {
    id: 118,
    image:
      "https://cdn.pixabay.com/photo/2023/11/10/17/00/mountains-8379756_1280.jpg",
    title: "Strategic Public Relations, Protocol and Travel Management",
    desc: "Strategic Protocol and Travel Management - enhance your organisation's profile internationally ",
    WhoShouldAttend: [
      "	Protocol officers",
      "Travel offices",
      "Travel executives",
      "Public relations officers",
      "Public relations executives",
      "Public Relations researchers",
      "Those responsible for protocol, travel and accommodation",
      "Those in PR in public sector, private sector and not -for-profit organisations",
      "Those in PR at the national, regional and community level",
      "Those working for international, global or supranational organisations",
    ],
    courseExpectation: [
      "	Build on existing knowledge of Strategic Public Relations",
      "Apply the tactical skills required for Strategic Public Relations",
      "Manage the key aspects of protocol ",
      "Apply tips, tools and techniques to arrange smooth business travel",
      "Meet the expectation of those involved in protocol and travel arrangements",
    ],
    courseContent: [
      {
        title: "What is PR – Public Relations?",
        contents: [
          "	Objectives and key elements of Public Relations",
          "Importance, benefits and challenges of Public Relations management",
          "How PR differs from marketing, advertising and sales",
          "Advantages and disadvantages of all PR options",
          "Importance of PR internally",
        ],
      },
      {
        title: "PR channels",
        contents: [
          "	Print media and its current and likely future role in PR",
          "Television and the impact it can have",
          "Radio - relying on voice only",
          "Mobile technology and increasing accessibility",
          "Social media and multi - media - growth, complexity and impact",
        ],
      },
      {
        title: "Writing for all media",
        contents: [
          "	What makes a good story?",
          "How to win and keep reader’s attention",
          "Writing for print: marketing materials, news, features, editorial and letters",
          "Press releases which are published not ignored",
          "Writing effectively for electronic media",
        ],
      },
      {
        title: "Target markets",
        contents: [
          "	Requirements of target audiences and stakeholders",
          "What are your competitors doing and how successfully ?",
          "15 different types of research to find out the answers",
          "Identifying the most appropriate way to reach your audiences",
          "Pitfalls to avoid on messages and content",
        ],
      },
      {
        title: "PR events and activities",
        contents: [
          "	Organising, preparing for and delivering presentations",
          "Visits to your organisation by media and journalists",
          "Lobbying: advantages, risks and impact",
          "Sponsorship: maximising return on investment(ROI)",
          "Corporate social responsibility",
        ],
      },
      {
        title: "The right documents, at the right time, in the right place",
        contents: [
          "	Organising passports, processing visas and overcoming the complexities and problems",
          "Compliance with entry requirements and immigration rules",
          "Ensuring the health, safety and security of staff and visitors",
          "Booking tickets; timetables; time differences; holidays; cancellations",
          "Airport procedures, requirements and duties",
        ],
      },
      {
        title: "Accommodation and international travel requirements",
        contents: [
          "	Factors to chose and book the most appropriate hotel and rooms",
          "Customer and protocol requirements; preferred hotels list; change in plans; families",
          "Car transport; tolls; parking; rental; security; motorcades",
          "Meeting the demands of business travel: payment methods and exchange rates",
          "Methods to research all possible options and prioritise the best",
        ],
      },
      {
        title:
          "Exhibitions, conferences, visits and events – nationally and internationally",
        contents: [
          "	Planning and organising events, conferences and visits",
          "Organising, exhibiting at or attending exhibitions",
          "Trade visits and the protocol for them",
          "Greeting foreign dignitaries, flying flags correctly and meeting expectations",
          "Order of precedence for official ceremonies",
        ],
      },
      {
        title: "Budgeting and cost control",
        contents: [
          "	Budgeting accurately and meaningfully",
          "Monitoring authorisation, approvals, expenditure, allowances, expenses and receipts",
          "Cost control for ensuring that expenses are legitimate",
          "Cancellations, claims, reimbursements, hospitality",
          "Insurance which is robust, appropriate and up-to-date",
        ],
      },
    ],
    courseFess: "6080",
    category: "Public Relations",
    courseCode: "PR19",
  },
  // Public Relations

  // Sales and Marketing
  {
    id: 119,
    image: Sales1,
    title: "Advanced Sales Skills",
    desc: "Strategic sales planning, leadership and management, to maximize sales and profit ",
    WhoShouldAttend: [
      "Sales Executives",
      "Sales Managers, Operations Managers and Account Managers",
      "Sales Directors / Operational Directors",
      "Managers / Directors moving more into a strategic business development role",
    ],
    courseExpectation: [
      "Refresh and develop key sales development skills",
      "Improve sales management methods and empower your team to succeed",
      "Drive sales performance, optimize the sales funnel and improve sales performance and results",
      "Create and give excellent sales presentations and pitches",
      "Develop and manage strategic key accounts and strengthen loyalty",
      "Analyze buyer motivation and sales psychology and its link to market positioning",
      "Overcome objections and win over skeptical buyers",
      "Improve communication and influencing skills",
      " Move towards  ‘consultative solution focused selling’",
      "Build lasting relationship with clients",
      "Identify the root causes of issues with clients and offer the best solutions / services",
      " Develop strategic customer relationship management",
      "Create a personal development plan",
    ],
    courseContent: [
      {
        title: "Selling – An Art or a Science",
        contents: [
          "Through interactive learning delegates will explore the factors that make excellent sales people",
          "How to raise personal standards in order to encourage profitability",
          "Do you use a ‘hunter’ or a ‘farmer’ selling style ?",
          "The background of selling and defining your role as part of the organization’s mission",
          "How to use persuasion without crossing boundaries",
        ],
      },
      {
        title: "Effective Planning and Prioritizing",
        contents: [
          "Account analysis, planning and time management",
          "How to plan your territory more productively",
          " Prioritizing prospects well to ensure more consistent sales conversion ratio",
          " Meeting and diary management and increasing opportunities for new business",
          " Strategies for hitting and surpassing your targets",
          "Researching into client, the global, market and customer spheres",
          "Identify key trends in the marketplace",
        ],
      },
      {
        title: "Making Lasting Impressions",
        contents: [
          "Tuning in to your client’s mindset and building trust",
          "Generate influence through matching body language and increased personal credibility",
          " Apply the ‘Aristotle Principle of Persuasion’",
          "Becoming a positive reference top your client and building more loyalty and sales compared to your competitors",
          "Becoming a better communicator using positive unconditional regard(Rogers and Carnegie)",
          "Personal psychometric profiling",
        ],
      },
      {
        title: "Overcoming Objections",
        contents: [
          "How to deal with client objections and still get the sale",
          "7 steps to maintain calm in adverse selling situations",
          "How to use objections as a basis to develop the sale into a mutual beneficial outcome",
          "Maintaining professionalism during the sales process to create a long term focus while gaining short and mid - term wins",
          "Dealing with client excuses of not buying and delaying strategies",
        ],
      },
      {
        title: "Winning the Business",
        contents: [
          "10 closing styles to suit your personality and clients buying style",
          "Overcoming any fear or asking for the business",
          " Dealing with delayed sales proposals",
          " Practical exercises to practice getting the sale with confidence",
          "Creating a clear vision for yourself using positive psychology",
        ],
      },
      {
        title: "Sales Presentation and Pitching Mastery",
        contents: [
          "How to be more effective and charismatic during sales presentations",
          "How to deal with presentation challenges for individual client meetings vs selling to a procurement team",
          "How to bring separate viewpoints together to still leave with a sale",
          "The elevator pitch",
          " How to present more confidently and describe your products and services using customers needs",
          "Moving from transactional selling to consultative selling",
          "Practical exercises and coaching to help you grow and improve",
        ],
      },
      {
        title: "Relationship Building",
        contents: [
          "Become an a trusted advisor to your client",
          "Using advanced influencing skills to connect to your client and get them to reveal more",
          "Selling across different cultures, code and practices",
          "Understanding your personal brand in sales",
          "Mastering emotional intelligence and positive psychology",
          " Explore psychometric profiling of yourself and clients",
          " Making a plan to increase loyalty and pin that to profitability",
          "Feedback of individual strategy assignment",
        ],
      },
      {
        title: "Dealing with Difficult Clients",
        contents: [
          "Problem clients and handling the effects of their action/inaction",
          " 5 different types of difficult buyers",
          " 5 things you must never do while handling a customer objection",
          " Winning back lost business and raising the stakes",
          "Using refined communication strategies of world’s leading business coaches and entrepreneurs to deal with any problem",
          "Buyer’s expectations of suppliers",
        ],
      },
      {
        title: "Strategic Sales",
        contents: [
          "Motivating yourself and your team to be results focused",
          "Dealing with ‘C Level’ selling – selling to the board",
          "Getting ‘buy in’ for internal stakeholders to improve strategy",
          "Increase conversion ratios and customer feedback ratings",
          "Create a success roadmap",
          "Develop your own personal development plan for post course success",
        ],
      },
    ],
    courseFess: 6080,
    category: "Sales and Marketing",
    courseCode: "SL01",
  },
  {
    id: 120,
    image: Sales2,
    title: "Core Sales Skills - Level 1",
    desc: " Basic sales skills to help you win more customers",
    WhoShouldAttend: [
      "Sales Executives and Sales Representatives",
      "Sales Team members",
      "Sales Account Managers",
      "Employees working as sales support staff and / or sales admin",
      "Customer Services Staff moving into a sales role",
    ],
    courseExpectation: [
      "Develop the key skills of successful sales people",
      "Understand the sales process and improve their sales performance and results",
      "Understand features and benefits of their products / service",
      "How to identify and relate that to client / customer needs",
      "Explore buyer motivation and discover how psychology affects winning or losing a sale",
      "Tailor their selling style to meet any situation and becoming more client / customer focused ",
      "Overcome objections and win over skeptical buyers ",
      "Improve communication and influencing skills",
    ],
    courseContent: [
      {
        title: "Selling – An Art or a Science",
        contents: [
          "Through interactive learning delegates will explore the factors that make excellent sales people",
          "How to raise personal standards in order to encourage profitability",
          "Do you use a ‘hunter’ or a ‘farmer’ selling style ?",
          "The background of selling and defining your role as part of the organization’s mission",
          "How to use persuasion without crossing boundaries",
        ],
      },
      {
        title: "Effective Planning and Prioritizing",
        contents: [
          "Account analysis, planning and time management",
          "How to plan your territory more productively",
          "Prioritizing prospects well to ensure more consistent sales conversion ratio",
          "Meeting and diary management and increasing opportunities for new business",
          "Strategies for hitting and surpassing your targets",
          "Researching into client, the global, market and customer spheres",
          "Identify key trends in the marketplace",
        ],
      },
      {
        title: "Making Lasting Impressions",
        contents: [
          "DTuning in to your client’s mindset and building trust",
          "Generate influence through matching body language and increased personal credibiility",
          "Apply the ‘Aristotle Principle of Persuasion’",
          " Becoming a positive reference top your client and building more loyalty and sales compared to your competitors",
          "Becoming a better communicator using positive unconditional regard(Rogers and Carnegie)",
          "Personal psychometric profiling",
        ],
      },
      {
        title: "Overcoming Objections",
        contents: [
          "How to deal with client objections and still get the sale",
          "7 steps to maintain calm in adverse selling situations",
          " How to use objections as a basis to develop the sale into a mutual beneficial outcome",
          "Maintaining professionalism during the sales process to create a long term focus while gaining short and mid - term wins",
          "Dealing with client excuses of not buying and delaying strategies",
        ],
      },
      {
        title: "Winning the business",
        contents: [
          "10 closing styles to suit your personality and clients buying style",
          "Overcoming any fear or asking for the business",
          "Dealing with delayed sales proposals",
          "Practical exercises to practice getting the sale with confidence",
          "Creating a clear vision for yourself using positive psychology",
        ],
      },
    ],
    courseFess: 4840,
    category: "Sales and Marketing",
    courseCode: "SL02",
  },
  {
    id: 121,
    image: Sales3,
    title: "Core Sales Skills - Level 2",
    desc: " Key skills that will help to increase your sales and boost your figures",
    WhoShouldAttend: [
      "Sales Executives and Account Handlers",
      " Sales Managers, Operations Managers and Account Managers",
      "Customer Service Managers and Relationship Managers",
    ],
    courseExpectation: [
      "Move towards a ‘consultative selling model’ from ‘transactional selling’ ",
      "Build lasting relationship with clients ",
      "Develop key accounts, strategy and plan ",
      "Identify the root causes of issues with clients and offer the best solutions / services.",
      "Mix elements such as influence, product knowledge and people skills to increase success of sales",
      "Use market and competitor knowledge to get the lead and generate the best solutions",
      "Develop strategic customer relationship management",
      "Create a personal development plan",
    ],
    courseContent: [
      {
        title: "Key Principles of Selling",
        contents: [
          "Increasing opportunities for new business",
          "Strategies for hitting and surpassing your targets",
          "Developing streams of income: New business vs.Existing customers",
          "How to deal with client objections and still get the sale",
          "Maintaining professionalism during the sales process to create a long term focus while gaining short and mid- term wins",
          "Apply the ‘Aristotle Principle of Persuasion’",
        ],
      },
      {
        title: "Sales Presentation and Pitching Mastery",
        contents: [
          "How to be more effective and charismatic during sales presentations",
          " How to deal with presentation challenges for individual client meetings vs selling to a procurement team",
          "How to bring separate viewpoints together to still leave with a sale",
          " The elevator pitch",
          "How to present more confidently and describe your products and services using customers needs",
          "Moving from transactional selling to consultative selling",
          " Practical exercises and coaching to help you grow and improve",
        ],
      },
      {
        title: "Relationship building",
        contents: [
          "Become an a trusted advisor to your client",
          "Using advanced influencing skills to connect to your client and get them to reveal more",
          "Selling across different cultures, code and practices",
          " Understanding your personal brand in sales",
          "Mastering emotional intelligence and positive psychology",
          "Explore psychometric profiling of yourself and clients",
          "Making a plan to increase loyalty and pin that to profitability",
          "Feedback of individual strategy assignment",
        ],
      },
      {
        title: "Dealing with Difficult Clients",
        contents: [
          "Problem clients and handling the effects of their action/inaction",
          "5 different types of difficult buyers",
          "5 things you must never do while handling a customer objection",
          "Winning back lost business and raising the stakes",
          " Using refined communication strategies of world’s leading business coaches and entrepreneurs to deal with any problem",
          "Buyer’s expectations of suppliers",
        ],
      },
      {
        title: "Strategic Sales",
        contents: [
          "Motivating yourself and your team to be results focused",
          "Dealing with ‘C Level’ selling – selling to the board",
          "Getting ‘buy in’ for internal stakeholders to improve strategy",
          "Increase conversion ratios and customer feedback ratings",
          "Create a success roadmap",
          "Develop your own personal development plan for post course success",
        ],
      },
    ],
    courseFess: 4840,
    category: "Sales and Marketing",
    courseCode: "SL03",
  },
  // Sales and Marketing

  // Secretarial Pratices
  {
    id: 122,
    image: Sskills1,
    title: "Advanced Dynamic Office Management and Administration ",
    desc: " Significantly improve your day-to-day performance in your Office Management and Administrative roles ",
    WhoShouldAttend: [
      "This course is designed for senior secretaries, executive secretaries, personal assistants and top-level management secretaries aiming to improve their performance and maximise opportunities for promotion to executive positions",
    ],
    courseExpectation: [
      "	Examine the evolving role of the modern secretary",
      "Carry out your duties more efficiently and effectively",
      "Update and raise stands of office management",
      "Align personal performance to organisational vision and success",
      "Develop interpersonal skills and manage excellent teams",
      "Enhance career opportunities and promotion capabilities",
    ],
    courseContent: [
      {
        title: "Office organisation",
        contents: [
          "Practical solution focused management skills",
          "How to support good workflow in the office for employee productivity",
          "How to manage the day-to-day running of the office",
          "Dealing with suppliers and developing good buying relationships",
        ],
      },
      {
        title: "Arranging meetings and taking minutes",
        contents: [
          "	Best practice for handling meetings",
          "The role of teams and teambuilding",
          "Taking accurate minutes and following up",
          "Arranging the environment for best meeting atmosphere",
        ],
      },
      {
        title: "Event management",
        contents: [
          "	Planning, arranging and monitoring events",
          "Coordination and facilitation skills",
          "How to manage different suppliers to deliver a successful event",
          "Managing on time and on budget",
        ],
      },
      {
        title: "Verbal communication skills",
        contents: [
          "	How to communicate clearly and concisely",
          "How to express ideas and core objectives to diverse audiences",
          "Communicating with a confident voice",
        ],
      },
      {
        title: "Written communication skills",
        contents: [
          "	Report writing, structure, theme, and executive summary",
          "Aiming reports to different stakeholders",
          "Email communication best practice",
          "Letter writing skills",
        ],
      },
      {
        title: "Team-working",
        contents: [
          "	Creating winning teams, key roles and team dynamic",
          "How to motivate staff and ensure results",
          "How to create interventions for team conflicts and problems",
          "The pitfalls of underperforming teams and how realign them",
        ],
      },
      {
        title: "Time and stress management",
        contents: [
          "	Emotional intelligence vs. Intelligence Quotient (IQ)",
          "The psychology of wellbeing and performance",
          "How to deal with pressure and stress in the workplace",
          "How to encourage high performance without creating stress",
        ],
      },
      {
        title: "Presentation skills",
        contents: [
          "Delivering professional and memorable presentations",
          "How to write a speech for conferences and events",
          "Proven public speaking tips and techniques for charismatic presentations",
          "Managing nerves and overcoming fear of public speaking",
        ],
      },
      {
        title: "Travel management",
        contents: [
          "Making travel arrangements (i.e. arranging flights, accommodation, visas)",
          "Managing timelines and preparing contingencies",
          "Negotiation with suppliers and getting the best quotes",
          "Researching the best routes for a journey both locally and internationally",
        ],
      },
    ],
    courseFess: 6080,
    category: "Secretarial Pratices",
    courseCode: "SS01",
  },
  {
    id: 123,
    image: Sskills2,
    title: "Senior Secretaries and Personal Assistants' Programme - Level 1 ",
    desc: "Introducing the latest best practice to update your skills and make your job easier and more effective  ",
    WhoShouldAttend: [
      "This course is ideal for Administrators, Office managers, executive secretaries and personal assistants seeking to build on essential practical skills to improve knowledge of office management and administration duties ",
    ],
    courseExpectation: [
      "	Provide high standards of modern office practices and methods",
      "Enhance managerial capabilities and career growth",
      "To manage the work environment to fulfil both organisational success and individual performance",
      "To be able to communicate effectively, handling challenges and duties professionally with confidence",
    ],
    courseContent: [
      {
        title: "Office organisation",
        contents: [
          "	Practical solution focused management skills",
          "How to support good workflow in the office for employee productivity",
          "How to manage the day-to-day running of the office",
          "Dealing with suppliers and developing good buying relationships",
        ],
      },
      {
        title: "Arranging meetings and taking minutes",
        contents: [
          "	Best practice for handling meetings",
          "The role of teams and teambuilding",
          "Taking accurate minutes and following up",
          "Arranging the environment for best meeting atmosphere",
        ],
      },
      {
        title: "Event management",
        contents: [
          "	Planning, arranging and monitoring events",
          "Coordination and facilitation skills",
          "How to manage different suppliers to deliver a successful event",
          "Managing on time and on budget",
        ],
      },
      {
        title: "Verbal communication skills",
        contents: [
          "	How to communicate clearly and concisely",
          "How to express ideas and core objectives to diverse audiences",
          "Communicating with a confident voice",
        ],
      },
      {
        title: "Written communication skills",
        contents: [
          "Report writing, structure, theme, and executive summary",
          "Aiming reports to different stakeholders",
          "Email communication best practice",
          "Letter writing skills",
        ],
      },
    ],
    courseFess: 4840,
    category: "Secretarial Pratices",
    courseCode: "SS02",
  },
  {
    id: 124,
    image: Sskills3,
    title: "Senior Secretaries and Personal Assistants' Programme - Level 2 ",
    desc: "Build on your skills as a personal assistant to further develop your career goals  ",
    WhoShouldAttend: [
      "This course is ideal for Administrators, Office managers, executive secretaries and personal assistants seeking to build on essential practical skills to improve knowledge of office management and administration duties.",
    ],
    courseExpectation: [
      "Provide high standards of modern office practices and methods",
      "Enhance managerial capabilities and career growth",
      "Manage the work environment to fulfil both organisational success and individual performance",
      "Be able to communicate effectively, handling challenges and duties professionally with confidence",
    ],
    courseContent: [
      {
        title: "Office Organisation",
        contents: [
          "	Practical solution-focused management skills ",
          "How to support good workflow in the office for employee productivity",
          "How to manage the day-to-day running of the office",
          "Dealing with suppliers and developing good buying relationships",
        ],
      },
      {
        title: "Presentation Skills",
        contents: [
          "	Delivering professional and memorable presentations",
          "How to write a speech for conferences and events",
          "Proven public speaking tips and techniques for charismatic presentations",
          "Managing nerves and overcoming fear of public speaking",
        ],
      },
      {
        title: "Team-working",
        contents: [
          "	Creating winning teams, key roles and team dynamic",
          "The role of teams and teambuilding",
          "How to motivate staff and ensure results",
          "How to create interventions for team conflicts and problems",
          "The pitfalls of underperforming teams and how realign them",
        ],
      },
      {
        title: "Time and Stress Management",
        contents: [
          "Emotional intelligence vs. Intelligence Quotient (IQ)",
          "The psychology of wellbeing and performance",
          "How to deal with pressure and stress in the workplace",
          "How to encourage high performance without creating stress",
        ],
      },
      {
        title: "Travel Management",
        contents: [
          "Making travel arrangements (i.e. arranging flights, accommodation, visas)",
          "Managing timelines and preparing contingencies",
          "Negotiation with suppliers and getting the best quotes",
          "Researching the best routes for a journey both locally and internationally",
        ],
      },
    ],
    courseFess: 4840,
    category: "Secretarial Pratices",
    courseCode: "SS03",
  },
  // Secretarial Pratices
];
