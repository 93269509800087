import { useTranslation } from "react-i18next";
import TopSection from "../../components/TopSection";

const Policies = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-slate-100 pt-20 lg:pt-36 pb-10 border text-gray-900 ">
      <TopSection title={t("privacyPolicy")} />
      <div className="w-full max-w-[1100px] lg:mx-auto lg:p-4 lg:py-8">
        <article className="mx-auto w-full max-w-[1100px]  format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
          <div className="mt-10">
            <h1 className="text-3xl text-center md:text-xl font-semibold capitalize text-[#003d8c]">
              {t("privacyPolicy")}
            </h1>
            <span>{t("privacyPolicyDisc")}</span>
          </div>
          <div className="px-6">
            <div>
              <h1 className="font-semibold xmb-6 mt-10 text-[#003d8c]">
                {t("Introduction")}
              </h1>
              <span>{t("IntroductionDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("WhatAreYourRights")}
              </h1>
              <span>{t("WhatAreYourRightsDesc")}</span>
              <ul className="list-disc pl-6">
                <li>{t("WhatAreYourRightsDesc1")}</li>
                <li>{t("WhatAreYourRightsDesc2")}</li>
                <li>{t("WhatAreYourRightsDesc3")}</li>
                <li>{t("WhatAreYourRightsDesc4")}</li>
                <li>{t("WhatAreYourRightsDesc5")}</li>
              </ul>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whencollectyourpersonaldata")}
              </h1>
              <ul className="list-disc pl-6">
                <li>{t("WhencollectyourpersonaldataDesc1")}</li>
                <li>{t("WhencollectyourpersonaldataDesc2")}</li>
                <li>{t("WhencollectyourpersonaldataDesc3")}</li>
              </ul>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whatwedowithyourdata")}
              </h1>
              <ul className="list-disc pl-6">
                <li>{t("WhatwedowithyourdataDesc1")}</li>
                <li>{t("WhatwedowithyourdataDesc2")}</li>
                <li>{t("WhatwedowithyourdataDesc3")}</li>
                <li>{t("WhatwedowithyourdataDesc4")}</li>
              </ul>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whydowecollectwedowithit")}
              </h1>
              <span>{t("WhydowecollectwedowithitDesc")}</span>
            </div>
            <div className="mt-10">
              <h1 className="text-3xl md:text-xl font-semibold capitalize text-[#003d8c]">
                {t("Whatdatadowecollect")}
              </h1>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Substitutions")}
              </h1>
              <span>{t("SubstitutionsDescs")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Doweshareyourdatawithanyone")}
              </h1>
              <span>{t("DoweshareyourdatawithanyoneDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whatlegalbasisforkeepingdata")}
              </h1>
              <span>{t("WhatlegalbasisforkeepingdataDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("WhatwouldhappenifIrefusedata")}
              </h1>
              <span>{t("WhatwouldhappenifIrefusedataDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whohasaccesstomyrecords")}
              </h1>
              <span>{t("WhohasaccesstomyrecordsDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("HowrecordswillbekeptforonceIleave")}
              </h1>
              <span>{t("HowrecordswillbekeptforonceIleaveDesc")}</span>
            </div>
            <div className="mt-10">
              <h1 className="text-3xl md:text-xl font-semibold capitalize text-[#003d8c]">
                {t("NewsletterDistribution")}
              </h1>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whatdatadowecollect")}
              </h1>
              <span>{t("WhatdatadowecollectDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Doshareyourdatawith")}
              </h1>
              <span>{t("DoshareyourdatawithDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whatthelegalbasikeepingdata")}
              </h1>
              <span>{t("WhatthelegalbasikeepingdataDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whoaccessmyrecords")}
              </h1>
              <span>{t("WhoaccessmyrecordsDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Howrecordskeptconsent")}
              </h1>
              <span>{t("HowrecordskeptconsentDesc")}</span>
            </div>
            <div className="mt-10">
              <h1 className="text-3xl md:text-xl font-semibold capitalize text-[#003d8c]">
                {t("EnquiriesList")}
              </h1>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whatdatacollect")}
              </h1>
              <span>{t("WhatdatacollectDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Dowesharedatawithanyoneoutside")}
              </h1>
              <span>{t("DowesharedatawithanyoneoutsideDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whathappenrefusedthis")}
              </h1>
              <span>{t("WhathappenrefusedthisDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Whoaccessrecords")}
              </h1>
              <span>{t("WhoaccessrecordsDesc")}</span>
            </div>
            <div>
              <h1 className="font-semibold mt-5 mb-2 text-[#003d8c]">
                {t("Howlongwillwithdraw")}
              </h1>
              <span>{t("HowlongwillwithdrawDesc")}</span>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default Policies;
